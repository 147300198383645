import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import SelectSingle from "../common/selects/select-single";
import { timezone_service } from "../../services/timezoneService";
import Languages from "./languages";
import { SelectOption } from "../common/selects/select-option";
import TourContext from "context/tour/tourContext";
import Switch from "components/common/switch";
import { Routes } from "Routes";
import { useHistory } from "react-router-dom";
import CustomButton from "components/common/buttons/custom-button";
import storage from "services/base/storage";

interface SettingsProps {
  show: boolean;
  onClose?: () => void;
}

const Settings: FunctionComponent<SettingsProps> = ({ show, onClose }) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption>();
  const { runTour, setTour } = useContext(TourContext);
  const history = useHistory();
  const current = timezone_service.getTimezone();

  const Initialize = () => {
    timezone_service
      .getTimezones()
      .then((timezones) => {
        const options_new: SelectOption[] = [];
        timezones.forEach((tz) => {
          options_new.push({
            label: `${tz.name}(${tz.offset})`,
            value: tz.name,
          });
        });
        setOptions(options_new);
      })
      .catch((err) => {
        //console.debug(err);
      });
  };
  const handleChange = (data: SelectOption) => {
    if (data.value) {
      timezone_service.setTimezone(data.value);
      setSelectedOption(data);
    }
  };

  useEffect(() => {
    Initialize();
  }, []);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === current));
  }, [options]);

  return (
    <>
      <Offcanvas placement="end" show={show} onHide={onClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SelectSingle
            label="Timezone"
            options={options}
            value={selectedOption}
            onValueChange={handleChange}
          />

          <hr />

          {storage.isDemo() && (
            <>
              <h4>Tour </h4>
              <CustomButton
                disabled={runTour}
                onClick={() => {
                  setTour(true);
                  history.push(Routes.dashboard);
                  onClose && onClose();
                }}
              >
                Start tour
              </CustomButton>
            </>
          )}

          {/* <Languages /> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default React.memo(Settings);
