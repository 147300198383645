import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Switch from "../../common/switch";

//Component for operation
//recive a data model like this {data.title,data.description,data.completed}
//when the switch change raise event

interface User {
  email: string;
  first_name: string;
  last_name: string;
  username: string;
}
export interface OperationData {
  id: number;
  scheduled_maintenance_id: number;
  created_at: Date;
  updated_at: Date;
  order: number;
  title: string;
  description: string;
  completed: boolean;
  user: User;
}

type Props = {
  data: OperationData;
  disabled?: boolean;
  onOperationChange: (data: OperationData, checked: boolean) => void;
};

const Operation: React.FunctionComponent<Props> = ({
  data,
  disabled,
  onOperationChange,
}) => {  
  return (
    <React.Fragment>
      <Row>
        <p>
          <b>{data && data.title && data.title}</b>
          {data && data.user && data.completed && (
            <span>
              {" "}
              (
              {data.user.first_name && data.user.last_name
                ? data.user.first_name + " " + data.user.last_name
                : data.user.email}
              )
            </span>
          )}
        </p>
      </Row>
      <Row>
        <Col xl={10} lg={10} md={10} sm={10} xs={10}>
          <span style={{ tabSize: 5 }}>
            {data && data.description && data.description}
          </span>
        </Col>

        <Col xl={2} lg={2} md={2} sm={2} xs={2}>
          <Switch
            disabled={disabled}
            style={{
              marginTop: "-15px",
              // cursor: "pointer",
              // height: "20px",
              // width: "40px",
            }}
            name={data && data.id}
            error={null}
            label=""
            checked={data && data.completed}
            onchange={({ currentTarget: input }: any, checked: boolean) =>
              onOperationChange(data, checked)
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Operation;
{
}
