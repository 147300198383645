import streamSaver from "streamsaver";
import { report_demo_url } from "demo/config";


export class FileSaverDemo {
  SaveFromUrl2(url: string,filename:string) {
      return new Promise<any>((resolve, reject) => {
        reject("The download option is not allowed on demo");
      });
    }

    SaveFromUrl = (url:string, fileName:string) => {
      const result = new Promise((resolve, reject) => {        
        const fileStream = streamSaver.createWriteStream(fileName);        
        fetch(report_demo_url)
          .then((res) => {
            if (res.status === 200) {
              const readableStream = res.body;
              // more optimized
              if (window.WritableStream && readableStream?.pipeTo) {
                return readableStream
                  .pipeTo(fileStream)
                  .then(() => resolve("done writing"))
                  .catch((err) => console.error(err));
              }
            } else {
              reject("Error file not found");
            }
             var writer = fileStream.getWriter()
            const reader = res.body?.getReader()
            const pump:any = () => reader?.read()
              .then(res => res.done
                ? writer.close()
                : writer.write(res.value).then(pump))
            pump()
          })
          .catch((err) => reject(err));
      });
      return result;
    };
  }
  