import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Moment } from "moment";
import { IFindOption } from "../../../components/features/plot-common/find-options";
import { SelectOption } from "../../../components/common/selects/select-option";
import { RootState } from "../../configureStore";
import { TimeSeries } from "../../../services/statistic/statistic-plot-service";


// Define a type for the slice state
interface StructState {
  search_by: IFindOption;
  dates?: { start_date: string; end_date: string };
  signal_type?: string;
  struct_selected?: number;  
  structures_selected?: SelectOption[];
  signals?:TimeSeries[]
}

// Define the initial state using that type
const initialState: StructState = {
  search_by: "category",
};

export const analyticsDefaultSlice = createSlice({
  name: "analytics-default",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveSearchBy(state, action: PayloadAction<IFindOption>) {
      state.search_by = action.payload;
    },
    saveDates(state, action: PayloadAction<any>) {
      state.dates = action.payload;
    },

    saveStruct(state, action: PayloadAction<number>) {
      state.struct_selected = action.payload;
    },
    saveSignalType(state, action: PayloadAction<string>) {
      state.signal_type = action.payload;
    },
    saveStructuresSelected(state, action: PayloadAction<SelectOption[]>) {
      state.structures_selected = action.payload;
    },
    saveAnalyticSignals(state,action: PayloadAction<TimeSeries[] | undefined>){
      state.signals = action.payload;
    }
  },
});

export const {
  saveSearchBy,
  saveDates,
  saveStruct,
  saveSignalType,
  saveStructuresSelected,
  saveAnalyticSignals
} = analyticsDefaultSlice.actions;

export const getAnalyticsDefaultState = (state: RootState) => state.analytics_default;

export default analyticsDefaultSlice.reducer;
