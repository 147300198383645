import { maintenance_scheduled_data } from "./data";
import { BaseServiceGenericDemo } from "../../base-service-demo";
import { IMaintenanceScheduled } from "../../../services/maintenance/scheduled/scheduled-service";

export class MaintenanceScheduledDemo extends BaseServiceGenericDemo<IMaintenanceScheduled> {
  constructor() {
    super();
    this.data = maintenance_scheduled_data.getData();
  }
}
