import { AxiosError } from "axios";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";

interface IPid {
  id: number;
  name: string;
  description: string;
}

export interface ITransient {
  duration?: number;
  threshold?: number;
  day_to_threshold?: any;
  monotony?: any;
  threshold_approaching?: any;
  unit_of_measurement: string;
  unfinished?: any;
}

export interface IStationary {
  default?: IDefault;
  peaks_number?: IPeaksNumber;
  peaks_time?: IPeaksTime;
}

interface IDefault {
  avg: number;
  threshold: number;
  day_to_threshold?: any;
  monotony?: any;
  threshold_approaching?: any;
  unit_of_measurement?: any;
}

interface IPeaksNumber {
  n_peaks_out_of_threshold: number;
  n_peaks_worst_window: number;
  n_peaks_threshold: number;
}

interface IPeaksTime {
  n_peaks_time_above_out_of_threshold: number;
  times_above_worst_window: number;
  peaks_time_above_threshold: number;
  unit_of_measurement: string;
}

interface ITypeId {
  id: number;
  created_at: Date;
  updated_at: Date;
  description: string;
  type: string;
}

interface IStruct {
  id: number;
  name: string;
  description: string;
  processing: string;
  type_id: ITypeId;
}

export interface IResultPidInfo {
  transient?: ITransient;
  stationary?: IStationary;
  reference_period: Date;
  resample_rule?: any;
}

export interface IPidNotification {
  id: number;
  updated_at: Date;
  type: string;
  pid: IPid;
  mail_sent_at?: any;
  is_sent: boolean;
  is_read: boolean;
  comment?: any;
  user?: any;
  is_incorrect: boolean;
  result_info: IResultPidInfo;
  struct: IStruct;
}

class NotificationPidService {
  private http: IHttp = Http;
  private endpoint = "notifications/warning/pid";

  private getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<IPidNotification[]>);
        })
        .catch((response: Error | AxiosError<any>) => {
          reject(response);
        });
    });
  }

  private generateParams(query_params: Record<string, any> = {}): string {
    let params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IPidNotification[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(this.http.get(this.endpoint + "/" + "?" + params));
  };

  // updateFeedback(data: ISaveValveNotification): Promise<ResponseWrapper<any>> {
  //   return this.getPromise(this.http.post(this.endpoint + "/", data));
  // }
}

export const notification_pid_service = new NotificationPidService();
