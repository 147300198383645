
//curryng functions
const logger = (store:any) => (next:any) => (action:any) => {
// console.debug("store",store)
// console.debug("next",next)
// console.debug("action",store)
next(action);
};

export default logger;
