import Http from "../base/http-services";
import { ResponseWrapper } from "../../models/reponse";
import { BaseService } from "../base/base-service";
import storage from "../base/storage";
import { WarningNotificationDemo } from "../../demo/notification/notification-default-demo";

interface User {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface IResultInfo {
  id: number;
  threshold_max?: number;
  threshold_min?: number;
  mean?: number;
  last_value?: any;
  result_date: string;
}

interface TypeId {
  id: number;
  created_at: Date;
  updated_at: Date;
  description: string;
  type: string;
}

interface Struct {
  id: number;
  name: string;
  description: string;
  type_id: TypeId;
}

interface Variable {
  id: number;
  itemid: string;
  unit_of_measurement: string;
  description: string;
  type: string;
  value: number;
  operation_type: string;
  discard_min: number;
  discard_max: number;
  threshold_min?: any;
  threshold_max: number;
  default_avg?: any;
  is_plot: boolean;
  is_stats: boolean;
}

export interface INotification {
  id: number;
  created_at: Date;
  updated_at: Date;
  type: string;
  day_to_threshold?: any;
  monotony?: any;
  threshold_approaching?: any;
  mail_sent_at?: any;
  is_sent?: any;
  is_read: boolean;
  comment: string;
  user: User;
  is_incorrect: boolean;
  result_info: IResultInfo;
  struct: Struct;
  variable: Variable;
}

export interface ISaveNotification {
  id: number;
  comment: string;
  is_incorrect: boolean;
  is_read: boolean;
}

const endpoint = "notifications/warning";

class WarningNotificationService extends BaseService<INotification> {
  constructor() {
    super(endpoint, Http);
  }

  updateFeedback(data: ISaveNotification): Promise<ResponseWrapper<INotification>> {
    return this.getPromise(this.http.post(this.endpoint + "/", data));
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new WarningNotificationDemo()
    : new WarningNotificationService();

export const warning_notification_service = instance;
