import { FunctionComponent } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { IMaintenanceUsedModel } from "../../../../services/maintenance/usage-based/usage-based-service";
import { createUseStyles } from "react-jss";

interface CardProgressProps {
  maintenance: IMaintenanceUsedModel; //TODO Type
  onClick?: (maintenace: any) => void;
}

const styleCircularProgressBar = (value: any) => {
  return buildStyles({
    textColor: "#fff",
    pathColor: "#fff",
    trailColor: "transparent",
    backgroundColor:
      value == null || value == 0 ? "grey" : value > 90 ? "#fc766d" : "#86d189",
  });
};

const useStyles = createUseStyles({
  cardHover: () => ({
    maxWidth:"350px",
    transition: "all ease 200ms",
    "&:hover": {
      transform: "scale(1.03)",
      //   boxShadow:
      //     "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
  }),
});

const CardProgress: FunctionComponent<CardProgressProps> = ({
  maintenance,
  onClick,
}) => {
  const classes = useStyles();
  if (!maintenance) return null;
  const {
    current_value,
    title,
    expiration,
    monitored_variable,
    is_seconds,
    forecast_deadline_date,
    forecast_suggestion_date,
  } = maintenance;

  const percIncrease = (maxValue: any, actualValue: any) => {
    const res = ((maxValue - actualValue) / maxValue) * 100.0;
    const percentuale: string = (100 - res).toFixed(2);
    if (parseInt(percentuale) >= 100) return 100;
    return percentuale;
  };
  const perStats = percIncrease(expiration, current_value);
  return (
    <Row className={classes.cardHover + " mb-2"}>
      <Col
        style={{ cursor: "pointer" }}
        onClick={() => onClick && onClick(maintenance)}
      >
        <div className="bg-white p-3 shadow" style={{ borderRadius: "1rem" }}>
          <h2 className="h5 font-weight-bold text-center mb-3">{title}</h2>

          <div style={{ width: "7rem", margin: "auto" }}>
            <CircularProgressbar
              minValue={0}
              background
              backgroundPadding={6}
              maxValue={expiration}
              value={current_value ? current_value : 0}
              text={perStats + "%"}
              styles={styleCircularProgressBar(perStats)}
            />
          </div>

          <Row className="text-center mt-4">
            <Col xs={6} className=" border-right">
              <h4 className="font-weight-bold mb-0">
                {" "}
                {current_value ? current_value : "N/A"}{" "}
                {is_seconds
                  ? "hrs"
                  : monitored_variable &&
                    monitored_variable.unit_of_measurement}
              </h4>
              <span className="small text-gray">Current value</span>
            </Col>
            {/* <Col xs={2} className="d-flex justify-content-center">
              <div className="vr"></div>
            </Col> */}
            <Col xs={6}>
              <h4 className="font-weight-bold mb-0">
                {expiration ? expiration : "N/A"}{" "}
                {is_seconds
                  ? "hrs"
                  : monitored_variable &&
                    monitored_variable.unit_of_measurement}
              </h4>
              <span className="small text-gray">Expiration</span>
            </Col>
          </Row>

          <Row className="text-center mt-4">
            <Col xs={6} className=" border-right">
              <h4 className="font-weight-bold mb-0">
                {forecast_deadline_date ? forecast_deadline_date : "N/A"}
              </h4>
              <span className="small text-gray">Deadline date</span>
            </Col>
            {/* <Col xs={2} className="d-flex justify-content-center">
              <div className="vr"></div>
            </Col> */}
            <Col xs={6}>
              <h4 className="font-weight-bold mb-0">
                {forecast_suggestion_date ? forecast_suggestion_date : "N/A"}
              </h4>
              <span className="small text-gray"> Suggestion date</span>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
    // </Container>
  );
};

export default CardProgress;
