import { ElementType, FunctionComponent, useContext } from "react";
import Joyride, { BeaconRenderProps, CallBackProps, STATUS, Step } from "react-joyride";
import { Routes } from "Routes";
import { useHistory } from "react-router-dom";
import ThemeContext from "./../context/theme/themeContext";
import { emitter } from "services/eventBus/emitter";
import TourContext from "context/tour/tourContext";

interface TourProps {
  run?: boolean;
  steps: Step[];
  redirectTo?: Routes;
  onTourFinish?: () => void;
}

//NEED TO DO A CONTEXT FOR MANIPULATE THE RUN

const Tour: FunctionComponent<TourProps> = ({
  run = true,
  steps,
  redirectTo,
  onTourFinish,
}) => {
  const { theme } = useContext(ThemeContext);
  const { runTour, setTour } = useContext(TourContext);
  const history = useHistory();
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (
      type === "tour:end" &&
      status != STATUS.SKIPPED /* or step.target === '#home' */
    ) {
      if (redirectTo) {
        history.push(redirectTo);
      }
      onTourFinish && onTourFinish();
    }

    if (status === STATUS.SKIPPED) {
      setTour(false);
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      disableOverlayClose
      disableCloseOnEsc
      continuous      
      disableScrolling
      beaconComponent={CustomBeacon}  //disabled floater     
      //hideCloseButton
      //showProgress // with this button the custom click not always work
      run={runTour}
      scrollToFirstStep
      showSkipButton
      hideBackButton
      steps={steps}
      locale={{
        skip: <strong aria-label="skip">SKIP</strong>,
        last: "Continue",
      }}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: theme.primary,
        },
      }}
    />
  );
};

export default Tour;


 
const CustomBeacon: ElementType<BeaconRenderProps> = ({}) => {
  return ( <></> );
}
 
