import { FunctionComponent, useEffect, useState } from "react";

import { IUser, IUserGroup } from "../../../../services/users/user-service";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import AppForm from "../../../formik-forms/AppForm";
import * as Yup from "yup";
import { FormikHelpers, FormikValues } from "formik";
import { SelectOption } from "../../../common/selects/select-option";
import FormText from "../../../formik-forms/FormText";
import FormSelect from "../../../formik-forms/FormSelect";
import FormSwitch from "../../../formik-forms/FormSwitch";
import FormSubmit from "../../../formik-forms/FormSubmit";
import CloseButton from "../../../common/buttons/close-button";
import Backdrop from "../../../common/backdrop/backdrop";
import withLayout from "../../../../hoc/withLayout";
import { useGetUserGroups } from "../../../../queries/user.groups.hook";
import { useGetByIdUser, useSaveUsers } from "../../../../queries/user.hook";
interface FormUserProps {}

const FormUser: FunctionComponent<FormUserProps> = () => {
  const [groups, setGroups] = useState<SelectOption[]>([]);
  const [user, SetUser] = useState<IUser>(); // for modify the user
  const history = useHistory();
  let { id } = useParams<any>();

  const getGroups = useGetUserGroups();

  const saveUser = useSaveUsers();
  const userById = useGetByIdUser();

  const mapGroups = (data: IUserGroup[] | undefined) => {
    const groupsOptions: SelectOption[] = [];
    data?.map((group) => {
      groupsOptions.push({ label: group.name, value: group.name });
    });
    setGroups(groupsOptions);
  };

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      console.debug(values);
      values.profile.gender =
        parseInt(values.profile.gender) != null
          ? parseInt(values.profile.gender)
          : values.profile.gender;

      saveUser.mutate(values);
    } catch (error: any) {}
  };

  const checkId = async () => {
    try {
      if (id === "new" || id === undefined) {
        SetUser({} as IUser);
        return;
      }
      const user = await userById.mutateAsync(id);

      if (!user) return history.replace("/not-found");

      SetUser(user.response);
    } catch (error: any) {
      history.replace("/404");
    }
  };

  const password_requirements = [
    { id: 1, name: "Your password must contain at least 8 characters." },
    {
      id: 2,
      name: "Your password can’t be too similar to your other personal information.",
    },
    { id: 3, name: "Your password can’t be a commonly used password." },
    { id: 4, name: "Your password can’t be entirely numeric." },
  ];

  const genders: SelectOption[] = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "A", label: "Other" },
  ];

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    username: Yup.string().required().label("Username"),
    email: Yup.string().email().required().label("Email"),
    first_name: Yup.string()
      .required()
      .matches(/^[a-zA-Z\s]+$/)
      .label("First name"),
    last_name: Yup.string()
      .required()
      .matches(/^[a-zA-Z\s]+$/)
      .label("Last name"),
    report_notification: Yup.bool()
      .required()
      .label("Report Notifications")
      .default(false),
    warning_notification: Yup.bool()
      .required()
      .label("Warning Notifications")
      .default(false),
    trend_notification: Yup.bool()
      .required()
      .label("Anomaly Trend Notifications")
      .default(false),
    maintenance_notification: Yup.bool()
      .required()
      .label("Expired Maintenance Notifications")
      .default(false),
    is_active: Yup.bool().label("active").default(false),
    is_staff: Yup.bool().label("active").default(false),
    confirm_password: Yup.string().label("confirm password"),
    password: Yup.string().label("password"),
    profile: Yup.object({
      address: Yup.string().nullable().min(0).label("Address"),
      gender: Yup.string().nullable().min(0).label("Gender"),
      phone: Yup.string()
        .matches(
          /^\+(?:[0-9]●?){3,15}[0-9]$/,
          "Phone must have the code of country (+39) and number of max of 15 numbers example: +155566685"
        )
        .nullable()
        .min(0)
        .label("Phone"),
      city: Yup.string().nullable().min(0).label("City"),
      zip: Yup.number().nullable().label("ZipCode"),
    }),
  });

  useEffect(() => {
    checkId();
  }, []);

  useEffect(() => {
    mapGroups(getGroups.data?.response);
  }, [getGroups.data]);

  return (
    <Container>
      <Backdrop show={saveUser.isLoading || getGroups.isLoading} />
      <Card className="p-3">
        {<h3>{id === "new" ? "New" : "Modify"}</h3>}
        <hr />
        {user && (
          <AppForm
            validationSchema={validationSchema}
            initialValues={{
              id: user?.id,
              username: user?.username,
              email: user?.email,
              first_name: user?.first_name,
              last_name: user?.last_name,
              group: {
                name: user && user?.group?.name,
              },
              profile: {
                gender: user && user.profile?.gender,
                address: user && user.profile?.address,
                phone: user && user.profile?.phone,
                zip: user && user.profile?.zip,
                city: user && user.profile?.city,
              },
              is_staff: user && user.is_staff,
              report_notification: user && user.report_notification,
              warning_notification: user && user.warning_notification,
              trend_notification: user && user.trend_notification,
              maintenance_notification: user && user.maintenance_notification,
            }}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <FormText
                  name="username"
                  label="Username"
                  placeholder="Insert username..."
                />
                <FormText
                  name="email"
                  label="Email"
                  placeholder="Insert email..."
                />
                <FormText
                  name="first_name"
                  label="First name"
                  placeholder="Insert first name..."
                />
                <FormText
                  name="last_name"
                  label="Last name"
                  placeholder="Insert last name..."
                />
              </Col>
              <Col>
                <FormText
                  name="password"
                  label="Password"
                  placeholder="Insert password..."
                  type="password"
                />

                <FormText
                  name="confirm_password"
                  label="Repeat Password"
                  placeholder="Repeat password..."
                  type="password"
                />
                <ul>
                  {password_requirements.map((requirement) => (
                    <li key={requirement.id}>{requirement.name}</li>
                  ))}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Profile</h4>
                <hr />
                <FormText
                  name="profile.address"
                  label="Address"
                  placeholder="Insert Address..."
                />
                <FormSelect
                  placeholder="Select Gender"
                  label="Gender"
                  name="profile.gender"
                  options={genders}
                />

                <FormText
                  name="profile.phone"
                  label="Phone"
                  placeholder="Insert Phone..."
                />
                <FormText
                  name="profile.city"
                  label="City"
                  placeholder="Insert City..."
                />
                <FormText
                  name="profile.zip"
                  label="Zip"
                  placeholder="Insert Zip..."
                />
                <br />
              </Col>
              <Col>
                <h4>Advance Option</h4>
                <hr />
                <FormSelect
                  placeholder="Select permissions"
                  label="Permissions"
                  name="group.name"
                  options={groups}
                />
                <FormSwitch name={"is_staff"} label={"Is staff"} />
                <FormSwitch
                  name={"report_notification"}
                  label={"Can recive report notitification"}
                />
                <FormSwitch
                  name={"warning_notification"}
                  label={"Can recive warning notification"}
                />
                <FormSwitch
                  name={"trend_notification"}
                  label={"Can recive trend notification"}
                />
                <FormSwitch
                  name={"maintenance_notification"}
                  label={"Can recive maintenance notification"}
                />
              </Col>
            </Row>
            <CloseButton label="Close" onClick={() => history.goBack()} />
            <span className="me-2"></span>
            <FormSubmit
              title="Save changes"
              //disabled={isCompleted(maintenance_info)}
              //disabled={profile_api.loading}
              //                  icon={}
            />
          </AppForm>
        )}
      </Card>
    </Container>
  );
};

export default FormUser;
