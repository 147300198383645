import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { MdHelpOutline } from "react-icons/md";
import { BsCircleFill } from "react-icons/bs";

import SelectSingle from "../../common/selects/select-single";
import { SelectOption } from "../../common/selects/select-option";

export type graphMode = "lines" | "markers" | "lines+markers";

type graphType = "single" | "multiple";
interface PlotBarAdvanceProps {
  /**
   * Select the drawing mode of the graph
   */
  selectedGraphMode: graphMode;
  isRefreshing: boolean;
  selectedGraphByRow: any;
  /**
   * /To defined
   */
  selectedRefreshOption?: SelectOption;
  /**
   * //Graph mode passing {currentTarget:input}
   */
  onChangeGraphMode?: (value?: graphMode) => void;
  /**
   * "value" the number of columns by row.
   */
  onChangeGraphByRow?: (value?: string) => void;
  /**
   * "value if the interval of refresh: "off | number.
   */
  onChangeAutoRefresh?: (value?: SelectOption) => void;
  /**
   *
   * The graph type is single or multiple.
   */
  onChangeGraphType?: (data?: string) => void;
}

const PlotBarAdvance: React.FunctionComponent<PlotBarAdvanceProps> = ({
  selectedGraphMode,
  selectedGraphByRow,
  selectedRefreshOption,
  isRefreshing,
  onChangeGraphMode,
  onChangeGraphByRow,
  onChangeAutoRefresh,
  onChangeGraphType,
}) => {
  const [graphMode, setGraphMode] = useState<graphMode>(selectedGraphMode);
  const [graphType, setGraphType] = useState<graphType>("single");
  const [graphByRow, setGraphByRow] = useState(selectedGraphByRow);
  const [autoRefresh, setAutoRefresh] = useState(selectedRefreshOption);

  const [refresh, setRefresh] = useState(isRefreshing);

  useEffect(() => {
    setRefresh(isRefreshing);
  }, [isRefreshing]);

  useEffect(() => {
    setAutoRefresh(undefined);
  }, [undefined]);

  useEffect(() => {
    setGraphMode(selectedGraphMode);
  }, [selectedGraphMode]);

  useEffect(() => {
    setGraphByRow(selectedGraphByRow);
  }, [selectedGraphByRow]);

  useEffect(() => {
    setAutoRefresh(selectedRefreshOption);
  }, [selectedRefreshOption]);

  console.debug("Render Plotbar Advance.jsx");

  interface RowOption {
    id: number;
    rows: number;
    label: string;
  }
  interface CheckboxOption<T> {
    label: string;
    value: T;
  }
  interface AutoRefreshOption {
    id: number;
    option: number;
    label: string;
  }
  const graphModeOptions: CheckboxOption<graphMode>[] = [
    { label: "Lines and markers", value: "lines+markers" },
    { label: "Lines", value: "lines" },
    { label: "Markers", value: "markers" },
  ];

  const graphByRowOptions: SelectOption[] = [
    { value: "1", label: "1 Column" },
    { value: "2", label: "2 Columns" },
    { value: "3", label: "3 Columns" },
  ];
  const autoRefreshOptions: SelectOption[] = [
    { value: "off", label: "Off" },
    //{ value: "0.15", label: "15 seconds", color: "green" },
    { value: "0.3", label: "30 seconds", color: "green" },
    { value: "1", label: "1 minute", color: "green" },
    { value: "3", label: "3 minutes", color: "green" },
    { value: "5", label: "5 minutes", color: "green" },
    { value: "10", label: "10 minutes", color: "green" },
  ];

  const graphTypeOptions: CheckboxOption<graphType>[] = [
    { label: "Single", value: "single" },
    { label: "Multiple", value: "multiple" },
  ];
  return (
    <React.Fragment>
      <ReactTooltip
        place="right"
        type="light"
        html={true}
        border={false}
        backgroundColor={"#6c757d"}
        textColor={"white"}
        arrowColor={"#6c757d"}
        effect="solid"
      />
      <Row className="mt-3">
        <Col sm={3}>
          <h5>
            <b>Advanced options</b>
          </h5>
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center">
        <Col xs={6} sm={12} md={3} lg={2} className="tour_columns">
          <SelectSingle
            dot
            label={
              <>
                Columns:
                <a
                  data-tip={
                    "<b> Info </b><br/>Define how many graph will be display by row. <b>In small devices the value will be always 1 column.</b>"
                  }
                >
                  <MdHelpOutline style={{ fontSize: "16px" }}></MdHelpOutline>
                </a>
              </>
            }
            value={
              graphByRow && graphByRowOptions.find((x) => x.value == graphByRow)
            }
            options={graphByRowOptions}
            onValueChange={(option: SelectOption) => {
              setGraphByRow(option.value);
              onChangeGraphByRow && onChangeGraphByRow(option.value);
            }}
          />
        </Col>

        <Col xs={6} sm={12} md={6} lg={2} className="tour_graphmode">
          <SelectSingle
            dot
            label={
              <React.Fragment>
                Mode:
                <a
                  data-tip={
                    "<b> Graph mode </b><br/> Define the drawing mode for the graph. "
                  }
                >
                  <MdHelpOutline style={{ fontSize: "15px" }} />
                </a>
              </React.Fragment>
            }
            value={
              graphModeOptions
                ? graphModeOptions.find((x) => x.value == graphMode)
                : { value: undefined, label: "Off" }
            }
            options={graphModeOptions}
            onValueChange={(option) => {
              setGraphMode(option.value as graphMode);
              onChangeGraphMode && onChangeGraphMode(option.value as graphMode);
            }}
          />
        </Col>

        <Col xs={6} sm={12} md={3} lg={2} className="tour_graphtype">
          <SelectSingle
            dot
            label={
              <React.Fragment>
                Type:
                <a
                  data-tip={
                    "<b> Graph type </b><br/> " + "Define the type of graph. "
                  }
                >
                  <MdHelpOutline style={{ fontSize: "15px" }} />
                </a>
              </React.Fragment>
            }
            value={
              graphTypeOptions
                ? graphTypeOptions.find((x) => x.value == graphType)
                : { value: undefined, label: "Off" }
            }
            options={graphTypeOptions}
            onValueChange={(option) => {
              setGraphType(option.value as graphType);
              onChangeGraphType && onChangeGraphType(option.value);
            }}
          />
        </Col>

        <Col xs={10} sm={10} md={3} lg={2} className="tour_autorefresh">
          <SelectSingle
            dot
            label={
              <React.Fragment>
                Auto Refresh:
                <a
                  data-tip={
                    "<b> Autmatic refresh </b><br/> When the interval is set the graph will be automatic refresh with the last datetime"
                  }
                >
                  <MdHelpOutline style={{ fontSize: "15px" }} />
                </a>
              </React.Fragment>
            }
            value={
              autoRefresh
                ? autoRefreshOptions.find((x) => x.value == autoRefresh.value)
                : { value: "off", label: "Off" }
            }
            options={autoRefreshOptions}
            onValueChange={(option) => {
              //setAutoRefresh(option.value);
              onChangeAutoRefresh && onChangeAutoRefresh(option);
            }}
          />
        </Col>
        <Col
          style={{ marginLeft: 0, marginTop: 20 }}
          xs={2}
          sm={2}
          md={1}
          lg={1}
        >
          <BsCircleFill
            size={25}
            color={
              (autoRefresh && autoRefresh.value === "off") || !autoRefresh
                ? "grey"
                : refresh
                ? "green"
                : "orange"
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default React.memo(PlotBarAdvance);
