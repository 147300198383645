import { ResponseWrapper } from "../../models/reponse";
import { IResponse } from "../../services/plot/plot-struct-service";
import { plot_demo_data } from "./data";

type IData = {
  variables: number[];
  start_date: string;
  end_date: string;
  timezone?: string;
  bucket?: string;
};

export class PlotVariableDemo {
  getMany(data: IData): Promise<ResponseWrapper<IResponse>> {
    return new Promise<any>((resolve, reject) => {
        const plot_category_struct = plot_demo_data.getData();    
        const response: ResponseWrapper<IResponse> = {
            status: 200,
            version: "demo",
            response: plot_category_struct,
          } as ResponseWrapper<IResponse>;
          resolve(response);
    });
  }
}
