import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { MdArrowUpward,MdArrowDownward,MdHelp } from "react-icons/md";
import ReactTooltip from "react-tooltip";

const onlyTwoDecimal = (number) => {
  if (number && !Number.isSafeInteger(number)) {
    return number.toFixed(2);
  }

  return number;
};

const compareAvgWithRef = (item, column) => {
  //el item a comparar es null ,previamente ya fue verificado.
  //el item es mayor
  // el item es menor

  //Compare Average with Ref
  //Its possible to compare differents attr to.
  var columnToCompare = _.get(item, column.compare);
  var columnKey = _.get(item, column.key);

  if (!columnToCompare) {
    return onlyTwoDecimal(columnKey); // essite però è null, return valore senza freccia.
  }

  if (columnToCompare && columnToCompare < columnKey) {
    return (
      <React.Fragment>
        {onlyTwoDecimal(columnKey)}
        <MdArrowUpward style={{ color: "green" }} />
      </React.Fragment>
    );
  } else if (columnToCompare && columnToCompare > columnKey) {
    return (
      <React.Fragment>
        {onlyTwoDecimal(columnKey)}
        <MdArrowDownward style={{ color: "red" }} />
      </React.Fragment>
    );
  } else {
    //Da fare il uguale??
    return onlyTwoDecimal(columnKey);
  }
};

const TableStas = ({ signal, columns }) => {
  return (
    <React.Fragment>
      <ReactTooltip
        place="top"
        type="light"
        html={true}
        border={true}
        effect="solid"
      />
      <table className="table text-center table-sm table-responsive table-bordered">
        <thead style={{ alignItems: "center" }}>
          <tr>
            {columns &&
              columns.map((column) => (
                <th key={column && column.key && column.key}>
                  {column && column.label && column.label} &nbsp;
                  {column && column.tooltip && (
                    <a data-tip={"<b> Info </b><br/>" + column.tooltip + ""}>
                      <MdHelp style={{ fontSize: "15px" }}></MdHelp>
                    </a>
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {signal &&
            signal.map((item) => (
              <tr key={item}>
                {columns &&
                  columns.map((column) => (
                    <td
                      className="text-wrap"
                      key={column.key}
                      style={
                        column &&
                        column.color && { backgroundColor: column.color }
                      }
                    >
                      {column && column.compare && column.key
                        ? compareAvgWithRef(item, column)
                        : onlyTwoDecimal(_.get(item, column.key))}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default TableStas;
