import React, { useState, useEffect, FunctionComponent } from "react";
import { Row, Col } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { GroupBase } from "react-select";
import Datetime, { ViewMode } from "react-datetime";
import Button from "react-bootstrap/Button";
import moment, { Moment } from "moment";
import {
  isLessThatTodayAndDate,
  isLessThatTodayAndIsAfterDate,
} from "../../../utils/date-operations";
import _ from "lodash";
import SelectSingle from "../../common/selects/select-single";
import { SelectOption } from "../../common/selects/select-option";

interface PlotBarAnalyticsProps {
  options: GroupBase<SelectOption>[];
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  selectedStartDate: Moment;
  onStartDate?: (date: any) => void | undefined;
  selectedEndDate: Moment;
  onEndDate?: (date: any) => void | undefined;
  selectedStruct: number;
  onStructChange?: (data: SelectOption) => void;
  timeEnabled: boolean;
  isInAutoRefresh: boolean;
}

const PlotBarAnalytics: FunctionComponent<PlotBarAnalyticsProps> = ({
  options,
  onClick,
  selectedStartDate,
  onStartDate,
  selectedEndDate,
  onEndDate,
  selectedStruct,
  onStructChange,
  isInAutoRefresh = false,
  timeEnabled = true,
}) => {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(7, "d")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const [endDate, setEndDate] = useState(moment());
  const [structId, setStructId] = useState(selectedStruct);
  const [disabledTo, setDisabledTo] = useState(isInAutoRefresh);
  console.debug("Render Plotbar.jsx");

  useEffect(() => {
    setDisabledTo(isInAutoRefresh);
  }, [isInAutoRefresh]);

  useEffect(() => {
    setStructId(selectedStruct);
  }, [selectedStruct]);

  useEffect(() => {
    setStartDate(selectedStartDate);
  }, [selectedStartDate]);

  useEffect(() => {
    setEndDate(selectedEndDate);
  }, [selectedEndDate]);

  const findMotor = () => {
    var group_value = undefined;
    options.forEach((io) => {
      var prospect = io.options.find((o: any) => o.value == structId);
      if (prospect !== undefined) {
        group_value = prospect;
      }
    });
    return group_value;
  };

  const isSameDate = () => {
    if (startDate === undefined) return false;
    if (endDate === undefined) return false;
    const start = moment(startDate);
    return start.isSame(endDate, "days");
  };

  const isSameHour = () => {
    if (startDate === undefined) return false;
    if (endDate === undefined) return false;
    const start = moment(startDate);
    const end = moment(endDate);
    return start?.hour() === end?.hour() && start.isSame(end, "days");
  };
  console.debug("render-plot-bar");
  return (
    <React.Fragment>
      <Row>
        <Col lg={7} md={3} xs={12}>
          <label>Structure:</label>
          <SelectSingle
            // menuPortalTarget={document.querySelector('main')}
            value={findMotor()}
            options={options}
            onValueChange={(data: SelectOption) => {
              onStructChange && onStructChange(data);
              setStructId(data.value ? parseInt(data.value) : 0);
            }}
          />
        </Col>

        <Col lg={2} md={3} xs={6}>
          <label>From:</label>
          <Datetime
            isValidDate={(current, selected) =>
              isLessThatTodayAndDate(current, endDate)
            }
            initialValue={startDate}
            value={startDate}
            inputProps={{
              placeholder: "From",
              style: {
                height: 35,
              },
            }}
            timeFormat={timeEnabled ? "HH:mm" : false}
            onChange={(date: any) => {
              setStartDate(date);
              onStartDate && onStartDate(date);
            }}
          />
        </Col>

        <Col lg={2} md={3} xs={6}>
          <label>To:</label>
          <Datetime
            isValidDate={(current, selected) =>
              isLessThatTodayAndIsAfterDate(current, startDate)
            }
            timeConstraints={{
              hours: {
                min:
                  startDate && endDate && isSameDate()
                    ? startDate.hours?.()
                    : 0,
                max: 23,
                step: 1,
              },
              minutes: {
                min:
                  startDate && endDate && isSameHour()
                    ? startDate.minutes?.()
                    : 0,
                max: 59,
                step: 1,
              },
            }}
            initialValue={endDate}
            value={endDate}
            timeFormat={timeEnabled ? "HH:mm" : false}
            inputProps={{
              placeholder: "To",
              disabled: disabledTo,
              style: {
                height: 35,
              },
            }}
            onChange={(date: any) => {
              setEndDate(date);
              onEndDate && onEndDate(date);
            }}
          />
        </Col>
        <Col lg={1} md={2} sm={12}>
          <label></label>
          <br></br>
          <Button variant="secondary" onClick={onClick}>
            <MdSearch size={25}></MdSearch>
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PlotBarAnalytics;
