import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Datatable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import styled from "styled-components";
import button from "./button";
import _ from "lodash";
import SearchInput from "./searchInput";
import ThemeContext from "../../context/theme/themeContext";
import { BsThermometerLow } from "react-icons/bs";
import { ITheme } from "../../context/theme/theme";
import FilterDropdown, {
  FilterDropdownItem,
  FilterItem,
} from "./filter-dropdown";
import useDebounce from "../../hooks/useDebounce";

interface TableProps {
  columns: TableColumn<any>[];
  data: any[];
  pagination: boolean;
  filterItems?: FilterDropdownItem[];
  searchText?: string;
  onSearchChange?: (searchText: string) => void;
}

const customStyles = (theme: ITheme) => {
  const customStyles: TableStyles = {
    head: {
      style: {
        //color: theme.text.primary,
        fontSize: "15px",
        fontWeight: 800,
      },
    },
    headRow: {
      style: {
        //border: 'none',
        borderBottomWidth: "3px",
      },
    },
    headCells: {
      style: {
        minWidth: "50px",
        color: "#202124",
        fontSize: "14px",
        borderBottomColor: "#FFFFFF",
        border: "1px",
      },
    },
    rows: {
      style: {
        minWidth: "50px",
        minHeight: "50px",
        fontSize: "14px",
      },

      highlightOnHoverStyle: {
        backgroundColor: theme.secondary,
        //backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "5px",
        outline: "1px solid #FFFFFF",
        color: "white",
        opacity: 0.7,
      },
    },
    pagination: {
      style: {
        //	border: 'none',
      },
    },
  };
  return customStyles;
};

const SearchAll = (data: any, query: string, properties: string[] = []) => {
  // data is an array of objects
  // query has to be an string
  //properties has to be an array or undefined

  // if properties is an array then only search in the properties passed
  if (properties !== undefined && properties.length > 0) {
    var test = searchByProperties(data, query, properties);
    return test;
  }

  // otherwise search in all fields (nested to) of the all objects.
  var searchFields2 = _.keys(data[0]);
  var filter = data.filter((item: any) => {
    return searchFields2.some((field) => {
      //check is the item is an object
      if (_.isObject(item[field])) {
        return searchInObject(item[field], query);
      } else {
        //otherwise search on the string
        return (
          item[field] &&
          item[field] !== undefined &&
          item[field].toString().toLowerCase().includes(query)
        );
      }
    });
  });
  //console.debug(data[0]);
  //console.debug(filter);

  return filter;
};

const searchByProperties = (
  data: any,
  query: string,
  properties: string[] = []
) => {
  //data has to be an array of objects
  //query an string
  // properties array of string with the path. ['name','user.name',..]
  var filter = _.filter(data, (item) => {
    var matchQuery = null;
    properties.some((path) => {
      if (_.has(item, path)) {
        matchQuery = _.get(item, path).toString().toLowerCase().includes(query);
        return matchQuery;
      }
    });
    if (matchQuery) return matchQuery;
  });
  //console.debug(filter);
  return filter;
};

const searchInObject = (object: any, query: string) => {
  // data has to be an object
  var result = Object.keys(object).find(
    (key) =>
      object[key] &&
      object[key] !== undefined &&
      object[key].toString().toLowerCase().includes(query)
  );
  return result;
};

const Table: FunctionComponent<TableProps> = ({
  columns,
  data,
  pagination,
  filterItems,
  searchText,
  onSearchChange,
}) => {
  const [filterText, setFilterText] = useState<string>("");
  const [resetPaginationToggle, setResetPaginationToggle] =
  useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<FilterItem[]>([]);
  const [items, setItems] = useState<any[]>(data); // the full elements passed
  const [filteredItems, setFilteredItems] = useState(
    SearchAll(data, filterText.toLowerCase())
  ); // the elements filtered
  
  const { theme } = useContext(ThemeContext);

  //Delay onchange event for avoid many request
  const debouncedValue = useDebounce<string>(filterText, 500)      
    useEffect(() => {
      onSearchChange && onSearchChange(debouncedValue);
    }, [debouncedValue])

  useEffect(() => {
    if (searchText && searchText != filterText) {
      setFilterText(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    setItems(data)
    const test = SearchAll(data, filterText.toLowerCase());
    setFilteredItems(test);
    //setItems(test);
  }, [data]);

  useEffect(() => {
    const filter_dropdown_items = filterByDropdown(checkedItems, items);
    setFilteredItems(
      SearchAll(filter_dropdown_items, filterText.toLowerCase())
    );
  }, [checkedItems]);

  const filterByDropdown = (data: FilterItem[], items: any[]) => {
    let filter: any[] = [...items];
    const new_filter: any[] = [];
    data.forEach((dataItem) => {
      type ObjectKey = keyof any;
      var result = _.filter(filter, (item) => {
        var element = item[dataItem.path as ObjectKey];
        return element == null
          ? false
          : element.toString() === dataItem.value.toString();
      });
      new_filter.push(...result);
    });
    //Remove duplicates in case that one or more filter find the same object
    const noduplicate_filter = _.uniqWith(new_filter, _.isEqual);

    return noduplicate_filter.length > 0 ? noduplicate_filter : filter;

    //setFilteredItems(new_filter.length > 0 ? new_filter : filter);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const filter_dropdown_items = filterByDropdown(checkedItems, items);

    setFilteredItems(
      SearchAll(filter_dropdown_items, filterText.toLowerCase())
    );

    return (
      <>
        {filterItems && (
          <FilterDropdown
            items={filterItems}
            onCheckedItemsChanged={setCheckedItems}
          />
        )}

        <SearchInput
          onFilter={(e: any) => {
            setFilterText(e.target.value);            
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  //Aggiunto perche con la cache non si aggiornava il ocmponent
  useEffect(() => {
    const test = SearchAll(data, filterText.toLowerCase());
    setFilteredItems(test);
    setItems(test);
  }, []);
  console.debug("render-table");
  return (
    <>
      <Datatable
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        pagination={pagination}
        customStyles={customStyles(theme)}
        columns={columns}
        data={filteredItems}
        responsive={true}
        highlightOnHover
        pointerOnHover
        persistTableHead
      ></Datatable>
    </>
  );
};

export default Table;
