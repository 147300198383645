import * as React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCompressArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { createUseStyles } from "react-jss";
import { useTranslation } from 'react-i18next';

const ConvertSize = (size: any) => {
  if (typeof size !== "number") {
    size = parseInt(size, 10);
  }

  if (!size || size === "undefined") return "0 MB";
  if (size > 1024) {
    if (size / 1024 > 1024) {
      return (size / 1024).toFixed(2) + " TB";
    }
    return (size / 1024).toFixed(2) + " GB";
  } else {
    return size && size.toFixed(2) + " MB";
  }
};


const useStyles = createUseStyles({
  card: () => ({
    minHeight: 200   
  }),
});
interface StatsDatabaseProps {
  totalSize?: number;
  totalCompressedSize?: number;
  totalUnompressedSize?: number;
  colorIcon?: string;
}


const StatsDatabase: React.FunctionComponent<StatsDatabaseProps> = ({
  totalSize,
  totalCompressedSize,
  totalUnompressedSize,
  colorIcon,
}) => {
  const { t} = useTranslation();
  const classes=useStyles();
  return (
    <>
      <Card data-testid="stats" className={"p-3 " + classes.card}>
        <div className="m-2">
          <div className="float-start">
            <h4 data-testid="header">{t('Compression Stats')}</h4>
          </div>
          <div className="float-end">
            <FontAwesomeIcon
              icon={faCompressArrowsAlt}
              color={colorIcon}
              size="2x"
            />
          </div>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <h3 className="font-weight-bold text-gray-800">
              <b data-testid="total-value">
                {totalSize ? ConvertSize(totalSize) : 0}
              </b>
            </h3>
            <span data-testid="total-text">Total storage size.</span>
          </li>
          <li className="list-group-item">
            <h3 className="font-weight-bold text-gray-800">
              <b data-testid="compressed-value">
                {totalCompressedSize ? ConvertSize(totalCompressedSize) : 0}{" "}
              </b>
            </h3>
            <span data-testid="compressed-text">Compressed size. </span>
          </li>
          <li className="list-group-item">
            <h3 className="font-weight-bold text-gray-800">
              <b data-testid="precompressed-value">
                {totalUnompressedSize ? ConvertSize(totalUnompressedSize) : 0}{" "}
              </b>
            </h3>
            <span data-testid="precompressed-text">Precompressed size.</span>
          </li>
        </ul>
      </Card>
    </>
  );
};

export default StatsDatabase;
