import React from 'react';
import { ITheme } from './theme';
import ThemeContext from './themeContext';


type Props = {    
    theme: ITheme
}

// This provider is intended to be surrounding the whole application.
// It should receive the theme as parameter
const ThemeProvider: React.FunctionComponent<Props> = ({theme, children}) => {
        

    // This component will render its children wrapped around a ThemeContext's provider whose
    // value is set to the method defined above
    return <ThemeContext.Provider value={{theme}}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;