import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_IT } from "./it/translations";
import { TRANSLATIONS_ES } from "./es/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      english: {
        //translation: TRANSLATIONS_EN, default keys in english
      },     
      // italian: {
      //   translation: TRANSLATIONS_IT,
      // },
      // spanish: {
      //   translation: TRANSLATIONS_ES,
      // },
    },
  });

i18n.changeLanguage("english");

export default i18n;
