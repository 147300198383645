import Http from "../base/http-services";
import { BaseService } from '../base/base-service';

const endpoint = "structs/detail";

export default class IStructDetail{
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  operation_type?: string;    
  is_plot?: boolean;
  is_stats?: boolean;
  category_id?: number;
  }

class StructDetailService extends BaseService<IStructDetail> {
  constructor() {
    super(endpoint, Http);
  }

}

export const struct_detail_service = new StructDetailService();
