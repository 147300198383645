import { BaseService } from "../base/base-service";
import Http from "../base/http-services";

const endpoint = "valve/condition";

export interface IValveCondition {
  id: number;
  created_at: Date;
  updated_at: Date;
  valve_id: any;
  name: string;
  description: string;
  threshold_min?: number;
  threshold_max?: number;
  default_avg?: number;
  start_position: number;
  target_position: number;
}

class ValveConditionService extends BaseService<IValveCondition> {
  constructor() {
    super(endpoint, Http);
  }
}

export const valve_condition_service = new ValveConditionService();
