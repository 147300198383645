import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { timezone_service } from "../../services/timezoneService";
import alertService from "../../utils/alertService";
import { QueryKeys, StaleTime } from "../query_keys";
import { ResponseError, ResponseWrapper } from "../../models/reponse";
import { useDispatch } from "react-redux";
import { usage_based_service } from "../../services/maintenance/usage-based/usage-based-service";
import { usage_based_operation_service } from "../../services/maintenance/usage-based/operation-service";
import { decreaseUsage } from "../../store/reducers/badge-reducer";
import { usage_based_complete_service } from "../../services/maintenance/usage-based/complete-service";

export const useGetUsageBased = () => {
  const timezone: string = timezone_service.getTimezone();

  const usage_based = useQuery({
    queryKey: [QueryKeys.GET_USAGEBASED_MAINTENANCE],
    queryFn: () => usage_based_service.get({ timezone: timezone }),
    onSuccess: (data) => {
      return data.response;
    },
    onError: (error?: ResponseError) => {
      if (error) {
        error.error && alertService.errorMessage(error?.error);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return usage_based;
};

type ISaveUsageBasedOperation = {
  usage_based_operation_id: number;
  checked: boolean;
};

export const useSaveUsageBasedOperations = () => {
  const timezone: string = timezone_service.getTimezone();
  const get_operations = useMutation<
    any, //no response
    any,
    ISaveUsageBasedOperation
  >({
    mutationFn: (data) => {
      return usage_based_operation_service.save(
        {
          usage_based_operation_id: data.usage_based_operation_id,
          completed: data.checked,
        },
        { timezone: timezone }
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {},
  });
  return get_operations;
};

type ICompleteUsage = {
  usage_based_maintenance_id: number;
  comment: string;
};

export const useCompleteUsageBased = () => {
  const timezone: string = timezone_service.getTimezone();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const get_operations = useMutation<
    any, //no response
    any,
    ICompleteUsage
  >({
    mutationFn: (data) => {
      return usage_based_complete_service.save(data, { timezone: timezone });
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {
      //invalid query per refresh notification
      queryClient.invalidateQueries([QueryKeys.GET_BADGES]);
      //decrease the number of expired notifications
      dispatch(decreaseUsage());
    },
  });
  return get_operations;
};
