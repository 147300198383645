import jwtDecode from "jwt-decode";
import { IAccess } from "./authentication";

export interface IUser {
  email: string;
  exp: number;
  first_name: string;
  is_staff: boolean;
  jti: string;
  last_name: string;
  token_type: string;
  user: string;
  user_id: number;
  permissions?: string[];
}

const key = "token";
const refresh = "refresh";
const permissions = "ps";
//const user = "user";

const setToken = (authToken: string) => {
  try {
    localStorage.setItem(key, authToken);
  } catch (error) {
    console.debug("Error storing the auth token");
  }
};

const getToken = () => {
  try {
    const authToken = localStorage.getItem(key);
    return authToken;
  } catch (error) {
    console.debug("Error getting the auth token");
    return null;
  }
};

const getRefreshToken = () => {
  try {
    const authToken = localStorage.getItem(refresh);
    return authToken;
  } catch (error) {
    console.debug("Error getting the refresh token");
    return null;
  }
};

const removeToken = () => {
  try {
    localStorage.removeItem(key);
    localStorage.removeItem(refresh);
    localStorage.removeItem(permissions);
    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  } catch (error) {
    console.debug("Error removing the auth token");
  }
};

const setTokenObj = (data: IAccess) => {
  localStorage.setItem(key, data.access);
  localStorage.setItem(refresh, data.refresh);
  localStorage.setItem(permissions, JSON.stringify(data.permissions));

  // if (localStorage.getItem(permissions) === null) {
  //}
};

const getUser = () => {
  const token = getToken();
  const perms = localStorage.getItem(permissions);

  if (token) {
    const user = jwtDecode<IUser>(token);

    if (
      perms === "undefined" ||
      typeof perms === "undefined" ||
      perms === null ||
      perms === undefined
    ) {
      user.permissions = JSON.parse("[]");
    } else {
      user.permissions = JSON.parse(perms);
    }
    return user;
  } else {
    return null;
  }
};

const isDemo = (): boolean => {
  const token = getToken();
  if (token) {
    const user = jwtDecode<IUser>(token);
    if (user) {
      return user.token_type == "demo";
    }
  }
  return false;
};

export default {
  getUser,
  setToken,
  removeToken,
  getToken,
  getRefreshToken,
  setTokenObj,
  isDemo
};
