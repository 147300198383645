import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QueryKeys, StaleTime } from "../query_keys";
import alertService from "../../utils/alertService";
import { valve_service } from "./../../services/valve/valve-service";

export const useGetValves = () => {
  const temperatures = useQuery({
    queryKey: [QueryKeys.MANAGEMENT_GET_VALVE],
    queryFn: () => valve_service.get(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_15, //Important when the data is not valid
  });
  return temperatures;
};
