import Http from "../../base/http-services";
import { BaseService } from "../../base/base-service";
import { BaseServiceGenericDemo } from "../../../demo/base-service-demo";
import { MaintenanceUsageBasedDemo } from "./../../../demo/maintenance/usage-based/usage-based-service-demo";
import storage from "../../base/storage";

const endpoint = "maintenance/usage-based";

export interface IMaintenanceUsedModel {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  description: string;
  struct: Struct;
  monitored_variable: MonitoredVariable;
  expiration: number;
  current_value?: number;
  is_seconds: boolean;
  initial_value: number;
  enabled: boolean;
  updated_variable?: any;
  operations: Operation[];
  forecast_performed_at?: Date;
  forecast_deadline_date?: Date;
  forecast_suggestion_date?: Date;
}

interface Struct {
  id: number;
  name: string;
  description: string;
}

interface MonitoredVariable {
  id: number;
  itemid: string;
  unit_of_measurement: string;
  description: string;
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
}

interface Operation {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  usage_based_maintenance_id: number;
  description: string;
  order: number;
  completed: boolean;
  user: User;
}

class MaintenanceUsageBasedService extends BaseService<IMaintenanceUsedModel> {
  constructor() {
    super(endpoint, Http);
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new MaintenanceUsageBasedDemo()
    : new MaintenanceUsageBasedService();

export const usage_based_service = instance;
