import { ResponseWrapper } from "../../models/reponse";
import { IReportNotification } from "../../services/reports/report-notification-service";
import { BaseServiceGenericDemo } from "./../base-service-demo";
import { report_notification_data } from "./data";

export class ReportnotificationDemo extends BaseServiceGenericDemo<IReportNotification> {
  constructor() {
    super();
    this.data = report_notification_data.getData();
  }

  generateReportByStructs = (
    start_date: string,
    end_date: string,
    structs: number[] | string[] | undefined
  ) => {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The report generation is not allowed on demo" },
          },
        },
      });
    });
  };

  generateReport(
    start_date: String,
    end_date: String
  ): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The report generation is not allowed on demo" },
          },
        },
      });
    });
  }
}
