import _ from "lodash";
import { INotification } from "../../services/notifications/warning-notification-service";
import { IStatsNotification } from "../../components/features/notification/notification-stats";
import Icon from "../../components/common/Icon";
import {
  MdCancel,
  MdWarning,
  MdArrowUpward,
  MdArrowDownward,
} from "react-icons/md";
import { Alert } from "react-bootstrap";
type IStats = {
  type: string;
  is_read: boolean;
  is_incorrect: boolean;
  struct: { name: string };
};

export const calcolateStats = <T extends IStats>(
  data: T[]
): IStatsNotification => {
  if (data) {
  }
  const sum_warning = _.sumBy(data, (main: IStats) => {
    return main.type === "warning" ? 1 : 0;
  });
  const sum_trend = _.sumBy(data, (main: IStats) => {
    return main.type === "trend" ? 1 : 0;
  });
  const sum_completed = _.sumBy(data, (main: IStats) => {
    return main.is_read ? 1 : 0;
  });
  const sum_pending = _.sumBy(data, (main: IStats) => {
    return !main.is_read ? 1 : 0;
  });
  const sum_anomalies = _.sumBy(data, (main: IStats) => {
    return main.is_incorrect ? 1 : 0;
  });

  const more_motors_with_problems = _.chain(data)
    .groupBy("struct.name")
    .map((notification, name) => ({
      name,
      total: notification.length,
      notifications: notification,
    }))
    .orderBy("total", "desc")
    .value();

  const labels_struct = more_motors_with_problems.map((struct) => struct.name);
  const values_struct = more_motors_with_problems.map((struct) => struct.total);

  return {
    complete: sum_completed,
    pending: sum_pending,
    anomalies: sum_anomalies,
    trend: sum_trend,
    warning: sum_warning,
    bar_data: {
      labels: labels_struct.slice(0, 5),
      values: values_struct.slice(0, 5),
    },
  };
};

const warning_color = "rgb(255, 205, 86)";
const trend_color = "rgb(255, 99, 132)";

export const renderNotificationIcon = (type: string) => {
  if (type === "warning") {
    return <Icon backgroundColor={warning_color} Icon={MdWarning} size={50} />;
  }

  if (type === "trend") {
    return <Icon backgroundColor={trend_color} Icon={MdCancel} size={50} />;
  }
  return <Icon Icon={MdCancel} size={50} />;
};

export const renderNotificationType = (type: string) => {
  return (
    <>
      {type === "warning" && (
        <span>
          <MdWarning style={{ color: "orange", fontSize: "20px" }} /> Warning
        </span>
      )}
      {type === "trend" && (
        <span>
          <MdCancel style={{ color: "red", fontSize: "15px" }} /> Trend
        </span>
      )}
    </>
  );
};

export const checkLimits = (
  value: number,
  limitMax: number | undefined,
  limitMin: number | undefined
) => {
  if (value === null)
    return

  if (limitMax && value > limitMax)
    return (
      <span style={{ color: "red" }}>
        {value.toFixed(2)} <MdArrowUpward />
      </span>
    );

  if (limitMin && value < limitMin)
    return (
      <span style={{ color: "red" }}>
        {value.toFixed(2)} <MdArrowDownward />{" "}
      </span>
    );

  return value.toFixed(2);
};

export const checkThresholds = (
  value?: number,
  limitMax?: number,
  limitMin?: number,
  name = "average"
) => {
  if (value == undefined) return null;

  if (limitMin && value < limitMin) {
    return (
      <>
        <Alert key={"warning"} variant="warning">
          <MdWarning style={{ color: "orange", fontSize: "20px" }} />
          The {name} have been out of the thresholds.
        </Alert>
      </>
    );
  }
  if (limitMax && value > limitMax) {
    return (
      <>
        <Alert key={"warning"} variant="warning">
          <MdWarning size={20} style={{ color: "orange" }} />
          The {name} have been out of the thresholds.
        </Alert>
      </>
    );
  }

  return null;
};
