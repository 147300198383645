import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import { ResponseWrapper } from "../models/reponse";
import { Routes } from "../Routes";

import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import {
  IUserGroup,
  user_group_service,
} from "./../services/users/user-groups-service";

export const useGetUserGroups = () => {
  const getGroups = useQuery({
    queryKey: [QueryKeys.GET_GROUPS],
    queryFn: user_group_service.get,
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error.response?.data) alertService.errorMessage(error.response?.data);
    },
    staleTime: StaleTime.SECONDS_30,
  });
  return getGroups;
};

export const useGetByIGroupUsers = () => {
  const queryClient = useQueryClient();
  const user = useMutation<ResponseWrapper<IUserGroup>, any, number>({
    mutationFn: (id) => {
      return user_group_service.getById(id);
    },
    onError: (error, variables, context) => {
      if (error.response?.data) alertService.errorMessage(error.response?.data);
    },
    onSuccess: (data, variables, context) => {},
  });

  return user;
};

export const useSaveGroupUsers = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const saveUser = useMutation<any, any, FormikValues>({
    mutationFn: (values) => {
      return user_group_service.save(values, {}, false);
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error.response?.data) alertService.errorMessage(error.response?.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_GROUPS] });
      history.push(Routes.user_groups);
    },
  });
  return saveUser;
};

export const useDeletGroupUsers = () => {
  const queryClient = useQueryClient();
  const deletUser = useMutation<any, any, any>({
    mutationFn: (data) => {
      return user_group_service.delete(data.dataId);
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error.response?.data) alertService.errorMessage(error.response?.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_GROUPS] });
    },
  });

  return deletUser;
};

export const useGetUserPermissions = () => {
  const getGroups = useQuery({
    queryKey: [QueryKeys.GET_PERMISSIONS],
    queryFn: user_group_service.getPermisisions,
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error.response?.data) alertService.errorMessage(error.response?.data);
    },
    staleTime: StaleTime.SECONDS_30,
  });
  return getGroups;
};
