import { AxiosError } from "axios";
import { ResponseWrapper } from "../../models/reponse";
import alertService from "../../utils/alertService";
import {
  IDashboardResponse,
  IDashboardService,
} from "../../services/dashboard/dashboard-types";
import moment from "moment";

export class DashboardDemo implements IDashboardService {
  get = (
    query_params: Record<string, any> = []
  ): Promise<ResponseWrapper<IDashboardResponse>> => {
    return new Promise<any>((resolve, reject) => {
      const dashboard: ResponseWrapper<IDashboardResponse> = {
        response: {
          expired_maintenance: 2,
          today_maintenance: 1,
          reports_generated: 1,
          struct: 5,
          database_stats: {
            tables: "1",
            total_size: 100000,
            total_uncompressed: 100,
            total_size_compressed: 90000,
            total_size_uncompressed: 900000,
          },
          disk_stats: { free: 200, total: 1024, used: 824 },
          last_month_notifications_types: {
            counts: [4, 0],
            labels: ["warning", "trend"],
          },
          last_month_notifications: {
            counts: [5, 4, 5, 2, 7, 1],
            dates: [
              moment().subtract(4, "d").toDate(),
              moment().subtract(3, "d").toDate(),
              moment().subtract(2, "d").toDate(),
              moment().subtract(1, "d").toDate(),
              moment().toDate(),
              moment().add(1, "d").toDate(),
            ],
          },
          scheduled_maintenance_by_date: {
            completed: [0, 0, 0, 0, 0],
            expired: [1, 0, 0, 0, 0],
            scheduled: [0, 0, 1, 0, 1],
            labels: [
              moment().subtract(1, "d").toDate(),
              moment().toDate(),
              moment().add(1, "d").toDate(),
              moment().add(2, "d").toDate(),
              moment().add(3, "d").toDate(),
            ],
          },
          scheduled_maintenance_by_month: {
            completed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            expired: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
            scheduled: [0, 0, 0, 0, 17, 18, 18, 18, 18, 18, 18, 18],
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        } as IDashboardResponse,
      } as ResponseWrapper<IDashboardResponse>;
      resolve(dashboard);
    });
  };

  public checkErrors(error: AxiosError<any, any>) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.status !== 403 &&
      error.response.status < 500
    ) {
      let message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }
}
