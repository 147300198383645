import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../configureStore";
import { IDate } from "../../pages/graph";
import { Moment } from "moment";
import { IRawSignal } from "../../services/plot/plot-struct-service";
import { SelectOption } from "../../components/common/selects/select-option";
import { IRange } from "../../components/features/plot-raw/time-series/interfaces";
import { IFindOption } from "../../components/features/plot-common/find-options";

// Define a type for the slice state
interface StructState {
  search_by: IFindOption;
  dates?: { start_date: string; end_date: string };
  struct_selected?: number;
  signals?: IRawSignal[];
  signal_type?: string;
  structures_selected?: SelectOption[];
  graph_range?: IRange;
  columns?: number;
  bucket_time?: string;
}

// Define the initial state using that type
const initialState: StructState = {
  search_by: "category",
};

export const graphSlice = createSlice({
  name: "graph",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveSearchBy(state, action: PayloadAction<IFindOption>) {
      state.search_by = action.payload;
    },
    saveDates(state, action: PayloadAction<any>) {
      state.dates = action.payload;
    },

    saveStruct(state, action: PayloadAction<number>) {
      state.struct_selected = action.payload;
    },
    saveSignals(state, action: PayloadAction<IRawSignal[] | undefined>) {
      state.signals = action.payload;
    },

    saveSignalType(state, action: PayloadAction<string>) {
      state.signal_type = action.payload;
    },
    saveStructuresSelected(state, action: PayloadAction<SelectOption[]>) {
      state.structures_selected = action.payload;
    },
    saveRange(state, action: PayloadAction<IRange>) {
      state.graph_range = action.payload;
    },
    saveColumns(state, action: PayloadAction<number>) {
      state.columns = action.payload;
    },
    saveBucketTime(state, action: PayloadAction<string|undefined>) {
      state.bucket_time = action.payload;
    },
  },
});

export const {
  saveSearchBy,
  saveDates,
  saveStruct,
  saveSignals,
  saveSignalType,
  saveStructuresSelected,
  saveRange,
  saveColumns,
  saveBucketTime
} = graphSlice.actions;

export const getGraphState = (state: RootState) => state.graph;

export default graphSlice.reducer;
