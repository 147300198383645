import { FunctionComponent, useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Plotly, { Config, Data, PlotRelayoutEvent } from "plotly.js";

import { MAX_POINTS_ON_GRAPH } from "./constants";
import { IRange } from "./interfaces";

type GrapphMode = "lines" | "markers" | "lines+markers";

interface PlotMultipleYAxesProps {
  signals: any[];
  title?: string;
  selectedGraphMode: GrapphMode;
  visibleYaxe: boolean;
  selectedRange?: IRange;
  onInitialized?: (figure: any) => any;
  // onRelayout?:
  //   | ((event: Readonly<Plotly.PlotRelayoutEvent>) => void)
  //   | undefined;
  onUpdate?: (figure: any) => any;
  onUpdateRange?: (range: IRange) => void;
}

//https://plotly.com/javascript/configuration-options/#
//https://github.com/plotly/plotly.js/blob/master/src/plot_api/plot_config.js#L22-L86
//https://github.com/plotly/react-plotly.js/blob/a42185ed5b773d423de684ecea4c13ddf399cfd4/README.md

const config: Partial<Config> = {
  displaylogo: false,
  responsive: true,
  autosizable: false,
  fillFrame: false,
  //plotlyServerURL: false,
  editable: false,
  //edits: false,
  showLink: false,
  displayModeBar: true,
  showEditInChartStudio: false,
  //watermark: false,
  showTips: false,
  scrollZoom: false,
  modeBarButtonsToRemove: ["lasso2d", "select2d", "resetScale2d"],
  // modeBarButtonsToAdd: [
  //   {
  //     name: 'button1',
  //     icon: Plotly.Icons.pencil,
  //     direction: 'up',
  //     click: function(gd) {alert('button1')
  //   }}],
};

const generateLegend = (signal: any) => {
  const statusLegend = [];
  if (signal.status) {
    signal.status.forEach((element: any) => {
      // var newStuff = element.map(function (item, index) {
      //   return item.replace(/,/g, "<br /> -");
      // });
      element = "<br /> - " + element
      var newStuff = element.replace(/,/g, "<br /> - ");
      //console.debug(newStuff);
      statusLegend.push(newStuff ? newStuff : "No Status");
    });
  } else {
    //Put fake date
    if (signal.time) {
      for (let index = 0; index < signal.time.length; index++) {
        statusLegend.push("No Status");
      }
    }
  }
  return statusLegend;
};

const createLayout = (
  title: string,
  layout_obj: any
): Partial<Plotly.Layout> => {
  const layout: Partial<Plotly.Layout> = {
    title: title,
    paper_bgcolor: "#1C00ff00",
    plot_bgcolor: "#1C00ff00",
    height: 350,
    hoverdistance: 100, //# Distance to show hover label of data point
    hoverlabel: { bgcolor: "#FFF" }, //Color hover
    autosize: true,
    dragmode: "zoom",
    margin: {
      t: 50,
      b: 50,
      l: 50,
      r: 50,
    },
    ...layout_obj, //merge layout_obj with layout
    modebar: {
      orientation: "h",
      bgcolor: "#1C00ff00",
      activecolor: "#6c757d",
      color: "#adb5bd",
    },
  };
  return layout;
};

const createTraces = (
  signals: any[],
  title: string | undefined,
  visibleYaxe: boolean,
  graphMode: GrapphMode,
  range: IRange
) => {
  const traces: Data[] = [];
  var layout_axes: any[] = [];
  var layout_obj: any = {};
  var count: number = 1;
  const spacing = 0.04;
  const colorPlotly: string[] = [
    '#636EFA',
    '#EF553B',
    '#00CC96',
    '#AB63FA',
    '#FFA15A',
    '#19D3F3',
    '#FF6692',
    '#B6E880',
    '#FF97FF',
    '#FECB52'
  ];
  signals.forEach((signal: any, index: number) => {
    const status = generateLegend(signal);
    const signal_size = signal.time ? signal.time.length : 0;
    const toManyPoints: boolean = signal_size >= MAX_POINTS_ON_GRAPH;
    traces.push({
      x: signal.time,
      y: signal.value,
      //xaxis: count > 1 ? "x" + count.toString() : "x",
      yaxis: count > 1 ? "y" + count.toString() : "y",
      mode: graphMode,
      type: "scattergl",
      name: title ? signal.struct_name : signal.description, //Blank always with custom hover template
      customdata: status, //handleStatus(signal.status),
      hovertemplate: toManyPoints
        ? undefined
        : "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br /> <b>Status:</b>  %{customdata} <br />",
      showlegend: true,
      line: {
        shape: "hv",
        width: 2.8,
        //dash:"dot",
        simplify: false,
        smoothing: 0.1,
        //color: colors.grey_light,
        color: graphMode === "lines+markers" ? "#E7E6F0" : undefined,
      },
      marker: {
        color: colorPlotly[index % colorPlotly.length],
        //color: colors.grey,
        size: 4.5,
      },
    });
    //Foreach yAxis create define styles
    if (count == 1) {
      //Create layout a Unique key for each Xaxis
      var Xdomain = visibleYaxe ? (signals.length - 1) * spacing : 0;
      var layout_first_xaxe = {
        key: "xaxis",
        data: {
          range: range && [range.rangeX1, range.rangeX2],
          domain: [Xdomain, 1],
          zeroline: false,
          showline: false,
          dividerwidth: 0,
        },
      };
      layout_axes.push(layout_first_xaxe);
      var layout_first_yaxe = {
        key: "yaxis", //chiave da mappare.
        data: {
          visible: visibleYaxe,
          zeroline: false,
          showline: false,
          ticksuffix:
            signal &&
            signal.unit_of_measurement &&
            " " + signal.unit_of_measurement,
          //showgrid:false,
        },
      };
      layout_axes.push(layout_first_yaxe);
    }
    if (count >= 2) {
      var position = count == 2 ? 0 : (count - 2) * spacing;
      var layout_yaxe = {
        key: "yaxis" + count.toString(), //chiave da mappare.
        data: {
          visible: visibleYaxe,
          anchor: "free",
          overlaying: "y",
          side: "left",
          position: position,
          zeroline: false,
          showgrid: false,
          ticksuffix:
            signal &&
            signal.unit_of_measurement &&
            " " + signal.unit_of_measurement,
        },
      };

      layout_axes.push(layout_yaxe);
    }

    count = count + 1;
  });

  //create the object layout.
  layout_axes.forEach((element: any) => {
    layout_obj[element.key] = { ...element.data };
  });
  var title_obj = title
    ? title
    : signals && signals.length > 0 && signals[0].struct_name;
  var layout = createLayout(title_obj, layout_obj);

  return { traces, layout };

  //return traces;
};

const PlotMultipleYAxes: FunctionComponent<PlotMultipleYAxesProps> = ({
  signals,
  title,
  selectedGraphMode,
  visibleYaxe = true,
  onInitialized,
  onUpdate,
  onUpdateRange,
  selectedRange = {} as IRange,
}) => {
  const [graphMode, setGraphMode] = useState<GrapphMode>(selectedGraphMode);
  const [rangeDefined, setRangeDefined] = useState<any>(selectedRange);

  useEffect(() => {
    setGraphMode(selectedGraphMode);
  }, [selectedGraphMode]);

  useEffect(() => {
    if (selectedRange.rangeX1 != rangeDefined.rangeX1 && selectedRange.rangeX2 != rangeDefined.rangeX2) {
      setRangeDefined(selectedRange);
    }
  }, [selectedRange]);

  const handleRelayout = async (e: any) => {
    const events: PlotRelayoutEvent = e;

    let key = Object.keys(e)[0];
    let key2 = Object.keys(e)[1];
    //if the keys are undefined return.
    if (key === undefined || key2 === undefined) return;
    //if the keys are not xaxis return.
    if (!key.includes("xaxis") && !key2.includes("xaxis")) {
      return;
    }
    let x1_new: any = e[key];
    let x2_new: any = e[key2];

    // if the values are undefined return
    if (x1_new === undefined || x2_new === undefined) return;

    onUpdateRange && onUpdateRange({ rangeX1: x1_new, rangeX2: x2_new });
    //setX2(x1);
    //setX2(x2);
  };
  const { traces, layout } = createTraces(
    signals,
    title,
    visibleYaxe,
    graphMode,
    rangeDefined
  );
  return (
    <Plot
      data={traces}
      layout={layout}
      config={config}
      divId={"Id1"}
      onInitialized={(figure: any) => onInitialized && onInitialized(figure)}
      onRelayout={handleRelayout}
      onUpdate={(figure) => onUpdate && onUpdate(figure)}
      //ref={signal1.ref}
      style={{ margin: 0, backgroundColor: "none" }}
      useResizeHandler={false}
    />
  );
};

export default PlotMultipleYAxes;
