import React, { FunctionComponent, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Alert, Badge, Card, Container } from "react-bootstrap";
import { permissions } from "../services/permissions/permissions";
import { IStruct, IStructByCategory } from "../services/struct/struct-service";
import withLayout from "../hoc/withLayout";
import * as alertService from "../utils/alertService";
import Restricted from "../context/permission/restricted";
import Backdrop from "../components/common/backdrop/backdrop";
import CardCollapsible from "../components/common/card-collapsible";
import PlotBar from "../components/features/plot-raw/plot-bar";
import PlotBarCategories from "../components/features/plot-raw/plot-bar-categories";
import PlotBarAdvance, {
  graphMode,
} from "../components/features/plot-raw/plot-bar-advance";
import moment, { Moment } from "moment";
import { SelectOption } from "../components/common/selects/select-option";
import _ from "lodash";
import { GroupBase } from "react-select";
import { plot_struct_service } from "../services/plot/plot-struct-service";
import { timezone_service } from "../services/timezoneService";
import * as dateOperation from "../utils/date-operations";
import { plot_variable_service } from "../services/plot/plot-variable-service";
import SubPlotRaw from "../components/features/plot-raw/time-series/subplot-raw";
import { FieldHasData } from "../utils/functions";
import { MdWarning } from "react-icons/md";
import PlotMultipleYAxes from "../components/features/plot-raw/time-series/plot-multiple-yaxes";
import { IRange } from "../components/features/plot-raw/time-series/interfaces";
import PlotAlarms from "../components/features/plot-raw/alarms/plot-alarms";
import NoDataFound from "../components/features/plot-common/no-data-found";
import PlotValues from "../components/features/plot-raw/lastvalue/plot-value";
import { emitter } from "../services/eventBus/emitter";
import { useDispatch, useSelector } from "react-redux";
import {
  getGraphState,
  saveBucketTime,
  saveColumns,
  saveDates,
  saveSearchBy,
  saveSignalType,
  saveSignals,
  saveStruct,
  saveStructuresSelected,
} from "../store/reducers/graph-reducer";
import {
  StructOrder,
  useGetStructs,
  useGetStructsByCategory,
} from "./../queries/struct.hook";
import { useGetSignalCategories } from "../queries/signal.categories.hook";
import FindOptions, {
  IFindOption,
} from "../components/features/plot-common/find-options";
import { Step } from "react-joyride";
import { Tour } from "tour";
import { Routes } from "Routes";

interface GraphProps {}

type IState = {
  signals?: any[];
  alarms?: any[];
  values?: any[];
  isRefreshing: boolean; // is if in Auto Refresh
  isBusy: boolean;
  bucket?: string;
};

type IAutoRefresh = {
  refreshInterval?: number; //time in milliseconds
  interval: any; //Instance of the interval
  option?: SelectOption;
};

export type IDate = {
  startDate: Moment;
  endDate: Moment;
};

const Graph: FunctionComponent<GraphProps> = () => {
  let timezone = timezone_service.getTimezone();
  const graph_height = 350;
  const [state, setState] = useState<IState>({
    signals: undefined,
    values: undefined,
    alarms: [],
    isBusy: false,
    isRefreshing: false,
  });
  const [showAlert, setAlert] = useState<boolean>(false);
  const [structs, setStruct] = useState<any[]>([]); //TODO options is struct;
  const [structSelected, setStructSelected] = useState<number>(0);
  const [categories, setCategories] = useState<SelectOption[]>([]);
  const [category, setCategory] = useState<number>(0);
  const [structuresSelected, setStructuresSelected] =
    useState<SelectOption[]>();
  const [structsByCategory, seStructsByCategory] = useState<
    GroupBase<SelectOption>[]
  >([]);
  const [findBy, setFindBy] = useState<IFindOption>("category");
  const [graphType, setGraphType] = useState<"single" | "multiple">("single");
  const [graphMode, setGraphMode] = useState<graphMode>("lines+markers");
  const [graphByRow, setGraphByRow] = useState<number>(2);

  const [rangeDefined, setRangeDefined] = useState<IRange>({} as IRange);
  const [autoRefresh, setAutoRefresh] = useState<IAutoRefresh>({
    refreshInterval: undefined,
    interval: undefined,
    option: undefined,
  });

  const [dates, setDates] = useState<IDate>({
    startDate: moment()
      .subtract(7, "d")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    endDate: moment(),
  });

  const structs_data = useGetStructs();

  const categories_data = useGetSignalCategories();

  const struct_by_category = useGetStructsByCategory();

  const dispatch = useDispatch();
  const graph_state = useSelector(getGraphState);

  useEffect(() => {
    loadState();

    window.addEventListener("resize", Responsive);
    emitter.on("change_timezone", (e) => {
      timezone = timezone_service.getTimezone();
    });    

    return () => {
      window.removeEventListener("resize", Responsive);
      emitter.off("change_timezone");
    };
  }, []);


  useEffect(() => {
    if (structs_data.data?.response) {
      const result = StructOrder(structs_data.data?.response, "type_id.type");
      setStruct(result);
    }
  }, [structs_data.data]);

  useEffect(() => {
    if (categories_data.data?.response) {
      const new_categories: SelectOption[] = [];

      const result = categories_data.data?.response;
      result.forEach((category: any) => {
        new_categories.push({ value: category.id, label: category.name });
      });
      setCategories(new_categories);
    }

    
  }, [categories_data.data]);

  const handleStartDate = (date: any) => {
    const date_ = moment(date);
    dates.startDate = moment(date_);
    dispatch(
      saveDates({ start_date: dates.startDate, end_date: dates.endDate })
    );
    checkRange();
  };
  const handleEndDate = (date: any) => {
    const date_ = moment(date);
    dates.endDate = moment(date_);
    dispatch(
      saveDates({ start_date: dates.startDate, end_date: dates.endDate })
    );
  };

  const checkRange = () => {
    const diffDays: number = dates.endDate.diff(dates.startDate, "days", false);
    if (diffDays > 35) {
      setAlert(true);
    }
  };

  //#region Structure
  const handleChangeStruct = async (data: SelectOption) => {
    if (data.value) {
      const id = parseInt(data.value);
      setStructSelected(id);
      dispatch(saveStruct(id));
    }
  };

  //#endregion

  const handleChangeCategory = async (obj: SelectOption) => {
    if (obj.value) {
      console.debug(obj.value);
      setCategory(parseInt(obj.value));
      setStructuresSelected([]);
      loadStructByCategory(obj.value);
      dispatch(saveSignalType(obj.value));
    }
  };

  const loadStructByCategory = async (categoryId: string) => {
    try {
      console.debug("loadStructByCategory" + categoryId);
      const structs = await struct_by_category.mutateAsync({
        categoryId: categoryId,
      });
      const { response } = structs;
      if (response) {
        const result = orderStructCategory(response);

        seStructsByCategory(result);
      }
    } catch (error: any) {
      //alert(error);
    }
  };

  const orderStructCategory = (response: IStructByCategory[]) => {
    const StructOrderByType = _.chain(response)
      .groupBy("struct_type_id.type")
      .map((structs, name) => ({
        label: name,
        options: _.map(structs, (struct) => {
          return {
            label:
              struct.struct_name +
              " - " +
              struct.struct_variable.variable.description,
            value: struct.struct_variable.id.toString(),
            isDisabled: !struct.struct_enabled,
            variable: {
              variable_id: struct.struct_variable.variable.id,
              struct_id: struct.struct_id,
            },
          };
        }),
      }))
      .value();

    //console.debug(StructOrderByType);
    return StructOrderByType;
  };

  const handleStructSelecteds = (data: SelectOption[]) => {
    if (data) {
      setStructuresSelected(data);
      dispatch(saveStructuresSelected(data));
    }
  };

  //#endregion

  //#region "Options"

  const handleFindOptions = (value: IFindOption) => {
    if (findBy !== value) {
      setFindBy(value);
      setState({
        ...state,
        signals: undefined,
        alarms: [],
        values: [],
      });
      dispatch(saveSearchBy(value));
      dispatch(saveSignals(undefined));
    }
  };

  //#endregion

  //#region Plot

  const handleRefresh = async () => {
    let result: any = undefined;

    if (findBy === "category") {
      if (!structuresSelected || structuresSelected?.length === 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
      setState({
        ...state,
        signals: undefined,
        alarms: [],
        values: [],
        isBusy: true,
      });

      result = await plotByCategories();
    } else {
      if (structSelected <= 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
      setState((prevState) => {
        return {
          ...state,
          signals: undefined,
          alarms: [],
          values: [],
          isBusy: true,
        };
      });

      result = await plotByStruct();
    }

    if (result) {
      setState({
        ...state,
        signals: result?.signals,
        alarms: result?.alarms,
        values: result?.values,
        isBusy: false,
      });
      dispatch(saveSignals(result?.signals));
    } else {
      setState({
        ...state,
        signals: undefined,
        alarms: [],
        values: [],
        isBusy: false,
      });
    }
  };

  const plotByCategories = async () => {
    try {
      let variables_onstruct: number[] | undefined = structuresSelected?.map(
        (x) => x.variable
      );
      console.debug(variables_onstruct);
      if (variables_onstruct) {
        let result = await plot_variable_service.getMany({
          variables: variables_onstruct,
          start_date: dateOperation.getDateTimeOnString(
            dates.startDate?.toDate(),
            false
          ),
          end_date: dateOperation.getDateTimeOnString(
            dates.endDate?.toDate(),
            false
          ),
          timezone: timezone,
          bucket: state.bucket,
          //bucket: state.bucket != null ? undefined : state.bucket,
        });

        const { response } = result;

        let signals_with_id = response ? response.signals : undefined;
        let num = 1;
        if (signals_with_id) {
          signals_with_id.forEach((signal: any) => {
            if (!signal.id) {
              signal.id = num.toString();
              num++;
            }
          });
        }

        return {
          signals: signals_with_id,
          alarms: response?.alarms,
          values: response?.values,
        };
      }
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        alertService.errorMessage("Oops! Internal Server Error");
      } else if (
        error.response &&
        error.response.data &&
        error.response.status !== 403
      ) {
        const message = error.response.data.response.error;
        alertService.errorMessage(message);
      }
    }
    return null;
  };

  const plotByStruct = async () => {
    try {
      let result = await plot_struct_service.getPlot(structSelected, {
        start_date: dateOperation.getDateTimeOnString(
          dates.startDate?.toDate(),
          false
        ),
        end_date: dateOperation.getDateTimeOnString(
          dates.endDate?.toDate(),
          false
        ),
        timezone: timezone,
        bucket: state.bucket,
      });

      const { response } = result;

      let signals_with_id = response ? response.signals : undefined;

      let num = 1;
      if (signals_with_id) {
        signals_with_id.forEach((signal: any) => {
          if (!signal.id) {
            signal.id = num.toString();
            num++;
          }
        });
      }

      return {
        signals: signals_with_id,
        alarms: response?.alarms,
        values: response?.values,
      };
    } catch (error: any) {
      if (error && error.response && error.response.status === 500) {
        alertService.errorMessage("Oops! Internal Server Error");
        return null;
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status !== 403
      ) {
        const message = error.response.data.response.error;
        alertService.errorMessage(message);
      }
    }
    return null;
  };

  //#endregion

  //#region BarAdvanced

  const handleModeGraph = (value?: graphMode) => {
    if (value) {
      if (graphMode !== value) {
        setGraphMode(value);
        //this.state.graphMode=input["value"];
      }
    }
  };

  const handleChangeGraphType = (value: any) => {
    //console.debug(input['value']);
    if (value) {
      setGraphType(value);
    }
  };

  const handleGraphByRow = (value: string | undefined) => {
    try {
      if (value) {
        const columns = parseInt(value);
        if (graphByRow !== columns) {
          setGraphByRow(columns);
          dispatch(saveColumns(columns));
        }
      }
    } catch (error) {}
  };

  const handleRange = (range: IRange) => {
    if (range) {
      if (rangeDefined) {
        if (
          range.rangeX1 !== rangeDefined?.rangeX1 &&
          range.rangeX2 !== rangeDefined?.rangeX2
        ) {
          rangeDefined.rangeX1 = range.rangeX1;
          rangeDefined.rangeX2 = range.rangeX2;
        }
      }
    }
  };
  //#endregion

  //#region AutoRefresh

  const handleAutoRefresh = async () => {
    if (state.isRefreshing) {
      console.debug("is Refreshing");
      return;
    }
    //Why this? setState is Async so we never going to catch the "isRefreshing" for avoid overlap the timer
    state.isRefreshing = true;
    setState({ ...state, isRefreshing: true });

    let result: any = undefined;
    if (findBy === "category") {
      console.debug(structuresSelected);
      if (!structuresSelected || structuresSelected?.length === 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
      result = await plotByCategories();
    } else {
      if (structSelected <= 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
      result = await plotByStruct();
    }

    if (result) {
      setState({
        ...state,
        signals: result?.signals,
        alarms: result?.alarms,
        values: result?.values,
        isBusy: false,
        isRefreshing: false,
      });
    } else {
      setState({
        ...state,
        isBusy: false,
        isRefreshing: false,
      });
    }
    //Why this? setState is Async so we never going to catch the "isRefreshing" for avoid overlap the timer
    state.isRefreshing = false;
  };

  const handleChangeAutoRefresh = (data?: SelectOption) => {
    const value = data?.value;
    if (!value) return;

    if (findBy === "category") {
      if (structuresSelected?.length === 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
    } else {
      if (structSelected <= 0) {
        alertService.errorMessage("You have to select a structure");
        return;
      }
    }

    if (value === "off") {
      console.debug("OFF");
      autoRefresh.refreshInterval = undefined;
      setAutoRefresh({
        ...autoRefresh,
        refreshInterval: undefined,
        option: data,
      });
      return;
    }
    if (parseFloat(value) !== autoRefresh.refreshInterval) {
      autoRefresh.refreshInterval = parseFloat(value) * 60000;
      setAutoRefresh({
        ...autoRefresh,
        refreshInterval: parseFloat(value) * 60000,
        option: data,
      });
      console.debug("ON");
    }
  };

  const startRefreshTimer = () => {
    const { refreshInterval } = autoRefresh;

    console.debug(refreshInterval);
    if (
      refreshInterval &&
      !isNaN(refreshInterval) &&
      refreshInterval !== undefined
    ) {
      console.debug("start timer");

      let refreshTimer = setInterval(async () => {
        handleEndDate(moment().toDate());
        await handleAutoRefresh();
      }, refreshInterval);

      autoRefresh.interval = refreshTimer;
      return refreshTimer;
    }
  };

  const stopRefreshTimer = () => {
    console.debug("stop timer");
    clearInterval(autoRefresh.interval);
    clearTimeout(autoRefresh.interval);
  };

  useEffect(() => {
    stopRefreshTimer();
    const timer = startRefreshTimer();

    return () => {
      if (timer) {
        clearInterval(timer);

        clearTimeout(timer);
      }
    };
  }, [autoRefresh]);

  //#endregion

  const findMotor = (): SelectOption | undefined => {
    let group_value: SelectOption | undefined = undefined;
    structs.forEach((io) => {
      let prospect = io.options.find((o: any) => o.value == structSelected);
      if (prospect !== undefined) {
        group_value = prospect;
      }
    });
    return group_value;
  };

  //#region responsive

  const Responsive = () => {
    if (window.innerWidth < 1215) {
      setGraphByRow(1);
    }
  };
  //#endregion

  const loadState = () => {
    if (graph_state.signals) {
      setState({
        ...state,
        signals: graph_state.signals,
        bucket: graph_state.bucket_time?.toString(),
      });
    }

    if (graph_state.search_by) {
      setFindBy(graph_state.search_by);
    }
    if (graph_state.dates) {
      setDates({
        startDate: moment(graph_state.dates.start_date),
        endDate: moment(graph_state.dates.end_date),
      });
    }
    if (graph_state.signal_type) {
      setCategory(parseInt(graph_state.signal_type));
      loadStructByCategory(graph_state.signal_type);
    }
    if (graph_state.structures_selected) {
      setStructuresSelected(graph_state.structures_selected);
    }
    if (graph_state.struct_selected) {
      setStructSelected(graph_state.struct_selected);
    }
    if (graph_state.columns) {
      setGraphByRow(graph_state.columns);
    }
  };


  const click_event = () => {
    handleRefresh();
  };
  //TODO move to tour folder
  const steps: Step[] = [
    {
      title: "Graph",
      placement: "center",
      target: "body",
      content: (
        <div>
          <p>
            In the section <b>Graph</b> is possible to view sampled from the
            data collection.
          </p>
        </div>
      ),
    },
    {
      title: "Search by",
      content: (
        <div>
          <p>It’s possible to view graphs in two different forms:</p>
          <ul>
            <li>
              <b>By structure:</b> all signals of a selected structures.
            </li>
            <li>
              <b>By type of signal</b>: all signals from diferente structures
              type (e.g. torque, current, speed, temperature) of all selected
              structures.
            </li>
          </ul>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_searchby",
    },
    {
      title: "Signal type",
      content: (
        <div>
          <p>Select the signal type. </p>
        </div>
      ),
      locale: {
        next: (
          <a
            onClick={() => {
              if (categories?.[0]) handleChangeCategory(categories[0]);
            }}
          >
            Next
          </a>
        ),
      },
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_signaltype",
    },
    {
      title: "Select structure",
      content: (
        <div>
          <p>Select the structures of the current type.</p>
        </div>
      ),
      locale: {
        next: (
          <a
            onClick={() => {
              if (structsByCategory?.[0]?.options?.[0])
                handleStructSelecteds([structsByCategory[0].options[0]]);
            }}
          >
            Next
          </a>
        ),
      },
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_structure",
    },
    {
      title: "From",
      content: (
        <div>
          <p>Select start date of the range to display.</p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_from",
    },
    {
      title: "To",
      content: (
        <div>
          <p>Select end date of the range to display.</p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_to",
    },
    {
      title: "Bucket",
      content: (
        <div>
          <p>
            Select the interval to group the data. If you want to display large
            intervals of data this option is recommended.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_bucket",
    },
    {
      title: "Request",
      content: (
        <div>
          <p>Click on the button to plot data.</p>
        </div>
      ),
      locale: {
        next: <a onClick={click_event}>Click</a>,
      },
      showSkipButton: false,
      hideBackButton: true,
      hideFooter: false,
      placement: "top",
      showProgress: false,
      target: ".tour_search",
    },
    {
      title: "",
      content: (
        <div>
          <p>
            Now we can go further, in both view mode we will see advance
            options.
          </p>
        </div>
      ),
      hideFooter: false,
      placement: "center",
      showProgress: false,
      target: "body",
    },
    {
      title: "Columns option",
      content: (
        <div>
          <p>
            Define how many graph will be display by row. In small devices the
            value will be always 1 column.
          </p>
        </div>
      ),
      hideFooter: false,
      placement: "top",
      showProgress: false,
      target: ".tour_columns",
    },
    {
      title: "Graph mode option",
      content: (
        <div>
          <p>Define the drawing mode for the graph.</p>
        </div>
      ),
      hideFooter: false,
      placement: "top",
      showProgress: false,
      target: ".tour_graphmode",
    },
    {
      title: "Graph type option",
      content: (
        <div>
          <p>Define the type of graph.</p>
        </div>
      ),
      hideFooter: false,
      placement: "top",
      showProgress: false,
      target: ".tour_graphtype",
    },
    {
      title: "Auto refresh option",
      content: (
        <div>
          <p>
            When the interval is set the graph will be automatic refresh with
            the last datetime.
          </p>
        </div>
      ),
      hideFooter: false,
      placement: "top",
      showProgress: false,
      target: ".tour_autorefresh",
    },

    {
      title: "Time-series signals",
      content: (
        <div>
          <p>
            On the card below we see all the time-series signals associate to
            the structures/type selected.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      placement: "top",
      target: ".tour_timeseries_signals",
    },
    {
      title: "Actions",
      content: (
        <div>
          <p>
            In both view mode it’s possible to interact with the graph by using
            the available buttons. From left to right:
          </p>
          <ul>
            <li>Download</li>
            <li>Drag</li>
            <li>Move</li>
            <li>Zoom in</li>
            <li>Zoom out</li>
            <li>Reset scale</li>
          </ul>
        </div>
      ),

      hideFooter: false,
      placement: "left",
      target: ".modebar",
    },
  ];

  console.debug("render Graph");
  return (
    <>
      <Tour steps={steps} redirectTo={Routes.analytics} />
      <Restricted to={permissions.main.graph} showFallback={true}>
        <Backdrop show={state.isBusy} />
        <Container fluid>
          <CardCollapsible
            title={
              <>
                <h5>Graph</h5>

                {findBy && findBy === "category" && (
                  <>
                    <Badge className="ms-1" bg="secondary" text="white">
                      {
                        categories.find(
                          (x) => x.value && parseInt(x.value) == category
                        )?.label
                      }
                    </Badge>
                    <Badge className="ms-1" bg="secondary">
                      {structuresSelected && structuresSelected?.length > 0 && (
                        <span>{structuresSelected?.length} structures</span>
                      )}
                    </Badge>
                  </>
                )}

                {findBy && findBy === "structure" && (
                  <Badge className="ms-1" bg="secondary">
                    {findMotor() && findMotor()?.label}
                  </Badge>
                )}

                <Badge className="ms-1" bg="secondary">
                  <span>
                    {dates.startDate.format("L")} - {dates.endDate.format("L")}
                  </span>
                </Badge>
              </>
            }
          >
            <div className="tour_searchby">
              <FindOptions
                selectedValue={findBy}
                onChangeFindOptions={handleFindOptions}
              />
            </div>

            {findBy && findBy === "structure" && (
              <React.Fragment>
                <PlotBar
                  timeEnabled={true}
                  isInAutoRefresh={autoRefresh.refreshInterval ? true : false}
                  options={structs}
                  selectedStartDate={dates.startDate}
                  selectedEndDate={dates.endDate}
                  selectedStruct={structSelected}
                  onClick={handleRefresh}
                  onStructChange={handleChangeStruct}
                  onStartDate={handleStartDate}
                  onEndDate={handleEndDate}
                  selectedBucket={state.bucket}
                  onChangeBucket={(interval) => {
                    state.bucket = interval;
                    dispatch(saveBucketTime(interval));
                  }}
                />
              </React.Fragment>
            )}
            {findBy && findBy === "category" && (
              <PlotBarCategories
                timeEnabled={true}
                isInAutoRefresh={autoRefresh.refreshInterval ? true : false}
                //currentInputValue={currentInputValue}
                structCategories={structsByCategory} //the structures filter by category
                selectedStartDate={dates.startDate} // current start date
                selectedEndDate={dates.endDate} // current end date
                selectedStructs={structuresSelected} // structs actual selected
                categories={categories} // categories avaliable
                selectedCategory={category} // category selected
                onCategoryChange={handleChangeCategory} // on change category
                onClick={handleRefresh}
                onStructSelected={handleStructSelecteds}
                onStartDate={handleStartDate}
                onEndDate={handleEndDate}
                selectedBucket={state.bucket}
                onChangeBucket={(interval) => {
                  state.bucket = interval;
                  dispatch(saveBucketTime(interval));
                }}
              />
            )}
            {showAlert && (
              <Row>
                <Col className="text-center" md={{ span: 4, offset: 5 }}>
                  <Alert
                    key={"light"}
                    variant={"warning"}
                    onClose={() => setAlert(false)}
                    dismissible
                  >
                    <MdWarning className="m-0 text-warning" /> The selected time
                    interval is too large, the process may be slow.
                  </Alert>
                </Col>
              </Row>
            )}

            {state.signals && (
              <PlotBarAdvance
                selectedGraphMode={graphMode}
                selectedGraphByRow={graphByRow}
                selectedRefreshOption={autoRefresh.option}
                isRefreshing={state.isRefreshing}
                onChangeGraphMode={handleModeGraph}
                onChangeGraphByRow={handleGraphByRow}
                onChangeAutoRefresh={handleChangeAutoRefresh}
                onChangeGraphType={handleChangeGraphType}
              />
            )}
          </CardCollapsible>

          {graphType &&
            graphType == "single" &&
            findBy &&
            (findBy === "structure" || findBy === "category") && (
              <Row className="p-1">
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  {state.signals &&
                    state.signals.length > 0 &&
                    FieldHasData(state.signals, "value") && (
                      <Card className="tour_timeseries_signals">
                        <SubPlotRaw
                          signals={state.signals}
                          SelectedGraphMode={graphMode}
                          selectedGraphByRow={graphByRow}
                          onUpdateRange={handleRange}
                          selectedRange={rangeDefined}
                        />
                      </Card>
                    )}
                </Col>
              </Row>
            )}

          {graphType &&
            graphType == "multiple" &&
            state.signals &&
            state.signals.length > 0 &&
            FieldHasData(state.signals, "value") && (
              <Row>
                <Card>
                  <PlotMultipleYAxes
                    signals={state.signals}
                    onUpdateRange={handleRange}
                    selectedRange={rangeDefined}
                    selectedGraphMode={graphMode}
                    visibleYaxe={false}
                    title={
                      findBy === "category" ? category.toString() : undefined
                    }
                  />
                </Card>
              </Row>
            )}

          {/* Alarms region */}
          {state.alarms &&
            state.alarms.length > 0 &&
            FieldHasData(state.alarms, "value") &&
            state.alarms.map((alarm) => (
              <>
                <Col
                  className="p-1"
                  xl={12 / graphByRow}
                  lg={12}
                  md={12}
                  sm={12}
                >
                  <Card className="p-1" style={{ minHeight: graph_height }}>
                    {alarm.duration && alarm.value ? (
                      <PlotAlarms alarm={alarm} />
                    ) : (
                      // <PlotAlarmsBar alarm={alarm} />
                      <span className="center-content">No alarms found..</span>
                    )}
                  </Card>
                </Col>
              </>
            ))}

          {state.signals && state.signals.length == 0 && (
            <NoDataFound height={graph_height} />
          )}

          {state.signals &&
            FieldHasData(state.signals, "name") &&
            !FieldHasData(state.signals, "value") && (
              <NoDataFound height={graph_height} />
            )}

          <PlotValues values={state.values} />
        </Container>
      </Restricted>
    </>
  );
};

export default React.memo(withRouter(Graph));
