import { report_demo_url } from "demo/config";
export class RequestDemo {
  /**
   *
   */
  req: XMLHttpRequest;
  constructor() {
    this.req = new XMLHttpRequest();
  }
  getUrl(url: string) {
    return new Promise<any>((resolve, reject) => {
      reject("The visualization option is not allowed on demo");
    });
  }

  getPromise(url: string) {
    return new Promise<string>((resolve, reject) => {
      this.req = new XMLHttpRequest();
      this.req.responseType = "blob";
      this.req.open("GET", report_demo_url, true); //true means request will be async

      this.req.onreadystatechange = (aEvt) => {
        if (this.req.readyState === this.req.DONE) {
          if (this.req.status === 200) {
            // this.response is a Blob, because we set responseType above
            var data_url = window.URL.createObjectURL(this.req.response);
            resolve(data_url);
          } else {
            reject(this.req.statusText);
          }
          return;
        }
      };

      this.req.send();
    });
  }
}
