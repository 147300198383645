import {
  MdDashboard,
  MdBuild,
  MdNotificationsNone,
  MdTimeline,
  MdAssignment,
  MdPoll,
  MdSettings,
} from "react-icons/md";
import { permissions } from "../../services/permissions/permissions";
import storage, { IUser } from "../../services/base/storage";
import { Badge } from "react-bootstrap";
import {
  getExpiredMaintenance,
  getPendingNotifications,
} from "../../store/reducers/badge-reducer";
import { useSelector } from "react-redux";
import { Routes } from "Routes";

export interface menuItem {
  text: string;
  icon: JSX.Element;
  path: string;
  activate?: boolean;
  badge: number;
}

const checkPermissions = (permission: string | undefined): boolean => {
  const user: IUser | null = storage.getUser();
  if (!permission) return false;
  return user && user.permissions
    ? user.permissions.includes(permission)
    : false;
};

export const useMenuItems = () => {
  const maintenance = useSelector(getExpiredMaintenance);
  const notifications = useSelector(getPendingNotifications);   
  const user: IUser | null = storage.getUser();
  const items: menuItem[] = [
    {
      text: "Dashboard",
      icon: <MdDashboard size={"1.8rem"} />,
      path: Routes.dashboard,
      activate: checkPermissions(permissions.dashboard.view),
      badge: 0,
    },

    {
      text: "Maintenance",
      icon: (
        <>
          <MdBuild size={"1.8rem"} />
        </>
      ),
      path: Routes.maintenance,
      activate: checkPermissions(permissions.main.usage_maintenances.view),
      badge: maintenance,
    },
    {
      text: "Graph",
      icon: <MdTimeline size={"1.8rem"} />,
      path: Routes.graph,
      activate: checkPermissions(permissions.main.graph),
      badge: 0,
    },
    {
      text: "Analytics",
      icon: <MdPoll size={"1.8rem"} />,
      path: "/analytics",
      activate: checkPermissions(permissions.main.analytics.view),
      badge: 0,
    },
    {
      text: "Notifications",
      icon: <MdNotificationsNone size={"1.8rem"} />,
      path: Routes.notification,
      activate: checkPermissions(permissions.main.notification.view),
      badge: notifications,
    },
    {
      text: "Report",
      icon: <MdAssignment size={"1.8rem"} />,
      path: Routes.report,
      activate: checkPermissions(permissions.main.report.view),
      badge: 0,
    },

    {
      text: "Management",
      icon: <MdSettings size={"1.8rem"} />,
      path: Routes.management,
      activate:
        user?.token_type != "demo" &&
        (checkPermissions(permissions.auth.add) ||
          checkPermissions(permissions.main.usage_maintenances.add) ||
          checkPermissions(permissions.main.scheduled_maintenance.add)),
      badge: 0,
    },
  ];
  return items.filter((x) => x.activate === true);
};
