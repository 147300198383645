import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ResponseWrapper } from "../models/reponse";
import alertService from "../utils/alertService";
import { Moment } from "moment";
import { IPidResponse, statistic_plot_pid_service } from "./../services/statistic/statistic-plot-pid";
import { timezone_service } from "../services/timezoneService";

type IPlot = {
  start: Moment;
  end: Moment;
  struct_ids: number[];
  resample_rule?: number;  
};

export const usePlotPid = () => {
  const timezone: string = timezone_service.getTimezone();
  const load_plot = useMutation<ResponseWrapper<IPidResponse[]>, any, IPlot>({
    mutationFn: (data) => {
      return statistic_plot_pid_service.getPlot(
        data.start,
        data.end,
        data.struct_ids,
        timezone,
        data.resample_rule,
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {},
  });
  return load_plot;
};
