import { FunctionComponent, useContext } from "react";
import { createUseStyles } from "react-jss";
import ThemeContext from "../../context/theme/themeContext";
import { ITheme } from "../../context/theme/theme";
interface RadioButton3Props {
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = createUseStyles({
  customRadio: (theme: any) => ({
    left: "-8px",
    top: " 4px",
    cursor: " pointer",
    width: "16px",
    height: "16px",
    border: "1px solid #9a9a9a",
    borderRadius: "50%",
    display: "inline-block",
    position: "relative",
    "&::after": {
      content: "''",
      width: "8px",
      height: "8px",
      background: theme?.primary && theme.primary,
      position: "absolute",
      borderRadius: " 50%",
      top: "50%",
      left: " 50%",
      transform: "translate(-50%, -50%)",
      opacity: 0,
    },
  }),
  radioLabel: () => ({
    color: " #303030;",
    fontSize: "1rem",
    marginRight: "5px",
    " -webkit-tap-highlight-color": "transparent",
    cursor: "pointer",
  }),
  radioInput: (theme: any) => ({
    margin: "0",
    visibility: "hidden",
    "&:checked + span": {
      border: `0.4px solid ${theme?.primary}`,
    },
    "&:checked + span:after": {
      opacity: 1,
    },
  }),
});

const RadioButton3: FunctionComponent<RadioButton3Props> = ({
  label,
  name,
  value,
  checked,
  onChange,
}) => {
  const { theme } = useContext(ThemeContext);  
  const classes = useStyles(theme);
  return (
    <label
      htmlFor={`inline-${"radio"}-1-${label}`}
      className={classes.radioLabel}
    >
      <input
        className={classes.radioInput}
        type="radio"
        name={name}
        id={`inline-${"radio"}-1-${label}`}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className={classes.customRadio} />
      {label}
    </label>
  );
};

export default RadioButton3;

/*

 <label htmlFor={`inline-${"radio"}-1-${label}`} className="radio-label">
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={`inline-${"radio"}-1-${label}`}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      {label}
    </label>
*/
