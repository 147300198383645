import { Step } from "react-joyride";

export const scheduled_steps = (click_event: () => void) => {
  const steps: Step[] = [
    {
      placement: "center",
      target: "body",
      title: "Scheduled maintenance",
      content: (
        <div>
          <p>
            In this section it's possible to accomplish daily, weekly, monthly
            or yearly maintenance concerning the inspection, cleaning and control of
            the equipment.
          </p>
        </div>
      ),
    },
    {
      title: "Calendar",
      content: (
        <div>
          <p>
            Here you can see a calendar in which you can find the
            scheduled maintenances.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".toastui-calendar-layout",
    },
    {
      title: "Overview",
      content: (
        <div>
          <p>
            In this segment you can view the total number of completed and
            expired ones.
          </p>
        </div>
      ),

      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
      target: ".demo_scheduled_info",
    },
    {
      title: "Maintenance event",
      locale: {
        next: <a onClick={click_event}>Open</a>,
      },
      content: (
        <div>
          <p>
            This is a scheduled maintenance, let's open it to see further informations.
          </p>
        </div>
      ),
      showProgress: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: false,
      target: ".toastui-calendar-weekday-event",
    },
  ];
  return steps;
};

export const usage_steps = (click_event: () => void) => {
  const steps: Step[] = [
    {
      placement: "center",
      target: "body",
      title: "Usage-based maintenance",
      content: (
        <div>
          <p>
            These maintenance are based on the use of the engine and rely on one
            of its variables (example: monitoring of equipment on operating
            times, production cycles, etc).
            <br />
          </p>
        </div>
      ),
    },
    {
      title: "Overview",
      content: (
        <div>
          <p>
          In this segment you can view the total number of completed and
            expired ones.
          </p>
        </div>
      ),

      hideFooter: false,
      placement: "bottom",
      target: ".tour_info",
    },
    {
      title: "Search",
      content: (
        <div>
          <p>You can search by structure for easier access.</p>
        </div>
      ),

      hideFooter: false,
      placement: "bottom",
      target: ".tour_search",
    },
    {
      title: "List",
      content: (
        <div>
          <p>
            We can find all the maintenance grouped by structure. By selecting a
            structure you can view all the maintenance related to it.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_elements",
    },
    {
      title: "Maintenance status",
      locale: {
        next: <a onClick={click_event}>Open maintenance</a>,
      },
      content: (
        <div>
          <p>
            For each maintenance we can see a progress bar that indicates how
            much is left with the current and the expiration value (based on the
            use) to do the maintenance
          </p>

          <p></p>
          <p>
            The deadline and suggested date refers to the recommended dates
            for the maintenance calculated by the system based on the actual use
            of the equipment.
          </p>
        </div>
      ),
      showProgress: false,
      hideFooter: false,
      placement: "left",
      target: ".tour_element",
    },
  ];
  return steps;
};

export const operation_steps: Step[] = [
  {
    placement: "center",
    target: "body",
    title: "Maintenance details",
    content: (
      <div>
        <p>
          In this window we can find the information relative to the
          maintenance.
        </p>
        <p>If it's expired, we can complete it.</p>
      </div>
    ),
  },
  {
    title: "General information",
    content: (
      <div>
        <p>These are the general information of the maintenance.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_body",
  },

  {
    title: "Operations",
    content: (
      <div>
        <p>
          Every maintenance is associated with one or more operations. To
          complete a maintenance you have to accomplish every operation by
          cliking on the switch button.
        </p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_operations",
  },
  {
    title: "Comment",
    content: (
      <div>
        <p>You must add a comment allusive to the maintenance performed. </p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_comment",
  },
  {
    title: "Complete",
    content: (
      <div>
        <p>Clicking on this button will complete the maintenance.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_complete",
  },
];

export const report_maintenance_steps: Step[] = [
  {
    title: "Report maintenance",
    placement: "center",
    target: "body",
    content: (
      <div>
        <p>
          In the report section, we can generate reports containing informations
          on maintenance completed in the selected period.
        </p>
      </div>
    ),
  },
  {
    title: "From",
    content: (
      <div>
        <p>Select the start date of the date range for the report.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_from",
  },
  {
    title: "To",
    content: (
      <div>
        <p>Select the end date of the date range for the report.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_to",
  },
  {
    title: "Generate",
    content: (
      <div>
        <p>
          Click on the "Generate" button to create a report in PDF format.
        </p>
      </div>
    ),

    hideFooter: false,
    placement: "bottom",
    spotlightClicks: true,
    target: ".tour_generate",
  },
  {
    title: "Download",
    content: (
      <div>
        <p>
          Click on the "Download" button to create and download a report in PDF format.
        </p>
      </div>
    ),
    showProgress: false,
    hideFooter: false,
    placement: "bottom",
    spotlightClicks: true,
    target: ".tour_download",
  },
];
