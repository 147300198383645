import { ResponseWrapper } from "../../models/reponse";

import {
  IStruct,
  IStructByCategory,
  IStructDetailVariable,
  IVariableShort,
} from "../../services/struct/struct-service";
import { BaseServiceGenericDemo } from "../base-service-demo";
import { struct_category_demo_data, struct_demo_data } from "./data";

export class StructDemo extends BaseServiceGenericDemo<IStruct> {
  temperature: IStruct[];
  valve: IStruct[];
  constructor() {
    super();
    this.data = struct_demo_data.getDefaultStruct();
    this.temperature = struct_demo_data.getTemperaturetStruct();
    this.valve = struct_demo_data.getValveStruct();
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IStruct[]>> => {
    return new Promise<any>((resolve, reject) => {
      const type = query_params["processing"];

      if (type == undefined) {
        const default_: ResponseWrapper<IStruct[]> = {
          status: 200,
          version: "demo",
          response: this.data,
        } as ResponseWrapper<IStruct[]>;
        resolve(default_);
      }

      switch (type) {
        case "Default":
          const default_struct: ResponseWrapper<IStruct[]> = {
            status: 200,
            version: "demo",
            response: this.data,
          } as ResponseWrapper<IStruct[]>;

          resolve(default_struct);
          break;
        case "Temperature":
          const temperature_struct: ResponseWrapper<IStruct[]> = {
            status: 200,
            version: "demo",
            response: this.temperature,
          } as ResponseWrapper<IStruct[]>;

          resolve(temperature_struct);
          break;
        case "Valve":
          const valve_struct: ResponseWrapper<IStruct[]> = {
            status: 200,
            version: "demo",
            response: this.valve,
          } as ResponseWrapper<IStruct[]>;

          resolve(valve_struct);
          break;
        default:
          const default_: ResponseWrapper<IStruct[]> = {
            status: 200,
            version: "demo",
            response: [],
          } as ResponseWrapper<IStruct[]>;
          resolve(default_);
          break;
      }
    });
  };

  GetDetailById = (
    dataId: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IStructDetailVariable[]>> => {
    return new Promise<any>((resolve, reject) => {
      resolve("data");
    });
  };

  getStructByCategory = (
    categoryId: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IStructByCategory[]>> => {
    return new Promise<any>((resolve, reject) => {
      const res = struct_category_demo_data.getData();
      const filtro = res.filter(
        (x) => x.struct_variable.category?.id == categoryId
      );
      const response: ResponseWrapper<IStructByCategory[]> = {
        status: 200,
        version: "demo",
        response: filtro,
      } as ResponseWrapper<IStructByCategory[]>;
      resolve(response);
    });
  };
  getStructVariables = (
    id: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IVariableShort[]>> => {
    return new Promise<any>((resolve, reject) => {
      resolve("data");
    });
  };
}
