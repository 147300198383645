import { ResponseWrapper } from "../models/reponse";
import Http, { IHttp } from "./base/http-services";
import momentTZ from "moment-timezone";
import { emitter } from "./eventBus/emitter";
import storage from "services/base/storage";

export interface ITimezone {
  name: string;
  offset: string;
}

class TimezoneService {
  private current: string = "";
  private timezones: ITimezone[] = [];
  private server: ITimezoneService;
  constructor() {
    this.current = momentTZ.tz.guess();
    this.server = new TimezoneServer(Http);
  }

  getTimezone() {
    return this.current;
  }

  getOffset() {
    var offset = momentTZ.tz(this.current).format("Z");
    return offset;
  }

  setTimezone(timezone: string) {
    if (this.timezones.find((x) => x.name === timezone)) {
      this.current = timezone;
      emitter.emit("change_timezone", timezone);
    }
  }

  async getTimezones() {
    if (this.timezones.length === 0) {
      var timezones_list = await this.getTimezonesList();
      this.timezones = timezones_list;
    }
    return this.timezones;
  }

  private async getTimezonesList() {
    var new_timezones: ITimezone[] = [];
    //Only if Production mode is set.
    if (!process.env.REACT_APP_API_URL) {
      const timeZonesList = momentTZ.tz.names();
      for (let i in timeZonesList) {
        new_timezones.push({
          name: timeZonesList[i],
          offset: momentTZ.tz(timeZonesList[i]).format("Z"),
        });
      }
      //this.current = momentTZ.tz.guess();
    } else {
      //Otherwise  get the list from the API
      //Get Local timezone
      //this.current = momentTZ.tz.guess();
      const offset = momentTZ.tz(this.current).format("Z");
      new_timezones.push({
        name: this.current,
        offset: offset,
      });
      const user = storage.getUser();
      if (user == undefined) {
        if (!storage.isDemo()) {
          //Get Server timezone
          const result: ResponseWrapper<ITimezone> =
            await this.server.getServerTimezone();
          const { response } = result;
          if (response && response?.name !== this.current) {
            console.debug("here");
            new_timezones.push(response);
          }
        }
      }
    }
    return new_timezones;
  }
}

interface ITimezoneService {
  getServerTimezone: () => Promise<any>;
}

class TimezoneServer implements ITimezoneService {
  private http: IHttp;
  constructor(http: IHttp) {
    this.http = http;
  }
  public getServerTimezone() {
    return this.getPromise<ITimezone>(this.http.get("timezone/"));
  }

  private getPromise<T>(func: Promise<ResponseWrapper<T>>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
}

export const timezone_service = new TimezoneService();
