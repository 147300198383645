import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "../../../components/common/selects/select-option";
import { RootState } from "../../configureStore";
import { ITemperatureResponse } from "../../../services/statistic/statistic-plot-temperature";

// Define a type for the slice state
interface AnalyticsTemperatureState {
  start_date?: string;
  end_date?: string;
  structures_selected?: SelectOption[];
  signals?: ITemperatureResponse[];
}

// Define the initial state using that type
const initialState: AnalyticsTemperatureState = {};

export const analyticsTemperatureSlice = createSlice({
  name: "analytics-temperature",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveTemperatureStartDate(state, action: PayloadAction<any>) {
      state.start_date = action.payload;
    },
    saveTemperatureEndDate(state, action: PayloadAction<any>) {
      state.end_date = action.payload;
    },
    saveTemperatureStructuresSelected(
      state,
      action: PayloadAction<SelectOption[]>
    ) {
      state.structures_selected = action.payload;
    },
    saveTemperatureSignals(
      state,
      action: PayloadAction<ITemperatureResponse[] | undefined>
    ) {
      state.signals = action.payload;
    },
  },
});

export const {
  saveTemperatureStartDate,
  saveTemperatureEndDate,
  saveTemperatureStructuresSelected,
  saveTemperatureSignals,
} = analyticsTemperatureSlice.actions;

export const getAnalyticsTemperatureState = (state: RootState) =>
  state.analytics_temperature;

export default analyticsTemperatureSlice.reducer;
