import { Step } from "react-joyride";

export const notification_detail_steps: Step[] = [
  {
    title: "Notification detail",
    placement: "center",
    target: "body",
    content: (
      <div>
        <p>
          On notification details we see an overview of the notification. 
        </p>
        <p>At
          this point we can decide to confirm and definitively mark the
          observation as anomalous or mark it only as read.</p>
      </div>
    ),
  },
  {
    title: "Notification detail",
    content: (
      <div>
        <p>
          A brief summary of the notification containing the type, structure,
          threshold and a system message.
        </p>
      </div>
    ),

    spotlightPadding: 20,
    placement: "right",
    target: ".tour_information",
  },
  {
    title: "Signal",
    content: (
      <div>
        <p>Graph that shows the signal trends and patterns over time.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    placement: "left",
    target: ".tour_signal",
  },
  {
    title: "Comment",
    content: (
      <div>
        <p>Here you must add a comment allusive to the notification. </p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_comment",
  },
  {
    title: "Mark as anomaly",
    content: (
      <div>
        <p>You can mark the notifications as anomaly. </p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    placement: "left",
    target: ".tour_anomaly",
  },
  {
    title: "Mark as read",
    content: (
      <div>
        <p>You can mark the notification as read.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    placement: "left",
    target: ".tour_read",
  },
  {
    title: "Save changes",
    content: (
      <div>
        <p>For saving the feedback click on the "Save changes" button.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    placement: "left",
    target: ".tour_save",
  },
];

export const notification_steps = (click_event: () => void) => {
  const notification_steps: Step[] = [
    {
      title: "Notifications",
      placement: "center",
      target: "body",
      content: (
        <div>
          <p>
            On the <b>Notification</b> section we can keep track all the
            anomalies detected by the software, and eventually leave feedback
            based to the problems encountered.
          </p>
        </div>
      ),
    },
    {
      title: "Stats",
      content: (
        <div>
          <p>A summary of the notifications such as: </p>
          <ul>
            <li>
              Pending notifications (the number of unread notifications)
            </li>
            <li>Marked as anomalies</li>
            <li>Number of Notification by type</li>
            <li>Average notifications per structure </li>
          </ul>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      placement: "left",
      spotlightPadding: 20,
      target: ".tour_stats",
    },
    {
      title: "Search",
      content: (
        <div>
          <p>
            On the search field you can quickly filter the notification by
            structure.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_search",
    },
    {
      title: "Notification list",
      content: (
        <div>
          <p>
            Each item in the list represents a single notification that includes
            the following information:
            <ul>
              <li>
                Icon that represent the type of the notification <b>Warning</b>{" "}
                or <b>Trend</b>{" "}
              </li>
              <li>Title that contains the structure and the signal refer.</li>
              <li>Status that contains time and status of the notification.</li>
              <li>Click the "eye" button to see the details.</li>
            </ul>
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      placement: "right",
      target: ".tour_list",
    },
    {
      title: "Detail notification",
      content: (
        <div>
          <p>Clicking the button you can open the notification in detail. </p>
        </div>
      ),
      locale: {
        next: <a onClick={click_event}>Open</a>,
      },
      showProgress: false,
      floaterProps: {
        disableAnimation: true,
      },
      placement: "left",
      spotlightPadding: 20,
      target: ".tour_view_notification",
    },
  ];

  return notification_steps;
};
