import { useFormikContext } from "formik";
import _ from "lodash";
import get from "lodash/get";
import { FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import CheckboxGroup, { CheckBoxItem } from "../common/checkbox/checkbox-group";

interface FormCheckBoxProps {
  name: string;
  label: string;
  items: CheckBoxItem[];
}

const FormCheckBox: FunctionComponent<FormCheckBoxProps> = ({
  name,
  label,
  items,
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const error = get(errors, name);
  const value = get(values, name);

  let selected =
    items && value && items.filter((x: any) => value.includes(x.value));

  return (
    <>
      <Form.Group>
        <Form.Label as="legend" column sm={2}>
          {label}
        </Form.Label>
      </Form.Group>
      <CheckboxGroup
        name={name}
        selectItems={selected}
        items={items}
        onCheckedItemsChanged={(items) => {
          if (items && items.length > 0) {
            var items_value = items.map(function (x) {
              return x.value;
            });
            setFieldValue(name, items_value, true);
          }
        }}
      />
      <br />
      <span className="text-danger">{error && error}</span>
    </>
  );
};

export default FormCheckBox;
