import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormikValues } from "formik";
import { user_profile_service } from "../services/users/user-profile-service";
import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";

// TODO implement keys by file? or globals?

export const useGetProfile = () => {
  const profile = useQuery({
    queryKey: [QueryKeys.GET_PROFILE],
    queryFn: () => user_profile_service.getMe(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      alertService.errorMessage(error.response?.data);
    },
    cacheTime:0,
    //staleTime: StaleTime.SECONDS_15, //Important when the data is not valid
  });

  return profile;
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const save_profile = useMutation<any, any, FormikValues>({
    mutationFn: (data) => {
      return user_profile_service.update(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_PROFILE] });
      alertService.Ok("Profile updated");
    },
  });

  return save_profile;
};
