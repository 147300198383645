import { Annotations, Config, Data, PlotData, ScatterLine } from "plotly.js";
import { FunctionComponent } from "react";
import Plot from "react-plotly.js";
import {
  IValve,
  IValveResponse,
} from "../../../../services/statistic/statistic-plot-valve";
import { config } from "../config";
import moment from "moment";
interface PlotValveProps {
  signal: IValve;
  name: string;
  unit_of_measurement: string;
  highlight?: { time: string; value: number };
  onInitialized?: any;
  onUpdate?: any;
  onRelayout?: any;
}

const PlotValve: FunctionComponent<PlotValveProps> = ({
  signal,
  name,
  unit_of_measurement,
  onInitialized,
  onRelayout,
  onUpdate,
  highlight,
}) => {
  const status = undefined;

  const start_error: Date[] = [];
  const start_success: Date[] = [];
  const durations_error: number[] = [];
  const durations_success: number[] = [];
  signal.out_of_tholds?.forEach((value, index) => {
    if (value >= 1) {
      start_error.push(signal.starts[index]);
      durations_error.push(signal.durations[index]);
    } else {
      start_success.push(signal.starts[index]);
      durations_success.push(signal.durations[index]);
    }
  });

  const bar_current: Partial<Data> = {
    x: start_success,
    y: durations_success,
    text: durations_success.map(String),
    name: "Motion time", // always blank with custom hover template
    mode: "lines+markers",
    type: "bar",
    customdata: undefined, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    showlegend: true,
    marker: {
      color: "rgb(102,194,165)",
      //color: myArrayColors,
      opacity: 0.6,
    },
  };

  const bar_not_reach: Partial<Data> = {
    x: start_error,
    y: durations_error,
    text: durations_error.map(String),
    name: "Warnings", // always blank with custom hover template
    mode: "number",
    type: "bar",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    showlegend: true,
    marker: {
      color: "red",
      opacity: 0.6,
    },
  };

  const signal_current_out: Partial<Data> = {
    x: start_error,
    y: durations_error,
    name: "Motion time", // always blank with custom hover template
    mode: "markers",
    customdata: status, //handleStatus(signal.status),
    hovertemplate:
      "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <b>Info:</b>  %{customdata} <br /><br />",
    line: {
      color: "red",
      shape: "spline",
      width: 4,
      //opacity: 0.5,
    },
    visible: false,
    showlegend: true,
  };

  const signal_current: Partial<Data> = {
    x: start_success,
    y: durations_success,
    name: "Motion time", // always blank with custom hover template
    mode: "lines+markers",
    customdata: status, //handleStatus(signal.status),
    hovertemplate:
      "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <b>Info:</b>  %{customdata} <br /><br />",
    line: {
      color: "rgb(102,194,165)",
      shape: "spline",
      width: 4,
      //opacity: 0.5,
    },
    visible: false,
    showlegend: true,
  };

  const trend_values = signal.trend ? signal.trend : null;

  //Aggiungo 1 giorno per allugare le threshold perché i grafici di tipo barra
  // hanno un width grosso.
  const x_high_low = start_success
    ? moment(start_success[0]).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;
  const y_high_low = trend_values
    ? moment(trend_values?.end.time).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : moment(start_success.slice(-1)[0])
        .add(1, "d")
        .format("YYYY-MM-DD HH:mm:ss");

  const high_threshold: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      signal.starts && signal.threshold_max ? signal.threshold_max : null,
      signal.starts && signal.threshold_max ? signal.threshold_max : null,
    ],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    // line: { color: "#E7E6F0", width: "2" },
  };

  const low_threashold: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      signal.starts && signal.threshold_min ? signal.threshold_min : null,
      signal.starts && signal.threshold_min ? signal.threshold_min : null,
    ],
    mode: "lines",
    name: "Low threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    //line: { color: "#E7E6F0", width: "2" },
  };

  const trend: Partial<Data> = {
    x: [
      trend_values && trend_values?.start.time,
      trend_values && trend_values?.end.time,
    ],
    y: [
      trend_values && trend_values?.start.value,
      trend_values && trend_values?.end.value,
    ],
    name: "Trend",
    mode: "lines",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFA3A6", shape: "spline", width: 1, dash: "dot" },
  };

  const annotation_highlight: Array<Partial<Annotations>> = [
    {
      x: highlight ? highlight?.time : "",
      y: highlight ? highlight?.value : "",
      xref: "x",
      yref: "y",
      text: "Warning",
      showarrow: true,
      arrowhead: 1,
      ax: 0,
      ay: -50,
      arrowcolor: "grey",
    },
  ];

  //Style
  //Style empty
  //xref /yref = x domain doest exist in the library react-plotly
  //const annotation_empty: Array<Partial<Annotations>>
  const annotation_empty = signal.durations
    ? undefined
    : [
        {
          text: "No data found",
          xref: "x domain",
          yref: "y domain",
          showarrow: false,
          font: {
            size: 24,
          },
        },
      ];
  //https://community.plotly.com/t/how-to-make-the-messy-date-ticks-organized/7477/3
  const layout: any = {
    //title: signal.description,
    title: name + "<br />" + signal.name,
    hoverlabel: { bgcolor: "#FFF" },
    height: 300,
    autosize: true,
    annotations: annotation_empty
      ? annotation_empty
      : highlight
      ? annotation_highlight
      : undefined,
    margin: {
      r: "50",
      l: "50",
      t: "80",
      b: "50",
    },
    showlegend: signal.durations ? true : false,
    xaxis: {
      range: [
        signal.starts && signal.starts[0],
        signal.starts && signal.starts.slice(-1),
      ],
      //type: "date",
      visible: signal.durations ? true : false,
      showgrid: true,
      //tickmode:"linear",
      //tickformat: '%e %b, %y %H:%M',
      //tickformat: "%e %b, %y",
      //tick0:signal.time[0],
      //dtick:"M1" //30 * 24 * 60 * 60 * 1000 // milliseconds
      // rangeselector: selectorOptions, TODO
    },
    yaxis: {
      //title: signal && signal.unit_of_measurement && signal.unit_of_measurement,
      visible: signal.durations ? true : false,
      ticksuffix: signal && unit_of_measurement && " " + unit_of_measurement,
      //showtickprefix : 'last'
      showgrid: true,
    },
    // transition: {
    //   duration: 500,
    //   easing: "cubic-in-out",
    // },
    // frame: {
    //   duration: 500,
    // },
    updatemenus: [
      {
        direction: "left",
        pad: { r: 0, t: 10 },
        showactive: true,
        type: "buttons",
        x: 0,
        xanchor: "left",
        y: 1.35,
        yanchor: "top",
        //bgcolor: '#aaaaaa',
        bordercolor: "#BEC8D9",
        buttons: [
          {
            args: [{ visible: [true, true, false, false, true, true, true] }],
            label: "Bar",
            method: "update",
          },
          {
            args: [{ visible: [false, false, true, true, true, true, true] }],
            label: "Points",
            method: "update",
          },
        ],
      },
    ],
  };

  return (
    <Plot
      data={[
        bar_current,
        bar_not_reach,
        signal_current,
        signal_current_out,
        high_threshold,
        low_threashold,
        trend,
      ]}
      style={{ margin: 0, backgroundColor: "none" }}
      config={config}
      useResizeHandler={true}
      layout={layout}
      divId={signal.name}
      //onInitialized={(figure) => onInitialized(figure, signal.id)}
      //onUpdate={(figure) => onUpdate(figure, signal.id)}
      onRelayout={onRelayout}
    />
  );
};

export default PlotValve;
