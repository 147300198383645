import { Data } from "plotly.js";
import {
  IPeakNumber,
  IPeakTime,
  IStationary,
  ITransient,
} from "../../../../services/statistic/statistic-plot-pid";
import { color_plot } from "../colors";
import moment from "moment";

export const createTransientTraces = (
  starts: Date[],
  transient: ITransient,
  isVisible: boolean = true
) => {
  const transient_error: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  const transient_success: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };
  const transient_unfinish: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };
  transient.n_durations_out_of_threshold?.forEach((value, index) => {
    if (value >= 1) {
      transient_error.time.push(starts[index]);
      transient_error.value.push(transient.durations[index]);
    } else {
      const unfinished = transient.n_unfinished[index];
      if (unfinished >= 1) {
        transient_unfinish.time.push(starts[index]);
        transient_unfinish.value.push(transient.durations[index]);
      } else {
        transient_success.time.push(starts[index]);
        transient_success.value.push(transient.durations[index]);
      }
    }
  });

  const bar_transient: Partial<Data> = {
    x: transient_success.time,
    y: transient_success.value,
    //text: durations_success.map(String),
    name: "Transient time", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",
    showlegend: true,
    marker: {
      color: "green",
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: "x",
    yaxis: "y",
    visible: true,
  };

  const bar_transient_warnings: Partial<Data> = {
    x: transient_error.time,
    y: transient_error.value,
    //text: durations_success.map(String),
    name: "Warnings", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",
    showlegend: true,
    marker: {
      color: "red",
      //color: myArrayColors,
      opacity: 0.6,
    },
    visible: true,
    xaxis: "x",
    yaxis: "y",
  };

  const bar_transient_unfinish: Partial<Data> = {
    x: transient_unfinish.time,
    y: transient_unfinish.value,
    //text: durations_success.map(String),
    name: "Unfinish", // always blank with custom hover template
    mode: "number",
    type: "bar",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    showlegend: true,
    marker: {
      color: color_plot[9],
      //color: myArrayColors,
      opacity: 0.6,
    },
    visible: true,
    xaxis: "x",
    yaxis: "y",
  };

  const { trend, duration_threshold } = transient;

  const x_high_low = trend?.start?.time
    ? moment(trend?.start?.time).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : starts
    ? moment(starts[0]).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;
  const y_high_low = trend?.end?.time
    ? moment(trend?.end?.time).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : starts
    ? moment(starts.slice(-1)[0]).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;

  const threshold_trasient: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      duration_threshold ? duration_threshold : null,
      duration_threshold ? duration_threshold : null,
    ],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    visible: true,
    xaxis: "x",
    yaxis: "y",
  };

  const trend_transient: Partial<Data> = {
    x: [
      trend?.start?.time ? trend.start?.time : null,
      trend?.end?.time ? trend.end?.time : null,
    ],
    y: [
      trend?.start?.value ? trend?.start?.value : null,
      trend?.end?.value ? trend?.end?.value : null,
    ],
    name: "Trend",
    mode: "lines",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFA3A6", shape: "spline", width: 1, dash: "dot" },
    xaxis: "x",
    yaxis: "y",
  };

  return [
    bar_transient,
    bar_transient_warnings,
    bar_transient_unfinish,
    threshold_trasient,
    trend_transient,
  ];
};

export const createStationary = (
  starts: Date[],
  stationary: IStationary,
  isVisible: boolean = true,
  xaxis: string = "x",
  yaxis: string = "y"
) => {
  const stationary_error: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };
  const stationary_success: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  stationary?.default?.n_avgs_out_of_threshold?.forEach((value, index) => {
    if (stationary.default?.avgs) {
      if (value >= 1) {
        stationary_error.time.push(starts[index]);
        stationary_error.value.push(stationary?.default?.avgs[index]);
      } else {
        stationary_success.time.push(starts[index]);
        stationary_success.value.push(stationary?.default?.avgs[index]);
      }
    }
  });

  const bar_stationary: Partial<Data> = {
    x: stationary_success.time,
    y: stationary_success.value,
    //text: durations_success.map(String),
    name: "Stationary time", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",
    showlegend: true,
    marker: {
      color: "blue",
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: xaxis,
    yaxis: yaxis,
    visible: isVisible,
  };

  const bar_stationary_warnings: Partial<Data> = {
    x: stationary_error.time,
    y: stationary_error.value,
    //text: durations_success.map(String),
    name: "Warnings", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",

    showlegend: true,
    marker: {
      color: "red",
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: xaxis,
    yaxis: yaxis,
    visible: isVisible,
  };
  const trend = stationary?.default?.trend;

  const x_high_low = trend?.start?.time
    ? moment(trend?.start?.time).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : starts
    ? moment(starts[0]).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;
  const y_high_low = trend?.end?.time
    ? moment(trend?.end?.time).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : starts
    ? moment(starts.slice(-1)[0]).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;

  const threshold_stationary: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      stationary.default?.avg_threshold
        ? stationary.default?.avg_threshold
        : null,
      stationary.default?.avg_threshold
        ? stationary.default?.avg_threshold
        : null,
    ],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    visible: isVisible,
    xaxis: xaxis,
    yaxis: yaxis,
  };

  const trend_stationary: Partial<Data> = {
    x: [
      trend?.start?.time ? trend.start?.time : null,
      trend?.end?.time ? trend.end?.time : null,
    ],
    y: [
      trend?.start?.value ? trend?.start?.value : null,
      trend?.end?.value ? trend?.end?.value : null,
    ],
    name: "Trend",
    mode: "lines",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFA3A6", shape: "spline", width: 1, dash: "dot" },
    visible: isVisible,
    xaxis: xaxis,
    yaxis: yaxis,
  };

  return [
    bar_stationary,
    bar_stationary_warnings,
    threshold_stationary,
    trend_stationary,
  ];
};

export const createPeakNumbers = (
  starts: Date[],
  peak_number: IPeakNumber,
  isVisible: boolean = true,
  xaxis: string = "x",
  yaxis: string = "y"
) => {
  const peak_error: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };
  const peak_success: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  peak_number?.n_peaks_out_of_threshold?.forEach((value, index) => {
    if (peak_number.n_peaks_worst_window) {
      if (value >= 1) {
        peak_error.time.push(starts[index]);
        peak_error.value.push(peak_number?.n_peaks_worst_window[index]);
      } else {
        peak_success.time.push(starts[index]);
        peak_success.value.push(peak_number?.n_peaks_worst_window[index]);
      }
    }
  });

  const bar_peak_success: Partial<Data> = {
    x: peak_success.time,
    y: peak_success.value,
    //text: durations_success.map(String),
    name: "Peak number", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",
    showlegend: true,
    marker: {
      color: color_plot[2],
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: xaxis,
    yaxis: yaxis,
    visible: isVisible,
  };

  const bar_peak_warnings: Partial<Data> = {
    x: peak_error.time,
    y: peak_error.value,
    //text: durations_success.map(String),
    name: "Warnings", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",

    showlegend: true,
    marker: {
      color: "red",
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: xaxis,
    yaxis: yaxis,
    visible: isVisible,
  };

  const x_high_low = starts
    ? moment(starts[0]).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;
  const y_high_low = starts
    ? moment(starts.slice(-1)[0]).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;

  const threshold_peak_number: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      peak_number.n_peaks_threshold ? peak_number.n_peaks_threshold : null,
      peak_number.n_peaks_threshold ? peak_number.n_peaks_threshold : null,
    ],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    visible: isVisible,
    xaxis: xaxis,
    yaxis: yaxis,
  };

  return [bar_peak_success, bar_peak_warnings, threshold_peak_number];
};

export const createPeaksTime = (
  starts: Date[],
  peak_number: IPeakTime,
  isVisible: boolean = true,
  xaxis: string = "x",
  yaxis: string = "y"
) => {
  const peak_error: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };
  const peak_success: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  peak_number?.n_peaks_time_above_out_of_threshold?.forEach((value, index) => {
    if (peak_number.times_above_worst_window) {
      if (value >= 1) {
        peak_error.time.push(starts[index]);
        peak_error.value.push(peak_number?.times_above_worst_window[index]);
      } else {
        peak_success.time.push(starts[index]);
        peak_success.value.push(peak_number?.times_above_worst_window[index]);
      }
    }
  });

  const bar_peak_success: Partial<Data> = {
    x: peak_success.time,
    y: peak_success.value,
    //text: durations_success.map(String),
    name: "Peak Time", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",
    showlegend: true,
    marker: {
      color: color_plot[3],
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: xaxis,
    yaxis: yaxis,
    visible: isVisible,
  };

  const bar_peak_warnings: Partial<Data> = {
    x: peak_error.time,
    y: peak_error.value,
    //text: durations_success.map(String),
    name: "Warnings", // always blank with custom hover template
    mode: "number",
    type: "bar",
    //customdata: status, //handleStatus(signal.status),
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <br />",

    showlegend: true,
    marker: {
      color: "red",
      //color: myArrayColors,
      opacity: 0.6,
    },
    xaxis: xaxis,
    yaxis: yaxis,
    visible: isVisible,
  };

  const x_high_low = starts
    ? moment(starts[0]).subtract(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;
  const y_high_low = starts
    ? moment(starts.slice(-1)[0]).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : null;

  const threshold_peak_number: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      peak_number.peaks_time_above_threshold
        ? peak_number.peaks_time_above_threshold
        : null,
      peak_number.peaks_time_above_threshold
        ? peak_number.peaks_time_above_threshold
        : null,
    ],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    visible: isVisible,
    xaxis: xaxis,
    yaxis: yaxis,
  };

  return [bar_peak_success, bar_peak_warnings, threshold_peak_number];
};
