import { ICategory } from "../../services/struct/signal-category-service";
import data from "./signal-category-data.json";

class CategoryDemoData {
  private data: ICategory[];
  constructor() {
    this.data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(data.response);
    const obj: ICategory[] = JSON.parse(myJSON);
    return obj;
  };

  getData = () => {
    return this.data;
  };

  update = (new_data: ICategory) => {
    const index = this.data.findIndex((x) => x.id === new_data.id);
    if (index > -1) {
      this.data[index] = new_data;
    }
  };
}
export const signal_category_data = new CategoryDemoData();
