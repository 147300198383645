import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QueryKeys, StaleTime } from "../query_keys";
import {
  ITemperatureCondition,
  temperature_condition_service,
} from "../../services/temperature/temperature-condition-service";
import alertService from "../../utils/alertService";
import { ResponseWrapper } from "../../models/reponse";
import { useHistory } from "react-router-dom";
import { FormikValues } from "formik";
import { Routes } from "../../Routes";
import { temperature_service } from './../../services/temperature/temperature-service';

export const useGetTemperatures = () => {
  const temperatures = useQuery({
    queryKey: [QueryKeys.MANAGEMENT_GET_TEMPERATURE],
    queryFn: () => temperature_service.get(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_15, //Important when the data is not valid
  });
  return temperatures;
};