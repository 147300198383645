import { ResponseWrapper } from "../models/reponse";
import { IApiClient } from "services/base/wrapper/api-client";
import Http, { IHttp } from "./base/http-services";
import storage from "services/base/storage";
import { BadgeDemoService } from "demo/badge/badge-service-demo";
import { AxiosError } from "axios";

export interface ScheduledMaintenance {
  expired_count: number;
  today_count: number;
}

export interface UsageBasedMaintenance {
  expired_count: number;
  today_count: number;
}

export interface Notifications {
  valve_count: number;
  temperature_count: number;
  default_count: number;
  pid_count: number;
}

export interface IBadge {
  scheduled_maintenance: ScheduledMaintenance;
  usage_based_maintenance: UsageBasedMaintenance;
  notifications: Notifications;
}

export class BadgeService {
  private http: IHttp;
  private endpoint = "badges";

  constructor() {
    this.http = Http;
  }

  //: Promise<AxiosResponse<ResponseWrapper<IBadge>>>

  get = (query_params: Record<string, any> = {}) => {
    let params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get<ResponseWrapper<IBadge>>(this.endpoint + "/" + "?" + params)
    );
  };

  protected getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<IBadge>);
        })
        .catch((response: Error | AxiosError<any>) => {
          reject(response);
        });
    });
  }

  private generateParams(query_params: Record<string, any> = {}): string {
    let params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }
}
//Workaround for the badges.
//Problem: when Logout and refresh the page the query will try to fecth the badges
// But if i don't have connection will fail,
//For avoid this i current check if an user is active if not understand that iam on demo.

//For testing this behavior you have to stop the analyser container
// login with demo account, them logout.
const user = storage.getUser();
const isDemo = storage.isDemo();


//only for demo
//const isDemo = user == undefined ? true : storage.isDemo();

export const badge_service = isDemo
  ? new BadgeDemoService()
  : new BadgeService();

