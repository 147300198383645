import moment, { Moment } from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import CardCollapsible from "../../components/common/card-collapsible";
import { SelectOption } from "../../components/common/selects/select-option";
import DateTimePicker from "../../components/common/date-time-picker";
import { Button, Col, Row, Card, Badge } from "react-bootstrap";
import SelectMultiple from "../../components/common/selects/select-multiple";
import { IStruct } from "../../services/struct/struct-service";
import { MdSearch } from "react-icons/md";
import { ITemperatureResponse } from "../../services/statistic/statistic-plot-temperature";
import PlotTemperature from "../../components/features/plot-analytics/temperature/plot-temperature";
import Backdrop from "../../components/common/backdrop/backdrop";
import SelectSingle from "../../components/common/selects/select-single";
import { usePlotTemperature } from "../../queries/analytics.temperature.hook";
import { useGeteStructTemp } from "../../queries/struct.hook";
import alertService from "../../utils/alertService";
import {
  isLessThatTodayAndDate,
  isLessThatTodayAndIsAfterDate,
} from "../../utils/date-operations";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsTemperatureState,
  saveTemperatureEndDate,
  saveTemperatureSignals,
  saveTemperatureStartDate,
  saveTemperatureStructuresSelected,
} from "./../../store/reducers/analytics/analytics.temperature-reducer";

interface TemperaturePageProps {}

const TemperaturePage: FunctionComponent<TemperaturePageProps> = () => {
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(7, "d"));
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [structs, setStruct] = useState<SelectOption[]>([]);
  const [structSelected, setStructsSelected] = useState<SelectOption[]>([]);
  const [signals, setSignals] = useState<ITemperatureResponse[]>([]);
  const [resample, setResample] = useState<SelectOption>();

  const dispatch = useDispatch();
  const analytics_state = useSelector(getAnalyticsTemperatureState);

  const get_struct_temp = useGeteStructTemp();

  const load_plot = usePlotTemperature();

  const mapObject = (response: IStruct[]) => {
    const structOptions: SelectOption[] = [];

    response.forEach((struct) => {
      if (struct.name && struct.id)
        structOptions.push({ label: struct.name, value: struct.id.toString() });
    });

    return structOptions;
  };

  const handlePlot = async () => {
    if (structSelected.length <= 0) {
      alertService.errorMessage("Select structures");
      return;
    }
    const ids: number[] = [];
    structSelected.forEach((x) => {
      if (x.value) {
        ids.push(parseInt(x.value));
      }
    });
    const result = await load_plot.mutateAsync({
      start: startDate,
      end: endDate,
      struct_ids: ids,
      resample_rule: resample?.value ? parseInt(resample.value) : undefined,
    });
    if (result.response) {
      setSignals(result.response);
      dispatch(saveTemperatureSignals(result.response));
    }
  };

  const resample_options: SelectOption[] = [
    { value: undefined, label: "All" },
    { value: "43200", label: "12H" },
    { value: "86400", label: "1D" },
  ];

  //Update when data changes.
  useEffect(() => {
    if (get_struct_temp.data?.response) {
      const options = mapObject(get_struct_temp.data?.response);
      setStruct(options);
    }
  }, [get_struct_temp.data]);

  //update on first render component.
  useEffect(() => {
    if (structs.length == 0) {
      if (get_struct_temp.data?.response) {
        const options = mapObject(get_struct_temp.data?.response);
        setStruct(options);
      }
    }
  }, []);

  useEffect(() => {
    if (analytics_state.signals) {
      setSignals(analytics_state.signals);
    }

    if (analytics_state.structures_selected) {
      setStructsSelected(analytics_state.structures_selected);
    }
    if (analytics_state.start_date) {
      setStartDate(moment(analytics_state.start_date));
    }
    if (analytics_state.end_date) {
      setEndDate(moment(analytics_state.end_date));
    }
  }, []);

  const graph_height = 350;

  return (
    <>
      <Backdrop show={load_plot.isLoading} />
      <CardCollapsible
        title={
          <>
            <h5>Temperatures</h5>
            <Badge className="ms-1" bg="secondary">
              {structSelected && structSelected?.length > 0 && (
                <span>{structSelected?.length} selected</span>
              )}
            </Badge>
            <Badge className="ms-1" bg="secondary">
              <span>
                {startDate.format("L")} - {endDate.format("L")}
              </span>
            </Badge>
          </>
        }
      >
        <Row>
          <Col sm={12} md={5} lg={5}>
            <SelectMultiple
              placeholder="Select temperatures..."
              label={"Temperatures"}
              options={structs}
              value={structSelected}
              onValueChange={(data) => {
                setStructsSelected(data);
                dispatch(saveTemperatureStructuresSelected(data));
              }}
            />
          </Col>
          <Col xs={6} sm={6} md={2} lg={2}>
            <label>From</label>
            <DateTimePicker
              selectedDate={startDate}
              timeEnabled={false}
              dateEnabled={true}
              onDateChange={(date: string | Moment) => {
                const _date = moment(date);
                setStartDate(_date);
                dispatch(saveTemperatureStartDate(_date));
              }}
              placeholder={"datetime"}
              isValidDate={(current, selected) =>
                isLessThatTodayAndDate(current, endDate)
              }
            />
          </Col>
          <Col xs={6} sm={6} md={2} lg={2}>
            <label>To</label>
            <DateTimePicker
              selectedDate={endDate}
              timeEnabled={false}
              dateEnabled={true}
              onDateChange={(date: string | Moment) => {
                const _date = moment(date);
                setEndDate(_date);
                dispatch(saveTemperatureEndDate(_date));
              }}
              placeholder={"datetime"}
              isValidDate={(current, selected) =>
                isLessThatTodayAndIsAfterDate(current, startDate)
              }
            />
          </Col>
          <Col xs={9} sm={8} md={2} lg={2}>
            <SelectSingle
              placeholder="Select ..."
              label={"Resample"}
              options={resample_options}
              onValueChange={setResample}
            />
          </Col>
          <Col xs={3} lg={1} md={1} sm={3}>
            <label></label>
            <br></br>
            <Button variant="secondary" onClick={handlePlot}>
              <MdSearch size={25}></MdSearch>
            </Button>
          </Col>
        </Row>
      </CardCollapsible>

      <Row>
        {signals &&
          signals.length > 0 &&
          signals.map((signal, index) => (
            <Col
              key={"plot-temperature-" + index}
              lg={6}
              md={12}
              sm={12}
              className="mb-2"
            >
              <Card className="p-2" style={{ minHeight: graph_height }}>
                <PlotTemperature name={signal.name} signal={signal.data[0]} />
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default TemperaturePage;
