import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import moment, { Moment } from "moment";
import { Card, Modal, Button, Row, Col, Container } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  MdSync,
  MdOpenInNew,
  MdDelete,
  MdCloudDownload,
  MdRefresh,
  MdMailOutline,
  MdTimer,
  MdOutlineAssignment,
} from "react-icons/md";

import { IReportNotification } from "services/reports/report-notification-service";
import {
  getDateOnString,
  isLessThatTodayAndDate,
  isLessThatTodayAndIsAfterDate,
} from "../utils/date-operations";
import Backdrop from "components/common/backdrop/backdrop";
import * as alertService from "utils/alertService";
import Restricted from "context/permission/restricted";
import { permissions } from "services/permissions/permissions";
import { request_service } from "services/requestService";
import { sleep } from "utils/timers";
import { file_saver } from "services/fileSaver/file-saver";
import PDFViewer from "components/pdf/pdfViewer";
import SelectMultiple from "components/common/selects/select-multiple";
import { SelectOption } from "components/common/selects/select-option";
import withLayout from "hoc/withLayout";
import CustomButton from "components/common/buttons/custom-button";
import { DEFAULT_HOST, getUrl } from "services/base/http-services";
import ListItemCustom, {
  ItemCustom,
} from "components/features/notification/list-item-custom";
import Icon from "components/common/Icon";
import { StructOrder, useGetStructs } from "queries/struct.hook";
import {
  useDeleteReport,
  useGenerateReportByStructs,
  useGetReports,
} from "queries/report.hook";

import { Tour, report_steps } from "tour";
import TourContext from "context/tour/tourContext";
import { useWindowSize } from "react-use";
interface ReportPDFProps {}

const ReportPDF: FunctionComponent<ReportPDFProps> = () => {
  const [data, setData] = useState<IReportNotification[]>([]);
  const [struct, setStruct] = useState<any[]>([]);
  const [structSelected, setStructSelected] = useState<SelectOption[]>([]);
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(7, "d"));
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [url_media, setUrlMedia] = useState<string>();
  const { setTour } = useContext(TourContext);
  const structs = useGetStructs();

  const reports = useGetReports();

  const generate_report = useGenerateReportByStructs();

  const delete_report = useDeleteReport();

  const { width, height } = useWindowSize();

  //#region actions button

  const handleOpenPdf = async (path: string) => {
    try {
      setIsBusy(true);
      await sleep(500);
      const result = await request_service.getUrl(path);
      setPdfUrl(result);
      setShowModal(true);
      setIsBusy(false);
    } catch (error) {
      alertService.errorMessage("An error occurred trying to open the PDF");
      setIsBusy(false);
    }
  };

  const handleDownloadPdf = async (path: string, name: string) => {
    try {
      setIsBusy(true);
      await sleep(500);

      file_saver
        .SaveFromUrl(path, name)
        .then(() => {})
        .catch((error) => {
          if (error) {
            alertService.errorMessage(error);
            return;
          }
          alertService.errorMessage(
            "An error occurred trying to download the PDF"
          );
        })
        .finally(() => {
          setIsBusy(false);
        });
      setIsBusy(false);
      //saveAs(path, "Report_" + name + ".pdf", { autoBom: false });
    } catch (error: any) {
      setIsBusy(false);
    }
  };

  const handleDeleteReport = async (id: number) => {
    try {
      let func = async () => await delete_report.mutateAsync(id);
      let response = await alertService.WaitForRequest(
        "Confirm",
        "This operation can't be undo ",
        func
      );
    } catch (error: any) {
      alertService.errorMessage(
        "An error occurred trying to delete the report"
      );
    }
  };

  //#endregion

  const GetReportGenerated = async () => {
    try {
      const start_date_string = getDateOnString(startDate.toDate());
      const end_date_string = getDateOnString(endDate.toDate());

      const struct_id: string[] = [];
      structSelected.forEach((x) => {
        if (x.value) {
          struct_id.push(x.value);
        }
      });

      await generate_report.mutateAsync({
        start_date: start_date_string,
        end_date: end_date_string,
        structs: struct_id,
      });
    } catch (error: any) {
      // alertService.errorMessage(
      //   "An error occurred trying to generate the report"
      // );
    }
  };

  const loadMediaUrl = () => {
    let url = getUrl();
    // in case of url return undefined put the localhost url in the url
    if (url) {
      const urlObject = new URL(url);
      console.debug(urlObject.origin);
      setUrlMedia(urlObject.origin + "/");
    } else {
      setUrlMedia(DEFAULT_HOST);
    }
  };

  useEffect(() => {
    loadMediaUrl();
  }, []);

  useEffect(() => {
    if (reports.data?.response) {
      setData(reports.data.response);
    }
  }, [reports.data]);

  useEffect(() => {
    if (structs.data?.response) {
      const { response } = structs.data;
      const result = StructOrder(response, "type_id.type");
      setStruct(result);
    }
  }, [structs.data]);

  const loading =
    isBusy ||
    structs.isLoading ||
    reports.isRefetching ||
    reports.isLoading ||
    generate_report.isLoading;

  return (
    <React.Fragment>
      <Tour steps={report_steps} onTourFinish={() => setTour(false)} />
      <Backdrop show={loading} />

      <Restricted to={permissions.main.report.view} showFallback={true}>
        <br />
        <Container fluid>
          <Row className="mb-2">
            <Col>
              <Restricted to={permissions.main.report.add}>
                <Card className="p-3">
                  <h5>Select range to generate a report</h5>
                  <hr />
                  <Row>
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="tour_structures"
                    >
                      <SelectMultiple
                        placeholder="Select structures..."
                        label={"Structures"}
                        options={struct}
                        onValueChange={setStructSelected}
                      />
                    </Col>
                    <Col
                      xl={2}
                      lg={2}
                      md={2}
                      sm={6}
                      xs={6}
                      className="tour_from"
                    >
                      <label>From:</label>
                      <Datetime
                        isValidDate={(current, selected) =>
                          isLessThatTodayAndDate(current, endDate)
                        }
                        initialValue={startDate}
                        inputProps={{ placeholder: "From" }}
                        timeFormat={false}
                        onChange={(date: any) => setStartDate(date)}
                      />
                    </Col>

                    <Col xl={2} lg={2} md={2} sm={6} xs={6} className="tour_to">
                      <label>To:</label>
                      <Datetime
                        isValidDate={(current, selected) =>
                          isLessThatTodayAndIsAfterDate(current, startDate)
                        }
                        initialValue={endDate}
                        timeFormat={false}
                        inputProps={{ placeholder: "From" }}
                        onChange={(date: any) => setEndDate(date)}
                      />
                    </Col>

                    <Col
                      className="justify-content-center align-items-center tour_generate"
                      xl={2}
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                    >
                      <div className="d-grid gap-4">
                        <label></label>

                        <CustomButton
                          color="#6c757d"
                          outline={true}
                          onClick={GetReportGenerated}
                        >
                          Generate <MdSync size={25}></MdSync>
                        </CustomButton>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Restricted>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Card className="p-3">
                <h4>Reports</h4>
                <hr></hr>

                <Row>
                  <div className="d-flex justify-content-end">
                    <a
                      onClick={() => reports.refetch()}
                      className="btn mr-1 btn-sm"
                    >
                      <MdRefresh /> Refresh
                    </a>
                  </div>
                </Row>
                <Row>
                  <Col className="tour_list">
                    <ListItemCustom
                      data={data}
                      keyExtrator={(item) => item.id.toString()}
                      renderItem={(item) => {
                        return (
                          <>
                            <ItemCustom
                              Icon={
                                <Icon
                                  backgroundColor={"grey"}
                                  Icon={MdOutlineAssignment}
                                  size={50}
                                />
                              }
                              title={
                                moment(item.start_date).format("MMM Do YY") +
                                " to " +
                                moment(item.end_date).format("MMM Do YY")
                              }
                              subtitle={
                                <>
                                  <span className="me-2">
                                    {item.interval_coefficient
                                      ? " every " +
                                        item.interval_coefficient +
                                        " " +
                                        item.interval_unit
                                      : "Custom"}

                                    {item &&
                                    item.structs &&
                                    item.structs.length > 0
                                      ? " - " +
                                        item.structs?.length +
                                        " Structures"
                                      : ""}
                                  </span>
                                  <span>
                                    {item.user
                                      ? "(" + item.user?.email + ")"
                                      : "(System)"}
                                  </span>
                                </>
                              }
                              actions={
                                <>
                                  <div>
                                    <p className="text-muted me-3">
                                      <>
                                        {item.is_sent && (
                                          <MdMailOutline
                                            style={{
                                              color: "green",
                                              fontSize: "15px",
                                            }}
                                          />
                                        )}
                                        {!item.is_sent && (
                                          <MdMailOutline
                                            style={{
                                              color: "red",
                                              fontSize: "15px",
                                            }}
                                          />
                                        )}
                                      </>
                                      <MdTimer />{" "}
                                      {moment(item.created_at).fromNow()}
                                    </p>
                                  </div>

                                  <div className="tour_action">
                                    {item && item.pdf_path ? (
                                      <>
                                        <Button
                                          className="me-1"
                                          size="sm"
                                          variant="outline-dark"
                                          onClick={async () =>
                                            await handleOpenPdf(
                                              url_media + item.pdf_path
                                            )
                                          }
                                        >
                                          <MdOpenInNew />
                                        </Button>
                                        <Button
                                          className="me-1"
                                          size="sm"
                                          variant="outline-primary"
                                          onClick={async () =>
                                            await handleDownloadPdf(
                                              url_media + item.pdf_path,
                                              item.pdf_path.slice(
                                                item.pdf_path.lastIndexOf("/") +
                                                  1
                                              )
                                            )
                                          }
                                        >
                                          <MdCloudDownload />
                                        </Button>
                                        {!item.interval_unit && (
                                          <Button
                                            className="me-1"
                                            size="sm"
                                            variant="outline-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleDeleteReport(item.id)
                                            }
                                          >
                                            <MdDelete />
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        Generating..
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleDeleteReport(item.id)
                                          }
                                        >
                                          <MdDelete style={{ color: "red" }} />
                                        </a>
                                      </>
                                    )}
                                  </div>
                                </>
                              }
                            />
                          </>
                        );
                      }}
                    />
                  </Col>
                </Row>
                {/* <Table columns={columns} data={data} pagination={true} /> */}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          scrollable={false}
          show={showModal}
          onHide={() => setShowModal(false)}
          size="xl"
          centered
          aria-labelledby="contained-modal-title-center"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{ backgroundColor: "transparent" }}>
            <PDFViewer url={pdfUrl} scale={width > 2000 ? 1.5 : 1.0} />
            {/* <div className="text-center">
              <PdfViewer url={pdfUrl} />
            </div> */}
          </Modal.Body>
        </Modal>
      </Restricted>
    </React.Fragment>
  );
};

export default ReportPDF;
