import { Config, Data, PlotData, ScatterLine, Annotations } from "plotly.js";
import React, { Component, FunctionComponent } from "react";
import Plot from "react-plotly.js";
import { TimeSeries } from "../../../../services/statistic/statistic-plot-service";
import { config } from "../config";
import _ from "lodash";

const SortToArrays = (source: string[], source2: number[]) => {
  //1) combine the arrays:
  var list = [];
  for (var j = 0; j < source.length; j++)
    list.push({ name: source[j], age: source2[j] });

  //2) sort:
  list.sort(function (a, b) {
    return a.name < b.name ? -1 : a.name == b.name ? 0 : 1;
    //Sort could be modified to, for example, sort on the age
    // if the name is the same. See Bonus section below
  });

  //3) separate them back out:
  for (var k = 0; k < list.length; k++) {
    source[k] = list[k].name;
    source2[k] = list[k].age;
  }

  return { x: source, y: source2 };
};

export type IThreashold = {
  high?: number;
  low?: number;
};
interface PlotAnalyticsProps {
  signal: TimeSeries;
  onInitialized: any;
  onUpdate: any;
  onRelayout: any;
  threshold?: IThreashold;
  highlight?: { time: string; value: number };
}

const PlotAnalytics: FunctionComponent<PlotAnalyticsProps> = ({
  signal,
  onInitialized,
  onUpdate,
  onRelayout,
  threshold,
  highlight,
}) => {
  //if mean values if null there is not data on the interval,
  // put signal.time a null per non graficare gli altri traces come thold e trend.
  if (signal.graph.mean_values === null) {
    signal.graph.time = undefined;
  }

  const signal_current: Partial<Data> = {
    x: signal.graph.time,
    y: signal.graph.mean_values,
    name: "", // always blank with custom hover template
    mode: "lines+markers",

    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    //line: { color: "#E7E6F0", shape: "spline", width: "5" },
    line: {
      color: "rgb(102,194,165)",
      shape: "spline",
      width: 4,
      //opacity: 0.5,
    },
    showlegend: false,
    // marker: {
    //   color: "#7D6B7D",
    //   size: 7,
    //   opacity: 1
    // },
  };
  const conect_x = [];
  const conect_y = [];
  if (signal.graph.prediction) {
    if (signal.graph.time?.includes(signal.graph.prediction.x[0])) {
      const index = signal.graph.time.findIndex(
        (x) => x === signal.graph.prediction.x[0]
      );
      console.debug(signal.graph.time[index - 1]);
      conect_x.push(signal.graph.time[index - 1]);
      conect_y.push(signal.graph.mean_values[index - 1]);
    }
  }

  const prediction_signal: Partial<Data> = {
    x: signal.graph.prediction && [...conect_x, ...signal.graph.prediction.x],
    y: signal.graph.prediction && [...conect_y, ...signal.graph.prediction.y],
    name: "Forecast", // always blank with custom hover template
    mode: "lines+markers",

    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    //line: { color: "#E7E6F0", shape: "spline", width: "5" },
    opacity: 0.8,
    line: {
      color: "pink",
      shape: "spline",
      width: 4,
    },
    showlegend: true,
    // marker: {
    //   color: "#7D6B7D",
    //   size: 7,
    //   opacity: 1
    // },
  };

  //TODO
  const final_prediction: { x: any[]; y: any[] } = { x: [], y: [] };
  _.merge(
    final_prediction,
    signal.graph.previous_prediction,
    signal.graph.prediction
  );

  //console.debug(final_prediction);

  const final_prediction_order = SortToArrays(
    final_prediction.x,
    final_prediction.y
  );

  const previus_prediction_signal: Partial<Data> = {
    //x: final_prediction_order && [...final_prediction_order.x],
    //y: final_prediction_order && [...final_prediction_order.y],
    x: signal.graph.previous_prediction && [...signal.graph.previous_prediction.x],
    y: signal.graph.previous_prediction && [...signal.graph.previous_prediction.y],
    name: "Prev Forecast", // always blank with custom hover template
    mode: "lines+markers",

    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    //line: { color: "#E7E6F0", shape: "spline", width: "5" },
    opacity: 0.8,
    line: {
      color: "pink",
      shape: "spline",
      width: 4,
    },
    showlegend: true,
    // marker: {
    //   color: "#7D6B7D",
    //   size: 7,
    //   opacity: 1
    // },
  };

  const warnings: Partial<Data> = {
    x: signal.graph.anomalies_time,
    y: signal.graph.anomalies_values,
    name: "Warnings", // warnings always blank with custom hover template
    mode: "markers",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    marker: {
      opacity: 0.7,
      color: "#FF665A",
      size: 10,
      line: { color: "#FF665A", width: 1 },
    },
  };

  const pctl_95: Partial<Data> = {
    x: signal.graph.time,
    y: signal.graph.pctl_95_values,
    name: "95% high",
    mode: "lines",
    opacity: 0.8,
    //fill:"tonextx",
    //fillcolor:"rgba(242, 242, 242, 0.5)",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",

    // fillcolor:'rgba(231, 230, 240,0.3)',
    line: { color: "#A3A1A8", shape: "spline", width: 1.5 },
  };

  const pctl_5: Partial<Data> = {
    x: signal.graph.time,
    y: signal.graph.pctl_5_values,
    name: "5% low",
    mode: "lines",
    //fill:"tonextx",
    //fillcolor:"rgba(242, 242, 242, 0.5)",
    opacity: 0.8,
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#A3A1A8", shape: "spline", width: 1.5 },
  };

  const trend: Partial<Data> = {
    x:
      Number.isInteger(signal.graph.days_left) && signal.graph.days_left <= 30
        ? [signal.graph.p_thold_begin.x, signal.graph.p_thold_end.x]
        : [signal.graph.p_thold_begin.x, signal.graph.p_period_end.x],
    y:
      Number.isInteger(signal.graph.days_left) && signal.graph.days_left <= 30
        ? [signal.graph.p_thold_begin.y, signal.graph.p_thold_end.y]
        : [signal.graph.p_thold_begin.y, signal.graph.p_period_end.y],
    name: "Trend",
    mode: "lines",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFA3A6", shape: "spline", width: 1, dash: "dot" },
  };

  const { days_left, p_period_begin, p_thold_end, p_period_end, time, prediction } =
    signal.graph;

  const pred =
    prediction && p_period_end.x
      ? prediction.x.slice(-1)[0] >= p_period_end.x
        ? prediction.x.slice(-1)[0]
        : p_period_end.x
      : null;

  const high_threshold: Partial<Data> = {
    x:
      days_left && days_left <= 30
        ? [p_period_begin.x, p_thold_end.x]
        : prediction
          ? [time ? time[0] : null, pred]
          : [time ? time[0] : null, time ? p_period_end.x : null],

    y: [signal.graph.high_threshold, signal.graph.high_threshold],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    // line: { color: "#E7E6F0", width: "2" },
  };

  const low_threashold: Partial<Data> = {
    x:
      days_left && days_left <= 30
        ? [p_period_begin.x, p_thold_end.x]
        : prediction
          ? [time ? time[0] : null, pred]
          : [time ? time[0] : null, time ? p_period_end.x : null],
    y: [signal.graph.low_threshold, signal.graph.low_threshold],
    mode: "lines",
    name: "Low threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    //line: { color: "#E7E6F0", width: "2" },
  };

  const new_high_threshold: Partial<Data> = {
    x:
      days_left && days_left <= 30
        ? [p_period_begin.x, p_thold_end.x]
        : [time ? time[0] : null, time ? time.slice(-1)[0] : null],
    y: threshold && [
      threshold.high ? threshold.high : null,
      threshold.high ? threshold.high : null,
    ],
    mode: "lines",
    name: "New High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FF6692", width: 2 },
  };

  const new_low_threshold: Partial<Data> = {
    x:
      days_left && days_left <= 30
        ? [p_period_begin.x, p_thold_end.x]
        : [time ? time[0] : null, time ? time.slice(-1)[0] : null],
    y: threshold && [
      threshold.low ? threshold.low : null,
      threshold.low ? threshold.low : null,
    ],
    mode: "lines",
    name: "New Low threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FF6692", width: 2 },
  };

  //Change by anotation
  // const highlight_trace: Partial<Data> = {
  //   name:"highlight",
  //   showlegend: false,
  //   y: [highlight ? highlight?.value : null],
  //   x: [highlight ? highlight?.time : null],
  //   type: "bar",
  //   marker: {
  //     color: "#FF665A",
  //     opacity: 0.6,
  //     line: {
  //       color: "#FF665A",
  //       width: 10,
  //     },
  //   },
  // };
  const annotation_highlight: Array<Partial<Annotations>> = [
    {
      x: highlight ? highlight?.time : "",
      y: highlight ? highlight?.value : "",
      xref: "x",
      yref: "y",
      text: "Warning",
      showarrow: true,
      arrowhead: 1,
      ax: 0,
      ay: -50,
      arrowcolor: "grey",
    },
  ];

  //Style empty
  //xref /yref = x domain doest exist in the library react-plotly
  //const annotation_empty: Array<Partial<Annotations>>
  const annotation_empty = signal.graph.mean_values
    ? undefined
    : [
      {
        text: "No data found",
        xref: "x domain",
        yref: "y domain",
        showarrow: false,
        font: {
          size: 24,
        },
      },
    ];

  //https://community.plotly.com/t/how-to-make-the-messy-date-ticks-organized/7477/3
  const layout: any = {
    //title: signal.description,
    title: signal.struct_name + "<br />" + signal.description,
    hoverlabel: { bgcolor: "#FFF" },
    height: 300,
    autosize: true,
    annotations: annotation_empty
      ? annotation_empty
      : highlight
        ? annotation_highlight
        : undefined,
    margin: {
      r: "50",
      l: "50",
      t: "80",
      b: "50",
    },
    showlegend: signal.graph.mean_values ? true : false,
    xaxis: {
      range: [
        signal.graph.time && signal.graph.time[0],
        signal.graph.time && signal.graph.time.slice(-1),
      ],
      type: "date",
      visible: signal.graph.mean_values ? true : false,
      showgrid: true,
      //tickmode:"linear",
      //tickformat: '%e %b, %y %H:%M',
      tickformat: "%e %b, %y",
      //tick0:signal.time[0],
      //dtick:"M1" //30 * 24 * 60 * 60 * 1000 // milliseconds
      // rangeselector: selectorOptions, TODO
    },
    yaxis: {
      //title: signal && signal.unit_of_measurement && signal.unit_of_measurement,
      visible: signal.graph.mean_values ? true : false,
      ticksuffix:
        signal &&
        signal.unit_of_measurement &&
        " " + signal.unit_of_measurement,
      //showtickprefix : 'last'
      showgrid: true,
    },
    transition: {
      duration: 500,
      easing: "cubic-in-out",
    },
    frame: {
      duration: 500,
    },
  };

  const data_ = [
    signal_current,
    prediction_signal,
    previus_prediction_signal,
    warnings,
    pctl_95,
    pctl_5,
    trend,
  ];
  if (signal.graph.high_threshold) {
    data_.push(high_threshold);
  }
  if (signal.graph.low_threshold) {
    data_.push(low_threashold);
  }
  //This are the threashold for vissualization in edit.
  if (threshold) {
    data_.push(new_high_threshold);
    data_.push(new_low_threshold);
  }

  return (
    <Plot
      data={data_}
      style={{ margin: 0, backgroundColor: "none" }}
      config={config}
      useResizeHandler={true}
      layout={layout}
      divId={signal.id.toString()}
      onInitialized={(figure) => onInitialized(figure, signal.id)}
      onUpdate={(figure) => onUpdate(figure, signal.id)}
      onRelayout={onRelayout}
    />
  );
};

export default React.memo(PlotAnalytics);
