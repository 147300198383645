import { FunctionComponent, useEffect, useState } from "react";
import { Modal, Badge } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import { MdWarning } from "react-icons/md";
import Operation, { OperationData } from "../operation";
import { Ok, WaitForRequest } from "../../../../utils/alertService";
import FormTextArea from "../../../formik-forms/FormTextArea";
import FormSubmit from "../../../formik-forms/FormSubmit";
import CloseButton from "../../../common/buttons/close-button";
import * as Yup from "yup";
import AppForm from "../../../formik-forms/AppForm";
import { FormikHelpers, FormikValues } from "formik";
import { useSaveUsageBasedOperations } from "../../../../queries/maintenance/usage.based.hook";
import { useCompleteUsageBased } from "../../../../queries/maintenance/usage.based.hook";
import { IMaintenanceUsedModel } from "../../../../services/maintenance/usage-based/usage-based-service";

interface MaintenanceUsedModalProps {
  maintenance_info: IMaintenanceUsedModel;
  showModal: boolean;
  onClose?: (refresh: boolean) => void;
}

const validationSchema = Yup.object().shape({
  usage_based_maintenance_id: Yup.number(),
  comment: Yup.string()
    .matches(/^(?![\n]*$)/, "Comment can't be only line breaks and whitespace")
    .required()
    .nullable()
    .max(1000)
    .label("comment"),
});

const MaintenanceUsedModal: FunctionComponent<MaintenanceUsedModalProps> = ({
  maintenance_info,
  showModal,
  onClose,
}) => {
  const [maintenance, setMaintenance] = useState<any>(maintenance_info);

  const saveOperation = useSaveUsageBasedOperations();

  const saveUsageBased = useCompleteUsageBased();

  useEffect(() => {
    setMaintenance(maintenance_info);
  }, [maintenance_info]);

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      const data: any = {
        usage_based_maintenance_id: maintenance_info.id,
        comment: values.comment,
      };
      let func = async () => await saveUsageBased.mutateAsync(data);
      const response = await WaitForRequest(
        "Confirm",
        "Once the maintenance is completed it will not be possible to make changes. <br /> Are you sure you want to complete the maintenance?",
        func
      );
      //console.debug(response);
      if (response) onClose && onClose(true); //TRUE update the elements
      //await loadMaintenance();
    } catch (error: any) {}
  };

  const isCompleted = (maintenance: IMaintenanceUsedModel): boolean => {
    if (maintenance.current_value != null && maintenance.expiration != null) {
      if (maintenance.current_value < maintenance.expiration) return true;
    }
    return false;
  };

  const handleOperationChange = async (
    operation: OperationData,
    checked: any
  ) => {
    try {
      let func = () =>
        saveOperation.mutate({
          usage_based_operation_id: operation.id,
          checked: checked,
        });
      const response = await WaitForRequest(
        "Confirm",
        "Finish operation?",
        func
      );
      if (response) {
        let update_maintenance = Object.assign({}, maintenance);
        let index = update_maintenance.operations.findIndex(
          (x: any) => x.id === operation.id
        );
        if (index !== -1) {
          update_maintenance.operations[index].completed = checked;
          console.debug(update_maintenance);
          setMaintenance(update_maintenance);
        }

        Ok("Operation save");
      }
    } catch (error) {}
  };

  const {
    current_value,
    is_seconds,
    expiration,
    struct,
    description,
    monitored_variable,
  } = maintenance_info;
  return (
    <>
      <Modal
        //key={showModal}
        centered
        scrollable={false}
        show={showModal}
        onHide={() => onClose && onClose(false)}
        size="lg"
        aria-labelledby="contained-modal-title-center"
      >
        <AppForm
          validationSchema={validationSchema}
          initialValues={{
            comment: undefined, //maintenance_info && maintenance_info.comment,
          }}
          onSubmit={doSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Overview usaged-based maintenance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {errors && <span className="text-danger">{errors[0]}</span>} */}
            <div className="tour_body">
              <p>
                <b>Structure</b>: {struct?.name}
              </p>
              <p>
                <b>Expiration</b>: {expiration}{" "}
                {is_seconds ? "hours" : monitored_variable?.unit_of_measurement}
              </p>
              <p>
                <b>Current value</b>: {current_value && current_value}{" "}
                {is_seconds ? "hours" : monitored_variable?.unit_of_measurement}
                {expiration != null &&
                current_value != null &&
                expiration > current_value ? (
                  <Badge pill bg="success" text="white" className="m-1">
                    <MdCheckCircle fontSize="10px" /> Ok
                  </Badge>
                ) : (
                  <Badge pill bg="danger" text="white" className="m-1">
                    <MdWarning fontSize="10px" /> Expired
                  </Badge>
                )}
              </p>

              <p>
                <b>Description</b>:{" "}
                {description
                  ? description
                  : "No description assing for this maintenance..."}
              </p>
            </div>

            <div className="tour_operations">
              <b>Operations: </b>
              {maintenance_info?.operations &&
              maintenance_info?.operations.length === 0 ? (
                <span> No operations assigned to this maintenance... </span>
              ) : (
                <ol style={{ lineHeight: "110%" }}>
                  {maintenance_info?.operations &&
                    maintenance_info?.operations.map((operation: any) => (
                      <li key={"operation-"+operation.id}>
                        <Operation
                          disabled={isCompleted(maintenance_info)}
                          data={operation}
                          onOperationChange={handleOperationChange}
                          //onOperationChange={() => console.debug("change")}
                        />
                      </li>
                    ))}
                </ol>
              )}
            </div>

            <FormTextArea
              className="tour_comment"
              name="comment"
              label="Comment"
              placeholder="Write a comment..."
              rows={3}
            />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <CloseButton
              label="Close"
              onClick={() => onClose && onClose(false)}
            />
            <FormSubmit
              className="tour_complete"
              title="Complete"
              disabled={isCompleted(maintenance_info)}
              //disabled={loginAPi.loading}
              //icon={faSignInAlt}
            />
          </Modal.Footer>
        </AppForm>
      </Modal>
    </>
  );
};

export default MaintenanceUsedModal;
