import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import ThemeContext from "../../context/theme/themeContext";
import { ITheme } from "../../context/theme/theme";
import "./style.css";
import { Row, Col, Button } from "react-bootstrap";
import { MdZoomIn, MdZoomOut } from "react-icons/md";
import DocumentWrapper from "./documentWrapper";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
type Props = {
  url?: string;
  scale?: number;
};

//https://codesandbox.io/s/useinview-ud2vo?fontsize=14&hidenavigation=1&theme=dark&file=/src/index.tsx
const useStyles = createUseStyles({
  styleDocument: (props: ITheme) => ({
    //height: "100%",
    //width: "100%",
    maxHeight: "800px",
    overflow: "auto",
  }),
});

type Data = {
  id: string /* Required */;
  name: string;
  children?: Data[];
  isOpen?: boolean;
};

type IZoom = {
  scale: number;
  page: number;
};

const PDFViewer: React.FunctionComponent<Props> = ({ url, scale }) => {
  const [zoom, setZoom] = useState<IZoom>({ scale: 1.0, page: 1 });
  const { theme } = useContext(ThemeContext);
  const classes = useStyles({ ...theme });

  useEffect(() => {
    if (scale) {
      setZoom({ ...zoom, scale });
    }
  }, [scale]);

  const [activePage, setActivePage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(1);

  const [bookmark, setBookmarks] = useState([]);

  const ref = useRef<any>();
  function PageHandle({ activePage, zoom }: any) {
    return (
      <Row className="mb-2">
        <Col lg={12} className="text-center">
          <span>
            Page: {activePage + " "} / {numPages}{" "}
          </span>
          <Button
            className="ms-1"
            size="sm"
            variant="outline-dark"
            onClick={() => setZoom({ scale: zoom - 0.1, page: activePage })}
          >
            <MdZoomOut size={25} />
          </Button>
          <span>{(zoom * 100).toFixed(0)} %</span>

          <Button
            className="me-1"
            size="sm"
            variant="outline-dark"
            onClick={() => setZoom({ scale: zoom + 0.1, page: activePage })}
          >
            <MdZoomIn size={25} />
          </Button>
        </Col>
      </Row>
    );
  }
  const Bar = React.memo(PageHandle);

  const handleLoad = useCallback(async (document: PDFDocumentProxy) => {
    setNumPages(document.numPages);
  }, []);

  console.debug("render 1");
  return (
    <>
      <Bar activePage={activePage} zoom={zoom.scale} />
      <Row className="text-center justify-content-center">
        <Col sm={12}>
          <div className={classes.styleDocument} ref={ref}>
            <DocumentWrapper
              url={url}
              zoom={zoom}
              onScroll={setActivePage}
              onDocumentLoadSuccess={handleLoad}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PDFViewer;
