import { Moment } from "moment";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";
import { PlotAnalyticsValveDemo } from "../../demo/analytics/plot-analytics-valve-demo";
import storage from "../base/storage";

const endpoint = "statistic/plot/valve";
const endpoint_condition = "statistic/plot/valve/condition";

export interface IValve {
  name: string;
  starts: Date[];
  durations: number[];
  out_of_tholds: number[];
  n_movements?: any;
  start_position: number;
  target_position: number;
  trend?: ITrend;
  threshold_max?: number;
  threshold_min?: number;
}

interface ITrend {
  start: { time: Date; value: number };
  end: { time: Date; value: number };
}

export interface IValveResponse {
  name: string;
  data: IValve[];
  unit_of_measurement: string;
}

export interface IValveConditionResponse {
  name: string;
  data: IValve;
  unit_of_measurement: string;
}

export type IRequestValveCondition = {
  start_date: Moment;
  end_date: Moment;
  struct_id: number;
  condition_id: number;
  timezone?: string;
};

class StatisticPlotValve {
  private http = Http;

  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
  getPlot = (
    star_date: Moment,
    end_date: Moment,
    structs_ids: number[] | string[],
    resample_rule?: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IValveResponse[]>> => {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    console.debug(star_date);
    const data = {
      start: star_date.format("YYYY-MM-DD"),
      end: end_date.format("YYYY-MM-DD"),
      struct_ids: structs_ids,
      resample_rule: resample_rule,
    };

    return this.getPromise<IValveResponse[]>(
      this.http.post(endpoint + "/?" + queryString, data)
    );
  };

  getPlotByCondition = (
    data: IRequestValveCondition,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IValveConditionResponse>> => {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    const data_send = {
      start: data.start_date.format("YYYY-MM-DD"),
      end: data.end_date.format("YYYY-MM-DD"),
      struct_id: data.struct_id,
      condition_id: data.condition_id,
      timezone: data.timezone,
    };

    return this.getPromise<IValveConditionResponse>(
      this.http.post(endpoint_condition + "/?" + queryString, data_send)
    );
  };
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new PlotAnalyticsValveDemo()
    : new StatisticPlotValve();

export const statistic_plot_valve_service = instance;
