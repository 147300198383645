import { FunctionComponent, useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import Select, { GroupBase, StylesConfig } from "react-select";
import { SelectOption } from "./select-option";
import ThemeContext from "./../../../context/theme/themeContext";
import { ITheme } from "../../../context/theme/theme";
interface SelectMultipleProps {
  label: string | JSX.Element;
  value?: SelectOption[];
  placeholder?: string;
  options: SelectOption[] | GroupBase<SelectOption>[];
  onValueChange?: (data: SelectOption[]) => void;
}

const useStyles = createUseStyles({
  labelStyles: () => ({
    fontSize: 15,
  }),
  groupStyles: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  groupBadgeStyles: (theme: ITheme) => ({
    //backgroundColor: "#EBECF0",
    //color: "#172B4D",
    borderRadius: "2em",
    backgroundColor: theme.primary ? theme.primary : "blue",
    color: "white",
    display: "inline-block",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }),
});

const grey = "#dee2e6";
const red = "red";

const SingleColourStyles: StylesConfig<SelectOption> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    minHeight: 25,
    boxShadow: "none",
    ":hover": {
      borderColor: grey,
    },
    borderColor: grey,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color_isSelected = isSelected ? grey : red;
    return {
      ...styles,
      ":hover": {
        backgroundColor: grey,
      },
      ":before": {
        backgroundColor: grey,
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? color_isSelected : undefined,
      },
      "&:after": {
        outline: "none !important",
        backgroundColor: "green",
        boxShadow: "none",
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#dee2e6",
    fontSize: "100%",
    color: "black",
    padding: "3px 6px 3px 6px",
    borderRadius: "2px",
    borderColor: grey,
    boxShadow: "none",
  }),
  // singleValue: (styles, { data }) => ({ ...styles, ...this.dot(data.color) }),
};

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const SelectMultiple: FunctionComponent<SelectMultipleProps> = ({
  label,
  placeholder,
  options,
  value,
  onValueChange,
}) => {
  const [input, setInput] = useState<string>();
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...theme });

  const onInputChange = (query: any, { action }: any) => {
    // Prevents resetting our input after option has been selected
    if (action !== "set-value") setInput(query);
  };
  const FormatGroupLabel = (data: GroupBase<SelectOption>) => {
    return (
      <div className={classes.groupStyles}>
        <span className={classes.labelStyles}>{data.label}</span>
        <span className={classes.groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  return (
    <>
      <label>{label}</label>
      <Select
        menuPortalTarget={document.querySelector("main")}
        isMulti
        pageSize={5}
        inputValue={input} // allows you continue where you left off
        onInputChange={onInputChange} // allows for actually typing
        styles={SingleColourStyles}
        escapeClearsValue={false}
        className="basic-single-iltiple"
        classNamePrefix="select"
        placeholder={placeholder ? placeholder : "Select ..."}
        //defaultValue={structCategories ? structCategories[0] : null}
        value={value}
        isDisabled={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={false} //set by default, but to be sure
        isLoading={false}
        isClearable={true}
        isRtl={false}
        formatGroupLabel={FormatGroupLabel}
        isSearchable={true}
        name="color"
        options={options}
        onChange={(data: any) => {
          onValueChange && onValueChange(data);
        }}
      />
    </>
  );
};

export default SelectMultiple;
