import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../configureStore";
import { IBadge } from "../../services/badge-service";

// Define a type for the slice state
interface ScheduledState {
  expired_maintenance_usage: number;
  expired_maintenance_scheduled: number;
  notification: IBadgeNotification;
}

type IBadgeNotification = {
  valve_pending: number;
  temperature_pending: number;
  default_pending: number;
  pid_pending: number;
};

// Define the initial state using that type
const initialState: ScheduledState = {
  expired_maintenance_usage: 0,
  expired_maintenance_scheduled: 0,
  notification: {
    valve_pending: 0,
    temperature_pending: 0,
    default_pending: 0,
    pid_pending: 0,
  },
};

export const badgeSlice = createSlice({
  name: "badge",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveBadges(state, action: PayloadAction<IBadge>) {
      state.expired_maintenance_scheduled =
        action.payload.scheduled_maintenance.expired_count;
      state.expired_maintenance_usage =
        action.payload.usage_based_maintenance.expired_count;

      state.notification.valve_pending =
        action.payload.notifications.valve_count;
      state.notification.temperature_pending =
        action.payload.notifications.temperature_count;
      state.notification.default_pending =
        action.payload.notifications.default_count;

      state.notification.pid_pending = action.payload.notifications.pid_count;
    },
    decreaseUsage(state) {
      state.expired_maintenance_usage--;
    },
    decreaseScheduled(state) {
      state.expired_maintenance_scheduled--;
    },

    update(state, action) {},
    remove(state, action) {},
  },
});

export const { saveBadges, decreaseScheduled, decreaseUsage } =
  badgeSlice.actions;

  export const getExpiredScheduled = (state: RootState) =>
  state.badge.expired_maintenance_scheduled

  export const getExpiredUsageBased = (state: RootState) =>
  state.badge.expired_maintenance_usage

export const getExpiredMaintenance = (state: RootState) =>
  state.badge.expired_maintenance_scheduled +
  state.badge.expired_maintenance_usage;

export const getPendingNotifications = (state: RootState) =>
  state.badge.notification.valve_pending +
  state.badge.notification.temperature_pending +
  state.badge.notification.default_pending +
  state.badge.notification.pid_pending;

export const getDetailNotifications = (state: RootState) =>
  state.badge.notification;

export default badgeSlice.reducer;
