import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import ThemeContext from "../../context/theme/themeContext";
import { ITheme } from "../../context/theme/theme";

interface CardCollasibleProps {
  title?: React.ReactNode;
  initialOpen?: boolean;
}

const CardCollapsible: FunctionComponent<CardCollasibleProps> = ({
  title,
  children,
  initialOpen,
}) => {
  const [activeKey, SetActiveKey] = useState<"0" | "1">(
    initialOpen == undefined ? "0" : initialOpen ? "0" : "1"
  );
  const { theme } = useContext(ThemeContext);

  const useStyles = createUseStyles({
    button: (theme: ITheme) => ({
      "&:focus": {
        outline: "none !important",
        boxShadow: "none",
      },
      "&:not(.collapsed)": {
        backgroundColor: "white !important",
        borderBottomColor: theme.primary,
        borderBottomWidth: "3px",
        borderStyle: "solid",
        color: "black",
      },
      "&:not(.collapsed)::after": {
        color: "white !important",
      },
    }),
  });

  const classes = useStyles({ ...theme });

  useEffect(() => {
    if (initialOpen) {
      SetActiveKey(initialOpen ? "0" : "1");
    }
  }, [initialOpen]);

  return (
    <Accordion
      bsPrefix="custom"
      className="accordion mb-2"
      defaultActiveKey={activeKey}
      activeKey={activeKey}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Button
          className={classes.button}
          onClick={() => SetActiveKey(activeKey == "0" ? "1" : "0")}
          // style={{ backgroundColor: "white", color: "black" }}
        >
          {title}
        </Accordion.Button>

        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CardCollapsible;
