import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ResponseWrapper } from "models/reponse";
import { QueryKeys, StaleTime } from "queries/query_keys";
import { APIError } from "services/base/wrapper/api-client";

import { IReport, maintenance_report } from "services/maintenance/maintenance-report";
import { timezone_service } from "services/timezoneService";
import alertService from "utils/alertService";

type IGenerateReport = {
  start_date: string;
  end_date: string;
};

export const useGenerateReport = () => {
  const timezone: string = timezone_service.getTimezone();

  const report_maintenance = useMutation<
    ResponseWrapper<IReport>, //no response
    any,
    IGenerateReport
  >({
    mutationFn: (data) => {
      return maintenance_report.generateReport(
        data.start_date,
        data.end_date,
        timezone
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error.response?.data.response.error) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {},
  });
  return report_maintenance;
};

export const useGetReportMaintenance = () => {
  const reports = useQuery({
    queryKey: [QueryKeys.GET_REPORTS_MAINTENANCE],
    queryFn: () => maintenance_report.get(),
    onSuccess: (data) => {
      return data.response;
    },
    onError: (error?: APIError) => {
      if (error) {
        alertService.errorMessage(error?.message);
      }
    },
    cacheTime: 1,
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return reports;
};
