import { FunctionComponent, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Backdrop from "../../../common/backdrop/backdrop";
import AppForm from "../../../formik-forms/AppForm";
import FormText from "../../../formik-forms/FormText";
import FormSelect from "../../../formik-forms/FormSelect";
import CloseButton from "../../../common/buttons/close-button";
import FormSubmit from "../../../formik-forms/FormSubmit";
import { IValveCondition } from "../../../../services/valve/valve-condition-service";
import { useHistory, useParams } from "react-router-dom";
import { FormikHelpers, FormikValues } from "formik";
import {
  useSaveValveCondition,
  useValveConditionById,
} from "../../../../queries/management/valve.condition.hook";
import * as Yup from "yup";
import { SelectOption } from "../../../common/selects/select-option";
import _ from "lodash";
import { useGetValves } from "../../../../queries/management/valve.hook";
import withLayout from "../../../../hoc/withLayout";
interface ValveConditionFormProps {}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required(),
  description: Yup.string()
    .matches(
      /^(?![\n]*$)/,
      "Description can't be only line breaks and whitespace"
    )
    .required(),
  valve_id: Yup.number().required("Temperature is required"),
  start_position: Yup.number().required("Start value is required").default(0),
  target_position: Yup.number().required("Target value is required").default(0),
  default_avg: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .nullable(true),
  threshold_max: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .nullable(true),
  threshold_min: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .nullable(true),
});

const ValveConditionForm: FunctionComponent<ValveConditionFormProps> = () => {
  const [SelectedCondition, setSelectedCondition] = useState<IValveCondition>();
  const [conditions, setConditions] = useState<SelectOption[]>([]);
  const history = useHistory();
  let { id } = useParams<any>();

  const dataById = useValveConditionById();

  const save = useSaveValveCondition();

  const valves = useGetValves();

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      save.mutate(values);
      console.debug(values);
    } catch (error: any) {}
  };

  const checkId = async () => {
    try {
      if (id === "new" || id === undefined) {
        setSelectedCondition({} as IValveCondition);
        return;
      }
      const condition = await dataById.mutateAsync(id);

      if (!condition) return history.replace("/not-found");

      setSelectedCondition(condition.response);
    } catch (error: any) {
      history.replace("/404");
    }
  };

  const mapOptions = (response: any[] | undefined) => {
    const structs_format = _.map(response, (item) => {
      console.debug(item);
      return {
        label: item.struct_id.name,
        value: item.id,
      };
    });
    return structs_format;
  };

  useEffect(() => {
    checkId();
  }, []);

  useEffect(() => {
    if (conditions.length == 0) {
      if (valves.data?.response) {
        const result = mapOptions(valves.data.response);
        console.debug(result);
        setConditions(result);
      }
    }
  }, [valves]);
  return (
    <Container>
      <Backdrop show={dataById.isLoading} />
      <Card className="p-3">
        {<h3>{id === "new" || id === undefined ? "New" : "Modify"}</h3>}
        <hr />
        <Card.Body>
          {SelectedCondition && (
            <AppForm
              validationSchema={validationSchema}
              initialValues={{
                id: SelectedCondition?.id,
                name: SelectedCondition?.name,
                description: SelectedCondition?.description,
                valve_id: SelectedCondition?.valve_id?.id,
                start_position: SelectedCondition?.start_position,
                target_position: SelectedCondition?.target_position,
                threshold_max: SelectedCondition?.threshold_max,
                threshold_min: SelectedCondition?.threshold_min,
                default_avg: SelectedCondition?.default_avg,
              }}
              onSubmit={doSubmit}
            >
              <Row>
                <h5>
                  <b>Condition details</b>
                </h5>
                <Col>
                  <FormText
                    name="name"
                    label="Name"
                    placeholder="Insert Name..."
                  />
                </Col>
                <Col>
                  <FormText
                    name="description"
                    label="Description"
                    placeholder="Insert description..."
                  />
                </Col>
                <Col>
                  <FormSelect
                    placeholder="Select Valve"
                    label="Valves"
                    helpText="The valve to apply to the condition"
                    name="valve_id"
                    options={conditions}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormText
                    name="start_position"
                    label="Start position"
                    placeholder="Insert start position..."
                    type={"number"}
                  />
                </Col>
                <Col>
                  <FormText
                    name="target_position"
                    label="Target position"
                    placeholder="Insert target position"
                    type={"number"}
                  />
                </Col>
              </Row>
              <Row></Row>
              <br />
              <h5>
                <b>Threshold options</b>
              </h5>
              <Row>
                <Col>
                  <FormText
                    name={"default_avg"}
                    label="Average"
                    placeholder="Define average..."
                    type={"number"}
                    helpText="The typical daily average"
                  />
                </Col>
                <Col>
                  <FormText
                    name={"threshold_max"}
                    label="Max limit"
                    placeholder="Define max limit..."
                    type={"number"}
                    helpText={
                      "The upper limit of the daily average to be considered good"
                    }
                  />
                </Col>
                <Col>
                  <FormText
                    name={"threshold_min"}
                    label="Min limit"
                    placeholder="Define min limit..."
                    type={"number"}
                    helpText="The lower limit of the daily average to be considered good"
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-5">
                <CloseButton label="Close" onClick={() => history.goBack()} />
                <span className="me-2"></span>
                <FormSubmit title="Save changes" />
              </div>
            </AppForm>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ValveConditionForm;
