import { ITheme } from "../context/theme/theme";
import costallogo from "../assests/costal/logo.png";
import costalsmall from "../assests/costal/logo-small.png";

import WaveCostal from "../assests/wave-login-costal.svg";
import WaveIema from "../assests/wave-login-iema.svg";
import WaveCostradamus from "../assests/wave-login-costradamus.svg";

import costradamus from "assests/costradamus/logo.png";
import costradamusLogo from "assests/costradamus/logo-small.png";

const isElectron = process.env.REACT_APP_IS_ELECTRON;

const theme_costal: ITheme = {
  title: "Costal Srl",
  primary: "rgb(0,78,125)",
  secondary: "#6c757d",
  third: "rgb(220,183,50)",
  light: "#ff5234",
  logo_url: isElectron ? costallogo : "/costal/logo.png",
  small_logo_url: isElectron ? costalsmall : "/costal/logo-small.png",
  favicon: "/costal/favicon.ico",
  colors: {
    danger: "#fc766d",
    success: "#86d189",
    warning: "orange",
  },
  background_login: WaveCostal,
};

const theme_costradamus: ITheme = {
  title: "Costradamus",
  primary: "#0B496D",
  secondary: "#6c757d",
  third: "#D8B131",
  light: "#ff5234",
  logo_url: isElectron ? costradamus : "/costradamus/logo.png",
  small_logo_url: isElectron ? costradamusLogo : "/costradamus/logo-small.png",
  favicon: "/costradamus/favicon.ico",
  colors: {
    danger: "#fc766d",
    success: "#86d189",
    warning: "orange",
  },
  background_login: WaveCostradamus,
};

const theme_iema: ITheme = {
  title: "Iema US",
  primary: "#134380",
  secondary: "#6c757d",
  third: "#ce1417",
  light: "#ff5234",
  logo_url: "/iema/iema.svg",
  small_logo_url: "/costal/costal-small.png",
  favicon: "/iema/icon.ico",
  colors: {
    danger: "#fc766d",
    success: "#86d189",
    warning: "orange",
  },
  background_login: WaveIema,
};

type ThemeType = "costal" | "iema" | "costradamus";
const theme_dictionary = {
  costal: theme_costal,
  iema: theme_iema,
  costradamus: theme_costradamus,
};

const getTheme = (theme: ThemeType) => {
  if (theme) return theme_dictionary[theme] ?? theme_dictionary["costal"];

  return theme_dictionary["costal"]; // default theme
};

export { theme_costal, theme_iema, getTheme, isElectron };

export type { ThemeType };