import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { timezone_service } from "../../services/timezoneService";
import alertService from "../../utils/alertService";
import { QueryKeys, StaleTime } from "../query_keys";
import { ResponseError } from "../../models/reponse";
import { useDispatch } from "react-redux";
import { notification_pid_service } from "./../../services/notifications/notification-pid-service";

export const useGetPidNotification = () => {
  const timezone: string = timezone_service.getTimezone();

  const dashboard = useQuery({
    queryKey: [QueryKeys.GET_NOTIFICATION_PID],
    queryFn: () => notification_pid_service.get({ timezone: timezone }),
    onError: (error?: ResponseError) => {
      if (error) {
        error.error && alertService.errorMessage(error?.error);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return dashboard;
};

// export const useSavePidNotification = () => {
//   const queryClient = useQueryClient();
//   const dispatch = useDispatch();
//   const saveNotification = useMutation<
//     any, //no response
//     any,
//     ISaveValveNotification
//   >({
//     mutationFn: (data) => {
//       return notification_valve_service.updateFeedback(data);
//     },
//     onError: (error, variables, context) => {
//       // An error happened!
//       //alertService.errorMessage(error.response?.data.response.error);
//     },
//     onSuccess: (data, variables, context) => {
//       //invalid query per refresh notification
//       //queryClient.invalidateQueries([QueryKeys.GET_NOTIFICATION_TEMPERATURE]);
//       queryClient.invalidateQueries([QueryKeys.GET_BADGES]);
//       //decrease the number of expired notifications
//     },
//   });
//   return saveNotification;
// };
