import { BaseServiceGenericDemo } from "../../base-service-demo";
import maintenance from "../../../demo//maintenance/usage-based/maintenance-usage-based.json";
import { IMaintenanceUsedModel } from "../../../services/maintenance/usage-based/usage-based-service";
import { maintenance_data } from "./data";

export class MaintenanceUsageBasedDemo extends BaseServiceGenericDemo<IMaintenanceUsedModel> {
  constructor() {
    super();
    this.data = maintenance_data.getData();
  }
}
