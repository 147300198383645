import Http from "../base/http-services";
import { ResponseWrapper } from "../../models/reponse";
import { BaseService } from "../base/base-service";
import { ReportnotificationDemo } from "../../demo/report/report-notification-demo";
import storage from "../base/storage";

export interface IReportNotification {
  id: number;
  created_at: Date;
  updated_at: Date;
  interval_unit: string;
  interval_coefficient: number;
  start_date: string;
  end_date: string;
  pdf_path: string;
  mail_sent_at?: any;
  is_sent: boolean;
  user?: any;
  structs?: number[];
}

const endpoint = "notifications/report";
const url_generate = "report/generate";

class ReportnotificationService extends BaseService<IReportNotification> {
  constructor() {
    super(endpoint, Http);
  }

  generateReportByStructs = (
    start_date: string,
    end_date: string,
    structs: number[] | string[] | undefined
  ) => {
    const data = {
      start_date,
      end_date,
      structs: structs,
    };
    return this.getPromise(this.http.post(url_generate + "/", data));
  };

  generateReport(
    start_date: String,
    end_date: String
  ): Promise<ResponseWrapper<any>> {
    const params = "start_date=" + start_date + "&end_date=" + end_date;
    return this.getPromise(this.http.get(url_generate + "?" + params));
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new ReportnotificationDemo()
    : new ReportnotificationService();

export const report_notification_service = instance;
