export enum QueryKeys {
  GET_DASHBOARD = "dashboard",
  GET_BADGES = "badges",
  GET_USERS = "users",
  GET_GROUPS = "groups",
  GET_REPORTS = "reports",
  GET_REPORTS_MAINTENANCE = "reports_maintenance",
  GET_PERMISSIONS = "permissions",
  GET_NOTIFICATION = "notification",
  GET_NOTIFICATION_TEMPERATURE = "notification-temperature",
  GET_NOTIFICATION_VALVE = "notification-valve",
  GET_NOTIFICATION_PID = "notification-pid",
  GET_SCHEDULED_MAINTENANCE = "scheduled",
  GET_USAGEBASED_MAINTENANCE = "usage-based",
  GET_PROFILE = "profile",
  GET_CATEGORIES = "categories",
  GET_MODEL_TYPE = "model_type",
  GET_STRUCTs = "structs",
  GET_STRUCT_DEFAULT = "struct_default",
  GET_STRUCT_TEMP = "struct_temperature",
  GET_STRUCT_VALVE = "struct_valve",
  GET_STRUCT_PID = "struct_pid",
  MANAGEMENT_GET_TEMPERATURE = "temperature",
  MANAGEMENT_GET_TEMPERATURE_CONDITION = "temperature-condition",
  MANAGEMENT_GET_VALVE = "valve",
  MANAGEMENT_GET_VALVE_CONDITION = "valve-condition",
}

export enum StaleTime {
  SECONDS_15 = 1 * 15 * 1000,
  SECONDS_30 = 1 * 30 * 1000,
  MiNUTE_1 = 1 * 60 * 1000,
  MiNUTE_5 = 5 * 60 * 1000,
}
