import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "../../../components/common/selects/select-option";
import { RootState } from "../../configureStore";
import { IValveResponse } from "../../../services/statistic/statistic-plot-valve";

// Define a type for the slice state
interface AnalyticsValveState {
  start_date?: string;
  end_date?: string;
  structures_selected?: SelectOption[];
  signals?: IValveResponse[];
}

// Define the initial state using that type
const initialState: AnalyticsValveState = {};

export const analyticsValeSlice = createSlice({
  name: "analytics-valve",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveValveStartDate(state, action: PayloadAction<any>) {
      state.start_date = action.payload;
    },
    saveValveEndDate(state, action: PayloadAction<any>) {
      state.end_date = action.payload;
    },
    saveValveStructuresSelected(state, action: PayloadAction<SelectOption[]>) {
      state.structures_selected = action.payload;
    },
    saveValveSignals(
      state,
      action: PayloadAction<IValveResponse[] | undefined>
    ) {
      state.signals = action.payload;
    },
  },
});

export const {
  saveValveEndDate,
  saveValveStartDate,
  saveValveStructuresSelected,
  saveValveSignals,
} = analyticsValeSlice.actions;

export const getAnalyticsValveState = (state: RootState) =>
  state.analytics_valve;

export default analyticsValeSlice.reducer;
