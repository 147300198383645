import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import { ResponseWrapper } from "../models/reponse";
import { Routes } from "../Routes";
import { IUser, user_service } from "../services/users/user-service";
import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";


export const useGetUsers = () => {
  const user = useQuery({
    queryKey: [QueryKeys.GET_USERS],
    queryFn: user_service.get,
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return user;
};

export const useGetByIdUser = () => {
  const queryClient = useQueryClient();
  const user = useMutation<ResponseWrapper<IUser>, any, number>({
    mutationFn: (id) => {
      return user_service.getById(id);
    },
    onError: (error, variables, context) => {
      if (error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    onSuccess: (data, variables, context) => {},
  });

  return user;
};

export const useSaveUsers = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const saveUser = useMutation<any, any, FormikValues>({
    mutationFn: (values) => {
      return user_service.save(values, {}, true);
    },
    onError: (error, variables, context) => {
      if (error.response.data.response.error) {
        alertService.errorMessage(error.response.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_USERS] });
      history.push(Routes.users);
    },
  });
  return saveUser;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const deletUser = useMutation<any, any, any>({
    mutationFn: (data) => {
      return user_service.deleteProtected(data.dataId, data.password);
    },
    onError: (error, variables, context) => {
      if (error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_USERS] });
    },
  });

  return deletUser;
};
