

import { ResponseWrapper } from '../../models/reponse';
import Http from "../base/http-services";
import { BaseService } from '../base/base-service';


const endpoint = "variable";

export type IDetermination = {
  itemid: number,
  mean_ref: number,
  threshold_high: number,
  threshold_low: number,
}

class VariableService extends BaseService<any> {
  constructor() {
    super(endpoint, Http)
  }

  getDetermination = (
    id: number,
    query_params: Record<string, any> = {},
    withSlashAtEnd: boolean = false
  ): Promise<ResponseWrapper<IDetermination>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get(this.Url(id, withSlashAtEnd) + "/determination/" + "?" + params)
    );
  };

}

export const variable_service = new VariableService()



