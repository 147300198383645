import { Config } from "plotly.js";

export const config: Partial<Config> = {
  displaylogo: false,
  responsive: true,
  autosizable: true,
  fillFrame: false,
  displayModeBar: true,
  showAxisRangeEntryBoxes: false,
  plotlyServerURL: "",
  editable: false,
  showLink: false,
  showEditInChartStudio: false,
  scrollZoom: false,
  //watermark: false,
  showTips: false,
  showAxisDragHandles: false,
  modeBarButtonsToRemove: ["lasso2d", "select2d", "resetScale2d"],
};

  //xref /yref = x domain doest exist in the library react-plotly
  //const annotation_empty: Array<Partial<Annotations>>
export const annotation_empty:any = [
  {
    text: "No data found",
    xref: "x domain",
    yref: "y domain",
    showarrow: false,
    font: {
      size: 24,
    },
  },
];
