import Http from "../base/http-services";
import { ResponseError, ResponseWrapper } from "../../models/reponse";
import axios, { AxiosError } from "axios";
import { IDashboardResponse, IDashboardService } from "./dashboard-types";
import { DashboardDemo } from "../../demo/dashboard/dashboard-demo";
import storage from "../base/storage";

class DashboardService implements IDashboardService {
  private http = Http;
  private endpoint = "dashboard";

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IDashboardResponse>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(this.http.get(this.endpoint + "/" + "?" + params));
  };

  private generateParams(query_params: Record<string, any> = {}): string {
    let params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  private handleError = (error: AxiosError<ResponseWrapper<ResponseError>>) => {
    //check if the server response with error.
    //if not, the handler for generic exceptions like 500, Network Error
    //are in http instance.
    if (axios.isAxiosError(error)) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status !== 403 &&
        error.response.status < 500
      ) {
        const { response } = error.response.data;

        return response;
      }
    }
    // undefined tell me that
    return undefined;
  };

  private getPromise<T>(func: Promise<T>) {
    return new Promise<ResponseWrapper<IDashboardResponse>>(
      (resolve, reject) => {
        func
          .then((response: any) => {
            resolve(response.data as ResponseWrapper<IDashboardResponse>);
          })
          .catch((error: AxiosError<any>) => {
            const result_error = this.handleError(error);
            reject(result_error);
          });
      }
    );
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new DashboardDemo()
    : new DashboardService();

export const dashboard_service = instance;
