import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Container, Card } from "react-bootstrap";
import AppForm from "../components/formik-forms/AppForm";
import FormSubmit from "../components/formik-forms/FormSubmit";
import FormTextFloating from "../components/formik-forms/FormTextFloating";
import * as Yup from "yup";
import { FormikHelpers, FormikValues } from "formik";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import ThemeContext from "../context/theme/themeContext";
import { useHistory } from "react-router-dom";
import storage from "../services/base/storage";
import BaseUrl from "../components/features/login/base-url-form";
import { createUseStyles } from "react-jss";
import PermissionContext from "./../context/permission/permissionContext";
import { authentication } from "services/base/authentication";
import axios, { AxiosError } from "axios";
interface LoginProps {}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

const useStyles = createUseStyles({
  "@keyframes rotate": {
    "100%": {
      backgroundPosition: "15% 50%",
    },
  },
  container: {
    animation: "$rotate 7s infinite alternate linear",
  },
});

const Login: FunctionComponent<LoginProps> = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const { theme } = useContext(ThemeContext);
  const { setPermissions } = useContext(PermissionContext);
  const [animate, setAnimate] = useState<boolean>(true);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (storage.getUser() !== null) {
      history.push("/dashboard");
    }
  }, []);

  const checkRedirect = async () => {
    const user = storage.getUser();
    if (user?.token_type == "demo") {
      window.location.reload();
    }
    setAnimate(false);
    setTimeout(() => {
      history.push("/dashboard");
    }, 900);
  };

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      setIsBusy(true);

      const result = await authentication.login(values.email, values.password);

      const { response } = result;

      if (response) {
        //save the data on localhost
        storage.setTokenObj(response);

        setPermissions(response.permissions);

        await checkRedirect();
      }

      setIsBusy(false);
    } catch (ex: any) {
      setIsBusy(false);
      if (axios.isAxiosError(ex)) {
        const err= ex as AxiosError<any,any>
        const message = err?.response?.data?.response?.error;
        setError(message);
      }
    }
  };

  return (
    <>
      <Container
        fluid
        className={
          "d-flex flex-column min-vh-100 justify-content-center align-items-center " +
          classes.container
        }
        style={{
          backgroundImage: `url(${theme.background_login})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Animated
          animationIn="bounceInLeft"
          //animationOut="zoomOutDown"
          //animationOut="slideOutUp"
          animationOut="bounceOut"
          isVisible={animate}
        >
          <Card
            className=" align-items-center shadow-lg  p-5"
            style={{ borderRadius: "1rem", width: "30rem" }}
          >
            <Card.Body>
              <img
                alt="Logo"
                className="img-fluid"
                style={{ marginBottom: "1rem" }}
                width={"700rem"}
                src={theme.logo_url}
              />
              <AppForm
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <FormTextFloating
                  name="email"
                  label="Email or username"
                  placeholder="Insert email..."
                  disabled={false}
                />
                <FormTextFloating
                  name="password"
                  label="Password"
                  placeholder="Insert password..."
                  type="password"
                  disabled={false}
                />
                <div className="d-grid gap-2">
                  <FormSubmit
                    title="Login"
                    disabled={isBusy}
                    icon={faSignInAlt}
                  />
                </div>
              </AppForm>
              <hr />
              {error && <div className="text-danger">{error}</div>}
              <BaseUrl />
            </Card.Body>
          </Card>
        </Animated>
      </Container>
      <Footer />
    </>
  );
};

export default Login;

/* Footer */
type Props = {};

const Footer: React.FunctionComponent<Props> = () => {
  const { theme } = useContext(ThemeContext);
  const footer: React.CSSProperties = {
    position: "absolute",
    bottom: "0",
    width: "100%",
    fontSize: "2rem",
    color: "white",
    // backgroundColor: theme.primary
  };
  return (
    <footer className="sticky-footer" style={{ ...footer }}>
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          {/* <span>Copyright &copy; 2021 {theme.title}. All rights reserved.</span> */}
          <span>Copyright &copy; 2021 Costal Srl. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};
