import { FunctionComponent } from "react";
import { ListGroup } from "react-bootstrap";
import Icon from "../../common/Icon";

interface ListItemCustomProps<T extends unknown> {
  data: T[];
  keyExtrator: (item: T) => string;
  renderItem: (item: T, index: number) => React.ReactNode;
}

type MyComponentI<T extends unknown> = FunctionComponent<
  ListItemCustomProps<T>
>;
const ListItemCustom = <T,>(props: ListItemCustomProps<T>) => {
  const { data, keyExtrator, renderItem } = props;
  return (
    <>
      <div
        className="p-2"
        style={{
          overflowY: "scroll",
          minHeight: "65vh",
          maxHeight: "65vh",
        }}
      >
        {!data ||
          (data.length == 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No notification to show...
            </div>
          ))}
        <ListGroup variant="flush">
          {data.map((item, index) => (
            <ListGroup.Item
              key={keyExtrator(item)}
              className="d-flex justify-content-between align-items-center mb-1"
            >
              {renderItem(item, index)}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div className=" p-2 d-flex justify-content-end">
        {data.length > 0 && data.length + " items"}
      </div>
    </>
  );
};

export default ListItemCustom;

export interface ItemCustomProps {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  Icon: JSX.Element; //typeof Icon; TODO do type
  actions?: JSX.Element;
}

export const ItemCustom: FunctionComponent<ItemCustomProps> = ({
  title,
  subtitle,
  Icon,
  actions,
}) => {
  return (
    <>
      <div id="left" className="d-flex align-items-center">
        {Icon}
        <div className="ms-3">
          <p className="fw-bold mb-1">{title}</p>
          <p className="text-muted mb-0"> {subtitle}</p>
        </div>
      </div>
      <div
        id="right"
        className="d-flex align-items-center justify-content-center"
      >
        {actions && actions}
      </div>
    </>
  );
};
