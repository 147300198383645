import moment from "moment";
import { IResponse } from "../../services/plot/plot-struct-service";
import plot_struct_data from "./plot-struct-data.json";

class PlotDemoData {
  private data: IResponse;
  constructor() {
    this.data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(plot_struct_data.response);
    const obj: IResponse = JSON.parse(myJSON);
    //const result = this.prepareData(obj);
    return obj;
  };

  getData = () => {
    return this.data;
  };

  //this function update the "dates" in the response to be always updated.

  private prepareData = (obj: IResponse) => {
    obj.signals.forEach((signal) => {
      let lenght = signal.time.length;

      const new_time: string[] = [];
      signal.time.forEach((time) => {
        new_time.push(
          moment().subtract(lenght, "minutes").format("YYYY-MM-DDTHH:mm:ss")
        );
        lenght--;
      });

      signal.time = new_time;
    });
    return obj;
  };
}

export const plot_demo_data = new PlotDemoData();
