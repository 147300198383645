import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Nav, Card, Row, Col } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { withRouter } from "react-router-dom";
import { ITheme } from "../context/theme/theme";
import ThemeContext from "../context/theme/themeContext";
import withLayout from "../hoc/withLayout";
import AnalyticsDefault from "./analytics/analytics-default";
import TemperaturePage from "./analytics/analytics-temperature";
import ValvePage from "./analytics/analytics-valve";
import { FaTemperatureLow } from "react-icons/fa";
import { TbTrendingUp } from "react-icons/tb";
import { AiOutlineGateway } from "react-icons/ai";
import AnalyticsPid from "./analytics/analytics-pid";
import { TfiControlShuffle } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsState,
  saveMenu,
} from "../store/reducers/analytics/analytics-reducer";
import storage from "services/base/storage";

export type IMenuAnalytics = "PID" | "valve" | "temperature" | "default";

interface AnalyticsProps {}

const useStyles = createUseStyles({
  card: (theme: ITheme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  tabStyle: (theme: ITheme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "&.nav-pills .nav-link": {
      color: "black",
      transition: "0.5s",
    },
    "&.nav-pills .nav-link:hover": {
      color: "black",
    },
    "&.nav-pills .nav-link.active": {
      color: "white",
      backgroundColor: theme.primary,
    },
    "& > *": {
      marginRight: "1rem",
    },
  }),
});

const Analytics: FunctionComponent<AnalyticsProps> = () => {
  const [value, setValue] = useState<IMenuAnalytics>("default");
  const { theme } = useContext(ThemeContext);
  const styles = useStyles({ ...theme });
  const dispatch = useDispatch();
  const state = useSelector(getAnalyticsState);

  const handleSelectNav = (eventKey: any) => {
    setValue(eventKey);
    dispatch(saveMenu(eventKey));
  };

  const isDemo=storage.isDemo();
  

  useEffect(() => {
    if (state.menu) {
      setValue(state.menu);
    }
  }, []);

  return (
    <>
   {false &&   <div className={styles.card}>
        <Card className="p-2 mb-1">
          <Nav
            className={styles.tabStyle}
            variant="pills"
            activeKey={value}
            defaultActiveKey={value}
            onSelect={handleSelectNav}
          >
            <Nav.Item>
              <Nav.Link eventKey="default">
                <span className="d-none d-md-inline">Default</span>
                <TbTrendingUp size={20} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="temperature">
                <span className="d-none d-md-inline">Temperature</span>

                <FaTemperatureLow size={15} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="valve">
                <span className="d-none d-md-inline">Valve</span>
                <AiOutlineGateway size={25} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="PID">
                <span className="d-none d-md-inline">Pid</span>
                <TfiControlShuffle size={25} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card>
      </div>}

      {value == "temperature" && <TemperaturePage />}

      {value == "default" && <AnalyticsDefault />}

      {value == "valve" && <ValvePage />}

      {value == "PID" && <AnalyticsPid />}
    </>
  );
};

export default withRouter(Analytics);
