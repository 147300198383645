import { FunctionComponent } from "react";
import Plot from "react-plotly.js";
import { IPidResponse } from "../../../../services/statistic/statistic-plot-pid";
import { Config, Data } from "plotly.js";
import { color_plot } from "../colors";
import {
  createPeakNumbers,
  createPeaksTime,
  createStationary,
  createTransientTraces,
} from "./traces-pid";
import { config } from "../config";
interface PlotPidProps {
  signal: IPidResponse;
  onInitialized?: any;
  onUpdate?: any;
  onRelayout?: any;
}

const PlotPid: FunctionComponent<PlotPidProps> = ({ signal }) => {
  if (signal.starts?.length == 0) return null;

  const traces_trasient = createTransientTraces(
    signal.starts,
    signal.transient
  );
  const traces_stationary = createStationary(
    signal.starts,
    signal.stationary,
    false
  );

  const traces_peaks_number = signal.stationary?.peaks_number
    ? createPeakNumbers(signal.starts, signal?.stationary.peaks_number, false)
    : [];

  console.debug(signal.stationary);

  const traces_peaks_time = signal.stationary?.peaks_time
    ? createPeaksTime(signal.starts, signal?.stationary?.peaks_time, false)
    : [];

  const annotation_empty = signal.starts
    ? undefined
    : {
        text: "No data found",
        xref: "x domain",
        yref: "y domain",
        showarrow: false,
        font: {
          size: 24,
        },
      };

  //https://community.plotly.com/t/how-to-make-the-messy-date-ticks-organized/7477/3
  const layout: any = {
    //title: signal.description,
    barmode: "group",
    title: signal.name,
    hoverlabel: { bgcolor: "#FFF" },
    height: 300,
    // autosize: true,
    annotations: annotation_empty ? [annotation_empty] : undefined,
    margin: {
      r: "50",
      l: "50",
      t: "80",
      b: "50",
    },
    //showlegend: signal.data[0].durations ? true : false,
    xaxis: {
      range: [
        signal.starts && signal.starts[0],
        signal.starts && signal.starts.slice(-1)[0],
      ],
      //type: "date",
      //visible: signal.data[0].durations ? true : false,
      showgrid: true,
      //tickmode:"linear",
      //tickformat: '%e %b, %y %H:%M',
      //tickformat: "%e %b, %y",
      //tick0:signal.time[0],
      //dtick:"M1" //30 * 24 * 60 * 60 * 1000 // milliseconds
      // rangeselector: selectorOptions, TODO
    },
    yaxis: {
      //title: signal && signal.unit_of_measurement && signal.unit_of_measurement,
      //visible: signal.data[0].durations ? true : false,
      ticksuffix: " s",
      //showtickprefix : 'last'
      showgrid: true,
      automargin: true,
    },
    updatemenus: [
      {
        visible: true,
        direction: "left",
        pad: { r: 0, t: 0 },
        showactive: true,
        type: "buttons",
        x: 0,
        xanchor: "left",
        y: 2.1,
        yanchor: "top",
        active: 0,
        bordercolor: "#BEC8D9",
        buttons: [
          {
            args: [
              {
                visible: [
                  true,
                  true,
                  true,
                  true,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              },
              {
                "yaxis.ticksuffix": " s",
              },
            ],
            label: "Transient",
            method: "update",
          },
          {
            args: [
              {
                visible: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              },
              {
                "yaxis.ticksuffix": " s",
              },
            ],
            label: "Stationary",
            method: "update",
            execute: true,
          },
          {
            args: [
              {
                visible: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                  false,
                  false,
                  false,
                ],
              },
              {
                "yaxis.ticksuffix": " ",
              },
            ],
            label: "Peaks number",
            method: "update",
            execute: true,
          },
          {
            args: [
              {
                visible: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                ],
              },
              {
                "yaxis.ticksuffix": " s",
              },
            ],
            label: "Peaks time",
            method: "update",
            execute: true,
          },
        ],
      },
    ],
  };

  return (
    <Plot
      data={[
        ...traces_trasient,
        ...traces_stationary,
        ...traces_peaks_number,
        ...traces_peaks_time,
      ]}
      style={{ margin: 0, backgroundColor: "none" }}
      config={config}
      useResizeHandler={true}
      layout={layout}
      divId={signal.name}
      //onInitialized={(figure) => onInitialized(figure, signal.id)}
      //onUpdate={(figure) => onUpdate(figure, signal.id)}
      //onRelayout={onRelayout}
    />
  );
};

export default PlotPid;
