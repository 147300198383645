import React, { FunctionComponent, useContext } from "react";
import CardInfo from "../components/common/card-info";
import {
  MdSchedule,
  MdLocalOffer,
  MdWarning,
  MdEqualizer,
} from "react-icons/md";
import Backdrop from "../components/common/backdrop/backdrop";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import _ from "lodash";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Card, Row, Col, Container } from "react-bootstrap";
import Restricted from "../context/permission/restricted";
import StatsDatabase from "../components/database/card-memory-stats";
import moment, { Moment } from "moment";
import DiskStats from "../components/database/card-disk-stats";
import { permissions } from "../services/permissions/permissions";
import ThemeContext from "../context/theme/themeContext";
import Paragraph from "../components/common/paragraph";
import withLayout from "../hoc/withLayout";
import { useGetDashboard } from "../queries/dashboard.hook";
import { Tour, dashboard_steps } from "tour";
import { Routes } from "Routes";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);
interface IState {}
interface DashboardPageProps {}

const DashboardPage: FunctionComponent<DashboardPageProps> = ({}) => {
  const { theme } = useContext(ThemeContext);
  const { data, isLoading } = useGetDashboard();

  const convertToDates = (
    dates: any,
    format: "MMM Do YYYY" | "ddd YY" | "ddd YYYY" | "ddd Do" = "MMM Do YYYY"
  ) => {
    if (dates) {
      var new_dates: any = [];
      dates.forEach((date: moment.MomentInput) => {
        new_dates.push(moment(date).format(format));
      });

      return new_dates;
    }
    return dates;
  };

  const Preview = () => {
    return (
      <>
        <Row className="mb-2">
          <Col>
            <Paragraph rows={4} />
          </Col>
          <Col>
            <Paragraph rows={4} />
          </Col>
          <Col>
            <Paragraph rows={4} />
          </Col>
          <Col>
            <Paragraph rows={4} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Paragraph rows={10} height={350} />
          </Col>
          <Col>
            <Paragraph rows={10} height={350} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Paragraph rows={10} height={350} />
          </Col>
          <Col>
            <Paragraph rows={10} height={350} />
          </Col>
          <Col>
            <Paragraph rows={10} height={350} />
          </Col>
          <Col>
            <Paragraph rows={10} height={350} />
          </Col>
        </Row>
      </>
    );
  };

  const notification_data = {
    labels: convertToDates(data?.response?.last_month_notifications?.dates),
    datasets: [
      {
        label: "Number of notifications",
        data: data?.response?.last_month_notifications?.counts,
        fill: true,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const notification_options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          min: 0,
          stepSize: 1,
        },
      },
    },
    //indexAxis: 'y',
    plugins: {
      title: {
        display: true,
        text: "Last month notification by day",
      },
      legend: {
        display: true,
        //align: 'center',
        labels: {
          //color: "rgb(255, 99, 132)",
        },
      },
    },
  };

  const upperFirst = (label: string[] | undefined) => {
    if (label === undefined) {
      return;
    }

    if (Array.isArray(label)) {
      var array: string[] = [];
      label.forEach((x) => {
        array.push(_.upperFirst(x));
      });
      return array;
    }
  };

  const notificationType_data = {
    labels: upperFirst(data?.response?.last_month_notifications_types?.labels),
    title: {
      display: true,
      text: "Text",
    },
    datasets: [
      {
        label: "My First Dataset",
        data: data?.response?.last_month_notifications_types?.counts,
        backgroundColor: [
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          // "rgb(54, 162, 235)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const notificationType_options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Last month notification by type",
      },
    },
  };

  const notificationType_plugins = [
    {
      id: "1",
      afterDraw: function (chart: any) {
        //: Chart
        //if (chart.data.datasets[0].data.length < 1) {
        if (chart.data.datasets[0].data.every((item: any) => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          //ctx.font = "30px Arial";
          ctx.font = "30px";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  const datasets_month = [
    {
      label: "Completed",
      data: data?.response?.scheduled_maintenance_by_month?.completed,
      backgroundColor: "rgba(75, 192, 192, 0.5)",
      borderColor: "rgb(75, 192, 192)",
      borderWidth: 1,
    },
    {
      label: "Expired",
      data: data?.response?.scheduled_maintenance_by_month?.expired,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 1,
    },
    {
      label: "Scheduled",
      data: data?.response?.scheduled_maintenance_by_month?.scheduled,
      backgroundColor: "rgba(201, 203, 207, 0.5)",
      borderColor: "rgb(201, 203, 207)",
      borderWidth: 1,
    },
  ];

  const label_month = data?.response?.scheduled_maintenance_by_month?.labels;
  const data_month = {
    labels: label_month,
    datasets: datasets_month,
    indexAxis: "y",
  };

  const options_month = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          min: 0,
          stepSize: 1,
        },
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Current year maintenance",
      },
    },
  };

  const labels_week = convertToDates(
    data?.response?.scheduled_maintenance_by_date?.labels,
    "ddd Do"
  ); //["Gen", "Feb", "Mar"];
  const options_week = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Current week maintenance",
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          min: 0,
          stepSize: 1,
        },
      },
    },
  };

  const data_week = {
    labels: labels_week,
    datasets: [
      {
        label: "Completed",
        data: data?.response?.scheduled_maintenance_by_date?.completed,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
      },
      {
        label: "Expired",
        data: data?.response?.scheduled_maintenance_by_date?.expired,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 1,
      },
      {
        label: "Scheduled",
        data: data?.response?.scheduled_maintenance_by_date?.scheduled,
        backgroundColor: "rgba(201, 203, 207, 0.5)",
        borderColor: "rgb(201, 203, 207)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  type ICard = {
    title: string;
    value: number;
    icon: JSX.Element;
  };
  const cards: ICard[] = [
    {
      title: "Monitored structures",
      value: data?.response?.struct ? data?.response?.struct : 0,
      icon: (
        <MdLocalOffer
          style={{
            fontSize: "2rem",
            color: theme.primary,
          }}
        />
      ),
    },
    {
      title: "Expired maintenance",
      value: data?.response?.expired_maintenance
        ? data?.response?.expired_maintenance
        : 0,
      icon: (
        <MdWarning
          style={{
            fontSize: "2rem",
            color: theme.primary,
          }}
        />
      ),
    },
    {
      title: "Maintenance for today",
      value: data?.response?.today_maintenance
        ? data?.response?.today_maintenance
        : 0,
      icon: (
        <MdSchedule
          style={{
            fontSize: "2rem",
            color: theme.primary,
          }}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <Tour steps={dashboard_steps} redirectTo={Routes.maintenance} />
      <Backdrop show={isLoading} />
      {isLoading ? (
        <Preview />
      ) : (
        <Container fluid>
          <Restricted to={permissions.dashboard.view} showFallback={true}>
            {/* <a onClick={async () => await this.loadDashboard()}>Refresh</a> */}
            <Row className="demo_cards mb-1">
              {cards.map((card, index) => {
                return (
                  <Col
                    key={"card-" + index}
                    className="mt-1"
                    lg
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <CardInfo
                      title={card.title}
                      value={card.value}
                      icon={card.icon}
                    />
                  </Col>
                );
              })}

              <Restricted to={permissions.dashboard.view_notifications}>
                <Col className="mt-1" lg md={6} sm={6} xs={6}>
                  <CardInfo
                    title="Reports generated"
                    value={
                      data?.response?.reports_generated
                        ? data?.response?.reports_generated
                        : 0
                    }
                    icon={
                      <MdEqualizer
                        style={{
                          fontSize: "2rem",
                          color: theme.primary,
                        }}
                      />
                    }
                  />
                </Col>
              </Restricted>
            </Row>

            <Row className="mb-1">
              <Restricted to={permissions.dashboard.view_notifications}>
                <Col className="mt-1" lg md={6} sm={12} xs={12}>
                  <Card
                    className="demo_month_anomalies p-3"
                    style={{ minHeight: 350, maxHeight: "30vh" }}
                  >
                    <Line
                      data={notification_data}
                      options={notification_options}
                    />
                  </Card>
                </Col>
                <Col className="mt-1" lg md={6} sm={12} xs={12}>
                  <Card
                    className="demo_month_notification_type p-3"
                    style={{ minHeight: 350, maxHeight: "30vh" }}
                  >
                    <Doughnut
                      data={notificationType_data}
                      options={notificationType_options}
                      plugins={notificationType_plugins}
                    />
                  </Card>
                </Col>
              </Restricted>
            </Row>

            <Row>
              <Restricted to={permissions.dashboard.view_maintenances}>
                <Col className="mb-1" xl={3} lg={6} md={6} sm={12} xs={12}>
                  <Card
                    className="demo_current_month_maintenance p-3"
                    style={{ minHeight: 350 }}
                  >
                    <Bar data={data_month} options={options_month} />
                  </Card>
                </Col>
                <Col className="mb-1" xl={3} lg={6} md={6} sm={12} xs={12}>
                  <Card
                    className="demo_current_week_maintenance p-3"
                    style={{ minHeight: 350 }}
                  >
                    <Bar data={data_week} options={options_week} />
                  </Card>
                </Col>
              </Restricted>
              <Restricted to={permissions.dashboard.view_stats}>
                <Col
                  className="demo_compression_stats mb-1"
                  xl={3}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <StatsDatabase
                    colorIcon={theme.primary}
                    totalSize={
                      data?.response?.database_stats &&
                      data?.response?.database_stats.total_size &&
                      data?.response?.database_stats.total_size
                    }
                    totalCompressedSize={
                      data?.response?.database_stats &&
                      data?.response?.database_stats.total_size_compressed
                    }
                    totalUnompressedSize={
                      data?.response?.database_stats &&
                      data?.response?.database_stats.total_size_uncompressed
                    }
                  />
                </Col>
                <Col
                  className="demo_disk_usage mb-1"
                  xl={3}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  {/* <Card className="p-3" style={{ minHeight: 350 }}> */}
                  <DiskStats
                    colorIcon={theme.primary}
                    diskFree={
                      data?.response?.disk_stats &&
                      data?.response.disk_stats.free
                    }
                    diskTotal={
                      data?.response?.disk_stats &&
                      data?.response?.disk_stats.total
                    }
                    diskUsed={
                      data?.response?.disk_stats &&
                      data?.response?.disk_stats.used
                    }
                  />
                  {/* </Card> */}
                </Col>
              </Restricted>
            </Row>
          </Restricted>
        </Container>
      )}
    </React.Fragment>
  );
};

export default DashboardPage;
