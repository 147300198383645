import { ResponseWrapper } from "../../models/reponse";
import Http from "../base/http-services";
import { AxiosError } from "axios";
import * as alertService from "../../utils/alertService";
import { BaseService } from "../base/base-service";

const endpoint = "auth/users/me";
const endpoint_change_password = "auth/users/set_password";
const endpoint_groups = "auth/groups";


export interface IProfile {
  id: number;
  date_joined:string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_active: boolean;
  report_notification: boolean;
  warning_notification: boolean;
  trend_notification: boolean;
  maintenance_notification: boolean;
  group: IProfileGroup;
  profile: ProfileDetails;
}

export interface ProfileDetails {
  birthday: any;
  gender: any;
  phone: any;
  address: any;
  city: any;
  zip: any;
}

export interface IProfileGroup {
  name: string;
  level: number;
  permissions: Permission[];
}

export interface Permission {
  name: string;
  codename: string;
}

class UserProfileService extends BaseService<IProfile> {
  constructor() {
    super(endpoint, Http);
  }

  getMe = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IProfile>> => {
    var params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return this.getPromise(this.http.get(this.endpoint + "/" + "?" + params));
  };

  update = (data: any) => {
    return this.getPromise(this.http.put(this.endpoint + "/", data));
  };

  changePassword = (data: any): Promise<ResponseWrapper<IProfile>> => {
    return this.getPromise(
      this.http.post(endpoint_change_password + "/", data)
    );
  };
}

export const user_profile_service = new UserProfileService();
