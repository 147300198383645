import { BaseService } from "../base/base-service";
import Http from "../base/http-services";

const endpoint = "temperature/condition";

export interface ITemperatureCondition {
  id: number;
  created_at: Date;
  updated_at: Date;
  temperature_id: any;
  name: string;
  description: string;
  threshold_min?: number;
  threshold_max?: number;
  default_avg?: number;
  start_lower_range: number;
  start_upper_range: number;
  target_lower_range: number;
  target_upper_range: number;
  unit_of_measurement?:string;
}

class TemperatureConditionService extends BaseService<ITemperatureCondition> {
  constructor() {
    super(endpoint, Http);
  }
}

export const temperature_condition_service = new TemperatureConditionService();
