import React from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import DefaultButton from "../components/common/buttons/default-button";
import { FcBinoculars } from "react-icons/fc";
type Props = {};
const NotFound: React.FunctionComponent<Props> = ({}) => {
  const history = useHistory();
  return (
    <Container className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <Row>
        <Col className="text-center">
        <FcBinoculars size={150} />                    
          <br></br>
          <br></br>
          <h1>Not Found.</h1>
          <br></br>
          {/* Always go to login that has the condition if the user is logged */}
          <DefaultButton
            label=" Go back to the site"
            onClick={() => {
              history.push("/login");
            }}
          ></DefaultButton>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
