import { ResponseWrapper } from "../../models/reponse";
import { IBadge } from "../../services/badge-service";
import { badge_demo_data } from "./badge-data-demo";

export class BadgeDemoService {
  data = badge_demo_data.getData();
  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IBadge>> => {
    return new Promise<any>((resolve, reject) => {
      const response: ResponseWrapper<IBadge> = {
        status: 200,
        version: "demo",
        response: this.data,
      } as ResponseWrapper<IBadge>;
      resolve(response);
    });
  };
}
