import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "../../../components/common/selects/select-option";
import { RootState } from "../../configureStore";
import { IPidResponse } from "../../../services/statistic/statistic-plot-pid";

// Define a type for the slice state
interface AnalyticsPidState {
  start_date?: string;
  end_date?: string;
  structures_selected?: SelectOption[];
  signals?: IPidResponse[];
}

// Define the initial state using that type
const initialState: AnalyticsPidState = {};

export const analyticsPidSlice = createSlice({
  name: "analytics-pid",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    savePidStartDate(state, action: PayloadAction<any>) {
      state.start_date = action.payload;
    },
    savePidEndDate(state, action: PayloadAction<any>) {
      state.end_date = action.payload;
    },
    savePidStructuresSelected(state, action: PayloadAction<SelectOption[]>) {
      state.structures_selected = action.payload;
    },
    savePidSignals(state, action: PayloadAction<IPidResponse[] | undefined>) {
      state.signals = action.payload;
    },
  },
});

export const {
  savePidStartDate,
  savePidEndDate,
  savePidStructuresSelected,
  savePidSignals,
} = analyticsPidSlice.actions;

export const getAnalyticsPidState = (state: RootState) => state.analytics_pid;

export default analyticsPidSlice.reducer;
