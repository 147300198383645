import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QueryKeys, StaleTime } from "../query_keys";
import {
  ITemperatureCondition,
  temperature_condition_service,
} from "../../services/temperature/temperature-condition-service";
import alertService from "../../utils/alertService";
import { ResponseWrapper } from "../../models/reponse";
import { useHistory } from "react-router-dom";
import { FormikValues } from "formik";
import { Routes } from "../../Routes";

export const useGetTemperatureCondition = () => {
  const struct_temp = useQuery({
    queryKey: [QueryKeys.MANAGEMENT_GET_TEMPERATURE_CONDITION],
    queryFn: () => temperature_condition_service.get(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_15, //Important when the data is not valid
  });
  return struct_temp;
};

export const useTemperatureConditionById = () => {
  const queryClient = useQueryClient();
  const temp_condition = useMutation<
    ResponseWrapper<ITemperatureCondition>,
    any,
    number
  >({
    mutationFn: (id) => {
      return temperature_condition_service.getById(id);
    },
    onError: (error, variables, context) => {
      alertService.errorMessage(error.response?.data);
    },
    onSuccess: (data, variables, context) => {},
  });

  return temp_condition;
};

export const useSaveTemperatureCondition = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const saveTemperatureCondition = useMutation<any, any, FormikValues>({
    mutationFn: (values) => {
      return temperature_condition_service.save(values, {});
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MANAGEMENT_GET_TEMPERATURE_CONDITION],
      });
      history.push(Routes.management_temperature_condition);
    },
  });
  return saveTemperatureCondition;
};

export const useDeleteTemperatureCondition = () => {
  const queryClient = useQueryClient();
  const deletUser = useMutation<any, any, { id: number }>({
    mutationFn: (data) => {
      return temperature_condition_service.delete(data.id);
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MANAGEMENT_GET_TEMPERATURE_CONDITION],
      });
    },
  });

  return deletUser;
};
