import moment from "moment";
import { IResponse } from "../../services/plot/plot-struct-service";
import { IPlotAnalyticsModel } from "../../services/statistic/statistic-plot-service";
import { ITemperatureResponse } from "../../services/statistic/statistic-plot-temperature";
import { IValveResponse } from "../../services/statistic/statistic-plot-valve";
import plot_analytcis from "./analytics-demo-data.json";
import plot_temperature from "./analytics-temperature-data.json";
import plot_valve from "./analytics-valve-data.json";

class PlotAnalyticsDemo {
  private default: IPlotAnalyticsModel;
  private temperature: ITemperatureResponse[];
  private valve: IValveResponse[];
  constructor() {
    this.default = this.loadDefault();
    this.temperature = this.loadTemperatures();
    this.valve = this.loadValves();
  }

  private loadDefault = () => {
    const myJSON = JSON.stringify(plot_analytcis.response);
    const obj: IPlotAnalyticsModel = JSON.parse(myJSON);
    //const result = this.prepareData(obj);
    return obj;
  };

  private loadTemperatures = () => {
    const myJSON = JSON.stringify(plot_temperature.response);
    const obj: ITemperatureResponse[] = JSON.parse(myJSON);
    return obj;
  };
  private loadValves = () => {
    const myJSON = JSON.stringify(plot_valve.response);
    const obj: IValveResponse[] = JSON.parse(myJSON);
    return obj;
  };

  private prepareData = (data: IPlotAnalyticsModel) => {
    data.time_series.forEach((signal) => {
      if (signal.graph.time) {
        const { time, anomalies_time, prediction } = signal.graph;
        const first_date = moment(time[0]);
        const last_date = moment(time.slice(-1)[0]);
        const dates_between_points = last_date.diff(first_date, "days");
        const today = moment();
        today.subtract(dates_between_points, "days");
        const diff = today.diff(first_date, "days");

        const new_time: string[] = [];
        time?.forEach((time) => {
          const new_date = moment(time);
          new_time.push(
            new_date.add(diff, "days").format("YYYY-MM-DDTHH:mm:ss")
          );
        });
        const new_anomalies: string[] = [];
        anomalies_time?.forEach((time) => {
          const new_date_anomalies = moment(time);

          new_anomalies.push(
            new_date_anomalies.add(diff, "days").format("YYYY-MM-DDTHH:mm:ss")
          );
        });
        const new_prediction: string[] = [];
        prediction?.x?.forEach((time) => {
          const new_date_prediction = moment(time);

          new_prediction.push(
            new_date_prediction.add(diff, "days").format("YYYY-MM-DDTHH:mm:ss")
          );
        });

        //set the new values

        signal.graph.p_thold_begin.x = moment(signal.graph.p_thold_begin.x)
          .add(diff, "days")
          .format("YYYY-MM-DD");

        signal.graph.p_period_end.x = moment(signal.graph.p_period_end.x)
          .add(diff, "days")
          .format("YYYY-MM-DD");

        signal.graph.time = new_time;
        signal.graph.anomalies_time = new_anomalies;
        signal.graph.prediction.x = new_prediction;
      }
    });
    return data;
  };

  getDefaultPlot = () => {
    return this.default;
  };
  getTemperaturePlot = () => {
    return this.temperature;
  };

  getValvePlot = () => {
    return this.valve;
  };
}

export const plot_analytics_demo = new PlotAnalyticsDemo();
