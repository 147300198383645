import { PlotStructDemo } from "../../demo/plot/plot-struct-demo";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";
import storage from "../base/storage";

const endpoint = "plot/struct";
//https://julietonyekaoha.medium.com/react-cancel-all-axios-request-in-componentwillunmount-e5b2c978c071

export interface IResponse {
  alarms: any[];
  signals: IRawSignal[];
  values: any[];
}

export interface IRawSignal {
  struct_name: string;
  description: string;
  unit_of_measurement: string;
  time: string[];
  value: number[];
  status: string[];
}

class PlotStructService {
  http = Http;

  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
  getPlot = (
    struct_id: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IResponse>> => {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    return this.getPromise<IResponse>(
      this.http.get(endpoint + "/" + struct_id + "?" + queryString)
    );
  };
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new PlotStructDemo()
    : new PlotStructService();

export const plot_struct_service = instance;
