import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import usersReduce from "./reducers/users.management-reducer";
import logger from "./middleware/logger";
import { thunkMiddleware } from "./middleware/thunk";
import usageBasedReducer from "./reducers/usage.based.management-reducer";
import scheduledReducer from "./reducers/scheduled.management-reducer";
import structReducer from "./reducers/struct.management";
import badgeReducer from "./reducers/badge-reducer";
import graphReducer from "./reducers/graph-reducer";
import analyticsReducer from "./reducers/analytics/analytics-reducer";
import analyticsDefaultReducer from "./reducers/analytics/analytics.default-reducer";
import analyticsTemperatureReducer from "./reducers/analytics/analytics.temperature-reducer";
import analyticsValveReducer from "./reducers/analytics/analytics.valve-reducer";
import analyticsPidReducer from "./reducers/analytics/analytics.pid-reducer";
import notificationReducer from "./reducers/notification-reducer";

const appReducer = combineReducers({
  users: usersReduce,
  usage_based: usageBasedReducer,
  scheduled: scheduledReducer,
  struct: structReducer,
  badge: badgeReducer,
  graph: graphReducer,
  notification: notificationReducer,
  analytics: analyticsReducer,
  analytics_default: analyticsDefaultReducer,
  analytics_temperature: analyticsTemperatureReducer,
  analytics_valve: analyticsValveReducer,
  analytics_pid: analyticsPidReducer,
});
//remove data from redux
//dispatch({ type: "USER_LOGOUT" }); from a page.
const rootReducer = (state: any, action: any) => {
  /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
  if (action.type === "USER_LOGOUT") {    
    return appReducer(undefined, { type: undefined });
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat([thunkMiddleware, logger]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
//export type RootState = ReturnType<typeof store.getState>

export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
