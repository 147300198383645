import { FunctionComponent, useContext } from "react";
import { Form } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { ITheme } from "../../../context/theme/theme";
import ThemeContext from "../../../context/theme/themeContext";

interface CheckBoxProps {
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange?: (data: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = createUseStyles({
  styleCheckbox: (props: ITheme) => ({
    marginRight: "5px",
    "&:checked": {
      backgroundColor: props.primary,
      borderColor: props.primary,
    },
    "&:focus": {
      outline: "none !important",
      boxShadow: "none",
    },
  }),
  styleRadioForm: {
    display: "inline",
    margin: "0.5rem",
  },
});

const CheckBox: FunctionComponent<CheckBoxProps> = ({
  label,
  name,
  value,
  checked,
  onChange,
}) => {
    const { theme } = useContext(ThemeContext);
    const classes = useStyles({ ...theme });
  return (
    <Form.Check
      inline
      className={classes.styleRadioForm}
      bsPrefix="default"
    >
      <Form.Check.Input        
        className={classes.styleCheckbox}
        id={`inline-${"checkbox"}-1-${label}`}
        type={"checkbox"}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange && onChange}
      />
      <Form.Check.Label htmlFor={`inline-${"checkbox"}-1-${label}`}>
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default CheckBox;
