import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import { signal_category_service } from "../services/struct/signal-category-service";

// TODO implement keys by file? or globals?

export const useGetSignalCategories = () => {
  const categories = useQuery({
    queryKey: [QueryKeys.GET_CATEGORIES],
    queryFn: () => signal_category_service.get(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });
  return categories;
};
