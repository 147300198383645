import Swal from "sweetalert2";
import { sleep } from "./timers";

type Icon = "warning" | "error" | "success" | "info" | "question";

let hasAlertOpened: boolean = false;

export function Confirm(icon: Icon, title: string, message: string) {
  return Swal.fire({
    icon: icon,
    title: title,
    html: message,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: `Yes, i'm sure`,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    //denyButtonText: `Don't save`,
  });
}

export function Ok(message: string) {
  return Swal.fire({
    icon: "success",
    title: "Success!",
    position: "center",
    html: message,
    showDenyButton: false,
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    //denyButtonText: `Don't save`,
  });
}

export function errorMessage(message: string) {
  return Swal.fire({
    icon: "error",
    title: "Error!",
    position: "center",
    html: message,
    showDenyButton: false,
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    //denyButtonText: `Don't save`,
  });
}

export function customMessage(title: string, message: string) {
  if (hasAlertOpened) {
    // Prevent continueing if the alert has opened before
    return;
  }
  hasAlertOpened = true;
  return Swal.fire({
    icon: "error",
    title: title,
    position: "center",
    html: message,
    showDenyButton: false,
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    //denyButtonText: `Don't save`,
  })
    .then((result) => {
      if (result.isConfirmed) {
        hasAlertOpened = false;
        console.debug("dismiss");
      }
    })
    .finally(() => {
      hasAlertOpened = false;
      console.debug("finally");
    });
}

export function WaitForRequest(
  title: string,
  message: string,
  requestFunction: any
) {
  return Swal.fire({
    icon: "question",
    title: title,
    position: "center",
    html: message,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: "Yes, i'm sure",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    preConfirm: async () => {
      try {
        await sleep(200);
        return await requestFunction();
      } catch (ex: any) {
        if (ex.response) {
          const { data, status } = ex.response;

          if (status < 500) {
            Swal.close({
              isConfirmed: false,
              isDenied: false,
              isDismissed: false,
            });
            Swal.fire({
              icon: "error",
              title: "Request error",
              text: data.response.error,
              confirmButtonColor: "#3085d6",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Request error",
            text: ex,
            confirmButtonColor: "#3085d6",
          });
        }
        return false;
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then(async (result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
}

export function WaitForRequestWithInput(
  title: string,
  message: string,
  requestFunction: any
) {
  return Swal.fire({
    icon: "question",
    title: title,
    html: message,
    position: "center",
    input: "password",
    inputLabel: "Password",
    inputPlaceholder: "Enter your password",
    inputAttributes: {
      autocapitalize: "off",
    },
    confirmButtonText: "Yes, i'm sure",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    showCancelButton: true,
    showLoaderOnConfirm: true,
    preConfirm: async (password: string) => {
      try {
        return await requestFunction(password);
      } catch (ex: any) {
        if (ex.response) {
          const { data, response, config, request, headers, status } =
            ex.response;
          Swal.close({
            isConfirmed: false,
            isDenied: false,
            isDismissed: false,
          });
          Swal.fire({
            icon: "error",
            title: "Request error",
            text: data.response.error,
            confirmButtonColor: "#3085d6",
          });
        } else {
          const { data, response, config, request, headers, status } =
            ex.response;
          Swal.fire({
            icon: "error",
            title: "Request error",
            text: ex,
            confirmButtonColor: "#3085d6",
          });
        }
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
    inputValidator: (value: string) => {
      if (!value) {
        return "You need to write something!";
      }
      return "";
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
}

export function autoRedirectMessage(
  title: string,
  message: string,
  onClose: () => void,
  interval: number = 3000,
  icon: Icon = "info"
) {
  let timerInterval: any = undefined;
  return Swal.fire({
    icon: icon,
    title: title, //"Your session expired!",
    position: "center",
    timer: interval,
    timerProgressBar: true,
    html: message,
    didOpen: () => {
      Swal.showLoading();
      const b: any = Swal.getHtmlContainer()?.querySelector("b");
      timerInterval = setInterval(() => {
        b.textContent = Swal.getTimerLeft();
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      onClose && onClose();
    }
  });
}

// Toast Part.

const ToastModel = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCancelButton: false,
  timer: 2000,
  grow: "row",
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export function Toast(icon: Icon, title: string) {
  ToastModel.fire({
    icon: icon,
    title: title,
  });
}

export default {
  Ok,
  Confirm,
  errorMessage,
  customMessage,
  WaitForRequest,
  WaitForRequestWithInput,
  Toast,
};
