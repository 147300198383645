import { FunctionComponent, useEffect, useState } from "react";
import { Card, Col, Container, Row, Button, Form } from "react-bootstrap";
import { MdAddCircleOutline, MdDeleteOutline } from "react-icons/md";
import AppForm from "../../../formik-forms/AppForm";
import FormArray, {
  FormArrayButtonTemplateProps,
  FormArrayTemplateProps,
} from "../../../formik-forms/FormArray";
import FormSelect from "../../../formik-forms/FormSelect";
import FormSubmit from "../../../formik-forms/FormSubmit";
import FormSwitch from "../../../formik-forms/FormSwitch";
import FormText from "../../../formik-forms/FormText";
import FormTextArea from "../../../formik-forms/FormTextArea";
import Backdrop from "../../../common/backdrop/backdrop";
import CloseButton from "../../../common/buttons/close-button";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import {
  IMaintenanceScheduled,
  scheduled_service,
} from "../../../../services/maintenance/scheduled/scheduled-service";
import useApi from "../../../../hooks/useApi";

import { FormikHelpers, FormikValues } from "formik";
import { Routes } from "../../../../Routes";
import _ from "lodash";
import FormDateTime from "../../../formik-forms/FormDateTime";
import withLayout from "../../../../hoc/withLayout";
import FormCheckBox from "../../../formik-forms/FormCheckbox";
import { sheduled_operation_service } from "../../../../services/maintenance/scheduled/operation-service";
import { WaitForRequest } from "../../../../utils/alertService";
import moment from "moment";
interface ScheduledFormProps { }

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  title: Yup.string().required(),
  description: Yup.string()
    .matches(
      /^(?![\n]*$)/,
      "Description can't be only line breaks and whitespace"
    )
    .required(),
  start_date: Yup.date().required(),
  end_date: Yup.date().required(),
  trigger_hour: Yup.string().required("Hours is required"),
  trigger_day: Yup.array().required("Triggger day is required"),
  enabled: Yup.boolean().required().default(true),
  operations: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().required("Order is required"),
      title: Yup.string().required("Title is required"),
      description: Yup.string()
        .required("Description is required")
        .typeError("A Description is required"),
    })
  ),
});
const ScheduledForm: FunctionComponent<ScheduledFormProps> = ({ }) => {
  const [scheduled, setScheduled] = useState<IMaintenanceScheduled>(); // for modify the user
  const history = useHistory();
  let { id } = useParams<any>();
  const getMaintenance = useApi(scheduled_service.getById);
  const saveMaintenance = useApi(scheduled_service.save);

  const checkId = async () => {
    try {
      if (id === "new" || id === undefined) {
        setScheduled({ enabled: true } as IMaintenanceScheduled);
        return;
      }
      const usageBased = await getMaintenance.request(id);

      if (!usageBased) return history.replace("/not-found");

      setScheduled(usageBased.response);
    } catch (error: any) {
      history.replace("/404");
    }
  };

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      //TODO
      //delete values.operations;
      await saveMaintenance.request(values);
      //const response = await maintenanceScheduledService.save(values)
      history.push(Routes.management_scheduled);
    } catch (error: any) {
      scheduled_service.checkErrors(error);
    }
  };

  useEffect(() => {
    checkId();
  }, []);
  const options_trigger_day = [
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 0 },
  ];
  let operations =
    scheduled && _.orderBy(scheduled.operations, ["order"], ["asc"]);

  let selected =
    scheduled &&
    scheduled.trigger_day &&
    options_trigger_day.filter((x) => scheduled.trigger_day.includes(x.value));

  return (
    <Container>
      <Backdrop show={getMaintenance.loading} />
      <Card>
        <Card.Body>
          {<h3>{id === "new" || id === undefined ? "New" : "Modify"}</h3>}
          <hr />
          {scheduled && (
            <AppForm
              validationSchema={validationSchema}
              initialValues={{
                id: scheduled && scheduled.id,
                title: scheduled && scheduled.title,
                start_date:
                  scheduled && scheduled.start_date
                    ? scheduled.start_date
                    : moment().format("L"),
                end_date:
                  scheduled && scheduled.end_date
                    ? scheduled.end_date
                    : moment()
                      .add(1, "month")
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .format("L"),
                trigger_hour:
                  scheduled && scheduled.trigger_hour
                    ? scheduled.trigger_hour
                    : moment().format("HH:mm"),
                description: scheduled && scheduled.description,
                enabled:
                  scheduled && scheduled.enabled ? scheduled.enabled : true,
                trigger_day: scheduled && scheduled.trigger_day, //selected && selected,
                operations: operations && operations,
              }}
              onSubmit={doSubmit}
            >
              <Row>
                <Col lg={6} sm={12}>
                  <FormText
                    name="title"
                    label="Title"
                    placeholder="Insert a title..."
                    helpText="Title of the maintenance"
                  />
                </Col>
                <Col lg={6} sm={12}>
                  <FormTextArea
                    name="description"
                    label="Description"
                    placeholder="Insert a description..."
                    helpText="Description of the maintenance"
                    rows={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} sm={12}>
                  <FormDateTime
                    name="start_date"
                    label="Start date"
                    placeholder="Insert a date..."
                    helpText="Date on which maintenance begins to be active."
                    timeEnabled={false}
                  />
                </Col>
                <Col lg={4} sm={12}>
                  <FormDateTime
                    name="end_date"
                    label="End date"
                    placeholder="Insert a date..."
                    helpText="Date on which maintenance will end"
                    timeEnabled={false}
                  />
                </Col>
                <Col lg={4} sm={12}>
                  <FormDateTime
                    name="trigger_hour"
                    label="Hour"
                    placeholder="Insert a date..."
                    helpText="Time of the day the maintenance will be triggered."
                    dateEnabled={false}
                    timeSnipped={[
                      { label: "06:00am", hour: 6, minutes: 0 },
                      { label: "12:00pm", hour: 12, minutes: 0 },
                      { label: "06:00pm", hour: 18, minutes: 0 },
                      { label: "00:00am", hour: 0, minutes: 0 },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormCheckBox
                    label="Trigger day"
                    name="trigger_day"
                    items={options_trigger_day}
                  />
                </Col>
                <Col sm={2} md={2}>
                  <br />
                  <FormSwitch
                    name={"enabled"}
                    label={"Is enabled"}
                    helpText="Select for active the maintenance."
                  />
                </Col>
              </Row>
              <Row></Row>

              <h4>Operations</h4>
              <hr />
              <FormArray
                name={"operations"}
                TemplateArrayComponent={FormArrayTemplate}
                TemplateAddButtonComponent={FormArrayButtonTemplate}
              />
              <br></br>

              <CloseButton
                label="Close"
                onClick={() => history.goBack()}
                disabled={saveMaintenance.loading}
              />
              <span className="me-2"></span>
              <FormSubmit
                title="Save changes"
                disabled={saveMaintenance.loading}
              />
            </AppForm>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ScheduledForm;

const FormArrayTemplate: FunctionComponent<FormArrayTemplateProps> = ({
  name,
  index,
  item,
  OnRemove,
}) => {
  const deleteFromDb = async (item: any, index: number) => {
    try {
      if (item && item.id) {
        let func = async () => await sheduled_operation_service.delete(item.id);
        const response = await WaitForRequest(
          "Confirm",
          "Are you sure you want to delete this operation ?",
          func
        );
        if (response) {
          //OK
        } else {
          return;
        }
      }

      OnRemove && OnRemove(index);
    } catch (error: any) {
      sheduled_operation_service.checkErrors(error);
    }
  };
  return (
    <Row className="border justify-content-center p-1 m-1 shadow-sm">
      <Col lg={1} md={2} sm={6}>
        <FormText
          name={`${name}.${index}.order`}
          label="Order"
          placeholder="Write a Order..."
          type={"number"}
          helpText="Define the order."
        />
      </Col>
      <Col lg={4} md={4} sm={6}>
        <FormText
          name={`${name}.${index}.title`}
          label="Title"
          placeholder="Write a title..."
          helpText="Title of the operation, needs to be unique."
        />
      </Col>
      <Col lg={5} md={4} sm={12}>
        <FormTextArea
          name={`${name}.${index}.description`}
          label="Description"
          placeholder="Write a description..."
          rows={2}
          helpText="Description of the operation"
        />
      </Col>
      <Col lg={2} md={2} sm={12}>
        <Button
          className="mt-5"
          variant="outline-danger"
          size="sm"
          onClick={() => {
            //OnRemove && OnRemove(index);
            deleteFromDb(item, index);
          }}
        >
          <MdDeleteOutline />
        </Button>
      </Col>
    </Row>
  );
};

export const FormArrayButtonTemplate: FunctionComponent<
  FormArrayButtonTemplateProps
> = ({ OnPush }) => {
  return (
    <>
      <Row>
        <div className="d-flex justify-content-start">
          <a
            onClick={() =>
              OnPush && OnPush({ order: "", description: "", title: "" })
            }
            className="btn mr-1 btn-sm"
          >
            <MdAddCircleOutline /> Add operation.
          </a>
        </div>
      </Row>
    </>
  );
};
