import React, { FunctionComponent } from "react";

interface SearchBoxProps {
  value: string;
  placeholder: string;
  size: "md" | "sm" | "xl";
  onChange?: (value: string) => void;
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({
  value,
  onChange,
  placeholder = "Search...",
  size = "md",
}) => {
  return (
    <input
      type="text"
      name="query"
      className={"form-control my-3 form-control-" + size}
      placeholder={placeholder}
      value={value}
      onChange={(e: any) => onChange && onChange(e.currentTarget.value)}
    />
  );
};
export default SearchBox;
