import { FunctionComponent } from "react";
import { Card } from "react-bootstrap";
import { Row, Container, Col } from "react-bootstrap";
import withLayout from "../hoc/withLayout";
import { BsCalendar3, BsTools, BsTagFill } from "react-icons/bs";
import { MdPeople, MdGroups } from "react-icons/md";
import CardManagment from "../components/features/management/card-managment";
import { Routes } from "../Routes";
import { permissions } from "../services/permissions/permissions";
import { IconType } from "react-icons/lib";
import { FaTemperatureLow } from "react-icons/fa";
import { AiOutlineGateway } from "react-icons/ai";
import { checkPermissions } from "../context/permission/helpers";
interface ManageProps {}

type SectionItem = {
  title: string;
  description: string;
  link: string;
  icon: IconType;
  isVisible: boolean;
};

const Manage: FunctionComponent<ManageProps> = () => {
  const menu: SectionItem[] = [
    {
      title: "Users",
      description: "Add, modify, or delete users",
      link: Routes.users,
      icon: MdPeople,
      isVisible: checkPermissions(permissions.auth.add),
    },
    {
      title: "User Groups",
      description: "Add, modify, or delete groups",
      link: Routes.user_groups,
      icon: MdGroups,
      isVisible: checkPermissions(permissions.auth.add),
    },
    {
      title: "Usage-based",
      description: "Configure maintenance based on equipment usage.",
      link: Routes.management_usage_based,
      icon: BsTools,
      isVisible: checkPermissions(permissions.main.usage_maintenances.add),
    },
    {
      title: "Scheduled",
      description:
        "Configure scheduled maintenance to trigger daily,weekly,monthly,ecc. ",
      link: Routes.management_scheduled,
      icon: BsCalendar3,
      isVisible: checkPermissions(permissions.main.scheduled_maintenance.add),
    },
    {
      title: "Structures",
      description: "View and configure the thresholds for structures",
      link: Routes.management_struct,
      icon: BsTagFill,
      isVisible: true,
    },
    {
      title: "Temperatures",
      description: "View and configure the thresholds for temperatures",
      link: Routes.management_temperature_condition,
      icon: FaTemperatureLow,
      isVisible: checkPermissions(
        permissions.management.temperature_condition.add
      ),
    },
    {
      title: "Valves",
      description: "View and configure the thresholds for valves",
      link: Routes.management_valve_condition,
      icon: AiOutlineGateway,
      isVisible: checkPermissions(permissions.management.valve_condition.add),
    },
    // {
    //   title: "Value changes",
    //   description:
    //     "Send messages to the PLC to change values of variables.",
    //   link: "not-found",
    //   icon: MdChangeCircle,
    //   isVisible: true,
    // },
  ];
  return (
    <Container fluid className="text-center ">
      <Card className="shadow-lg">
        <Card.Body>
          <h3>Management</h3>
        </Card.Body>
      </Card>
      {/* <Restricted to={permissions.auth.add} showFallback={true}></Restricted> */}
      <Row className="justify-content-center">
        {menu.map(
          (menu, index) =>
            menu.isVisible && (
              <Col key={index} xl={4} lg={4} md={6} sm={6} xs={6}>
                <CardManagment
                  title={menu.title}
                  description={menu.description}
                  link={menu.link}
                  icon={menu.icon}
                />
              </Col>
            )
        )}
      </Row>
    </Container>
  );
};

export default (Manage);
