import _ from "lodash";
import { ResponseWrapper } from "../../../models/reponse";
import { maintenance_data } from "./data";
export class MaintenanceUsageBasedOperationDemo {
  //Operations send always a post request, if the operation already exists automatic
  // update.

  save(
    data: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      const maintenances = maintenance_data.getData();

      const maintenance_info = _.find(maintenances, (item, index) => {
        const operation = _.find(
          item.operations,
          (x) => x.id == data.usage_based_operation_id
        );
        return operation ? true : false;
      });

      if (maintenance_info) {
        if (
          maintenance_info.current_value != null &&
          maintenance_info.current_value < maintenance_info.expiration
        ) {
          reject({
            status: 400,
            version: "demo",
            response: {
              data: { response: { error: "The maintenance is not expired" } },
            },
          });
        }
      }

      const operation = _.find(maintenance_info?.operations, {
        id: data.usage_based_operation_id,
      });
      if (operation) {
        operation.completed = !operation?.completed;

        const indexMaintenance = maintenances.findIndex(
          (x) => x.id == maintenance_info?.id
        );

        maintenances[indexMaintenance].operations[0] = operation;

        maintenance_data.updateData(maintenances);
      }

      const response: ResponseWrapper<any> = {
        status: 200,
        version: "demo",
        response: !operation?.completed,
      } as ResponseWrapper<any>;

      resolve(response);
    });
  }

  delete(dataId: number): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The delete operation not allowed in demo" },
          },
        },
      });
    });
  }
  checkErrors(error: any) {
    //Nothing
    console.debug(error);
  }
}
