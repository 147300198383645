import { FunctionComponent } from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import Backdrop from "../../../common/backdrop/backdrop";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import { Link, useHistory } from "react-router-dom";
import Restricted from "../../../../context/permission/restricted";
import { Routes } from "../../../../Routes";
import { permissions } from "../../../../services/permissions/permissions";
import {
  MdAddCircle,
  MdDeleteOutline,
  MdModeEditOutline,
  MdRefresh,
} from "react-icons/md";
import Table from "../../../table/table";
import {
  useDeletGroupUsers,
  useGetUserGroups,
} from "../../../../queries/user.groups.hook";
import { TableColumn } from "react-data-table-component";
import { IUserGroup } from "../../../../services/users/user-groups-service";
import alertService from "../../../../utils/alertService";
import withLayout from "../../../../hoc/withLayout";
import Loader from "../../../common/loader";

interface UserGroupsListProps {}

const UserGroupsList: FunctionComponent<UserGroupsListProps> = () => {
  const history = useHistory();
  //RQ
  const { data, isLoading, refetch, isRefetching } = useGetUserGroups();
  const deletgroup = useDeletGroupUsers();

  const actionOptions = (group: IUserGroup) => (
    <>
      <Restricted to={permissions.auth.change}>
        <Button
          variant="outline-warning"
          onClick={() => {
            history.push(Routes.user_groups + "/" + group.id);
          }}
          size="sm"
        >
          <MdModeEditOutline />
        </Button>
      </Restricted>
      <span> &nbsp; </span>
      <Restricted to={permissions.auth.delete}>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => handleDelete(group)}
        >
          <MdDeleteOutline />
        </Button>
      </Restricted>
    </>
  );

  const columns: TableColumn<IUserGroup>[] = [
    {
      maxWidth: "10px",
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Level",
      selector: (row) => row.level,
      sortable: true,
    },
    {
      name: "Permissions",
      selector: (row) => row.permissions?.length,
      sortable: true,
    },
    {
      cell: (group) => actionOptions(group),

      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  const handleDelete = async (user: IUserGroup) => {
    let func = () => deletgroup.mutate({ dataId: user.id });
    alertService.WaitForRequest(
      "Confirm",
      "This operation can't be undo ",
      func
    );
  };
  return (
    <>
      <Backdrop show={isLoading || isRefetching} />
      <Container>
        <Restricted to={permissions.auth.view} showFallback={true}>
          <Backdrop show={isLoading} />
          <Card className="p-3">
            <h4>
              <GoBackNavigation url={Routes.management} />
              User groups
            </h4>
            <hr />
            <Row>
              <div className="d-flex justify-content-start">
                <Restricted to={permissions.auth.add}>
                  <Link to={Routes.user_groups_new} className="btn mr-1 btn-sm">
                    <MdAddCircle /> New
                  </Link>
                </Restricted>
                <a
                  onClick={() => {
                    refetch();
                  }}
                  className="btn mr-1 btn-sm"
                >
                  <MdRefresh /> Refresh
                </a>
              </div>
            </Row>

            <Table
              columns={columns}
              data={data?.response ? data?.response : []}
              pagination={true}
              filterItems={undefined}
              //searchText={search}
              onSearchChange={(text: string) => {
                //  dispatch(saveSearch(text));
              }}
            />
          </Card>
        </Restricted>
      </Container>
    </>
  );
};

export default UserGroupsList;
