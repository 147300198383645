import { ResponseWrapper } from "../../models/reponse";
import { INotificationTemperature, ISaveTemperatureNotification } from "../../services/notifications/notification-temperature-service";
import { notification_data } from "./data";

export class NotificationTemperatureDemo {
  data:INotificationTemperature[]
    constructor() {   
    this.data = notification_data.getNotificationTemperature();
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<INotificationTemperature[]>> => {
    return new Promise<any>((resolve, reject) => {
        const data: ResponseWrapper<INotificationTemperature[]> = {
          status: 200,
          version: "demo",
          response: this.data,
        } as ResponseWrapper<INotificationTemperature[]>;
  
        resolve(data);
      });
  };


  updateFeedback(data: ISaveTemperatureNotification): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The update feedback is not allowed on demo" },
          },
        },
      });
    });
  }
}


