import React, { FunctionComponent, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import { SelectOption } from "./select-option";

const formatOptionLabel = (data: SelectOption) => {
  //the images needs to be in folder Public.
  return (
    <div>
      {data.image && (
        <img
          alt="logo"
          width={20}
          src={window.location.origin + "/" + data.image}
        />
      )}
      <span> {data.label}</span>
    </div>
  );
};
//#region styles

const grey = "#dee2e6";
const red = "red"; //#FFA3A6
const dot_color_active = "rgb(220,183,50)";

const dot = (color = "transparent") => {
  if (color === "transparent") return null;
  return {
    alignItems: "center",
    display: "flex",
    width: "100%",
    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 12,
      width: 12,
    },
  };
};

const GenerateStyle = (isDot: boolean): StylesConfig<SelectOption> => {
  const SingleColourStyles: StylesConfig<SelectOption> = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      //minHeight: 35,
      backgroundColor: isDisabled ? "#e9ecef" : "white",
      boxShadow: "none",
      ":hover": {
        borderColor: grey,
      },
      borderColor: grey,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color_isSelected = isSelected ? grey : red;
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? grey : undefined,
        color: isDisabled ? "#cccccc" : "black",
        ":hover": {
          backgroundColor: grey,
        },
        ":before": {
          backgroundColor: grey,
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? color_isSelected : grey,
        },
      };
    },
    input: (styles, { isDisabled }) => ({
      ...styles,
      marginRight: 10,
      ...dot(),
    }),
    placeholder: (styles) => ({
      ...styles,
      ...dot("#ccc"),
    }),
    singleValue: (styles, { isDisabled, data }) => ({
      ...styles,
      backgroundColor: isDot ? "white " : "#dee2e6", //Change background here.
      color: "black",
      padding: "3px 6px 3px 6px",
      borderRadius: "2px",
      ...dot(
        isDot ? (data.color ? data.color : dot_color_active) : "transparent"
      ),
    }),
    valueContainer: (styles) => ({
      ...styles,
    }),
    container: (styles) => ({
      ...styles,
    }),
  };

  return SingleColourStyles;
};

//#endregion

const useStyles = createUseStyles({
  labelStyles: () => ({
    fontSize: 15,
  }),
  groupStyles: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  groupBadgeStyles: () => ({
    //backgroundColor: "#EBECF0",
    //color: "#172B4D",
    borderRadius: "2em",
    backgroundColor: "blue",
    color: "white",
    display: "inline-block",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }),
});

interface SelectSingleProps {
  label?: string | JSX.Element;
  value?: SelectOption;
  options: SelectOption[] | GroupBase<SelectOption>[];
  placeholder?: string;
  onValueChange?: (data: SelectOption) => void;
  dot?: boolean;
  disabled?: boolean;
}

const SelectSingle: FunctionComponent<SelectSingleProps> = ({
  label,
  placeholder = "Select...",
  options,
  value,
  onValueChange,
  // styles = SingleColourStyles,
  dot = false,
  disabled = false,
}) => {
  const [source, setSource] = useState<
    SelectOption[] | GroupBase<SelectOption>[]
  >([]);
  const classes = useStyles();
  const style_select = GenerateStyle(dot);

  const FormatGroupLabel = (data: GroupBase<SelectOption>) => {
    return (
      <div className={classes.groupStyles}>
        <span className={classes.labelStyles}>{data.label}</span>
        <span className={classes.groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  useEffect(() => {
    setSource(options);
  }, [options]);

  return (
    <>
      <label>{label}</label>
      <Select
        //menuPortalTarget={document.querySelector('main')}
        pageSize={5}
        styles={style_select}
        className="basic-single-sm"
        classNamePrefix="select"
        placeholder={placeholder}
        value={value}
        isDisabled={disabled}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        name="select-single"
        options={source}
        formatGroupLabel={FormatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        onChange={(
          data: MultiValue<SelectOption> | SingleValue<SelectOption>,
          actionMeta: ActionMeta<SelectOption>
        ) => {
          onValueChange && onValueChange(data as SelectOption);
        }}
      />
    </>
  );
};

export default React.memo(SelectSingle);
