import React, { FunctionComponent, useContext, useState } from "react";
import MaintenanceUsed from "../components/features/maintenance/usage-based/maintenance-used";
import MaintenanceScheduledCalendar from "../components/features/maintenance/scheduled/maintenance-scheduled-calendar";
import { Tabs, Tab, Container, Card, Badge } from "react-bootstrap";
import ReportMaintenance from "../components/features/maintenance/report-maintenance";
import { permissions } from "../services/permissions/permissions";
import { createUseStyles } from "react-jss";
import ThemeContext from "../context/theme/themeContext";
import { ITheme } from "../context/theme/theme";
import { BsTools, BsCalendar3, BsFileText } from "react-icons/bs";
import withLayout from "../hoc/withLayout";
import { checkPermissions } from "../context/permission/helpers";
import { useSelector } from "react-redux";
import {
  getExpiredScheduled,
  getExpiredUsageBased,
} from "store/reducers/badge-reducer";

const useStyles = createUseStyles({
  closeButton: (theme: ITheme) => ({
    color: "#fff",
    backgroundColor: "white",
    //borderColor: theme.primary,
    justifyContent: "center",
  }),
  tabStyle: (theme: ITheme) => ({
    "&.nav-tabs .nav-link": {
      color: "black",
    },
    "&.nav-tabs .nav-link:hover": {
      color: "black",
    },
    "&.nav-tabs .nav-link.active": {
      color: "black",
      borderColor: theme.primary + " #dee2e6 #fff",
      borderStyle: "double",
      borderWidth: "2px 1px 1px 1px",
    },
  }),
});

interface MaintenanceProps {}

export type IMaintenanceTabs = "ms" | "mm" | "mr" | string;

const Maintenance: FunctionComponent<MaintenanceProps> = () => {
  const { theme } = useContext(ThemeContext);
  const [tab, setTab] = useState<IMaintenanceTabs>("ms");
  const styles = useStyles({ ...theme });
  const expired_scheduled = useSelector(getExpiredScheduled);
  const expired_usage_based = useSelector(getExpiredUsageBased);
  const onSelectTab = (
    eventKey: string | null,
    e: React.SyntheticEvent<unknown> | undefined
  ) => {
    if (eventKey) setTab(eventKey);
  };

  const icon_size = "1.4rem";
  return (
    <>
      <Container fluid>
        <Card className="p-2">
          <Tabs
            defaultActiveKey="ms"
            activeKey={tab}
            transition={true}
            onSelect={onSelectTab}
            id="noanim-tab-example"
            //className={styles.closeButton}
            className={
              styles.tabStyle +
              " mb-3 justify-content-center align-items-center"
            }
          >
            <Tab
              tabClassName="link"
              eventKey="ms"
              title={
                <>
                  <BsCalendar3 size={icon_size} />

                  <span className="d-none d-md-inline">
                    Scheduled Maintenance
                  </span>
                  {expired_scheduled > 0 && (
                    <Badge pill bg="danger" className="ms-1 rounded-pill">
                      {expired_scheduled > 99 ? "99+" : expired_scheduled}
                    </Badge>
                  )}
                </>
              }
            >
              {tab == "ms" && (
                <MaintenanceScheduledCalendar
                  onChangeTab={(data) => onSelectTab(data, undefined)}
                />
              )}
            </Tab>
            <Tab
              eventKey="mm"
              title={
                <>
                  <BsTools size={icon_size} />
                  <span className="d-none d-md-inline">
                    Usage-based Maintenance
                  </span>
                  {expired_usage_based > 0 && (
                    <Badge pill bg="danger" className="ms-1 rounded-pill">
                      {expired_usage_based > 99 ? "99+" : expired_usage_based}
                    </Badge>
                  )}
                </>
              }
            >
              {tab == "mm" && (
                <MaintenanceUsed
                  onChangeTab={(data) => onSelectTab(data, undefined)}
                />
              )}
            </Tab>
            {checkPermissions(permissions.main.report.add_maintenance) && (
              <Tab
                eventKey="re"
                title={
                  <>
                    <BsFileText size={icon_size} />
                    <span className="d-none d-md-inline">
                      Report Maintenance
                    </span>
                  </>
                }
                //title="Report Maintenance"
              >
                {tab == "re" && <ReportMaintenance />}
              </Tab>
            )}
          </Tabs>
        </Card>
      </Container>
    </>
  );
};

export default Maintenance;
