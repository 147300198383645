import { FunctionComponent, useContext } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { ITheme } from "../../../context/theme/theme";
import ThemeContext from "../../../context/theme/themeContext";

interface CustomButtonProps extends ButtonProps {
  color?: string;
  outline?: boolean;
  size?: "sm" | "lg";
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const CustomButton: FunctionComponent<CustomButtonProps> = ({
  size,
  color,
  outline,
  children,
  onClick,
  className,
  ...rest
}) => {
  const useStyles = createUseStyles({
    defaultButton: (theme: ITheme) => ({
      color: outline ? "black" : "#fff",
      backgroundColor: outline ? "white" : color ? color : theme.primary,
      borderColor: color ? color : theme.primary,
      outlineColor: color ? color : theme.primary,
      "&:hover": {
        color: "#fff",
        backgroundColor: color ? color : theme.primary,
        borderColor: color ? color : theme.primary,
      },
      "&:focus": {
        outline: "none !important",
        backgroundColor: color ? color : theme.primary,
        boxShadow: "none",
      },
      "&:after": {
        outline: "none !important",
        backgroundColor: color ? color : theme.primary,
        boxShadow: "none",
      },
      "&:disabled": {
        outline: "none !important",
        backgroundColor: color ? color : theme.primary,
        boxShadow: "none",
        borderColor: color ? color : theme.primary,
        color:"white !important",
      },
    }),
  });
  const { theme } = useContext(ThemeContext);
  const styles = useStyles({ ...theme });

  return (
    <Button
      bsPrefix="costal-button"
      size={size}
      data-testid="customButton"
      {...rest}
      onClick={onClick}
      className={"btn " + styles.defaultButton + " " + className}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
