import { IMaintenanceScheduled } from "../../../services/maintenance/scheduled/scheduled-service";
import { IOperation } from "../../../services/maintenance/scheduled/operation-service";
import scheduled_data from "./scheduled-data.json";
import operation_data from "./operation-data.json";
import moment from "moment";

class MaintenanceDemoData {
  private data: IMaintenanceScheduled[];
  constructor() {
    this.data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(scheduled_data.response);
    const obj: IMaintenanceScheduled[] = JSON.parse(myJSON);
    obj.forEach((maintenance) => {
      maintenance.start_date = moment().subtract(2, "d").format("YYYY-MM-DD");
      maintenance.end_date = moment().add(1, "year").format("YYYY-MM-DD");
    });
    return obj;
  };

  getData = () => {
    return this.data;
  };

  update = (new_data: IMaintenanceScheduled) => {
    const index = this.data.findIndex((x) => x.id === new_data.id);
    if (index > -1) {
      this.data[index] = new_data;
    }
  };
}

class OperationDemoData {
  private data: IOperation[];
  constructor() {
    this.data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(operation_data.response);
    const obj: IOperation[] = JSON.parse(myJSON);
    return obj;
  };

  getData = () => {
    return this.data;
  };

  update_all = (new_data: IOperation[]) => {
    this.data = new_data;
  };

  update = (new_data: IOperation) => {
    const index = this.data.findIndex((x) => x.id === new_data.id);
    if (index > -1) {
      this.data[index] = new_data;
    }
  };
}

export const maintenance_scheduled_data = new MaintenanceDemoData();
export const operation_Data = new OperationDemoData();
