import { INotificationTemperature } from "../../services/notifications/notification-temperature-service";
import { INotificationValve } from "../../services/notifications/notification-valve-service";
import { INotification } from "../../services/notifications/warning-notification-service";
import { IReportNotification } from "../../services/reports/report-notification-service";
import notification_default from "./notification-demo-data.json";
import notification_temperature from "./notification-temperature-data.json";
import notification_valve from "./notification-valve-data.json";

class NotificationDemoData {
  private noti_default: INotification[];
  private noti_temperature: INotificationTemperature[];
  private noti_valve: INotificationValve[];
  constructor() {
    this.noti_default = this.loadNotificationDefault();
    this.noti_temperature=this.loadNotificationTemperature();
    this.noti_valve=this.loadNotificationValve();
  }

  private loadNotificationDefault = () => {
    const myJSON = JSON.stringify(notification_default.response);
    const obj: INotification[] = JSON.parse(myJSON);
    return obj;
  };

  private loadNotificationTemperature = () => {
    const myJSON = JSON.stringify(notification_temperature.response);
    const obj: INotificationTemperature[] = JSON.parse(myJSON);
    return obj;
  };

  private loadNotificationValve = () => {
    const myJSON = JSON.stringify(notification_valve.response);
    const obj: INotificationValve[] = JSON.parse(myJSON);
    return obj;
  };

  getNotificationDefault = () => {
    return this.noti_default;
  };

  getNotificationTemperature = () => {
    return this.noti_temperature;
  };

  getNotificationValve = () => {
    return this.noti_valve;
  };
}

export const notification_data = new NotificationDemoData();
