import { FunctionComponent } from "react";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
interface GoBackNavigationProps {
  url?: string;
}

const GoBackNavigation: FunctionComponent<GoBackNavigationProps> = ({
  url,
}) => {
  return (
    <>
      <Link
        className="btn mr-1 btn-sm"
        to={{
          pathname: url,
          search: "", //"?sort=name",
          hash: "", //"#the-hash"
          //state: { fromDashboard: true },
        }}
      >
        <MdArrowBack size={25} />
      </Link>
    </>
  );
};

export default GoBackNavigation;
