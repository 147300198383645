import _ from "lodash";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import CheckBox from "./checkbox";

export type CheckBoxItem = {
  label: string;
  value: string | number;
};

interface CheckboxGroupProps {
  name: string;
  items: CheckBoxItem[];
  selectItems?: CheckBoxItem[];
  onCheckedItemsChanged?: (items: CheckBoxItem[]) => void;
}

const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({
  name,
  items,
  selectItems,
  onCheckedItemsChanged,
}) => {
  const [selectedItems, setSelecteditems] = useState<
    CheckBoxItem[] | undefined
  >([]);

  useEffect(() => {
    setSelecteditems(selectItems);
  }, [selectItems]);

  const handleCheckedItem = (
    data: ChangeEvent<HTMLInputElement>,
    item: CheckBoxItem
  ) => {
    const { checked } = data.currentTarget;
    const newSelectedItems = selectedItems ? [...selectedItems] : [];
    if (checked) {
      newSelectedItems.push(item);

      setSelecteditems(newSelectedItems);
      onCheckedItemsChanged && onCheckedItemsChanged(newSelectedItems);
    } else {
      const selectedItemsRemove = newSelectedItems.filter(function (ele) {
        return !_.isEqual(ele, item);
      });
      //console.debug(selectedItemsRemove);
      setSelecteditems(selectedItemsRemove);
      onCheckedItemsChanged && onCheckedItemsChanged(selectedItemsRemove);
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <CheckBox
          key={index}
          label={item.label}
          value={item.value.toString()}
          checked={selectedItems?.includes(item)}
          name={name}
          onChange={(data) => handleCheckedItem(data, item)}
        />
      ))}
    </>
  );
};

export default CheckboxGroup;
