import { useQuery } from "@tanstack/react-query";
import { timezone_service } from "../services/timezoneService";
import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import { useDispatch } from "react-redux";
import { saveBadges } from "../store/reducers/badge-reducer";

import { APIError } from "services/base/wrapper/api-client";
import { badge_service } from "services/badge-service";
import { AxiosError } from "axios";

export const useGetBadges = () => {
  const timezone: string = timezone_service.getTimezone();
  const dispatch = useDispatch();
  const badges = useQuery({
    queryKey: [QueryKeys.GET_BADGES],
    queryFn: () => badge_service.get({ timezone: timezone }),
    onSuccess: (data) => {
      if (data.response) {
        dispatch(saveBadges(data.response));
      }
      return data.response;
    },
    onError: (error?: AxiosError<any>) => {
      
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    cacheTime: 1,
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return badges;
};
