import React, { Component, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
    
}
 
interface ErrorBoundaryState {
    hasError:boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}
 
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props:any) {
        super(props);
        this.state = { hasError: false,error: undefined, errorInfo: undefined };
      }
      static getDerivedStateFromError = (error:any) => {
        return { hasError: true };
      };
    //state = { hasError:false,error: null, errorInfo: null  }
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Display fallback UI        
        
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
          })
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
      }
    
      render() {
        if (this.state.hasError) {
          // You can render any custom fallback UI
          return (
          <React.Fragment>

          <h1>Something went wrong.</h1>
            <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
          </React.Fragment>
          );
        }
        return this.props.children;
      }
}
 
export default ErrorBoundary;
