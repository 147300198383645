import { Moment } from "moment";
import { ResponseWrapper } from "../../models/reponse";
import { plot_analytics_demo } from "./data";
import {
  IRequestValveCondition,
  IValveResponse,
} from "../../services/statistic/statistic-plot-valve";

export class PlotAnalyticsValveDemo {
  getPlot = (
    star_date: Moment,
    end_date: Moment,
    structs_ids: number[] | string[],
    resample_rule?: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IValveResponse[]>> => {
    return new Promise<any>((resolve, reject) => {
      const plot_analytics = plot_analytics_demo.getValvePlot();
      const response: ResponseWrapper<IValveResponse[]> = {
        status: 200,
        version: "demo",
        response: plot_analytics,
      } as ResponseWrapper<IValveResponse[]>;
      resolve(response);
    });
  };

  getPlotByCondition = (
    data: IRequestValveCondition,
    query_params: Record<string, any> = {}
  ) => {
    throw new Error("Not implemented");
  };
}
