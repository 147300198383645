import { FormikHelpers, FormikValues, useFormikContext } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import { Modal, Col, Row, Container } from "react-bootstrap";
import AppForm from "../../../formik-forms/AppForm";
import FormSubmit from "../../../formik-forms/FormSubmit";
import FormSwitch from "../../../formik-forms/FormSwitch";
import FormText from "../../../formik-forms/FormText";
import { IStructDetailVariable } from "../../../../services/struct/struct-service";
import {
  IDetermination,
  variable_service,
} from "../../../../services/variables/variable-service";
import * as alertService from "../../../../utils/alertService";
import Backdrop from "../../../common/backdrop/backdrop";
import CustomButton from "../../../common/buttons/custom-button";
import {
  IPlotAnalyticsModel,
  statistic_plot_service,
} from "../../../../services/statistic/statistic-plot-service";

import * as dateOperation from "../../../../utils/date-operations";
import PlotAnalytics, {
  IThreashold,
} from "../../plot-analytics/default/plot-analytics";

import { MdArrowBack, MdContentCopy } from "react-icons/md";
interface StructVariableModalProps {
  data?: IStructDetailVariable;
  showModal: boolean;
  onClose?: (refresh: boolean) => void;
}

const StructVariableModal: FunctionComponent<StructVariableModalProps> = ({
  data,
  showModal,
  onClose,
}) => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [signal, setSignal] = useState<IPlotAnalyticsModel>();
  const [determination, setDetermination] = useState<
    IDetermination | undefined
  >(undefined);

  const [threshold, setThreshold] = useState<
    { high?: number; low?: number } | undefined
  >(undefined);

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      setIsBusy(true);
      await variable_service.partialUpdate(values);
      alertService.Ok("Data saved successfully.");
      onClose && onClose(true);
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      variable_service.checkErrors(error);
    }
  };

  const handleDetermination = async () => {
    setIsBusy(true);
    if (data?.id) {
      try {
        const result = await variable_service.getDetermination(data?.id);

        setDetermination(result?.response);
      } catch (error: any) {
        variable_service.checkErrors(error);
      }
    }
    setIsBusy(false);
  };

  const getPlotStatistic = async () => {
    try {
      const start_date = new Date(2021, 10, 1);
      const end_date = new Date();
      if (data?.variable && data?.struct_id) {
        const response = await statistic_plot_service.getPlotAndStatisticByIds(
          [{ variable_id: data?.variable?.id, struct_id: data?.struct_id }],
          dateOperation.getDateOnString(start_date),
          dateOperation.getDateOnString(end_date)
        );

        if (response.response) {
          setSignal(response.response);
        }
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    setDetermination(undefined);
    setThreshold(undefined);
    data && getPlotStatistic();
  }, [data]);

  return (
    <>
      <Backdrop show={isBusy} />
      <Modal
        centered
        scrollable={false}
        show={showModal}
        onHide={() => onClose && onClose(false)}
        size="lg"
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title>
              {data?.variable.description}
              {data?.variable.unit_of_measurement
                ? " " + data?.variable.unit_of_measurement
                : null}
            </Modal.Title>
          </Modal.Header>
          {data && (
            <AppForm
              //validationSchema={validationSchema}
              initialValues={{
                id: data?.variable.id,
                default_avg: data?.variable.default_avg,
                threshold_max: data?.variable.threshold_max,
                threshold_min: data?.variable.threshold_min,
                is_writable: data?.variable.is_writable,
              }}
              onSubmit={doSubmit}
            >
              <Container className="p-2 mt-2 mb-2">
                <h5>
                  <b>Threshold options</b>
                </h5>
                <Row>
                  <Col>
                    <FormText
                      name={"default_avg"}
                      label="Average"
                      placeholder="Define average..."
                      type={"number"}
                      size="sm"
                      helpText="The typical daily average"
                    />
                    <p className="text-success text-center">
                      {determination && determination.mean_ref != null && (
                        <>
                          {determination.mean_ref}

                          <a
                            className="btn mr-1 btn-sm"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                determination.mean_ref.toString()
                              )
                            }
                          >
                            <MdContentCopy size={15} />
                          </a>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col>
                    <FormText
                      name={"threshold_max"}
                      label="Max limit"
                      placeholder="Define max limit..."
                      type={"number"}
                      size="sm"
                      helpText={
                        "The upper limit of the daily average to be considered good"
                      }
                      onChangeValue={(value) => {
                        if (value) {
                          if (isNaN(parseFloat(value.toString()))) return;
                          setThreshold({
                            high: parseFloat(value.toString()),
                            low: threshold?.low,
                          });
                        }
                      }}
                    />
                    <p className="text-success text-center">
                      {determination && (
                        <>
                          {determination.threshold_high}

                          <a
                            className="btn mr-1 btn-sm"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                determination.threshold_high.toString()
                              )
                            }
                          >
                            <MdContentCopy size={15} />
                          </a>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col>
                    <FormText
                      name={"threshold_min"}
                      label="Min limit"
                      placeholder="Define min limit..."
                      type={"number"}
                      size="sm"
                      helpText="The lower limit of the daily average to be considered good"
                      onChangeValue={(value) => {
                        if (value) {
                          if (isNaN(parseFloat(value.toString()))) return;
                          setThreshold({
                            low: parseFloat(value.toString()),
                            high: threshold?.high,
                          });
                        }
                      }}
                    />
                    <p className="text-success text-center">
                      {determination && (
                        <>
                          {determination.threshold_low}

                          <a
                            className="btn mr-1 btn-sm"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                determination.threshold_low.toString()
                              )
                            }
                          >
                            <MdContentCopy size={15} />
                          </a>
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormSwitch
                      name={"is_writable"}
                      label={"Writable"}
                      helpText={
                        "Enable to allow modify the variable value via cms"
                      }
                    />
                  </Col>
                </Row>

                {signal && signal.time_series.length > 0 && (
                  <>
                    <hr />
                    <PlotAnalytics
                      threshold={
                        threshold && {
                          high: threshold.high,
                          low: threshold.low,
                        }
                      }
                      signal={signal.time_series[0]}
                      onInitialized={null}
                      onUpdate={null}
                      onRelayout={null}
                    />
                  </>
                )}
              </Container>

              <Modal.Footer>
                <FormSubmit title="Save" />
                <CustomButton color="grey" onClick={handleDetermination}>
                  Calcolate Determination
                </CustomButton>
              </Modal.Footer>
            </AppForm>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StructVariableModal;
