import { FunctionComponent } from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
interface AppFormProps {}

export interface Values {
  [field: string]: any;
}

interface AppFormProps {
  initialValues: Values;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
  validationSchema?: any;
}

const AppForm: FunctionComponent<AppFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props:any) =>{

        const {
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
           <>
           <form onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}>
           {children}
           </form>
           </>
           )}
      }
    </Formik>
  );
};

export default AppForm;
