import axios, { AxiosResponse, AxiosError, Axios } from "axios";
import { IHttp } from "./http-services";
import { ResponseWrapper } from "../../models/reponse";
import * as alertService from "../../utils/alertService";

export abstract class BaseService<T> {
  endpoint: string;
  http: IHttp;
  constructor(url: string, http: IHttp) {
    this.endpoint = url;
    this.http = http;
  }

  protected Url(id: any, withSlashAtEnd: boolean = false): string {
    const result = this.endpoint + "/" + id;
    return withSlashAtEnd ? result + "/" : result;
  }

  protected generateParams(query_params: Record<string, any> = {}): string {
    let params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  protected getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: Error | AxiosError<any>) => {
          reject(response);
        });
    });
  }

  checkErrors(error: AxiosError<any>) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.status !== 403 &&
      error.response.status < 500
    ) {
      let message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }

  protected handleError(error: AxiosError<any>) {
    if (error.response) {
      // The request was made and the server responded with a status code
      //Forbbiden, no access , you dont have permissions
      if (error.response.data && error.response.status !== 403) {
        let message = error.response.data.response.error;
      }
    } else if (error.request) {
      // The request was made but no response was received
      //console.debug("request")
    } else {
      // Something happened in setting up the request that triggered an Error
      //console.debug("Internal")
    }
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<T[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(this.http.get(this.endpoint + "/" + "?" + params));
  };

  getById = (
    dataId: number,
    query_params: Record<string, any> = {},
    withSlashAtEnd: boolean = false
  ): Promise<ResponseWrapper<T>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get(this.Url(dataId, withSlashAtEnd) + "?" + params)
    );
  };

  save = (
    data: any,
    query_params: Record<string, any> = {},
    withSlashAtEnd: boolean = false
  ): any => {
    let queryString = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    if (data.id) {
      const body = { ...data };
      delete body.id;
      //console.debug(body);
      return this.http.put(this.Url(data.id, withSlashAtEnd), body);
    }
    if (data.id === undefined) {
      delete data.id;
    }

    return this.getPromise(
      this.http.post(this.endpoint + "/" + "?" + queryString, data)
    );
  };

  partialUpdate = (data: any) => {
    if (data.id) {
      const body = { ...data };
      delete body.id;
      return this.http.patch(this.Url(data.id), body);
    }
  };

  delete = (
    dataId: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<T>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(this.http.delete(this.Url(dataId + "?" + params)));
  };

  deleteProtected = (
    dataId: any,
    password: string
  ): Promise<ResponseWrapper<T>> => {
    return this.getPromise(
      this.http.delete(this.Url(dataId), {
        data: { current_password: password },
      })
    );
  };

  count = (): Promise<ResponseWrapper<T>> => {
    return this.getPromise(this.http.get(this.endpoint + "/count"));
  };
}
