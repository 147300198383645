import { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import PlotAnalytics from "../plot-analytics/default/plot-analytics";
import PlotRaw from "../plot-raw/time-series/plot-raw";
import moment from "moment";
import {
  IStatisticVariableId,
  statistic_plot_service,
} from "../../../services/statistic/statistic-plot-service";
import * as dateOperation from "../../../utils/date-operations";
import { plot_variable_service } from "../../../services/plot/plot-variable-service";
import * as Yup from "yup";
import AppForm from "../../formik-forms/AppForm";
import FormTextArea from "../../formik-forms/FormTextArea";
import FormSubmit from "../../formik-forms/FormSubmit";
import CloseButton from "../../common/buttons/close-button";
import FormSwitch from "../../formik-forms/FormSwitch";
import { FormikHelpers, FormikValues } from "formik";
import * as alertService from "../../../utils/alertService";
import Backdrop from "../../common/backdrop/backdrop";
import { useSaveNotification } from "../../../queries/notification/notification.hook";
import { INotification } from "../../../services/notifications/warning-notification-service";
import {
  checkLimits,
  checkThresholds,
  renderNotificationType,
} from "../../../pages/notification/notification.utils";
import Descriptor from "./notification-descriptor";

interface NotificationFormProps {
  notification: INotification;
  showModal: boolean;
  onClose?: (refresh: boolean) => void;
}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  comment: Yup.string()
    .matches(/^(?![\n]*$)/, "Comment can't be only line breaks and whitespace")
    .required()
    .nullable()
    .label("Comment"),
  is_incorrect: Yup.boolean().default(false).required().label("Anomaly"),
  is_read: Yup.boolean().default(false).required().label("Read"),
});

const NotificationDefaultModal: FunctionComponent<NotificationFormProps> = ({
  notification: modalNotification,
  showModal,
  onClose,
}) => {
  const [signal, setSignal] = useState<any>(undefined);
  const [signalRaw, setSignalRaw] = useState<any>(undefined);
  const [isBusy, SetIsBusy] = useState<boolean>(false);
  const saveNotification = useSaveNotification();

  const plotSignalAnalytics = async (
    variable_id: number,
    struct_id: number,
    start_date: Date,
    end_date: Date
  ) => {
    try {
      let variables_onstruct: IStatisticVariableId[] = [
        { variable_id: variable_id, struct_id: struct_id },
      ];
      let result = await statistic_plot_service.getPlotAndStatisticByIds(
        variables_onstruct,
        dateOperation.getDateOnString(start_date),
        dateOperation.getDateOnString(end_date)
      );

      const { response } = result;
      setSignal(response?.time_series[0]);
    } catch (error: any) {
      statistic_plot_service.checkErrors(error);
    }
  };

  const plotSignalRaw = async (
    id: number,
    start_date: Date,
    end_date: Date
  ) => {
    try {
      let variables_onstruct: any = [id];

      const result = await plot_variable_service.getMany({
        variables: variables_onstruct,
        start_date: dateOperation.getDateTimeOnString(start_date, false),
        end_date: dateOperation.getDateTimeOnString(end_date, false),
        timezone: undefined,
        bucket: undefined,
      });

      const { response } = result;
      setSignalRaw(response?.signals[0]);
    } catch (error: any) {}
  };

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      let func = async () =>
        await saveNotification.mutateAsync({
          id: values.id,
          comment: values.comment,
          is_incorrect: values.is_incorrect,
          is_read: values.is_read,
        });
      const response = await alertService.WaitForRequest(
        "Confirm",
        "Are you sure to save the changes? ",
        func
      );

      if (response) {
        onClose && onClose(true);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (modalNotification && modalNotification.result_info) {
      const { result_date } = modalNotification.result_info;
      const startDate = moment(result_date)
        .subtract(120, "d")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const endDate = moment(result_date).add(10, "d");
      plotSignalAnalytics(
        modalNotification.variable.id,
        modalNotification.struct.id,
        startDate.toDate(),
        endDate.toDate()
      );
    }
  }, [modalNotification]);

  const {
    type,
    struct,
    variable,
    result_info,
    user,
    day_to_threshold,
    updated_at,
  } = modalNotification;

  return (
    <>
      <Backdrop show={isBusy} />
      <Modal
        scrollable={false}
        show={showModal}
        onHide={() => onClose && onClose(false)}
        size="xl"
        aria-labelledby="contained-modal-title-center"
        centered
      >
        <AppForm
          validationSchema={validationSchema}
          initialValues={{
            id: modalNotification.id,
            comment: modalNotification.comment,
            is_incorrect: modalNotification.is_incorrect,
            is_read: modalNotification.is_read,
          }}
          onSubmit={handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="rounded-3 d-flex align-items-center">
              <br />

              <Col
                lg={4}
                //style={{ backgroundColor: theme.secondary }}
                className="rounded-3 text-black p-3 tour_information"
              >
                <h5>Structure details</h5>
                <hr />
                <Descriptor
                  label="Type"
                  value={type && renderNotificationType(modalNotification.type)}
                />
                <Descriptor label="Structure" value={struct?.name} />

                <Descriptor
                  label="Signal"
                  value={variable && variable.description}
                />

                <Descriptor
                  label="Unit of measurement"
                  value={variable && variable.unit_of_measurement}
                />
                <br />
                <h5>Threshold details</h5>
                <hr />

                <Descriptor
                  label="Date"
                  value={
                    result_info &&
                    moment(result_info?.result_date).format("L") +
                      " ( " +
                      moment(result_info.result_date).fromNow() +
                      ")"
                  }
                />

                <Descriptor
                  label="Average"
                  value={
                    result_info && result_info.mean != undefined
                      ? checkLimits(
                          result_info.mean,
                          result_info.threshold_max,
                          result_info.threshold_min
                        )
                      : "Not defined"
                  }
                />
                <Descriptor
                  label="Threshold max"
                  value={
                    result_info && result_info.threshold_max != undefined
                      ? result_info.threshold_max?.toFixed(2)
                      : "Not defined"
                  }
                />
                <Descriptor
                  label="Threshold min"
                  value={
                    result_info && result_info.threshold_min != undefined
                      ? result_info.threshold_min?.toFixed(2)
                      : "Not defined"
                  }
                />

                <Descriptor
                  label="Day to hold"
                  value={
                    day_to_threshold != undefined
                      ? day_to_threshold
                      : "Not defined"
                  }
                />

                {user && (
                  <Descriptor
                    label="Review by"
                    value={
                      user.email
                        ? user.email + " (" + moment(updated_at).fromNow() + ")"
                        : "None"
                    }
                  />
                )}
                {result_info &&
                  checkThresholds(
                    result_info.mean,
                    result_info.threshold_max,
                    result_info.threshold_min
                  )}
              </Col>

              <Col className="justify-content-center align-items-center tour_signal">
                {signal && (
                  <PlotAnalytics
                    signal={signal}
                    onInitialized={null}
                    onUpdate={null}
                    onRelayout={null}
                    highlight={{
                      time: result_info.result_date,
                      value: result_info.mean ? result_info.mean : 0,
                    }}
                  />
                )}

                {signalRaw && (
                  <PlotRaw signal={signalRaw} graphMode={"lines+markers"} />
                )}
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={12} md={8} className="tour_comment">
                <FormTextArea
                  name="comment"
                  label="Comment"
                  placeholder="Write a comment..."
                  rows={3}
                />
              </Col>
              <Col sm={12} md={2} className="tour_anomaly">
                <FormSwitch name={"is_incorrect"} label={"Mark as anomly"} />
              </Col>
              <Col sm={12} md={2} className="tour_read">
                <FormSwitch name={"is_read"} label={"Mark as read"} />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <CloseButton
              label="Close"
              onClick={() => onClose && onClose(false)}
            />
            <FormSubmit
              className="tour_save"
              title="Save changes"
              //disabled={isCompleted(maintenance_info)}
              //disabled={loginAPi.loading}
              //icon={faSignInAlt}
            />
          </Modal.Footer>
        </AppForm>
      </Modal>
    </>
  );
};

export default NotificationDefaultModal;
