import { FunctionComponent, useEffect, useState } from "react";
import Backdrop from "../../components/common/backdrop/backdrop";
import { Card, Col, Row, Badge, Button, Modal, Alert } from "react-bootstrap";
import SearchBox from "../../components/common/searchbox";
import ListItemCustom, {
  ItemCustom,
} from "../../components/features/notification/list-item-custom";
import {
  calcolateStats,
  checkLimits,
  checkThresholds,
  renderNotificationIcon,
  renderNotificationType,
} from "./notification.utils";
import {
  MdCancel,
  MdError,
  MdRefresh,
  MdRemoveRedEye,
  MdTimer,
} from "react-icons/md";
import NotificationStats, {
  IStatsNotification,
} from "../../components/features/notification/notification-stats";
import moment from "moment";
import { useGetPidNotification } from "../../queries/notification/notification.pid.hook";
import {
  IPidNotification,
  IStationary,
  ITransient,
} from "../../services/notifications/notification-pid-service";
import _ from "lodash";
import AppForm from "../../components/formik-forms/AppForm";
import Descriptor from "../../components/features/notification/notification-descriptor";
import FormTextArea from "../../components/formik-forms/FormTextArea";
import FormSwitch from "../../components/formik-forms/FormSwitch";
import CloseButton from "../../components/common/buttons/close-button";
import FormSubmit from "../../components/formik-forms/FormSubmit";
import { usePlotPid } from "../../queries/analytics.pid.hook";
import { IPidResponse } from "../../services/statistic/statistic-plot-pid";
import PlotPidSingle, {
  IPidType,
} from "../../components/features/plot-analytics/pid/plot-pid-single";
interface NotificationPidProps {}

const NotificationPid: FunctionComponent<NotificationPidProps> = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchValue, setSerachValue] = useState<string>("");
  const [statsNotification, setStatsNotification] =
    useState<IStatsNotification>({} as IStatsNotification);
  const [notification, SetNotification] = useState<
    IPidNotification | undefined
  >(undefined);
  const [notificationFilter, setNotificationFilter] = useState<
    IPidNotification[]
  >([]);

  const pid = useGetPidNotification();

  const handleSearch = (value: string) => {
    if (value === "") {
      setSerachValue(value);
      if (pid.data?.response) setNotificationFilter(pid.data?.response);
      return;
    }
    const result = _.filter(pid.data?.response, (item) => {
      return item.struct.name.toLowerCase().includes(value.toLowerCase());
    });
    console.debug(result);
    setNotificationFilter(result);
    setSerachValue(value);
  };

  const handleClose = (refresh: boolean) => {
    if (refresh) {
      pid.refetch();
    }
    setShowModal(false);
  };

  const handleOpenDetail = async (notification: IPidNotification) => {
    setShowModal(true);
    SetNotification(notification);
  };

  useEffect(() => {
    if (pid.data?.response) {
      const stats = calcolateStats(pid.data?.response);
      setStatsNotification(stats);
      setNotificationFilter(pid.data?.response);
    }
  }, [pid.data]);

  return (
    <>
      <Backdrop show={pid.isRefetching || pid.isLoading} />
      <Row>
        <Col className="mb-1" sm={12} md={9} lg={9}>
          <Card className="p-3">
            <h4>Notifications</h4>
            <hr></hr>
            <Row>
              <div className="d-flex justify-content-end">
                <a onClick={() => pid.refetch()} className="btn mr-1 btn-sm">
                  <MdRefresh /> Refresh
                </a>
              </div>
            </Row>
            <SearchBox
              value={searchValue}
              size="md"
              placeholder="Search by pid..."
              onChange={handleSearch}
            />

            <ListItemCustom
              key={"list temperature"}
              data={notificationFilter}
              keyExtrator={(item) => item.id.toString()}
              renderItem={(item, index) => (
                <ItemCustom
                  Icon={renderNotificationIcon(item.type)}
                  title={item.struct.name}
                  subtitle={
                    <>
                      {item.result_info.transient
                        ? "Trasient error"
                        : item.result_info.stationary
                        ? "Stationary error"
                        : "Error"}
                    </>
                  }
                  actions={
                    <>
                      <div>
                        <p className="text-muted me-3">
                          <MdTimer />{" "}
                          {moment(item.result_info.reference_period).fromNow()}
                        </p>

                        <span className="">
                          {item.is_incorrect && (
                            <MdError size={20} color="red" />
                          )}

                          {item.is_read ? (
                            <Badge pill bg="success">
                              Viewed{" "}
                            </Badge>
                          ) : (
                            <Badge pill bg="warning">
                              Pending
                            </Badge>
                          )}
                        </span>
                      </div>

                      <Button
                        className="me-1"
                        variant="outline-dark"
                        onClick={() => handleOpenDetail(item)}
                      >
                        <MdRemoveRedEye />
                      </Button>
                    </>
                  }
                />
              )}
            />
          </Card>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <NotificationStats data={statsNotification} />
        </Col>
      </Row>
      {notification && (
        <NotificationPidModal
          notification={notification}
          open={showModal}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default NotificationPid;

interface NotificationPidModalProps {
  notification: IPidNotification;
  open: boolean;
  onClose?: (refresh: boolean) => void;
}

const NotificationPidModal: FunctionComponent<NotificationPidModalProps> = ({
  notification,
  open,
  onClose,
}) => {
  const [graph, setGraph] = useState<IPidResponse>();
  const load_plot = usePlotPid();
  const { result_info, type, struct } = notification;

  const handlePlot = async () => {
    if (notification.struct?.id === undefined) return;

    const id: number = notification.struct?.id;
    const result = await load_plot.mutateAsync({
      start: moment(notification.result_info?.reference_period).subtract(
        30,
        "d"
      ),
      end: moment(notification.result_info?.reference_period),
      struct_ids: [id],
      resample_rule: undefined,
    });
    if (result.response) setGraph(result.response[0]);
  };

  useEffect(() => {
    if (notification) {
      handlePlot();
    }
  }, [notification]);

  const getTypePlot = (): IPidType | undefined => {
    if (result_info.transient) {
      return "transient";
    } else if (result_info.stationary?.default) {
      return "stationary";
    } else if (result_info.stationary?.peaks_number) {
      return "peakNumber";
    } else if (result_info.stationary?.peaks_time) {
      return "peakTime";
    }

    return undefined;
  };

  return (
    <Modal
      scrollable={false}
      show={open}
      onHide={() => onClose && onClose(false)}
      size="xl"
      aria-labelledby="contained-modal-title-center"
    >
      <AppForm
        //validationSchema={validationSchema}
        initialValues={{
          id: notification.id,
          comment: notification.comment,
          is_incorrect: notification.is_incorrect,
          is_read: notification.is_read,
        }}
        onSubmit={() => console.debug("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-center">
            <Col lg="4" className="">
              <div className="p-3">
                <h5>
                  <b>Structure Info</b>
                </h5>

                <hr />
                <Descriptor
                  label="Type"
                  value={type && renderNotificationType(type)}
                />
                <Descriptor label="Name" value={notification.struct?.name} />

                <Descriptor
                  label="Period"
                  value={
                    moment(notification.result_info?.reference_period).format(
                      "L"
                    ) +
                    " ( " +
                    moment(
                      notification.result_info?.reference_period
                    ).fromNow() +
                    ")"
                  }
                />

                <br />

                {result_info.transient && (
                  <TansientDescription transient={result_info.transient} />
                )}

                {result_info.stationary && (
                  <StationaryDescription stationary={result_info.stationary} />
                )}
              </div>
            </Col>
            <Col className="justify-content-center">
              {graph && <PlotPidSingle signal={graph} type={getTypePlot()} />}
            </Col>
          </Row>
          <Row>
            <Row>
              <Col sm={12} md={8}>
                <FormTextArea
                  name="comment"
                  label="Comment"
                  placeholder="Write a comment..."
                  rows={3}
                />
              </Col>
              <Col sm={12} md={2}>
                <FormSwitch name={"is_incorrect"} label={"Mark as anomly"} />
              </Col>
              <Col sm={12} md={2}>
                <FormSwitch name={"is_read"} label={"Mark as read"} />
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton
            label="Close"
            onClick={() => onClose && onClose(false)}
          />
          <FormSubmit
            title="Save changes"
            //disabled={saveNotification.isLoading}
          />
        </Modal.Footer>
      </AppForm>
    </Modal>
  );
};

interface StationaryDescriptionProps {
  stationary?: IStationary;
}

const StationaryDescription: FunctionComponent<StationaryDescriptionProps> = ({
  stationary,
}) => {
  return (
    <div>
      <h5>
        <b>Stationary</b>
      </h5>
      <hr />

      <br />

      <Descriptor
        label="Unit of measurement"
        value={stationary?.default?.unit_of_measurement}
      />
      <Descriptor
        label="Duration"
        value={
          stationary &&
          stationary.default?.avg &&
          stationary.default?.threshold &&
          checkLimits(
            stationary?.default?.avg,
            stationary.default?.threshold,
            0
          )
        }
      />

      <Descriptor
        label="Threshold max"
        value={stationary?.default?.threshold?.toFixed(2)}
      />

      <div className="d-flex justify-content-between total font-weight-bold mt-4">
        {stationary &&
          stationary?.default?.avg &&
          stationary?.default?.threshold &&
          checkThresholds(
            stationary.default?.avg,
            stationary?.default.threshold,
            undefined
          )}
      </div>

      <br />
      <h6>Peaks time</h6>
      <hr style={{ width: "50%" }} />

      <Descriptor
        label="Number peaks above threshold"
        value={stationary?.peaks_time?.n_peaks_time_above_out_of_threshold}
      />
      <Descriptor
        label="Time peaks above threshold"
        value={stationary?.peaks_time?.peaks_time_above_threshold?.toFixed(2)}
      />
      <Descriptor
        label="Time above worst window"
        value={stationary?.peaks_time?.times_above_worst_window}
      />

      <br />
      <h6>Peaks number</h6>
      <hr style={{ width: "50%" }} />

      <Descriptor
        label="Number peaks above threshold"
        value={stationary?.peaks_number?.n_peaks_out_of_threshold}
      />

      <Descriptor
        label="Number peaks threshold"
        value={stationary?.peaks_number?.n_peaks_threshold.toFixed(2)}
      />

      <Descriptor
        label="Time above worst window"
        value={stationary?.peaks_number?.n_peaks_worst_window}
      />
    </div>
  );
};

interface TansientDescriptionProps {
  transient?: ITransient;
}

const TansientDescription: FunctionComponent<TansientDescriptionProps> = ({
  transient,
}) => {
  return (
    <div>
      <h5>
        <b>Transient</b>
      </h5>
      <hr />

      <Descriptor
        label="Unit of measurement"
        value={transient?.unit_of_measurement}
      />
      <Descriptor
        label="Duration"
        value={
          transient &&
          transient.duration &&
          transient.threshold &&
          checkLimits(transient?.duration, transient.threshold, 0)
        }
      />

      <Descriptor
        label="Threshold max"
        value={transient?.threshold?.toFixed(2)}
      />

      <div className="d-flex justify-content-between total font-weight-bold mt-4">
        {transient &&
          transient?.duration &&
          transient?.threshold &&
          checkThresholds(transient.duration, transient?.threshold, undefined)}
      </div>
      {transient?.unfinished && transient?.unfinished >= 1 && (
        <Alert key={"not_reach"} variant="danger">
          <MdCancel style={{ color: "danger", fontSize: "20px" }} />
          The Transient had not finish the process.
        </Alert>
      )}
    </div>
  );
};
