import { ResponseWrapper } from "../models/reponse";

export class BaseServiceGenericDemo<T> {
  data: T[];
  constructor() {
    this.data = [];
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<T[]>> => {
    return new Promise<any>((resolve, reject) => {
      const data: ResponseWrapper<T[]> = {
        status: 200,
        version: "demo",
        response: this.data,
      } as ResponseWrapper<T[]>;

      resolve(data);
    });
  };

  getById = (
    dataId: number,
    query_params: Record<string, any> = {},
    withSlashAtEnd: boolean = false
  ): Promise<ResponseWrapper<T>> => {
    return new Promise<any>((resolve, reject) => {
      const data: ResponseWrapper<T[]> = {
        response: this.data.length > 0 ? this.data[0] : [],
      } as ResponseWrapper<T[]>;

      resolve(data);
    });
  };

  delete = (
    dataId: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<T>> => {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The delete operation is not allowed on demo" },
          },
        },
      });
    });
  };

  save = (data: any, query_params: Record<string, any> = {}): any => {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The save operation is not allowed on demo" },
          },
        },
      });
    });
  };

  checkErrors(error: any) {
    console.debug(error);
  }

  //   save = (data: any, query_params: Record<string, any> = {}) => {
  //     let queryString = Object.keys(query_params)
  //       .map((key: any) => key + "=" + query_params[key])
  //       .join("&");
  //     if (data.id) {
  //       const body = { ...data };
  //       delete body.id;
  //       return this.http.put(this.Url(data.id), body);
  //     }
  //     if (data.id === undefined) {
  //       delete data.id;
  //     }
  //     return this.http.post(this.endpoint + "/" + "?" + queryString, data);
  //   };

  //   partialUpdate = (data: any) => {
  //     if (data.id) {
  //       const body = { ...data };
  //       delete body.id;
  //       return this.http.patch(this.Url(data.id), body);
  //     }
  //   };

  //   deleteProtected = (
  //     dataId: any,
  //     password: string
  //   ): Promise<ResponseWrapper<T>> => {
  //     return this.getPromise(
  //       this.http.delete(this.Url(dataId), {
  //         data: { current_password: password },
  //       })
  //     );
  //   };

  //   count = (): Promise<ResponseWrapper<T>> => {
  //     return this.getPromise(this.http.get(this.endpoint + "/count"));
  //   };
}
