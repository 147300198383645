import React, { FunctionComponent, useState } from "react";
import { Dropdown, Badge } from "react-bootstrap";
import { MdFilterAlt } from "react-icons/md";
import { Form } from "react-bootstrap";
import _ from "lodash";

interface FilterDropdownProps {
  items?: FilterDropdownItem[];
  onCheckedItemsChanged?: (item: FilterItem[]) => void;
}

export interface FilterItem {
  label: string;
  path: string;
  value: string | boolean | number;
}

export interface FilterDropdownItem {
  header: string;
  items: FilterItem[];
}
const FilterDropdown: FunctionComponent<FilterDropdownProps> = ({
  items,
  onCheckedItemsChanged,
}) => {
  const [selectedItems, setSelectedItems] = useState<FilterItem[]>([]);

  const handleCheckedItems = (itemNew: FilterItem, checked: boolean) => {
    const newSelectedItems = [...selectedItems];
    if (checked) {
      newSelectedItems.push(itemNew);
      //console.debug(newSelectedItems);
      setSelectedItems(newSelectedItems);
      onCheckedItemsChanged && onCheckedItemsChanged(newSelectedItems);
    } else {
      const selectedItemsRemove = newSelectedItems.filter(function (ele) {
        return !_.isEqual(ele, itemNew);
      });
      //console.debug(selectedItemsRemove);
      setSelectedItems(selectedItemsRemove);
      onCheckedItemsChanged && onCheckedItemsChanged(selectedItemsRemove);
    }

    //}
  };
  //console.debug(selectedItems.length);
  return (
    <Dropdown bsPrefix="dropdown1" autoClose="outside">
      <>
        <Dropdown.Toggle
          variant="dark"
          id="dropdown-basic"
          as={CustomToggle}
          badge={selectedItems.length}
        />
        <Dropdown.Menu>
          {items &&
            items.length > 0 &&
            items.map((section, indexSection) => (
              <div key={indexSection}>
                <Dropdown.Header>{section.header}</Dropdown.Header>
                {section.items &&
                  section.items.map((item, indexItem) => (
                    <Dropdown.Item
                      id={`${indexSection}-${indexItem}`}
                      key={`${indexSection}-${indexItem}`}
                      as={CheckDropdownItem}
                      item={item}
                      onChange={()=>handleCheckedItems}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
              </div>
            ))}
        </Dropdown.Menu>
      </>
    </Dropdown>
  );
};

export default FilterDropdown;

const CustomToggle = React.forwardRef(
  ({ badge, children, onClick }: any, ref: any) => (
    <a
      style={{ color: "black", marginTop: "-18px" }}
      href=""
      className="btn me-1 btn-sm"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <MdFilterAlt size={25} />

      {badge > 0 && <Badge bg="secondary">{badge}</Badge>}
    </a>
  )
);

interface CheckDropdownItemProps {
  id: string;
  item: FilterItem;
  onChange?: (item: FilterItem, checked: boolean) => void;
}
const CheckDropdownItem: FunctionComponent<CheckDropdownItemProps> =
  React.forwardRef(({ id, item, onChange, children }, ref: any) => {
    return (
      <Form.Check ref={ref} className="mb-0 m-2">
        <Form.Check.Label
          htmlFor={`inline-${"radio"}-${id}`}
          style={{ cursor: "pointer" }}
        >
          {children}
        </Form.Check.Label>
        <Form.Check.Input
          id={`inline-${"radio"}-${id}`}
          type="checkbox"
          //checked={}
          value={item.value.toString()}
          onChange={({ currentTarget }: any) => {
            onChange && onChange(item, currentTarget.checked);
          }}
        />
      </Form.Check>
    );
  });
