import { ResponseWrapper } from "../../models/reponse";
import Http from "../base/http-services";
import { BaseService } from "../base/base-service";

const endpoint = "auth/users";

const endpoint_groups = "auth/groups";

export interface IProfile {
  birthday?: any;
  gender?: any;
  phone?: any;
  address?: any;
  city?: any;
  zip?: any;
}

export interface IUser {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  report_notification?: boolean;
  warning_notification?: boolean;
  trend_notification?: boolean;
  maintenance_notification?: boolean;
  is_staff?: boolean;
  is_active?: boolean;
  profile?: IProfile;
  group: any;
  group_update?: string;
  profile_update?: IProfile;
}

export interface IPermission {
  id: number;
  name: string;
  codename: string;
}

export interface IUserGroup {
  name: string;
  level: number;
  permissions: IPermission[];
}

class UserService extends BaseService<IUser> {
  constructor() {
    super(endpoint, Http);
  }

  getGroups = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IUserGroup[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(this.http.get(endpoint_groups + "/" + "?" + params));
  };
}

export const user_service = new UserService();
