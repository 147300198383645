import { ResponseWrapper } from "../../models/reponse";
import { BaseService } from "../base/base-service";
import Http from "../base/http-services";

const endpoint = "valve";

class ValveService extends BaseService<any> {
  constructor() {
    super(endpoint, Http);
  }
}

export const valve_service = new ValveService();
