import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Nav, Card, Badge, ListGroup, Row, Col } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { withRouter } from "react-router-dom";
import { ITheme } from "../context/theme/theme";
import ThemeContext from "../context/theme/themeContext";
import withLayout from "../hoc/withLayout";
import { FaTemperatureLow } from "react-icons/fa";
import { TbTrendingUp } from "react-icons/tb";
import { AiOutlineGateway } from "react-icons/ai";
import Notifications from "./notification/notifications-default";
import NotificationTemperature from "./notification/notification-temperature";
import NotificationValve from "./notification/notifications-valve";
import { useDispatch, useSelector } from "react-redux";
import { getDetailNotifications } from "../store/reducers/badge-reducer";
import NotificationPid from "./notification/notification-pid";
import { TfiControlShuffle } from "react-icons/tfi";
import {
  getNotificationState,
  saveNotificationMenu,
} from "../store/reducers/notification-reducer";
import TabsCustom from "../components/common/tabs-custom";
import storage from "services/base/storage";
interface AnalyticsViewProps {}

export type INotificationType = "pid" | "valve" | "temperature" | "default";

const useStyles = createUseStyles({
  card: (theme: ITheme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  tabStyle: (theme: ITheme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "&.nav-pills .nav-link": {
      color: "black",
      transition: "0.5s",
    },
    "&.nav-pills .nav-link:hover": {
      color: "black",
    },
    "&.nav-pills .nav-link.active": {
      color: "white",
      backgroundColor: theme.primary,
    },
    "& > *": {
      marginRight: "1rem",
    },
  }),
});

const AnalyticsView: FunctionComponent<AnalyticsViewProps> = () => {
  const state = useSelector(getNotificationState);

  const [value, setValue] = useState<INotificationType>(
    state.menu ? state.menu : "default"
  );
  const { theme } = useContext(ThemeContext);
  const styles = useStyles({ ...theme });

  const badge_notification = useSelector(getDetailNotifications);

  const dispatch = useDispatch();

  const isDemo = storage.isDemo();

  const handleSelectNav = (eventKey: any) => {
    setValue(eventKey);
    dispatch(saveNotificationMenu(eventKey));
  };
  useEffect(() => {
    if (state.menu) {
      setValue(state.menu);
    }
  }, []);

  const options = [
    {
      key: "default",
      badge: badge_notification.default_pending,
      content: (
        <>
          <span className="d-none d-md-inline">Default</span>
          <TbTrendingUp size={20} />
        </>
      ),
    },
    {
      key: "temperature",
      badge: badge_notification.temperature_pending,
      content: (
        <>
          <span className="d-none d-md-inline">Temperature</span>
          <FaTemperatureLow size={15} />
        </>
      ),
    },
    {
      key: "valve",
      badge: badge_notification.valve_pending,
      content: (
        <>
          <span className="d-none d-md-inline">Valve</span>
          <AiOutlineGateway size={25} />
        </>
      ),
    },
    {
      key: "pid",
      badge: badge_notification.pid_pending,
      content: (
        <>
          <span className="d-none d-md-inline">Pid</span>
          <TfiControlShuffle size={15} />
        </>
      ),
    },
  ];
  return (
    <>
      {false && (
        <div className={styles.card}>
          <Card className="p-2 mb-1">
            {/* <TabsCustom
            value={value}
            onTabChanged={handleSelectNav}
            source={options}
          /> */}
            <Nav
              className={styles.tabStyle}
              variant="pills"
              activeKey={value}
              defaultActiveKey={value}
              onSelect={handleSelectNav}
            >
              {options.map((tab, index) => (
                <Nav.Item key={"tab2-" + index}>
                  <Nav.Link
                    style={{ display: "flex-column" }}
                    eventKey={tab.key}
                    className={"position-relative"}
                  >
                    {tab.content}
                    <Badge
                      pill
                      bg="danger"
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      {tab.badge !== undefined && tab.badge > 0 && tab.badge}
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card>
        </div>
      )}

      {value == "default" && <Notifications />}
      {value == "temperature" && <NotificationTemperature />}
      {value == "valve" && <NotificationValve />}
      {value == "pid" && <NotificationPid />}
    </>
  );
};

export default withRouter(AnalyticsView);
