import { FunctionComponent } from "react";
import {
  Placeholder,
  Card,
  Container,
  PlaceholderProps,
} from "react-bootstrap";
interface ParagraphProps extends PlaceholderProps {
  height?: number;
  rows?: number;
  as?: any;
  card?: boolean;
}

const createRows = (rows: number) => {
  const items: number[] = [];
  for (let index = 0; index <= rows; index++) {
    const random = Math.floor(Math.random() * 13);
    items.push(random);
  }
  return items;
};

const Paragraph: FunctionComponent<ParagraphProps> = ({
  rows = 3,
  height = 50,
  as = "p",
  card = true,
  className,
}) => {
  const items = createRows(rows);

  const element = (
    <Container>
      <Placeholder
        className={className}
        as={as}
        animation="glow"
        style={{ opacity: 0.5 }}
      >
        {items.map((row, index) => (
          <Placeholder key={"par-" + index} xs={row} size="sm" />
        ))}
      </Placeholder>
    </Container>
  );
  return (
    <>
      {card ? (
        <Card style={{ backgroundColor: "white", minHeight: height }}>
          {element}
        </Card>
      ) : (
        <>{element}</>
      )}
    </>
  );
};

export default Paragraph;
