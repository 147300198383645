import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../configureStore";

// Define a type for the slice state
interface UsageBasedState {
  search?: string;
}

// Define the initial state using that type
const initialState: UsageBasedState = {
  search: "",
};

export const usageBasedManagementSlice = createSlice({
  name: "usage-based",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    add(state, action) {},
    update(state, action) {},
    remove(state, action) {},
  },
});

export const { saveSearch } = usageBasedManagementSlice.actions;

export const getSearch = (state: RootState) => state.usage_based.search;

export default usageBasedManagementSlice.reducer;
