import { FunctionComponent, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";

import Datetime from "react-datetime";
import moment from "moment";
import * as dateOperation from "../../../utils/date-operations";
import { prediction_service } from "../../../services/prediction/prediction-service";
import Backdrop from "../../common/backdrop/backdrop";
import alertService from "utils/alertService";

interface PredictionOptionsProps {
  struct_id: number;
}

const PredictionOptions: FunctionComponent<PredictionOptionsProps> = ({
  struct_id,
}) => {
  const [startDate, setStartDate] = useState(moment());
  const [isBusy, setisBusy] = useState<boolean>(false);

  const handleDoPrediction = async () => {
    try {
      setisBusy(true);
      if (struct_id === 0) {
        alert("select struct");
        setisBusy(false);
        return;
      }
      await prediction_service.doPrediction(
        struct_id,
        { last_date: dateOperation.getDateOnString(startDate.toDate()) });
      setisBusy(false)
    } catch (error:any) {
      if (error.response.data.response.error) {
        alertService.errorMessage(error.response.data.response.error);
      }
      setisBusy(false)
      
    }
  };

  return (
    <Row>
      <Backdrop show={isBusy} />
      <Col lg={2} md={3} xs={6}>
        {/* <Button className="me-1">Do model</Button> */}
        <Datetime
          initialValue={startDate}
          value={startDate}
          inputProps={{
            placeholder: "From",
            style: {
              height: 35,
            }
          }}
          timeFormat={false}
          onChange={(date: any) => {
            setStartDate(date);
          }}
        />
      </Col>
      <Col>
        <Button onClick={handleDoPrediction}>Do prediction</Button>
      </Col>
    </Row>
  );
};

export default PredictionOptions;
