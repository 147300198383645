import { useQuery } from "@tanstack/react-query";
import { dashboard_service } from "../services/dashboard/dashboard-service";
import { timezone_service } from "../services/timezoneService";
import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import { ResponseError } from "./../models/reponse";
import { useDispatch } from "react-redux";

export const useGetDashboard = () => {
  const timezone: string = timezone_service.getTimezone();
  const dispatch = useDispatch();
  const dashboard = useQuery({
    queryKey: [QueryKeys.GET_DASHBOARD],
    queryFn: () => dashboard_service.get({ timezone: timezone }),
    onSuccess: (data) => {      
      // if (data.response) {
      //   dispatch(saveExpireMaintenance(data.response?.expired_maintenance));
      // }
      return data.response;
    },
    onError: (error?: ResponseError) => {
      if (error) {
        error.error && alertService.errorMessage(error?.error);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return dashboard;
};
