import { useFormikContext } from "formik";
import { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import DefaultButton from "../common/buttons/default-button";
import { IconType } from "react-icons/lib";
import { ButtonProps } from "react-bootstrap";

interface FormSubmitProps extends ButtonProps {
  title: string;
  icon?: IconProp;
  disabled?: boolean;
  size?: "sm" | "lg";
}

const FormSubmit: FunctionComponent<FormSubmitProps> = ({
  title,
  icon,
  size,
  disabled,
  className,
}) => {
  const { handleSubmit } = useFormikContext();
  return (
    <DefaultButton
      className={className}
      size={size}
      disabled={disabled}
      label={title}
      icon={icon}
      onClick={() => handleSubmit()}
    />
  );
};

export default FormSubmit;
