import { AxiosError } from "axios";
import { MaintenanceScheduledResultDemo } from "../../../demo/maintenance/scheduled/completed-demo";
import alertService from "../../../utils/alertService";
import storage from "../../base/storage";
import Http, { IHttp } from "../../base/http-services";

type IResult = {
  comment: string;
  programmed_date: string;
  programmed_hour: string;
  scheduled_maintenance_id: number;
};

const endpoint_result = "maintenance/scheduled/complete";
class MaintenanceScheduledResultService {
  http: IHttp;
  constructor() {
    this.http = Http;
  }

  protected Url(id: any): string {
    return endpoint_result + "/" + id + "/";
  }

  save(data: IResult, query_params: Record<string, any> = {}) {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    return this.http.post(endpoint_result + "/" + "?" + queryString, data);
  }

  checkErrors(error: AxiosError<any>) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.status !== 403 &&
      error.response.status < 500
    ) {
      var message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new MaintenanceScheduledResultDemo()
    : new MaintenanceScheduledResultService();

export const scheduled_result_service = instance;
