import "./App.css";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Maintanance from "./pages/maintenance";
import Dashboard from "./pages/dashboard";
import ProtectedRoute from "./components/authorization/protected-route";
import ReportPDF from "./pages/report";
import Notifications from "./pages/notifications";
import Users from "./components/features/management/user/users-list";
import FormUser from "./components/features/management/user/user-form";
import Profile from "./pages/profile";
import PermissionProvider from "./context/permission/permissionProvider";
import "moment/locale/en-au";
import moment from "moment";
import ThemeProvider from "./context/theme/themeProvider";
import Login from "./pages/login";
import storage from "./services/base/storage";
import NotFound from "./pages/notFound";
import Manage from "./pages/manage";
import { Routes } from "./Routes";
import ListUsageBased from "./components/features/management/usage-based/list-usage-based";
import UsageBasedForm from "./components/features/management/usage-based/usage-based-form";
import ListScheduled from "./components/features/management/scheduled/scheduled-list";
import ScheduledForm from "./components/features/management/scheduled/scheduled-form";
import ListStruct from "./components/features/management/struct/struct-list";
import StructForm from "./components/features/management/struct/struct-form";
import Graph from "./pages/graph";
import { ThemeType, getTheme } from "./theme/themes";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
import Analytics from "./pages/analytics";
import TemperatureConditionList from "./components/features/management/temperature/temperature-condition-list";
import TemperatureConditionForm from "./components/features/management/temperature/temperature-condition-form";
import ValveConditionList from "./components/features/management/valve/valve-condition-list";
import ValveConditionForm from "./components/features/management/valve/valve-condition-form";
import UserGroupsList from "./components/features/management/user-groups/user-groups-list";
import UserGroupsForm from "./components/features/management/user-groups/user-groups-form";
import TourProvider from "context/tour/tourProvider";
import Layout from "components/nav-bar/layout";

moment.locale("en"); // default english
console.debug = function () {};
//moment.updateLocale("en-ua") // import the language and update.
// Function that get permission from the logged user.
const fetchPermission = () => {
  // Simulate a delay from a request
  const user = storage.getUser();

  if (user) return user.permissions;
  return [];
};

const CustomRedirect = ({}) => {
  const user = storage.getUser();
  const location = useLocation();
  if (!user) {
    return <Redirect to={{ pathname: "/login" }} />;
  } else {
    if (location.pathname === "/" || location.pathname === "") {
      return (
        <Redirect to={{ pathname: "dashboard", search: location.search }} />
      );
    }
    if (Object.values(Routes).includes(location.pathname as Routes)) {
      return (
        <Redirect
          to={{ pathname: location.pathname, search: location.search }}
        />
      );
    } else if (
      Object.values(Routes).some(function (key) {
        let regex = new RegExp(key);        
        let result = regex.test(location.pathname);
        return result;
      })
    ) {
      return (
        <Redirect
          to={{ pathname: location.pathname, search: location.search }}
        />
      );
    } else {
      return <Redirect to={{ pathname: "/404" }} />;
    }
  }
};

function MainComponent() {
  const runDemo = storage.isDemo();
  return (
    <TourProvider runTour={runDemo}>
      <PermissionProvider permissions={fetchPermission()}>
        <Layout>
          <ProtectedRoute exact path={[Routes.dashboard, "/", ""]}>
            <Dashboard />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.user_groups}>
            <UserGroupsList />
          </ProtectedRoute>

          <ProtectedRoute
            path={[Routes.user_groups_edit, Routes.user_groups_new]}
          >
            <UserGroupsForm />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.users}>
            <Users />
          </ProtectedRoute>

          <ProtectedRoute path={[Routes.users_edit, Routes.users_new]}>
            <FormUser />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.report}>
            <ReportPDF />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.notification}>
            <Notifications />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.graph}>
            <Graph />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.analytics}>
            {/* <Analytics /> */}
            <Analytics />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.maintenance}>
            <Maintanance />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.profile}>
            <Profile />
          </ProtectedRoute>
          <ProtectedRoute exact path={Routes.management}>
            <Manage />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.management_struct}>
            <ListStruct />
          </ProtectedRoute>

          <ProtectedRoute
            path={[Routes.management_struct_new, Routes.management_struct_edit]}
          >
            <StructForm />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.management_usage_based}>
            <ListUsageBased />
          </ProtectedRoute>

          <ProtectedRoute
            path={[
              Routes.management_usage_based_new,
              Routes.management_usage_based_edit,
            ]}
          >
            <UsageBasedForm />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.management_scheduled}>
            <ListScheduled />
          </ProtectedRoute>

          <ProtectedRoute
            path={[
              Routes.management_scheduled_new,
              Routes.management_scheduled_edit,
            ]}
          >
            <ScheduledForm />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.management_temperature_condition}>
            <TemperatureConditionList />
          </ProtectedRoute>

          <ProtectedRoute
            path={[
              Routes.management_temperature_condition_new,
              Routes.management_temperature_condition_edit,
            ]}
          >
            <TemperatureConditionForm />
          </ProtectedRoute>

          <ProtectedRoute exact path={Routes.management_valve_condition}>
            <ValveConditionList />
          </ProtectedRoute>

          <ProtectedRoute
            path={[
              Routes.management_valve_condition_new,
              Routes.management_valve_condition_edit,
            ]}
          >
            <ValveConditionForm />
          </ProtectedRoute>
          <CustomRedirect />
        </Layout>
      </PermissionProvider>
    </TourProvider>
  );
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      //staleTime:1000,
      cacheTime: 1 * 30 * 1000, // 1minutes
      //cacheTime:0 //0
      //enabled: storage.getUser() != null ? true : false, // error perché on si aggiorna quando si fa login
    },
  },
});

function App() {
  const envTheme = process.env.REACT_APP_THEME;

  const theme = getTheme(envTheme?.toLowerCase() as ThemeType);

  document.title = theme.title ? theme.title : "Costal";
  let icon: any = document.getElementById("favicon");

  if (icon && icon.href) {
    icon.href = theme.favicon ? theme.favicon : "";
  }
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path={Routes.login} component={Login} />
            <Route exact path={Routes.notFound} component={NotFound} />

            <Route component={MainComponent} />
          </Switch>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
