import * as React from "react";
import * as ponyfill from "web-streams-polyfill/ponyfill";
import streamSaver from "streamsaver";
import { FileSaverDemo } from "../../demo/file-saver-demo";
import storage from "../base/storage";


streamSaver.WritableStream = ponyfill.WritableStream;
streamSaver.mitm = 'http://localhost:3001/mitm.html?version=2.0.0';
//streamSaver.mitm = 'https://jimmywarting.github.io/StreamSaver.js/mitm.html?version=2.0.0';

class FileSaver {
  SaveFromUrl = (url, fileName) => {
    const result = new Promise((resolve, reject) => {
     // console.log("here")
      const fileStream = streamSaver.createWriteStream(fileName);
      //console.log("here2")
      fetch(url)
        .then((res) => {
          if (res.status === 200) {
            const readableStream = res.body;

            // more optimized
            if (window.WritableStream && readableStream?.pipeTo) {
              return readableStream
                .pipeTo(fileStream)
                .then(() => resolve("done writing"))
                .catch((err) => console.error(err));
            }
          } else {
            reject("Error file not found");
          }
           var writer = fileStream.getWriter()
          const reader = res.body?.getReader()
          const pump = () => reader?.read()
            .then(res => res.done
              ? writer.close()
              : writer.write(res.value).then(pump))
          pump()
        })
        .catch((err) => reject(err));
    });
    return result;
  };
}
//Typescript version dont support the change of writableStream.
// class FileSaver {
//   SaveFromUrl(url: string, fileName: string) {
//     return new Promise<string>((resolve, reject) => {
//       const fileStream = streamSaver.createWriteStream(fileName);
//       //const fileStream = new WritableStream(fileName);
//       console.log("here");
//       fetch(url)
//         .then((res) => {
//           if (res.status === 200) {
//             const readableStream: ReadableStream<Uint8Array> | null = res.body;

//             // more optimized
//             if (window.WritableStream && readableStream?.pipeTo) {
//               return readableStream
//                 ?.pipeTo(fileStream)
//                 .then(() => resolve("done writing"))
//                 .catch((err) => console.error(err));
//             }
//           } else {
//             reject("Error file not found");
//           }

//           var writer = fileStream.getWriter()
//           const reader = res.body?.getReader()
//           const pump:any = () => reader?.read()
//             .then(res => res.done
//               ? writer.close()
//               : writer.write(res.value).then(pump))
//           pump()
//         })
//         .catch((err) => reject(err));
//     });
//   }
// }

const user = storage.getUser();
const instance =
  user && user.token_type == "demo" ? new FileSaverDemo() : new FileSaver();

export const file_saver = instance;
