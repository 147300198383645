import Http, { IHttp } from "../../base/http-services";
import { ResponseWrapper } from "../../../models/reponse";
import * as alertService from "../../../utils/alertService";
import { MaintenanceUsageBasedOperationDemo } from "./../../../demo/maintenance/usage-based/operation-service-demo";
import storage from "../../base/storage";

const endpoint_operations_complete =
  "maintenance/usage-based/operation/complete";
const endpoint_operations = "maintenance/usage-based/operation";
class MaintenanceUsageBasedOperationService {
  //Operations send always a post request, if the operation already exists automatic
  // update.
  private http = Http;
  save(
    data: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    var params = this.generateParams(query_params);
    return this.getPromise(
      this.http.post(endpoint_operations_complete + "/?" + params, data)
    );
  }

  delete(dataId: number): Promise<ResponseWrapper<any>> {
    return this.getPromise(
      this.http.delete(endpoint_operations + "/" + dataId.toString())
    );
  }

  protected getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<any>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  protected generateParams(query_params: Record<string, any> = {}): string {
    var params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  checkErrors(error: any) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error.response &&
      error.response.data &&
      error.response.status !== 403
    ) {
      var message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new MaintenanceUsageBasedOperationDemo()
    : new MaintenanceUsageBasedOperationService();

export const usage_based_operation_service = instance;
