import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  IStruct,
  IStructByCategory,
  struct_service,
} from "../services/struct/struct-service";
import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import { ResponseWrapper } from "../models/reponse";
import { SelectOption } from "components/common/selects/select-option";
import _ from "lodash";

// TODO implement keys by file? or globals?

export const useGetStructs = () => {
  const struct_temp = useQuery({
    queryKey: [QueryKeys.GET_STRUCTs],
    queryFn: () => struct_service.get(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });
  return struct_temp;
};

export const useGetStructsById = () => {
  const save_profile = useMutation<ResponseWrapper<IStruct>, any, number>({
    mutationFn: (id) => {
      return struct_service.getById(id);
    },
    onError: (error, variables, context) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {},
  });

  return save_profile;
};

type IDataGetStructsByCategory = {
  categoryId: string;
  processing?: string;
};

export const useGetStructsByCategory = () => {
  const save_profile = useMutation<
    ResponseWrapper<IStructByCategory[]>,
    any,
    IDataGetStructsByCategory
  >({
    mutationFn: (data) => {
      const params: any = {
        enabled: true,
      };
      if (data.processing) params.processing = data.processing;
      return struct_service.getStructByCategory(data.categoryId, params);
    },
    onError: (error, variables, context) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {},
  });

  return save_profile;
};

//#region struct by processing

export const useGeteStructsDefault = () => {
  const struct_default = useQuery({
    queryKey: [QueryKeys.GET_STRUCT_DEFAULT],
    queryFn: () => struct_service.get({ processing: "Default" }),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });
  return struct_default;
};

export const useGeteStructTemp = () => {
  const struct_temp = useQuery({
    queryKey: [QueryKeys.GET_STRUCT_TEMP],
    queryFn: () => struct_service.get({ processing: "Temperature" }),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });
  return struct_temp;
};

export const useGeteStructPID = () => {
  const struct_temp = useQuery({
    queryKey: [QueryKeys.GET_STRUCT_PID],
    queryFn: () => struct_service.get({ processing: "Pid" }),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });
  return struct_temp;
};

export const useGetStructValve = () => {
  const struct_valve = useQuery({
    queryKey: [QueryKeys.GET_STRUCT_VALVE],
    queryFn: () => struct_service.get({ processing: "Valve" }),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return struct_valve;
};
//#endregion

export const StructOrder = (data: IStruct[], orderBy: string) => {
  const StructOrderByType = _.chain(data)
    .groupBy(orderBy)
    .map((structs, name) => ({
      label: name,
      options: _.map<any, SelectOption>(structs, (struct) => {
        return {
          label: struct.name,
          value: struct.id,
          isDisabled: !struct.enabled,
        };
      }),
    }))
    .value();
  return StructOrderByType;
};
