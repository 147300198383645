import moment, { Moment } from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import CardCollapsible from "../../components/common/card-collapsible";
import { SelectOption } from "../../components/common/selects/select-option";
import { timezone_service } from "../../services/timezoneService";
import DateTimePicker from "../../components/common/date-time-picker";
import { Button, Col, Row, Card, Badge } from "react-bootstrap";
import SelectMultiple from "../../components/common/selects/select-multiple";
import { IStruct, struct_service } from "../../services/struct/struct-service";
import alertService, { errorMessage } from "../../utils/alertService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys, StaleTime } from "../../queries/query_keys";
import { AxiosError } from "axios";
import { MdSearch } from "react-icons/md";
import { ResponseWrapper } from "../../models/reponse";
import Backdrop from "../../components/common/backdrop/backdrop";
import {
  IValveResponse,
  statistic_plot_valve_service,
} from "../../services/statistic/statistic-plot-valve";
import PlotValve from "../../components/features/plot-analytics/valve/plot-valve";
import { useGetStructValve } from "../../queries/struct.hook";
import { usePlotValve } from "../../queries/analytics.valve.hook";
import SelectSingle from "../../components/common/selects/select-single";
import {
  isLessThatTodayAndDate,
  isLessThatTodayAndIsAfterDate,
} from "../../utils/date-operations";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsValveState,
  saveValveEndDate,
  saveValveSignals,
  saveValveStartDate,
  saveValveStructuresSelected,
} from "./../../store/reducers/analytics/analytics.valve-reducer";
interface ValvePageProps {}

const ValvePage: FunctionComponent<ValvePageProps> = () => {
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(7, "d"));
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [structs, setStruct] = useState<SelectOption[]>([]);
  const [structSelected, setStructsSelected] = useState<SelectOption[]>([]);
  const [signals, setSignals] = useState<IValveResponse[]>([]);
  const [resample, setResample] = useState<SelectOption>();

  const dispatch = useDispatch();
  const analytics_state = useSelector(getAnalyticsValveState);

  const get_struct_valve = useGetStructValve();

  const load_plot = usePlotValve();

  const mapObject = (response: IStruct[]) => {
    const structOptions: SelectOption[] = [];

    response.forEach((struct) => {
      if (struct.name && struct.id)
        structOptions.push({ label: struct.name, value: struct.id.toString() });
    });

    return structOptions;
  };

  const handlePlot = async () => {
    if (structSelected.length <= 0) {
      errorMessage("Select structures");
      return;
    }
    const ids: number[] = [];
    structSelected.forEach((x) => {
      if (x.value) {
        ids.push(parseInt(x.value));
      }
    });
    const result = await load_plot.mutateAsync({
      start: startDate,
      end: endDate,
      struct_ids: ids,
      resample_rule: resample?.value ? parseInt(resample.value) : undefined,
    });
    if (result.response) {
      setSignals(result.response);
      dispatch(saveValveSignals(result.response));
    }
  };

  const resample_options: SelectOption[] = [
    { value: undefined, label: "All" },
    { value: "43200", label: "12H" },
    { value: "86400", label: "1D" },
  ];

  //Update when data changes.
  useEffect(() => {
    if (get_struct_valve.data?.response) {
      const options = mapObject(get_struct_valve.data?.response);
      setStruct(options);
    }
  }, [get_struct_valve.data]);

  useEffect(() => {
    if (structs.length == 0) {
      if (get_struct_valve.data?.response) {
        const options = mapObject(get_struct_valve.data?.response);
        setStruct(options);
      }
    }
  }, []);

  useEffect(() => {
    if (analytics_state.signals) {
      setSignals(analytics_state.signals);
    }

    if (analytics_state.structures_selected) {
      setStructsSelected(analytics_state.structures_selected);
    }
    if (analytics_state.start_date) {
      setStartDate(moment(analytics_state.start_date));
    }
    if (analytics_state.end_date) {
      setEndDate(moment(analytics_state.end_date));
    }
  }, []);

  const graph_height = 350;

  return (
    <>
      <Backdrop show={load_plot.isLoading} />
      <CardCollapsible
        title={
          <>
            <h5>Valves</h5>
            <Badge className="ms-1" bg="secondary">
              {structSelected && structSelected?.length > 0 && (
                <span>{structSelected?.length} selected</span>
              )}
            </Badge>
            <Badge className="ms-1" bg="secondary">
              <span>
                {startDate.format("L")} - {endDate.format("L")}
              </span>
            </Badge>
          </>
        }
      >
        <Row>
          <Col sm={12} md={5} lg={5}>
            <SelectMultiple
              placeholder="Select valves..."
              label={"Valves"}
              value={structSelected}
              options={structs}
              onValueChange={(data) => {
                setStructsSelected(data);
                dispatch(saveValveStructuresSelected(data));
              }}
            />
          </Col>
          <Col xs={6} sm={6} md={2} lg={2}>
            <label>From</label>
            <DateTimePicker
              selectedDate={startDate}
              timeEnabled={false}
              dateEnabled={true}
              onDateChange={(date: string | Moment) => {
                const _date = moment(date);
                setStartDate(_date);
                dispatch(saveValveStartDate(_date));
              }}
              placeholder={"datetime"}
              isValidDate={(current, selected) =>
                isLessThatTodayAndDate(current, endDate)
              }
            />
          </Col>
          <Col xs={6} sm={6} md={2} lg={2}>
            <label>To</label>
            <DateTimePicker
              selectedDate={endDate}
              timeEnabled={false}
              dateEnabled={true}
              onDateChange={(date: string | Moment) => {
                const _date = moment(date);
                setEndDate(_date);
                dispatch(saveValveEndDate(_date));
              }}
              placeholder={"datetime"}
              isValidDate={(current, selected) =>
                isLessThatTodayAndIsAfterDate(current, startDate)
              }
            />
          </Col>
          <Col xs={9} sm={8} md={2} lg={2}>
            <SelectSingle
              placeholder="Select ..."
              label={"Resample"}
              options={resample_options}
              onValueChange={setResample}
            />
          </Col>
          <Col xs={3} lg={1} md={1} sm={3}>
            <label></label>
            <br></br>
            <Button variant="secondary" onClick={handlePlot}>
              <MdSearch size={25}></MdSearch>
            </Button>
          </Col>
        </Row>
      </CardCollapsible>
      {/* TODO Refactoring */}
      <Row>
        {signals &&
          signals.length > 0 &&
          signals.map((signal, index) => {
            return signal.data.map((data, index2) => {
              return (
                <Col
                  key={"plot-valve-" + index}
                  lg={6}
                  md={12}
                  sm={12}
                  className="mb-2"
                >
                  <Card className="p-2" style={{ minHeight: graph_height }}>
                    <PlotValve
                      key={index}
                      name={signal.name}
                      unit_of_measurement={signal.unit_of_measurement}
                      signal={signal.data[0]}
                    />
                  </Card>
                </Col>
              );
            });
          })}
      </Row>
    </>
  );
};

export default ValvePage;
