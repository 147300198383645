import React, { FunctionComponent, useEffect, useState } from "react";
import Restricted from "../../../../context/permission/restricted";
import { permissions } from "../../../../services/permissions/permissions";
import { Row, Card, Button } from "react-bootstrap";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import {
  MdAddCircle,
  MdCancel,
  MdDeleteOutline,
  MdCheckCircle,
  MdModeEditOutline,
  MdRefresh,
} from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import Table from "../../../table/table";
import withLayout from "../../../../hoc/withLayout";
import { TableColumn } from "react-data-table-component";
import Backdrop from "../../../common/backdrop/backdrop";
import { Routes } from "../../../../Routes";
import { scheduled_service } from "../../../../services/maintenance/scheduled/scheduled-service";

import { FilterDropdownItem } from "../../../table/filter-dropdown";
import { getDays } from "../../../../utils/date-operations";
import moment from "moment";
import { WaitForRequest } from "../../../../utils/alertService";
import { IMaintenanceScheduled } from "../../../../services/maintenance/scheduled/scheduled-service";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearch,
  saveSearch,
} from "../../../../store/reducers/scheduled.management-reducer";

interface ListScheduledProps {}

const ListScheduled: FunctionComponent<ListScheduledProps> = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [maintenanceScheduled, setMaintenanceScheduled] = useState<
    IMaintenanceScheduled[]
  >([]);
  const history = useHistory();
  //redux
  const dispatch = useDispatch();
  const search = useSelector(getSearch);

  const actionOptions = (maintenance: IMaintenanceScheduled) => (
    <>
      <Restricted to={permissions.main.scheduled_maintenance.change}>
        <Button
          variant="outline-warning"
          onClick={() =>
            history.push(Routes.management_scheduled + "/" + maintenance.id)
          }
          size="sm"
        >
          <MdModeEditOutline />
        </Button>
      </Restricted>
      <span> &nbsp; </span>
      <Restricted to={permissions.main.scheduled_maintenance.delete}>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => handleDelete(maintenance.id)}
        >
          <MdDeleteOutline />
        </Button>
      </Restricted>
    </>
  );

  const enableCell = (enabled: boolean) => {
    if (enabled === true) {
      return <MdCheckCircle color="green" />;
    } else {
      return <MdCancel color="red" />;
    }
  };

  const columns: TableColumn<IMaintenanceScheduled>[] = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Start",
      selector: (row) => moment(row.start_date).format("L"),
      sortable: true,
    },
    {
      name: "End",
      selector: (row) => moment(row.end_date).format("L"),
      sortable: true,
    },
    {
      name: "Day",
      selector: (row) => getDays(row.trigger_day),
      sortable: true,
    },
    {
      name: "Hour",
      selector: (row) => row.trigger_hour,
      sortable: true,
    },
    {
      name: "Enable",
      cell: (row) => enableCell(row.enabled),
      sortable: false,
    },
    {
      cell: (maintenance) => actionOptions(maintenance),
      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  const handleDelete = async (id: number) => {
    try {
      let func = async () => await scheduled_service.delete(id);
      const response = await WaitForRequest(
        "Confirm",
        "Are you sure you want to delete the maintenance ?",
        func
      );
      if (response) {
        let update_maintenance = maintenanceScheduled.filter(
          (x) => x.id !== id
        );
        setMaintenanceScheduled(update_maintenance);
      }
    } catch (error: any) {
      scheduled_service.checkErrors(error);
    }
  };
  const loadScheduledMaintenance = async () => {
    try {
      setIsBusy(true);
      const result = await scheduled_service.get();
      if (result.response) {
        setMaintenanceScheduled(result.response);
      }
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
    }
  };
  useEffect(() => {
    loadScheduledMaintenance();
  }, []);

  const filterItems: FilterDropdownItem[] = [
    {
      header: "Status",
      items: [
        { label: "Enabled", path: "enabled", value: "true" },
        { label: "Disabled", path: "disabled", value: "false" },
      ],
    },
  ];
  return (
    <>
      <Restricted
        to={permissions.main.scheduled_maintenance.view}
        showFallback={true}
      >
        <Backdrop show={isBusy} />

        <Card className="p-3">
          <h4>
            <GoBackNavigation url="/management" />
            Scheduled maintenance
          </h4>
          <hr />
          <Row>
            <div className="d-flex justify-content-start">
              <Restricted to={permissions.main.scheduled_maintenance.add}>
                <Link
                  to={Routes.management_scheduled_new}
                  className="btn mr-1 btn-sm"
                >
                  <MdAddCircle /> New
                </Link>
              </Restricted>
              <a
                onClick={async () => await loadScheduledMaintenance()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </div>
          </Row>

          <Table
            filterItems={filterItems}
            columns={columns}
            data={maintenanceScheduled}
            pagination={true}
            searchText={search}
            onSearchChange={(text: string) => {
              dispatch(saveSearch(text));
            }}
          />
        </Card>
      </Restricted>
    </>
  );
};

export default ListScheduled;
