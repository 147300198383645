import React, {
  Component,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { MdRefresh } from "react-icons/md";
import { INotification } from "../../services/notifications/warning-notification-service";
import _ from "lodash";
import Backdrop from "../../components/common/backdrop/backdrop";
import { Row, Card, Col } from "react-bootstrap";
import Restricted from "../../context/permission/restricted";
import { permissions } from "../../services/permissions/permissions";
import NotificationDefaultModal from "../../components/features/notification/notification-default-modal";
import NotificationStats, {
  IStatsNotification,
} from "../../components/features/notification/notification-stats";
import ListItemNotification from "../../components/features/notification/list-item-notification";
import SearchBox from "../../components/common/searchbox";
import { useGetNotification } from "../../queries/notification/notification.hook";
import { calcolateStats } from "./notification.utils";
import { Tour, notification_detail_steps, notification_steps } from "tour";
import { Routes } from "Routes";

interface NotificationsProps {}

const Notifications: FunctionComponent<NotificationsProps> = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchValue, setSerachValue] = useState<string>("");
  const [statsNotification, setStatsNotification] =
    useState<IStatsNotification>({} as IStatsNotification);
  const [notification, SetNotification] = useState<INotification | undefined>(
    undefined
  );
  const [notificationFilter, setNotificationFilter] = useState<INotification[]>(
    []
  );

  const notifications = useGetNotification();

  const handleOpenDetail = async (notification: INotification) => {
    SetNotification(notification);
    setShowModal(true);
  };

  const handleClose = (refresh: boolean) => {
    setShowModal(false);
    if (refresh) {
      notifications.refetch();
    }
  };

  const handleSearch = (value: string) => {
    if (value === "") {
      setSerachValue(value);
      if (notifications.data?.response)
        setNotificationFilter(notifications.data?.response);

      return;
    }
    const result = _.filter(notifications.data?.response, (item) => {
      return item.struct.name.toLowerCase().includes(value.toLowerCase());
    });

    setNotificationFilter(result);
    setSerachValue(value);
  };

  useEffect(() => {
    if (notifications.data?.response) {
      const stats = calcolateStats(notifications.data?.response);
      setStatsNotification(stats);
      setNotificationFilter(notifications.data?.response);
    }
  }, [notifications.data]);

  const click_event = () =>
    notificationFilter && handleOpenDetail(notificationFilter[0]);

  const steps = [
    ...notification_steps(click_event),
    ...notification_detail_steps,
  ];

  return (
    <React.Fragment>
      <Tour steps={steps} redirectTo={Routes.report} />
      <Restricted to={permissions.main.notification.view} showFallback={true}>
        <Backdrop
          show={notifications.isRefetching || notifications.isLoading}
        />

        <Row>
          <Col className="mb-1" sm={12} md={9} lg={9}>
            <Card className="p-3">
              <h4>Notifications</h4>
              <hr></hr>
              <Row>
                <div className="d-flex justify-content-end">
                  <a
                    onClick={() => notifications.refetch()}
                    className="btn mr-1 btn-sm"
                  >
                    <MdRefresh /> Refresh
                  </a>
                </div>
              </Row>
              <Row className="tour_search">
                <Col>
                  <SearchBox
                    value={searchValue}
                    size="md"
                    placeholder="Search by structure..."
                    onChange={handleSearch}
                  />
                </Col>
              </Row>
              <Row className="tour_list">
                <Col>
                  <ListItemNotification
                    notifications={notificationFilter}
                    onView={handleOpenDetail}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={12} md={3} lg={3} className="tour_stats">
            <NotificationStats data={statsNotification} />
          </Col>
        </Row>
      </Restricted>

      <NotificationDefaultModal
        onClose={handleClose}
        showModal={showModal}
        notification={notification ? notification : ({} as INotification)}
      />
    </React.Fragment>
  );
};

export default Notifications;
