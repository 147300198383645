import { IMaintenanceUsedModel } from "../../../services/maintenance/usage-based/usage-based-service";
import maintenance from "../../../demo//maintenance/usage-based/maintenance-usage-based.json";

class MaintenanceDemoData {
  private data: IMaintenanceUsedModel[];
  constructor() {
    this.data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(maintenance.response);
    const obj: IMaintenanceUsedModel[] = JSON.parse(myJSON);
    return obj;
  };

  getData = () => {
    return this.data;
  };

  updateData = (new_data:IMaintenanceUsedModel[]) =>{
    this.data = new_data;
  }

  update =(new_data:IMaintenanceUsedModel) =>{
      const index = this.data.findIndex(x => x.id === new_data.id);
      if(index > -1){
        this.data[index] = new_data;
      }
  }
}


export const maintenance_data = new MaintenanceDemoData();
