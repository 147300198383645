import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import { ResponseWrapper } from "../models/reponse";
import { Routes } from "../Routes";
import { user_service } from "../services/users/user-service";
import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import {
  IReportNotification,
  report_notification_service,
} from "services/reports/report-notification-service";
import { timezone_service } from "services/timezoneService";

export const useGetReports = () => {
  const timezone: string = timezone_service.getTimezone();
  const reports = useQuery({
    queryKey: [QueryKeys.GET_REPORTS],
    queryFn: () => report_notification_service.get({ timezone: timezone }),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error.response?.data.response.error) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return reports;
};

type IGenerateReport = {
  start_date: string;
  end_date: string;
  structs: number[] | string[] | undefined;
};

export const useGenerateReportByStructs = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const saveUser = useMutation<any, any, IGenerateReport>({
    mutationFn: (data) => {
      return report_notification_service.generateReportByStructs(
        data.start_date,
        data.end_date,
        data.structs
      );
    },
    onError: (error, variables, context) => {
      if (error.response.data.response.error) {
        alertService.errorMessage(error.response.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_REPORTS] });
    },
  });
  return saveUser;
};

export const useDeleteReport = () => {
  const queryClient = useQueryClient();
  const deletReport = useMutation<
    ResponseWrapper<IReportNotification>,
    any,
    number
  >({
    mutationFn: (data) => {
      return report_notification_service.delete(data);
    },
    onError: (error, variables, context) => {
      if (error.response?.data.response.error) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_REPORTS] });
    },
  });

  return deletReport;
};
