import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Col } from "react-bootstrap";

interface NoDataFoundProps {
  height: number;
  message?: string;
}

const NoDataFound: FunctionComponent<NoDataFoundProps> = ({
  message = "No data found...",
  height = 200,
}) => {
  return (
    <React.Fragment>
     
        <Card className="mb-1" style={{ minHeight: height }}>
          <Col>
            <span className="h4 center-content" style={{ fontSize: 20 }}>
              <FontAwesomeIcon icon={faCalendarXmark} size={"lg"} />
              <br />
              {message}
            </span>
          </Col>
        </Card>
    
    </React.Fragment>
  );
};

export default NoDataFound;
