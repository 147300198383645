import React, {
  FunctionComponent,
  InputHTMLAttributes,
  useContext,
} from "react";
import { createUseStyles } from "react-jss";
import ThemeContext from "../../context/theme/themeContext";
import { ITheme } from "../../context/theme/theme";

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  //string | undefined;
  name: any; 
  label: string | JSX.Element;
  error: string | undefined | null | any;
  checked: boolean;
  onchange?: (input: any, checked: boolean) => void;
}

const useStyles = createUseStyles({
  styleSwitch: (props: ITheme) => ({
    cursor: "pointer",
    minHeight: "1.5rem",
    minWidth: "3rem",
    "&:checked": {
      backgroundColor: props.primary,
      borderColor: props.primary,
    },
    "&:focus": {
      outline: "none !important",
      boxShadow: "none",
    },
  }),
});

const Switch: FunctionComponent<SwitchProps> = ({
  name,
  label,
  error,
  checked,
  onchange,
  ...rest
}) => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles({ ...theme });
  return (
    <div className="form-check form-switch form-inline">
      <br />
      <input
        {...rest}
        name={name}
        id={name}
        //defaultChecked={checked}
        className={"form-check-input " + classes.styleSwitch}
        type="checkbox"
        value={checked != null ? checked.toString() : undefined}
        checked={checked}
        onChange={(input) => onchange && onchange(input, !checked)}
      />
      <label className="form-check-label ms-1 mt-1" htmlFor={name}>
        {label}
      </label>
      {error && <div className="text-danger">{"*" + error}</div>}
    </div>
  );
};
export default Switch;

// const Switch = ({name,label,error,checked,onchange,...rest}) => {

//     return (<div class="form-check form-switch">
//       <br />
//     <input
//       {...rest}
//       name={name}
//       id={name}
//       defaultChecked={checked}
//       className="form-check-input"
//       type="checkbox"
//       value={checked}
//       checked={checked}
//       onChange={(input)=>onchange(input,!checked)}
//     />
//     <label className="form-check-label" for={name}>
//      {label}
//     </label>
//     {error && <div className="text-danger">{"*"+error}</div>}
//   </div>);
// }

// export default Switch;
