import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
  LegacyRef,
} from "react";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";

import { pdfjs, Document, Page, Outline } from "react-pdf";
import useIntersectionObserver from "./../../hooks/useIntersectionObserver";

//old version to load from cloud. Not working in Electron appp.
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//We copy the file from the cloud https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js in the public folder.
// Attention with the version of pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

interface DocumentWrapperProps {
  url: string | undefined;
  zoom: { scale: number; page: number };
  onDocumentLoadSuccess?: (document: PDFDocumentProxy) => void;
  onScroll?: (
    currentPage: number,
    position?: { x?: number; y?: number }
  ) => void;
}

const observerConfig = {
  // How much of the page needs to be visible to consider page visible
  threshold: 0.3,
};

type PageWithObserver = {
  pageNumber: number;
  setPageVisibility: any;
};

type IBookmark = {
  id: string /* Required */;
  parentId?: string | undefined;
  label: string;
  items?: IBookmark[];
};

/*END OBSERVER*/

const DocumentWrapper: FunctionComponent<DocumentWrapperProps> = ({
  url,
  zoom,
  onDocumentLoadSuccess,
  onScroll,
}) => {
  type IState = {
    numPages: number;
    bookmarks: IBookmark;
  };
  const [state, setState] = useState<IState>({
    numPages: 0,
  } as IState);

  const handleDocumentLoad = async (pdf: PDFDocumentProxy) => {
    if (state.numPages === 0) {
      const bookmarks = await getBookmarks(pdf);
      setState({ ...state, numPages: pdf.numPages, bookmarks: bookmarks });
      onDocumentLoadSuccess && onDocumentLoadSuccess(pdf);
    }
  };

  const getBookmarks = async (pdf: PDFDocumentProxy) => {
    //Bookmarks..
    const bookmark_obj: IBookmark = { id: "A", label: "Root" };
    const _bookmarks = await pdf.getOutline();
    console.debug(_bookmarks);
    _bookmarks.forEach((bookmark, index) => {
      bookmark_obj.id = index.toString();
      bookmark_obj.label = bookmark.title;
      if (bookmark.items && bookmark.items.length > 0) {
        bookmark_obj.items = getBookmarksRecursive(bookmark.items);
      }
    });
    //setBookmarks(bookmark_obj);
    return bookmark_obj;
  };

  const getBookmarksRecursive = (bookmarks: any[]) => {
    const data_bookmark: IBookmark[] = [];
    bookmarks.forEach((bookmark, index) => {
      const data: IBookmark = {
        id: "",
        label: "",
      };
      data.id = index.toString();
      data.label = bookmark.title;
      if (bookmark.items && bookmark.items.length > 0) {
        data.items = getBookmarksRecursive(bookmark.items);
      }
      data_bookmark.push(data);
    });
    return data_bookmark;
  };

  const setPageActive = useCallback(
    (pageNumber, entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        onScroll &&
          onScroll(pageNumber, {
            x: entry.rootBounds?.x,
            y: entry.rootBounds?.y,
          });
      }
    },
    []
  );

  function PageWithObserver({
    pageNumber,
    setPageVisibility,
    ...otherProps
  }: any) {
    const [page, setPage] = useState();
    const onIntersectionChange = useCallback(
      ([entry]) => {
        setPageVisibility(pageNumber, entry);
      },
      [pageNumber, setPageVisibility]
    );

    useIntersectionObserver(page, observerConfig, onIntersectionChange);

    const Return_page = (
      <Page canvasRef={setPage} pageNumber={pageNumber} {...otherProps} />
    );

    return Return_page;
  }

  const myRef = React.createRef<HTMLDivElement>();

  const executeScroll = (page: number) => {
    const el1 = document.querySelector(
      `[data-page-number='${page.toString()}']`
    );
    console.debug(el1);
    el1?.scrollIntoView();
  };

  console.debug("render");
  return (
    <>
      <Document
        inputRef={myRef}
        renderMode="canvas"
        className={"text-center"}
        file={{
          url: url,
        }}
        // onLoadProgress={"() => <Backdrop show={true} />"}
        //options={options}
        loading={""} //empty
        onLoadError={(error: any) => console.debug("Inside Error", error)}
        onLoadSuccess={handleDocumentLoad}
      >
        {Array.from({ length: state.numPages }, (_, index) => (
          <PageWithObserver
            id={`page_${index + 1}`}
            href={`page_${index + 1}`}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={zoom.scale}
            renderAnnotationLayer={true}
            renderTextLayer={false}
            loading={""}
            // onRenderSuccess={() => {
            //   if (zoom.page == index + 1) {
            //     executeScroll(zoom.page);
            //   }
            // }}
            onLoadSuccess={() => {
              //When the page is loaded, do the scrolling into the page active.
              if (zoom.page == index + 1) {
                executeScroll(zoom.page);
              }
            }}
            setPageVisibility={setPageActive}
          />
        ))}
      </Document>
    </>
  );
};

export default React.memo(DocumentWrapper);
