import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../configureStore";
import { Moment } from "moment";
import { IMenuAnalytics } from "../../../pages/analytics";

// Define a type for the slice state
interface StructState {
  menu?: IMenuAnalytics;
}

// Define the initial state using that type
const initialState: StructState = {
    menu: "default",
};

export const analyticsSlice = createSlice({
  name: "analytics",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveMenu(state, action: PayloadAction<IMenuAnalytics>) {
      state.menu = action.payload;
    },
  },
});

export const {
    saveMenu,  
} = analyticsSlice.actions;

export const getAnalyticsState = (state: RootState) => state.analytics;

export default analyticsSlice.reducer;
