import { FunctionComponent, useContext } from "react";
import { Row, Card, Col } from "react-bootstrap";
import CardInfo from "../../common/card-info";
import {
  MdTrendingUp,
  MdPendingActions,
  MdCheckBox,
  MdError,
  MdWarning,
} from "react-icons/md";
import { Doughnut, Bar } from "react-chartjs-2";
import { ChartOptions, ChartData } from "chart.js";
import ThemeContext from "../../../context/theme/themeContext";
interface NotificationStatsProps {
  data: IStatsNotification;
}

export type IStatsNotification = {
  warning: number;
  trend: number;
  complete: number;
  pending: number;
  anomalies: number;
  bar_data: IBarData;
};

type IBarData = {
  labels: string[];
  values: number[];
};
const notificationType_options: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1,
  resizeDelay: 0,
  plugins: {
    title: {
      display: true,
      text: "Total number of notifications",
    },
  },
  spacing: 0,
  cutout: 50,
};

const notificationType_plugins = [
  {
    id: "1",
    afterDraw: function (chart: any) {
      //: Chart
      //if (chart.data.datasets[0].data.length < 1) {
      if (chart.data.datasets[0].data.every((item: any) => item === 0)) {
        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        //ctx.font = "30px Arial";
        ctx.font = "30px";
        ctx.fillText("No data to display", width / 2, height / 2);
        ctx.restore();
      }
    },
  },
];

const NotificationStats: FunctionComponent<NotificationStatsProps> = ({
  data,
}) => {
  const { theme } = useContext(ThemeContext);

  const cards: any[] = [
    {
      title: "Viewed",
      value: data.complete,
      icon: <MdCheckBox size={"3rem"} color={theme.colors?.success} />,
    },
    {
      title: "Pending",
      value: data.pending,
      icon: <MdPendingActions size={"3rem"} color={theme.colors?.warning} />,
    },
    {
      title: "Marked as anomalies",
      value: data.anomalies,
      icon: <MdError size={"3rem"} color={theme.colors?.danger} />,
    },
  ];

  const notificationType_data: ChartData<"doughnut"> = {
    labels: ["Warning", "Trend"],
    datasets: [
      {
        label: "My First Dataset",
        data: [data.warning, 0],
        backgroundColor: ["rgb(255, 205, 86)", "rgb(255, 99, 132)"],
        offset: 5,
        hoverOffset: 5,
        spacing: 5,
        weight: 10,
      },
      {
        label: "My First Dataset2",
        data: [0, data.trend],
        backgroundColor: [
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          // "rgb(54, 162, 235)",
        ],
        offset: 0,
        hoverOffset: 0,
        //clip: {left: 5, top: 100, right: 0, bottom: 0}
      },
    ],
  };

  const options_struct_count: ChartOptions<"bar"> = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Structs with more notifications",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          callback(value) {
            const label = this.getLabelForValue(parseInt(value.toString()));
            if (label.length >= 20) {
              return label.substring(0, 25) + "...";
            } else {
              return label;
            }
          },
        },
      },
      x: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const colors: string[] = [
    "#636EFA",
    "#EF553B",
    "#00CC96",
    "#AB63FA",
    "#FFA15A",
    "#19D3F3",
    "#FF6692",
    "#B6E880",
    "#FF97FF",
    "#FECB52",
  ];

  const data_struct_count: ChartData<"bar"> = {
    labels: data.bar_data?.labels,
    datasets: [
      {
        label: "",
        data: data.bar_data?.values,

        backgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
        ],
        //borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Row>
      <Col
        style={{
          minHeight: "20rem",
        }}
        xs={6}
        sm={12}
      >
        {cards &&
          cards.length > 0 &&
          cards.map((item, index) => (
            <div key={index} className="mb-1">
              <CardInfo
                title={item.title}
                value={item.value}
                icon={item.icon}
              />
            </div>
          ))}
      </Col>
      <Col xs={6} sm={12}>
        <Card className="p-1">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "20rem", maxHeight: "30rem" }}
          >
            <Doughnut
              data={notificationType_data}
              options={notificationType_options}
              plugins={notificationType_plugins}
            />
          </div>
        </Card>
      </Col>
      <Col>
        <Card
          className="p-3 mt-1"
          style={{ minHeight: 350, maxHeight: "30vh" }}
        >
          <Bar
            height={"90vw"}
            data={data_struct_count}
            options={options_struct_count}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default NotificationStats;
