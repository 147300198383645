import { FunctionComponent, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { createUseStyles } from "react-jss";
import { ITheme } from "../../../context/theme/theme";
import ThemeContext from "../../../context/theme/themeContext";
import { Button,ButtonProps } from "react-bootstrap";
interface BackButtonProps extends ButtonProps {
  label: string;
  icon?: IconProp | IconDefinition;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = createUseStyles({
  closeButton: (theme: ITheme) => ({
    color: "#fff",
    backgroundColor: theme.secondary,
    borderColor: theme.secondary,
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.secondary,
      borderColor: theme.secondary,
    },
    "&:focus": {
      outline: "none !important",
      boxShadow: "none",
    },
    "&:disabled": {
      outline: "none !important",
      backgroundColor: theme.secondary,
      boxShadow: "none",
      borderColor: theme.secondary,
    },
  }),
});

const CloseButton: FunctionComponent<BackButtonProps> = ({
  label,
  icon,
  onClick,
  ...rest
}) => {
  const { theme } = useContext(ThemeContext);
  const styles = useStyles({ ...theme });

  return (
    <Button onClick={onClick} className={styles.closeButton} {...rest}>
      <FontAwesomeIcon icon={icon ? icon : faTimes} className="mr-1" /> {label}
    </Button>
  );
};

export default CloseButton;
