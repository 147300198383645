import { RequestDemo } from "../demo/request-service-demo";
import storage from "./base/storage";

class RequestService {
  /**
   *
   */
  req: XMLHttpRequest;
  constructor() {
    this.req = new XMLHttpRequest();
  }

  async getPromise(url: string) {
    return new Promise<string>((resolve, reject) => {
      const jwt = storage.getToken();
      this.req = new XMLHttpRequest();
      this.req.responseType = "blob";
      this.req.open("GET", url, true); //true means request will be async
      this.req.setRequestHeader("Authorization", "JWT " + jwt);
      this.req.onreadystatechange = (aEvt) => {
        if (this.req.readyState === this.req.DONE) {
          if (this.req.status === 200) {
            // this.response is a Blob, because we set responseType above
            var data_url = window.URL.createObjectURL(this.req.response);
            resolve(data_url);
            //window.open(data_url, "_blank");
          } else {
            reject(this.req.statusText);
            //console.error("no pdf :(");
          }
          return;
        }
        //reject(this.req.statusText);
      };
      this.req.send();
    });
  }

  getUrl = async (url: string): Promise<string> => {
    const blob = await fetch(url)
      .then((r) => {
        if (r.status == 200) {
          return r.blob();
        }

        if (r.status == 404) {
          throw new Error("Not found");
        }

        throw new Error("An error occurred trying get the PDF");
      })
      .catch((err) => {
        throw new Error(err);
      });
    const url_new = window.URL.createObjectURL(blob);
    return url_new;
  };
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo" ? new RequestDemo() : new RequestService();

export const request_service = instance;
