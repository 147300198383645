import { ResponseWrapper } from "../../models/reponse";
import {
  INotificationTemperature,
  ISaveTemperatureNotification,
} from "../../services/notifications/notification-temperature-service";
import { INotificationValve } from "../../services/notifications/notification-valve-service";
import { notification_data } from "./data";

export class NotificationValveDemo {
  data: INotificationValve[];
  constructor() {
    this.data = notification_data.getNotificationValve();
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<INotificationValve[]>> => {
    return new Promise<any>((resolve, reject) => {
      const data: ResponseWrapper<INotificationValve[]> = {
        status: 200,
        version: "demo",
        response: this.data,
      } as ResponseWrapper<INotificationValve[]>;

      resolve(data);
    });
  };

  updateFeedback(
    data: ISaveTemperatureNotification
  ): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The update feedback is not allowed on demo" },
          },
        },
      });
    });
  }
}
