import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import type {
  EventObject,
  ExternalEventTypes,
  Options,
  TZDate,
} from "@toast-ui/calendar";
import { ButtonGroup, Row, Col, Container, Button } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import DefaultButton from "../../../common/buttons/default-button";
import { IScheduledEvent } from "../scheduled/maintenance-scheduled-calendar";

import "./calendar.css";

import ThemeContext from "../../../../context/theme/themeContext";
//SEE
//https://codesandbox.io/s/f84s1?file=/src/Story.js:1270-1291

function _getFormattedTime(time: any) {
  const date = new Date(time);
  var hour = "" + date.getHours();
  var minuts = "" + date.getMinutes();

  if (hour.length < 2) hour = "0" + hour;
  if (minuts.length < 2) minuts = "0" + minuts;

  return `${hour}:${minuts}`;
}

function _getTimeTemplate(schedule: EventObject, isAllDay: any) {
  var html = [];
  html.push("<div style=background-color:" + schedule.backgroundColor + ">");
  if (isAllDay) {
    html.push("<strong>" + _getFormattedTime(schedule.start) + "</strong> ");
  }
  if (!isAllDay) {
    html.push("<strong>" + _getFormattedTime(schedule.start) + "</strong> ");
  }
  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"></span>');
    html.push(" Private");
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
    } else if (schedule.attendees?.length) {
      html.push('<span class="calendar-font-icon ic-user-b"></span>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"></span>');
    }
    html.push(" " + schedule.title);
  }
  html.push("</div>");
  return html.join("");
}

interface ToastCalendarProps {
  events: IScheduledEvent[] | undefined;
  onOpenEvent?: (event: IScheduledEvent) => void;
}

const ToastCalendar: FunctionComponent<ToastCalendarProps> = ({
  events,
  onOpenEvent,
}) => {
  const calendarRef = React.createRef<Calendar>();
  const [dateRange, setDateRange] = useState<string>();
  const { theme } = useContext(ThemeContext);
  const [calendarType, setCalendarType] = useState<"month" | "week" | "day">(
    "month"
  );
  const colors = {
    completed: "#A5D6A7",
    expired: "#faa19b",
    scheduled: "#ced4da",
  };

  const getCalInstance = useCallback(
    () => calendarRef.current?.getInstance?.(),
    []
  );

  useEffect(() => {
    handleToday();
  }, []);

  const ConnectToInsideEvents = async (event: EventObject) => {
    const doc = document.getElementById("eventOpen");
    //Close the body detail mannually
    const popUp = document.getElementsByClassName(
      "toastui-calendar-event-detail-popup-slot"
    );

    doc?.addEventListener("click", () => {
      //console.debug("clicked");
      //console.debug(event);
      onOpenEvent && onOpenEvent(event.raw as unknown as IScheduledEvent);
      popUp[0].innerHTML = "";
    });
  };

  const onClickEvent: ExternalEventTypes["clickEvent"] = (res) => {
    // console.group("onClickEvent");
    // console.debug("MouseEvent : ", res.nativeEvent);
    // console.table("Event Info : ", res.event);
    // console.groupEnd();

    //The reason of calling the connectEvents waiting 1 seconds is because
    // the form detail is not available in the web imidialy yet, so when i do
    //  var doc = document.getElementById("eventOpen"); is undefined.
    //Waiting a second the element is founded.
    const myTimeout = setTimeout(() => {
      ConnectToInsideEvents(res.event);
    }, 500);
  };
  const determinateState = (state: any) => {
    if (state == null) {
      return "3"; //Scheduled
    } else if (state == true) {
      return "1"; //Completed
    } else if (state == false) {
      return "2"; //Expired
    }
  };

  const calendars: Options["calendars"] = [
    {
      id: "1",
      name: "Completed",
      color: "#ffffff",
      backgroundColor: colors.completed,
      dragBackgroundColor: colors.completed,
      borderColor: colors.completed,
    },
    {
      id: "2",
      name: "Expired",
      color: "#ffffff",
      backgroundColor: colors.expired,
      dragBackgroundColor: colors.expired,
      borderColor: colors.expired,
    },
    {
      id: "3",
      name: "Scheduled",
      color: "#ffffff",
      backgroundColor: colors.scheduled,
      dragBackgroundColor: colors.scheduled,
      borderColor: colors.scheduled,
    },
  ];

  const gridOptions: Options["gridSelection"] = {
    enableClick: false,
    enableDblClick: false,
  };

  const templates: Options["template"] = {
    time: function (event: EventObject) {
      return _getTimeTemplate(event, true);
    },
    allday: (event: EventObject) => {
      return _getTimeTemplate(event, false);
    },
    popupDetailAttendees: function (event: EventObject) {
      console.debug(event.raw.user);
      if (event.raw.user) {
        const { user } = event.raw;
        return user?.first_name + " " + user?.last_name;
      } else {
        return "N/A";
      }
    },
    popupIsAllday: function () {
      return "false";
    },
    popupStateBusy: function () {
      return "frasd";
    },
    popupDetailState: function (event: EventObject) {
      return ""; //needs to be blank the icon was remove with css in "calendar.css"
    },
    popupDetailDate: (event: EventObject) => {
      console.debug(event);
      //return ""
      if (event.start instanceof Date) {
        return moment(event.start).format("MMMM Do YYYY");
      }
      if (typeof event.start === "string") {
        return event.start;
      } else {
        return moment(event.start.toDate()).format("MMMM Do YYYY HH:mm");
      }
    },
    popupDetailBody: function (event: EventObject) {
      const data = event.raw as unknown as IScheduledEvent;
      return ReactDOMServer.renderToString(
        <>
          <ul>
            <li> {data.eventData.operations.length} Operations</li>
          </ul>
          <p></p>
          <div className="m-1 p-1 toastui-calendar-popup-section toastui-calendar-section-button">
            {/* <DefaultButton label="View" id="eventOpen" color="rgb(0,78,125)" /> */}
            {/* <CustomButton title="View" id="eventOpen" color="rgb(0,78,125)">
              View
            </CustomButton> */}
            <Button
              id="eventOpen"
              style={{
                color: "#fff",
                backgroundColor: theme.primary,
                borderColor: theme.primary,
                outlineColor: theme.primary,
              }}
            >
              View
            </Button>
          </div>
        </>
      );
    },
  };

  const events2: EventObject[] = [];
  if (events && events.length > 0) {
    events.forEach((element, index) => {
      events2.push({
        id: index.toString(),
        calendarId: determinateState(element.isFinish),
        calendars: calendars,
        category: "time",
        isVisible: true,
        title: element.eventData.title,
        isAllDay: false,
        isReadOnly: true,
        state: undefined,
        body: "Test",
        start: element.date,
        end: element.date,
        raw: { ...element },
        onClickEvent: function () {
          console.debug(element);
        },
      });
    });
  }

  const handleNext = () => {
    const calendarInstance = calendarRef.current?.getInstance();
    calendarInstance?.next();
    const rangeStart = calendarInstance?.getDateRangeStart();
    const rangeEnd = calendarInstance?.getDateRangeEnd();
    //setDateRange(`${rangeStart?.toUTCString()} - ${rangeEnd?.toUTCString()}`);
    const calDate = calendarInstance?.getDate();
    const formate_date = moment(calDate?.toDate()).format("MMMM  YYYY");
    setDateRange(formate_date);
  };
  const handlePrev = () => {
    const calendarInstance = calendarRef.current?.getInstance();
    calendarInstance?.prev();
    const rangeStart = calendarInstance?.getDateRangeStart();
    const rangeEnd = calendarInstance?.getDateRangeEnd();
    //setDateRange(`${rangeStart?.toUTCString()} - ${rangeEnd?.toUTCString()}`);
    const calDate = calendarInstance?.getDate();
    const formate_date = moment(calDate?.toDate()).format("MMMM  YYYY");
    setDateRange(formate_date);
  };
  const handleToday = () => {
    const calendarInstance = calendarRef.current?.getInstance();
    calendarInstance?.today();

    const calDate = calendarInstance?.getDate();
    const formate_date = moment(calDate?.toDate()).format("MMMM  YYYY");
    setDateRange(formate_date);
  };

  //https://github.com/nhn/tui.calendar/blob/main/docs/en/apis/theme.md
  const theme_ = {
    common: {
      gridSelection: {
        backgroundColor: "red",
      },
      today: {
        color: "white",
      },
    },
    week: {
      nowIndicatorLabel: {
        color: "#00FF00",
      },
    },
    month: {
      dayName: {
        borderLeft: "1px solid #e5e5e5",
      },
    },
  };
  return (
    <>
      <Container>
        <Row className="mb-2 justify-content-center">
          <Col className="text-md-left">
            <ButtonGroup aria-label="Basic example">
              <DefaultButton
                label="Today"
                outline={true}
                onClick={handleToday}
              />
              <DefaultButton label="Prev" outline={true} onClick={handlePrev} />
              <DefaultButton label="Next" outline={true} onClick={handleNext} />
            </ButtonGroup>
          </Col>
          <Col className="text-center">
            <b style={{ fontSize: "1.2rem" }}>{dateRange}</b>
          </Col>
          <Col className="pull-right"></Col>
        </Row>
      </Container>
      <Calendar
        usageStatistics={false}
        ref={calendarRef}
        isReadOnly={true}
        //scheduleView
        //taskView
        month={{ startDayOfWeek: 1 }}
        events={events2}
        height="50rem"
        view={calendarType}
        week={{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: true,
          taskView: false,
          showNowIndicator: false,
        }}
        //theme={theme_}
        useDetailPopup={true}
        useFormPopup={false}
        calendars={calendars}
        template={templates}
        gridSelection={gridOptions}
        onClickEvent={onClickEvent}
      />
    </>
  );
};

export default ToastCalendar;
