import { Layout, PlotData } from "plotly.js";
import { FunctionComponent } from "react";
import Plot from "react-plotly.js";
import { config, annotation_empty } from "../config";
import { IPidResponse } from "../../../../services/statistic/statistic-plot-pid";
import {
  createPeakNumbers,
  createPeaksTime,
  createStationary,
  createTransientTraces,
} from "./traces-pid";

export type IPidType = "transient" | "stationary" | "peakNumber" | "peakTime";
interface PlotPidSingleProps {
  title?: string;
  signal: IPidResponse;
  type?: IPidType;
}

const PlotPidSingle: FunctionComponent<PlotPidSingleProps> = ({
  signal,
  type,
  title,
}) => {
  if (!type) return null;
  let traces = undefined;
  let uof: string | undefined = "";
  const { starts, stationary, transient } = signal;
  if (type == "transient") {
    traces = createTransientTraces(starts, transient);
    uof = signal.transient.unit_of_measurement;
  } else if (type == "stationary") {
    traces = createStationary(starts, stationary);
    uof = signal.stationary.default?.unit_of_measurement;
  } else if (type == "peakNumber") {
    traces = stationary?.peaks_number
      ? createPeakNumbers(starts, stationary?.peaks_number)
      : [];
    //Peak number dont have
    uof = "";
  } else if (type == "peakTime") {
    traces = stationary?.peaks_time
      ? createPeaksTime(starts, stationary?.peaks_time)
      : [];    
    uof = stationary.peaks_time?.unit_of_measurement;
  }

  if (!traces) return null;

  const layout: Partial<Layout> = {
    //title: signal.description,
    barmode: "group",
    title: title,
    hoverlabel: { bgcolor: "#FFF" },
    height: 300,
    // autosize: true,
    annotations: traces.length == 0 ? annotation_empty : undefined,
    margin: {
      r: 50,
      l: 50,
      t: 80,
      b: 50,
    },
    //showlegend: signal.data[0].durations ? true : false,
    xaxis: {
      //   range: [
      //     signal.starts && signal.starts[0],
      //     signal.starts && signal.starts.slice(-1)[0],
      //   ],
      //type: "date",
      //visible: signal.data[0].durations ? true : false,
      showgrid: true,
      //tickmode:"linear",
      //tickformat: '%e %b, %y %H:%M',
      //tickformat: "%e %b, %y",
      //tick0:signal.time[0],
      //dtick:"M1" //30 * 24 * 60 * 60 * 1000 // milliseconds
      // rangeselector: selectorOptions, TODO
    },
    yaxis: {
      ticksuffix: uof,
      //showtickprefix : 'last'
      showgrid: true,
      automargin: true,
    },
  };

  return (
    <Plot
      data={traces}
      style={{ margin: 0, backgroundColor: "none" }}
      config={config}
      useResizeHandler={true}
      layout={layout}
      divId={title}
    />
  );
};

export default PlotPidSingle;
