import { IBadge } from "../../services/badge-service";
import data from "./badge-data.json";
class BadgeDemoData {
  private badge_data: IBadge;
  constructor() {
    this.badge_data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(data.response);
    const obj: IBadge = JSON.parse(myJSON);
    return obj;
  };
  getData = () => {
    return this.badge_data;
  };
}

export const badge_demo_data = new BadgeDemoData();