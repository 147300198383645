import Http, { IHttp } from "../../base/http-services";
import { BaseService } from "../../base/base-service";
import { ResponseWrapper } from "../../../models/reponse";
import * as alertService from "../../../utils/alertService";
import { MaintenanceUsageBasedCompleteDemo } from "../../../demo/maintenance/usage-based/complete-service-demo";
import storage from "../../base/storage";

const endpoint_result = "maintenance/usage-based/complete";
class MaintenanceUsageBasedCompleteService {
  private http = Http;

  save(
    data: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    var params = this.generateParams(query_params);
    return this.getPromise(
      this.http.post(endpoint_result + "/" + "?" + params, data)
    );
  }

  protected getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<any>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  protected generateParams(query_params: Record<string, any> = {}): string {
    var params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  checkErrors(error: any) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error.response &&
      error.response.data &&
      error.response.status !== 403
    ) {
      var message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new MaintenanceUsageBasedCompleteDemo()
    : new MaintenanceUsageBasedCompleteService();

export const usage_based_complete_service = instance;
