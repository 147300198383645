import { X509Certificate } from "crypto";
import { update } from "lodash";
import { ResponseWrapper } from "../../../models/reponse";
import { IOperation } from "../../../services/maintenance/scheduled/operation-service";
import * as alertService from "../../../utils/alertService";
import { operation_Data } from "./data";

export class MaintenanceScheduledOperationDemo {
  //Operations send always a post request, if the operation already exists automatic
  // update.
  data: IOperation[];
  constructor() {
    this.data = operation_Data.getData();
  }
  save(
    data: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IOperation>> {
    console.debug(data);
    return new Promise<any>((resolve, reject) => {
      const operation = this.data.find(
        (x) => x.id == data.scheduled_operation_id
      );
      if (operation) {
        operation.completed = data.completed;
        operation_Data.update(operation);
      }

      const response: ResponseWrapper<IOperation> = {
        status: 200,
        version: "demo",
        response: operation,
      } as ResponseWrapper<IOperation>;

      resolve(response);
    });
  }

  getOperations(
    id: number,
    date: string,
    hour: string,
    timezone: string,
  ): Promise<ResponseWrapper<IOperation[]>> {
    const params = "date=" + date + "&hour=" + hour + "&timezone=" + timezone;
    return new Promise<any>((resolve, reject) => {
      const operations = operation_Data.getData();
      //If the maintenance is completed then return all operations 'completed = true'.
      if (operations.length > 0) {
        const result = operations[0].scheduled_maintenance_id.results.find(
          (x) => x.programmed_date == date && x.programmed_hour == hour
        );

        operations.forEach((x) => {
          x.completed = result ? true : false;
        });
      }

      const response: ResponseWrapper<IOperation[]> = {
        status: 200,
        version: "demo",
        response: operations,
      } as ResponseWrapper<IOperation[]>;

      resolve(response);
    });
  }

  delete(dataId: number): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      resolve("data");
    });
  }

  checkErrors(error: any) {
    console.debug("error");
  }
}
