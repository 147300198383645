import React, { useEffect, useState } from "react";
import Backdrop from "../../common/backdrop/backdrop";
import Datetime from "react-datetime";
import { MdSync, MdCloudDownload } from "react-icons/md";
import {
  isLessThatTodayAndDate,
  isLessThatTodayAndIsAfterDate,
} from "../../../utils/date-operations";
import moment, { Moment } from "moment";

import { Alert, Row, Col, Modal, Container } from "react-bootstrap";
import { request_service } from "../../../services/requestService";
import PdfViewer from "../../pdf/pdfViewer";
import { file_saver } from "../../../services/fileSaver/file-saver";
import CustomButton from "../../common/buttons/custom-button";
import {
  useGenerateReport,
  useGetReportMaintenance,
} from "queries/maintenance/report-maintenance.hook";
import { Tour } from "tour";
import { report_maintenance_steps } from "tour/maintenance-steps";
import { Routes } from "Routes";
import alertService from "utils/alertService";

type Props = {};

const ReportMaintenance: React.FunctionComponent<Props> = () => {
  const [startDate, setStartDate] = useState<Moment>(
    moment()
      .subtract(7, "d")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const [endDate, setEndDate] = useState<Moment>(moment());

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>();

  const generateReport = useGenerateReport();
  //const reports = useGetReportMaintenance(); //TODO
  const generate = async () => {
    try {
      const start_date_string = startDate.format("YYYY-MM-DD HH:mm");
      const end_date_string = endDate.format("YYYY-MM-DD HH:mm");

      const report_reponse = await generateReport.mutateAsync({
        start_date: start_date_string,
        end_date: end_date_string,
      });

      const { response } = report_reponse;

      return response;
    } catch (error: any) {}
  };

  const handleOpenPDF = async () => {
    try {
      const response = await generate();

      if (response?.url) {
        setIsBusy(true);
        const result = await request_service.getUrl(response.url);
        setPdfUrl(result);
        setShowModal(true);
        setIsBusy(false);
      }
    } catch (error) {
      alertService.errorMessage("An error occurred trying to generate the PDF");
      setIsBusy(false);
    }
  };

  const handleDownloadPdf = async (path: string, name: string) => {
    const response = await generate();
    if (response?.url) {
      try {
        setIsBusy(true);
        file_saver
          .SaveFromUrl(response.url, "Report_" + name + ".pdf")
          .then(() => {})
          .catch((error: any) => {
            alertService.errorMessage(error);
          })
          .finally(() => {
            setIsBusy(false);
          });
      } catch (error: any) {
        setIsBusy(false);
      }
    }
  };

  return (
    <>
      <Tour steps={report_maintenance_steps} redirectTo={Routes.graph} />
      <Container fluid>
        <Backdrop show={isBusy || generateReport.isLoading} />
        <h3>Generate report</h3>
        <hr></hr>

        <Row>
          <Col lg={2} md={3} sm={6} xs={6}>
            <label>From:</label>
            <Datetime
              className="tour_from"
              isValidDate={(current, selected) =>
                isLessThatTodayAndDate(current, endDate)
              }
              initialValue={startDate}
              inputProps={{ placeholder: "From" }}
              timeFormat="HH:mm"
              onChange={(date) => setStartDate(moment(date))}
            />
          </Col>

          <Col lg={2} md={3} sm={6} xs={6}>
            <label>To:</label>
            <Datetime
              className="tour_to"
              isValidDate={(current, selected) =>
                isLessThatTodayAndIsAfterDate(current, startDate)
              }
              initialValue={endDate}
              timeFormat="HH:mm"
              inputProps={{ placeholder: "To" }}
              onChange={(date) => setEndDate(moment(date))}
            />
          </Col>

          <Col lg={4} md={4} sm={12}>
            <label></label>
            <br></br>
            <span className="me-1">
              <CustomButton
                className="tour_generate"
                color="#6c757d"
                outline={true}
                onClick={async () => await handleOpenPDF()}
              >
                Generate <MdSync size={25}></MdSync>
              </CustomButton>
            </span>
            <CustomButton
              className="tour_download"
              outline={true}
              onClick={async () =>
                await handleDownloadPdf(
                  pdfUrl ? pdfUrl : "",
                  moment().format("L")
                )
              }
            >
              <MdCloudDownload size={25}></MdCloudDownload>
            </CustomButton>
          </Col>
        </Row>
        <Row className="ms-1">
          <Col>
            <Alert key={"light"} variant={"light"}>
              The intervals correspond to the date of completion of the
              maintenances.
            </Alert>
          </Col>
        </Row>
      </Container>

      <Modal
        scrollable={false}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ backgroundColor: "transparent" }}>
          <PdfViewer url={pdfUrl} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportMaintenance;
