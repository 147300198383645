import { Step } from "react-joyride";

export const report_steps: Step[] = [
  {
    title: "Report",
    placement: "center",
    target: "body",
    content: (
      <div>
        <p>
          In the report section, we can generate a report of the choosen
          structures on the range selected.
        </p>
      </div>
    ),
  },
  {
    title: "Structures",
    content: (
      <div>
        <p>Here you can choose the structures that you desire in the report.</p>
        <p>You can leave in blank for generate a report for all structures.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_structures",
  },
  {
    title: "From",
    content: (
      <div>
        <p>Select beginning date of the range for the report.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_from",
  },
  {
    title: "To",
    content: (
      <div>
        <p>Select end date of the range for the report.</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".tour_to",
  },
  {
    title: "Generate",
    content: (
      <div>
        <p>
          Click on the "Generate" button to generate the report in PDF format.
        </p>
      </div>
    ),

    hideFooter: false,
    placement: "top",
    spotlightClicks: true,
    target: ".tour_generate",
  },
  {
    title: "Report list",
    content: (
      <div>
        <p>
          Each item in the list represents a single report generated.
        </p>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    placement: "top",
    target: ".tour_list",
  },
  {
    title: "Actions",
    content: (
      <div>
        <p>You can due the following actions:</p>
        <ul>
          <li>Open the report</li>
          <li>Download the report</li>
          <li>Delete the report</li>
        </ul>
      </div>
    ),

    hideFooter: false,
    placement: "top",
    spotlightClicks: false,
    target: ".tour_action",
  },
];
