import { MaintenanceScheduledDemo } from "../../../demo/maintenance/scheduled/scheduled-service-demo";
import { BaseService } from "../../base/base-service";
import Http, { IHttp } from "../../base/http-services";
import storage from "../../base/storage";

export interface IMaintenanceScheduled {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  description: string;
  trigger_hour: string;
  trigger_day: number[];
  start_date: string;
  end_date: string;
  enabled: boolean;
  results: ScheduledCompleted[];
  operations: ScheduledOperation[];
}
interface ScheduledOperation {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  scheduled_maintenance_id: number;
  description: string;
  order: number;
}
interface ScheduledCompleted {
  programmed_date: string;
  programmed_hour: string;
  comment?: any;
  updated_at: Date;
  user: User;
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
}

const endpoint = "maintenance/scheduled";
class MaintenanceScheduledService extends BaseService<IMaintenanceScheduled> {
  constructor() {
    super(endpoint, Http);    
  }
}

const user = storage.getUser();

const instance =
  user && user.token_type == "demo"
    ? new MaintenanceScheduledDemo()
    : new MaintenanceScheduledService();

export const scheduled_service = instance;
