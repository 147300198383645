import { useFormikContext } from "formik";
import { FunctionComponent, useContext } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";
import get from "lodash/get";
import { MdHelpOutline } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import ThemeContext from "../../context/theme/themeContext";
interface FormTextAreaProps {
  name: string;
  label: string;
  placeholder: string;
  helpText?: string;
  rows?: number;
  className?: string;
}

const FormTextArea: FunctionComponent<FormTextAreaProps> = ({
  rows,
  name,
  label,
  placeholder,
  helpText,
  className,
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const { theme } = useContext(ThemeContext);
  const error = get(errors, name);
  const value = get(values, name);
  return (
    <>
      <ReactTooltip
        place="right"
        type="light"
        html={true}
        border={false}
        backgroundColor={theme.secondary}
        textColor={"white"}
        arrowColor={theme.secondary}
        effect="solid"
      />
      <Form.Group
        className={className + " mb-3"}
        controlId="exampleForm.ControlTextarea1"
      >
        <Form.Label>
          {label + " "}
          {helpText && (
            <a data-tip={helpText}>
              <MdHelpOutline style={{ fontSize: "15px" }}></MdHelpOutline>
            </a>
          )}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={rows}
          placeholder={placeholder}
          value={value ? value : ""}
          isInvalid={error ? true : false}
          onChange={({ currentTarget }: any) => {
            setFieldValue(name, currentTarget.value);
          }}
        />
        <Form.Text className="text-danger">{error && error}</Form.Text>
      </Form.Group>
    </>
  );
};

export default FormTextArea;
