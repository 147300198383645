import { ResponseWrapper } from "../../models/reponse";
import { IResponse } from "../../services/plot/plot-struct-service";
import { plot_demo_data } from "./data";

export class PlotStructDemo {
  getPlot = (
    struct_id: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IResponse>> => {
    return new Promise<any>((resolve, reject) => {
      const plot_struct = plot_demo_data.getData();
      const response: ResponseWrapper<IResponse> = {
        status: 200,
        version: "demo",
        response: plot_struct,
      } as ResponseWrapper<IResponse>;
      resolve(response);
    });
  };
}
