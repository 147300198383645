import React from "react";
import { Permission } from "./permission";
import PermissionContext from "./permissionContext";

type Props = {
  permissions: Permission[] | undefined;
};

type PermissionCache = {
  [key: string]: boolean;
};

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({
  permissions,
  children,
}) => {
  const cache: PermissionCache = {};

  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = (permission: string) => {
    //console.debug(permission)
    //console.debug(permissions)
    //console.debug(permissions.includes(permission))
    if (permissions == undefined) return false;
    return permissions.includes(permission);
  };

  const setPermissions = (new_permissions: Permission[] | undefined) =>
    (permissions = new_permissions);

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider value={{ isAllowedTo,setPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
