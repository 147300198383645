import { ResponseWrapper } from "../../models/reponse";
import { BaseService } from "../base/base-service";
import Http from "../base/http-services";

const endpoint = "temperature";

class TemperatureService extends BaseService<any> {
  constructor() {
    super(endpoint, Http);
  }
}

export const temperature_service = new TemperatureService();
