import { FunctionComponent, useEffect, useState } from "react";
import { Card, Row, Badge } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { MdRefresh, MdModeEditOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Restricted from "../../../../context/permission/restricted";
import { permissions } from "../../../../services/permissions/permissions";
import {
  IStruct,
  struct_service,
} from "../../../../services/struct/struct-service";
import Backdrop from "../../../common/backdrop/backdrop";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import { Routes } from "../../../../Routes";
import Table from "../../../table/table";
import withLayout from "../../../../hoc/withLayout";
import CustomButton from "../../../common/buttons/custom-button";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearch,
  saveSearch,
} from "../../../../store/reducers/struct.management";
interface StructListProps {}

const ListStruct: FunctionComponent<StructListProps> = ({}) => {
  const [structs, setStructs] = useState<IStruct[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const history = useHistory();
  //Redux
  const dispatch = useDispatch();
  const search = useSelector(getSearch);
  const columns: TableColumn<IStruct>[] = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : ""),
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : ""),
      sortable: true,
    },
    {
      name: "Model type",
      cell: (row) => (
        <Badge bg="secondary">
          {row.type_id?.description ? row.type_id?.description : "N/A"}
        </Badge>
      ),
    },
    {
      name: "Processing",
      cell: (row) => <Badge bg="dark">{row.processing}</Badge>,
    },

    {
      cell: (row) => (
        <>
          <Restricted to={permissions.main.usage_maintenances.change}>
            <CustomButton
              color="grey"
              outline={true}
              size="sm"
              onClick={() =>
                history.push({
                  pathname: Routes.management_struct + "/" + row.id,
                  state: row,
                })
              }
            >
              <MdModeEditOutline />
            </CustomButton>
          </Restricted>
          <span> &nbsp; </span>
        </>
      ),
      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  const loadStruct = async () => {
    try {
      setIsBusy(true);
      const structs_db = await struct_service.get();

      const { response } = structs_db;
      setStructs(response ? response : []);
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      struct_service.checkErrors(error);
    }
  };

  useEffect(() => {
    loadStruct();
  }, []);

  return (
    <>
      <Restricted
        to={permissions.main.usage_maintenances.view}
        showFallback={true}
      >
        <Backdrop show={isBusy} />

        <Card className="p-3">
          <h4>
            <GoBackNavigation url="/management" />
            Structures
          </h4>
          <hr />
          <Row>
            <div className="d-flex justify-content-start">
              {/* <Restricted to={permissions.main.usage_maintenances.add}>
                <Link
                  to={{
                    pathname: Routes.management_struct_new,
                  }}
                  className="btn mr-1 btn-sm"
                >
                  <MdAddCircle /> New
                </Link>
              </Restricted> */}
              <a
                onClick={async () => await loadStruct()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </div>
          </Row>

          <Table
            columns={columns}
            data={structs}
            pagination={true}
            searchText={search}
            onSearchChange={(text: string) => {
              dispatch(saveSearch(text));
            }}
          />
        </Card>
      </Restricted>
    </>
  );
};

export default ListStruct;
