import { copyFile } from "fs/promises";
import React, { useState } from "react";
import { AnyObject } from "yup/lib/types";

type GenericObject<T> = {
  [key: string]: T;
};

interface Action<T> {
  (item: T): void;
}

interface Func<T, TResult> {
  (item: T): TResult;
}

const useApi = <U extends (...args: any) => any>(apiFunc: U) => {
  const [data, setData] = useState<any>([]); //Data will be ReturnType<U> ??
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  //const request = async (...args: any) => {
  const request = async (...args: Parameters<typeof apiFunc>) => {
    setLoading(true);

    try {
      const response = await apiFunc(...args);      
      setData(response.response);
      setLoading(false);
      return response;
    } catch (error: any) {
      setLoading(false);
      throw error;    
    }

    setLoading(false);
  };

  async function requestWithFunc<U extends (...args: any) => any>(
    func: U,
    ...args: Parameters<typeof func>
  ) {
    setLoading(true);
    const response = await apiFunc(...args);
    setLoading(false);

    setError(!response.ok);
    setData(response.data);
    return response;
  }

  return { request, data, error, loading };
};

export default useApi;
