import React, { useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { MdEqualizer } from "react-icons/md";
import ThemeContext from "../../context/theme/themeContext";
interface CardInfoProps {
  title: string;
  value: number;
  icon?: JSX.Element;
}

const CardInfo: React.FunctionComponent<CardInfoProps> = ({
  title,
  value,
  icon,
  ...rest
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Card className="p-3" {...rest}>
      <Row className="justify-content-center align-items-center">
        <Col xs={10} sm={10}>
          <h4>{value}</h4>
          <span className="text-secondary ">{title}</span>
        </Col>

        <Col xs={2} sm={2}>
          <div className="float-end">
            {icon ? (
              icon
            ) : (
              <MdEqualizer style={{ fontSize: "2rem", color: theme.primary }} />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CardInfo;
