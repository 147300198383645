import storage, { IUser } from "../../services/base/storage";

export const checkPermissions = (permission: string | undefined) => {
    const user: IUser | null = storage.getUser();
    if (!user) return false;
    if (permission === undefined) return false;
    return user && user.permissions
      ? user.permissions.includes(permission)
      : false;
  };
  