import React, { useContext } from "react";
import Loader from "../loader";
import ThemeContext from "../../../context/theme/themeContext";
import { createUseStyles } from "react-jss";
import { ITheme } from "../../../context/theme/theme";

interface BackDropProps {
  show: boolean;
}

const useStyles = createUseStyles({
  backDrop: (props: ITheme) => ({
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: 1200 /* the fixed-top bootstrap has 1030*/,
    left: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  }),
  centerBackDrop: () => ({
    margin: "auto",
    position: "absolute",
    top: "48%",
    left: "48%",
    /* border: 3px solid green; */
    /*padding: 12px;*/
    textAlign: "center",
  }),
});

const Backdrop: React.FunctionComponent<BackDropProps> = ({ show }) => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles();
  return show ? (
    <div data-testid="backdrop" className={classes.backDrop}>
      <div className={classes.centerBackDrop}>
        <Loader size={80} color={theme.primary} />
        <br />
      </div>
    </div>
  ) : null;
};

export default Backdrop;
