import { FunctionComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";
import CardInfo from "../../../common/card-info";

type ValueType = {
  description: string;
  value: any;
  [key: string]: any;
};

interface PlotValueProps {
  values?: ValueType[];
}

const PlotValues: FunctionComponent<PlotValueProps> = ({ values }) => {
  return (
    <Row className="p-2">
      {values && values.length > 0 && (
        <Col>
          {values.map((value,index) => (
            <Row key={index} className="mb-1" style={{ paddingLeft: "5px" }}>
              <CardInfo
                title={value.description}
                value={value.value ? value.value : "N/A"}
                //style={{ paddingLeft: "5px" }}
              />
            </Row>
          ))}
        </Col>
      )}
    </Row>
  );
};

export default PlotValues;
