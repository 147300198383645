import { BaseServiceGenericDemo } from "../base-service-demo";
import { ICategory } from "../../services/struct/signal-category-service";
import { signal_category_data } from "./data";

export class SignalCategoryDemo extends BaseServiceGenericDemo<ICategory> {
  constructor() {
    super();
    this.data = signal_category_data.getData();
  }
}
