import { IReportNotification } from "../../services/reports/report-notification-service";
import report_data from "./report-notification-data.json";

class ReportNotificationDemoData {
  private data: IReportNotification[];
  constructor() {
    this.data = this.loadData();
  }

  private loadData = () => {
    const myJSON = JSON.stringify(report_data.response);
    const obj: IReportNotification[] = JSON.parse(myJSON);
    return obj;
  };

  getData = () => {
    return this.data;
  };
}

export const report_notification_data = new ReportNotificationDemoData();
