import React,{ FunctionComponent } from "react";
import { Button, ListGroup, Badge } from "react-bootstrap";
import {
  MdWarning,
  MdLockClock,
  MdRemoveRedEye,
  MdPendingActions,
  MdTimer,
  MdError,
  MdOutlineWbTwilight,
  MdCancel,
  MdBusAlert,
} from "react-icons/md";
import Icon from "../../common/Icon";
import { INotification } from "../../../services/notifications/warning-notification-service";
import moment from "moment";
import SearchBox from "../../common/searchbox";
import ListItemCustom, { ItemCustom } from "./list-item-custom";

interface ListItemNotificationProps {
  notifications?: INotification[];
  onView?: (notification: INotification) => void;
}

const warning_color = "rgb(255, 205, 86)";
const trend_color = "rgb(255, 99, 132)";

const renderNotificationType = (notification: INotification) => {
  if (notification.type === "warning") {
    return <Icon backgroundColor={warning_color} Icon={MdWarning} size={50} />;
  }

  if (notification.type === "trend") {
    return <Icon backgroundColor={trend_color} Icon={MdCancel} size={50} />;
  }

  return <Icon Icon={MdBusAlert} />;
};

const ListItemNotification: FunctionComponent<ListItemNotificationProps> = ({
  notifications,
  onView,
}) => {
  if (!notifications) return null;
  return (
    <ListItemCustom
      key={"default"}
      data={notifications}
      keyExtrator={(item) => item.id.toString()}
      renderItem={(item) => (
        <ItemCustom
          key={item.id}
          Icon={renderNotificationType(item)}
          title={item.struct.name}
          subtitle={item.variable.description}
          actions={
            <React.Fragment key={item.id}>
              <div>
                <p className="text-muted me-3">
                  <MdTimer /> {moment(item.result_info.result_date).fromNow()}
                </p>
                <span className="">
                  {item.is_incorrect && <MdError size={20} color="red" />}
                  {item.is_read ? (
                    <Badge pill bg="success">
                      Viewed{" "}
                    </Badge>
                  ) : (
                    <Badge pill bg="warning">
                      Pending
                    </Badge>
                  )}
                </span>
              </div>

              <Button
                className="me-1 tour_view_notification"
                variant="outline-dark"
                onClick={() => onView && onView(item)}
              >
                <MdRemoveRedEye />
              </Button>
            </React.Fragment>
          }
        />
      )}
    />
  );
};

export default ListItemNotification;
