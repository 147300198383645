import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { INotificationType } from "../../pages/notifications";


// Define a type for the slice state
interface StructState {
  menu?: INotificationType;
}

// Define the initial state using that type
const initialState: StructState = {
    menu: "default",
};

export const notificationSlice = createSlice({
  name: "analytics",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveNotificationMenu(state, action: PayloadAction<INotificationType>) {
      state.menu = action.payload;
    },
  },
});

export const {
    saveNotificationMenu,  
} = notificationSlice.actions;

export const getNotificationState = (state: RootState) => state.notification;

export default notificationSlice.reducer;
