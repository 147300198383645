import { FunctionComponent } from "react";
import { Button, Form } from "react-bootstrap";
import DefaultButton from "../common/buttons/default-button";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
interface SearchInputProps {
  filterText: string;
  onFilter?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClear?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const SearchInput: FunctionComponent<SearchInputProps> = ({
  filterText,
  onFilter,
  onClear,
}) => {
  return (
    <>
    
    <Form.Group className="mb-3">     
      <Form.Control
        size="sm"
        width={"300px"}
        placeholder="Search"
        onChange={onFilter}
        value={filterText}
      />
    </Form.Group>  
    </>
  );
};

export default SearchInput;
