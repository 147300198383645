import React, {
  Component,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Joi from "joi";
import {
  scheduled_service,
  IMaintenanceScheduled,
} from "../../../../services/maintenance/scheduled/scheduled-service";
import * as dateService from "../../../../utils/date-operations";
import { errorMessage } from "../../../../utils/alertService";
import { Row, Modal, Button, Col } from "react-bootstrap";
import Loader from "../../../common/loader";
import _, { isEqual } from "lodash";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import Backdrop from "../../../common/backdrop/backdrop";
import {
  MdCheckCircle,
  MdRefresh,
  MdWarning,
  MdSchedule,
} from "react-icons/md";
import ToastCalendar from "../toastui-calendar/toast-calendar";
import { timezone_service } from "../../../../services/timezoneService";

import MaintenanceScheduledModal from "./maintenance-scheduled-modal";
import {
  IOperation,
  sheduled_operation_service as operations,
} from "../../../../services/maintenance/scheduled/operation-service";
import {
  useGetScheduled,
  useGetScheduledOperations,
} from "../../../../queries/maintenance/scheduled.hook";
import ThemeContext from "../../../../context/theme/themeContext";
import { Step } from "react-joyride";
import { Tour, operation_steps, scheduled_steps } from "tour";
import { Routes } from "Routes";
import { IMaintenanceTabs } from "pages/maintenance";
export interface IScheduledEvent {
  title: string;
  date: Date;
  eventData: IMaintenanceScheduled;
  isFinish: boolean | null;
  user?: any;
  comment?: any;
}

interface IState {
  events: IScheduledEvent[] | undefined;
  maintenance_info: any;
  maintenance_operation?: IOperation[];
  showModal: boolean;
  completed: number;
  pending: number;
}

interface Props {
  onChangeTab?: (tab: IMaintenanceTabs) => void; //Only used for the tour.
}

const MaintenanceScheduledCalendar: FunctionComponent<Props> = ({
  onChangeTab,
}) => {
  const [state, setState] = useState<IState>({} as IState);
  const timezone = timezone_service.getTimezone();
  const maintenance = useGetScheduled();
  const maintenance_operations = useGetScheduledOperations();

  const { theme } = useContext(ThemeContext);

  const handleClose = async (refresh: boolean) => {
    if (refresh) {
      maintenance.refetch();
    }
    setState({ ...state, showModal: false });
  };

  const generateDates = (maintenance: IMaintenanceScheduled) => {
    var dates: IScheduledEvent[] = [];
    var maintenance_done = maintenance.results;
    var today = new Date();
    //month deleted now i use start date and end date.
    //var month_m = maintenance.trigger_month;
    var day_m = maintenance.trigger_day;
    var hour_m: any[] = maintenance.trigger_hour.split(":"); //split in hour,min,seconds
    var start = new Date(maintenance.start_date);
    var end = new Date(maintenance.end_date);
    var year_start = start.getFullYear();
    var year_end = end.getFullYear();
    //console.debug(hour_m);
    // console.debug(start.getFullYear());
    // console.debug(end.getFullYear());
    //--All day on the specific dates.

    //Control if the maintenance is enabled
    if (maintenance && maintenance.enabled === false) {
      return dates;
    }

    if (day_m.length === 7) {
      for (let year = year_start; year <= year_end; year++) {
        var month_start = year > year_start ? 0 : start.getMonth();
        var month_end = year === year_end ? end.getMonth() : 11; // end 11 is december

        for (let month = month_start; month <= month_end; month++) {
          // end in 11 decembre every year

          var days = dateService.getDaysInMonth(month, year);

          var day_start =
            month === month_start && year === year_start ? start.getDate() : 1;

          var day_end =
            month === month_end && year === year_end
              ? end.getDate()
              : days.length;

          for (let day = day_start; day <= day_end; day++) {
            var date = new Date(year, month, day, hour_m[0], hour_m[1]);

            const { _date, _time } = dateService.separateDateAndTime(date);

            var isCompleted = false;
            var user = undefined;
            if (maintenance_done) {
              var completed_exist = maintenance_done.find(
                (x) => x.programmed_date === _date
              );
              if (completed_exist) {
                isCompleted = true;
                user = completed_exist.user;
              }
            }
            var finish = date > today ? null : isCompleted; //maintenance_done.include(_date);

            dates.push({
              title: maintenance.title,
              date: date,
              eventData: maintenance,
              isFinish: finish,
              user: user,
            });
          }
        }
      }
    }
    //  One day(mon,sunday,friday..) on the specific dates.
    else if (day_m != null && day_m.length > 0) {
      for (let year = year_start; year <= year_end; year++) {
        var month_start = year > year_start ? 0 : start.getMonth();
        var month_end = year === year_end ? end.getMonth() : 11; // end 11 is december

        for (let month = month_start; month <= month_end; month++) {
          // end in 11 decembre every year

          var days = dateService.getDaysInMonth(month, year);

          var day_start =
            month === month_start && year === year_start ? start.getDate() : 1;

          var day_end =
            month === month_end && year === year_end
              ? end.getDate()
              : days.length;

          for (let day = day_start; day <= day_end; day++) {
            var date = new Date(year, month, day, hour_m[0], hour_m[1]);
            if (day_m.includes(date.getDay())) {
              const { _date, _time } = dateService.separateDateAndTime(date);
              var isCompleted = false;
              var user = undefined;
              if (maintenance_done) {
                var completed_exist = maintenance_done.find(
                  (x) => x.programmed_date === _date
                );
                if (completed_exist) {
                  isCompleted = true;
                  user = completed_exist.user;
                }
              }
              var finish = date > today ? null : isCompleted;
              //console.debug(finish);
              dates.push({
                title: maintenance.title,
                date: date,
                eventData: maintenance,
                isFinish: finish,
                user: user,
              });
            }
          }
        }
      }
    }

    //check if are results outside off the interval
    var results_off_interval = checkResultsOffTheInterval(
      maintenance,
      // maintenance_done,
      dates
    );

    dates = dates.concat(results_off_interval);

    return dates;
  };

  const checkResultsOffTheInterval = (
    maintenance: IMaintenanceScheduled,
    dates: IScheduledEvent[]
  ) => {
    try {
      var maintenance_done = maintenance.results;
      //Get all results where the maintenance is before the start date
      var dates_to_add: any = [];
      //var start = new Date(maintenance.start_date);
      //start.setHours(0,0,0,0); //set to 00:00 beacuse the moment date do in
      var start = moment(maintenance.start_date);
      var results_outside_interval: any = _.filter(
        maintenance_done,
        (item, index) => {
          if (moment(item.programmed_date).isBefore(start)) {
            return item;
          }
        }
      );
      //console.debug(start);
      //console.debug(results_outside_interval);
      //check if date are already added

      //console.debug(results_outside_interval);
      if (results_outside_interval && results_outside_interval.length > 0) {
        results_outside_interval.forEach((result_outside: any) => {
          const date = moment(
            result_outside.programmed_date +
              " " +
              result_outside.programmed_hour
          );
          var check_date = _.find(dates, function (date_element: any) {
            if (moment(date_element).isSame(date)) {
              return date_element;
            }
          });
          //console.debug(check_date);
          //if is undefined means that the date is completed, but don't appear in the list,
          // because maybe could be change the date off start of maintenance.
          if (check_date === undefined) {
            //console.debug(date);
            var old_event_complete = {
              title: maintenance.description,
              date: date.toDate(),
              eventData: maintenance,
              isFinish: true,
            };
            //console.debug(old_event_complete);
            //dates.push(old_event_complete);
            dates_to_add.push(old_event_complete);
          }
        });
      }

      return dates_to_add;
    } catch (ex: any) {
      return dates_to_add;
    }
  };

  const prepareScheduledMaintenance = async (
    maintenances: IMaintenanceScheduled[]
  ) => {
    try {
      let events_db: IScheduledEvent[] = [];

      maintenances?.forEach((element) => {
        let dates_events = generateDates(element);
        events_db.push(...dates_events);
      });
      let sum_pending = _.sumBy(events_db, (value: IScheduledEvent) => {
        return value.isFinish === false ? 1 : 0;
      });

      let sum_completed = _.sumBy(events_db, (value: IScheduledEvent) => {
        return value.isFinish === true ? 1 : 0;
      });
      setState({
        ...state,
        events: events_db,
        completed: sum_completed,
        pending: sum_pending,
      });
    } catch (error: any) {
      scheduled_service.checkErrors(error);
      setState({ ...state, events: [] });
    }
  };

  const handleCalendarClick = async (event: IScheduledEvent) => {
    try {
      const { eventData, date, isFinish } = event;

      const { _date, _time } = dateService.separateDateAndTime(date);

      //find comment in result
      var result_data = eventData.results.find(
        (x: any) => x.programmed_date === _date && x.programmed_hour === _time
      );
      // set the user and the comment;
      event.comment = result_data === undefined ? null : result_data.comment;
      event.user = result_data === undefined ? null : result_data.user;
      //eventData.event = date; // put event date on the data of the event.
      //eventData.isFinish = isFinish; // put is finish on data event
      //eventData.user = result_data === undefined ? null : result_data.user;

      const result = await maintenance_operations.mutateAsync({
        id: eventData.id,
        date: _date,
        hour: _time,
        timezone: timezone,
      });
      setState({
        ...state,
        maintenance_info: event, //eventData,
        maintenance_operation: result.response,
        showModal: true,
      });
    } catch (ex: any) {
      errorMessage(ex);
    }
  };

  useEffect(() => {
    if (maintenance.data?.response) {
      prepareScheduledMaintenance(maintenance.data.response);
    }
  }, [maintenance.data]);

  const steps = [
    ...scheduled_steps(() => events && handleCalendarClick(events[0])),
    ...operation_steps,
  ];

  const {
    events,
    showModal,
    maintenance_info,
    maintenance_operation,
    completed,
    pending,
  } = state;
  return (
    <>
      <Tour
        steps={steps}
        onTourFinish={() => onChangeTab && onChangeTab("mm")}
      />
      <Backdrop
        show={
          maintenance.isLoading ||
          maintenance.isRefetching ||
          maintenance_operations.isLoading
        }
      />

      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            <h5 className="demo_scheduled_info">
              <Badge bg="light" text="dark">
                <MdWarning size={20} style={{ color: theme.colors?.danger }} />{" "}
                Expired: {pending && pending}
              </Badge>{" "}
              <Badge bg="light" text="dark">
                <MdCheckCircle
                  size={20}
                  style={{ color: theme.colors?.success }}
                />{" "}
                Complete: {completed && completed}
              </Badge>{" "}
              <a
                onClick={() => maintenance.refetch()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </h5>
          </div>
        </Col>
      </Row>
      <br />

      <ToastCalendar events={events} onOpenEvent={handleCalendarClick} />
      {/* <BigCalendar events={events} onchange={this.handleCalendarClick} /> */}
      <MaintenanceScheduledModal
        showModal={showModal}
        maintenance_info={maintenance_info}
        maintenance_operation={maintenance_operation}
        onClose={handleClose}
      />
    </>
  );
};

export default MaintenanceScheduledCalendar;
