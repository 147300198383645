import { Moment } from "moment";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";
import storage from "../base/storage";
import { PlotAnalyticsTemperatureDemo } from "../../demo/analytics/plot-analytics-temp-demo";

const endpoint = "statistic/plot/temperature";

const endpoint_condition = "statistic/plot/temperature/condition";

export interface ITemperature {
  name: string;
  starts: Date[];
  durations: number[];
  did_not_reach: number[];
  out_of_tholds: number[];
  n_heatings?: any;
  initial_values: number[];
  setpoints: number[];
  trend?: ITrend;
  threshold_max?: number;
  threshold_min?: number;
}

interface ITrend {
  start: { time: Date; value: number };
  end: { time: Date; value: number };
}

export interface ITemperatureResponse {
  name: string;
  data: ITemperature[];
}

export interface ITemperatureConditionResponse {
  name: string;
  data: ITemperature;
}

export type IRequestCondition = {
  start_date: Moment;
  end_date: Moment;
  struct_id: number;
  condition_id: number;
  timezone?:string;
};

class StatisticPlotTemperature {
  private http = Http;

  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
  getPlot = (
    star_date: Moment,
    end_date: Moment,
    structs_ids: number[] | string[],
    resample_rule?: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<ITemperatureResponse[]>> => {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    const data = {
      start: star_date.format("YYYY-MM-DD"),
      end: end_date.format("YYYY-MM-DD"),
      struct_ids: structs_ids,
      resample_rule: resample_rule,
    };

    return this.getPromise<ITemperatureResponse[]>(
      this.http.post(endpoint + "/?" + queryString, data)
    );
  };

  getPlotByCondition = (
    data: IRequestCondition,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<ITemperatureConditionResponse>> => {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    const data_send = {
      start: data.start_date.format("YYYY-MM-DD"),
      end: data.end_date.format("YYYY-MM-DD"),
      struct_id: data.struct_id,
      condition_id: data.condition_id,
      timezone:data.timezone
    };

    return this.getPromise<ITemperatureConditionResponse>(
      this.http.post(endpoint_condition + "/?" + queryString, data_send)
    );
  };
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new PlotAnalyticsTemperatureDemo()
    : new StatisticPlotTemperature();

export const statistic_plot_temperature_service = instance;
