import sign from "jwt-encode";
import { ResponseWrapper } from "models/reponse";
import permission from "../../demo/permissions.json";
import { IUser } from "./storage";
import Http, { IHttp } from "services/base/http-services";
import { AxiosError } from "axios";
export interface IAccess {
  access: string;
  refresh: string;
  permissions?: string[];
}

export class Authentication {
  http: IHttp;
  endpoint = "auth/jwt/create";
  endpoint_refresh = "auth/jwt/refresh";

  constructor() {
    this.http = Http;
  }

  //: Promise<ResponseWrapper<IAccess>>
  login = async (
    username: string,
    password: string
  ): Promise<ResponseWrapper<IAccess>> => {
    if (username == "demo" && password == "demo") {
      return this.login_demo();
    }

    const payload = {
      username,
      password,
    };
    const data = await this.getPromise(
      this.http.post(this.endpoint + "/", payload)
    );

    return data;
  };
  //Function to login with demo.
  private login_demo = (): ResponseWrapper<IAccess> => {
    const data: IUser = {
      first_name: "demo",
      last_name: "demo",
      email: "demo@costal.it",
      is_staff: false,
      user: "demo",
      user_id: 0,
      exp: Date.now(),
      token_type: "demo",
      jti: "demo",
    };
    const jwt = sign(data, "DemoKeyString");
    const demo_login: ResponseWrapper<IAccess> = {
      status: 200,
      version: "demo",
      response: {
        access: jwt,
        refresh: jwt,
        permissions: permission.permissions,
      },
    };
    return demo_login;
  };

  protected getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<IAccess>);
        })
        .catch((response: Error | AxiosError<any>) => {
          reject(response);
        });
    });
  }
}

export const authentication = new Authentication();
