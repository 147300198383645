import { ResponseWrapper } from "models/reponse";
import {
  IMaintenanceReport,
  IReport,
} from "services/maintenance/maintenance-report";

export class MaintenanceReportDemo implements IMaintenanceReport {
  generateReport(
    start_date: string,
    end_date: string,
    timezone: string
  ): Promise<ResponseWrapper<IReport>> {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The generation report is not allowed on demo" },
          },
        },
      });
    });
  }
  get = (): Promise<ResponseWrapper<IReport[]>> => {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The generation report is not allowed on demo" },
          },
        },
      });
    });
  };
}
