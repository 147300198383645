import { Moment } from "moment";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";
import storage from "../base/storage";

const endpoint = "statistic/plot/pid";

type ITrend = {
  start?: { time: Date; value: number };
  end?: { time: Date; value: number };
};

export interface IStationary {
  default?: IDefault;
  peaks_area?: IPeakArea;
  peaks_number?: IPeakNumber;
  peaks_time?: IPeakTime;
}

export interface IPeakTime {
  times_above_worst_window: number[];
  n_peaks_time_above_out_of_threshold: number[];
  peaks_time_above_threshold?: any;
  unit_of_measurement?:string;
}

export interface IPeakNumber {
  n_peaks_out_of_threshold: number[];
  n_peaks_threshold?: any;
  n_peaks_worst_window: number[];
}

interface IPeakArea {
  areas_above_worst_window: number[];
  peaks_area_above_threshold?: any;
  n_peaks_area_above_out_of_threshold: number[];
  unit_of_measurement?: string;
}

interface IDefault {
  avgs: number[];
  avg_threshold?: number;
  default_avg?: number;
  n_avgs_out_of_threshold: number[];
  trend?: ITrend;
  unit_of_measurement: string;
}

export interface ITransient {
  durations: number[];
  n_unfinished: number[];
  n_durations_out_of_threshold: number[];
  duration_default_avg?: any;
  duration_threshold?: any;
  trend?: ITrend;
  unit_of_measurement: string;
}

export interface IPidResponse {
  name: string;
  id: number;
  description: string;
  starts: Date[];
  total_durations: number[];
  n_processes?: any;
  stationary: IStationary;
  transient: ITransient;
  resample_rule?: any;
}

class StatisticPlotPid {
  private http = Http;

  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
  getPlot = (
    star_date: Moment,
    end_date: Moment,
    structs_ids: number[] | string[],
    timezone: string,
    resample_rule?: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IPidResponse[]>> => {
    const queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    const data = {
      start: star_date.format("YYYY-MM-DD"),
      end: end_date.format("YYYY-MM-DD"),
      struct_ids: structs_ids,
      resample_rule: resample_rule,
      timezone: timezone,
    };

    return this.getPromise<IPidResponse[]>(
      this.http.post(endpoint + "/?" + queryString, data)
    );
  };
}

export const statistic_plot_pid_service = new StatisticPlotPid();
