import { FunctionComponent } from "react";
import { Card, Image, Badge, Row, Col } from "react-bootstrap";
import moment from "moment";
import img from "../../../assests/profile.png";
import { useGetProfile } from "../../../queries/profile.hook";
import Paragraph from "../../common/paragraph";
import { Placeholder } from "react-bootstrap";
interface ProfileOverviewProps {}

const ProfileOverview: FunctionComponent<ProfileOverviewProps> = () => {
  const get_profile = useGetProfile();
  const user = get_profile.data?.response;

  return (
    <>
      {get_profile.isLoading ? (
        <Paragraph className="mt-1" rows={10} height={200} />
      ) : (
        <Card className="mb-1">
          {user && (
            <Card.Body>
              <Card.Title>
                {user.first_name} {user.last_name}
                <div className="text-center float-end">
                  <div className="text-muted">
                    <small>
                      {"Joined " + moment(user.date_joined).format("MMM Do Y")}
                    </small>
                  </div>
                </div>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <Badge bg="secondary">{user.group.name}</Badge>
              </Card.Subtitle>
              <div className="mb-1 text-center justify-content-center">
                <Image
                  src={img}
                  style={{
                    borderRadius: "50%",
                    width: "10rem",
                    height: "10rem",
                  }}
                />
              </div>
              <Card.Text className="justify-content-center">
                {user.email}
              </Card.Text>
            </Card.Body>
          )}
        </Card>
      )}
    </>
  );
};

export default ProfileOverview;
