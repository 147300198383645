import { Step } from "react-joyride";
export const dashboard_steps: Step[] = [
  {
    content: (
      <div>
        <h2>Before start</h2>

        <p>
          To end the tour prematurely, press <b>SKIP</b> button.
        </p>
        <p>
          To close the tour only on this page press the <b>X</b> button.
        </p>

        <p>
          Don't worry, after pressing the SKIP button, you can restart the tour
          anytime by clicking
          <b>"Settings"</b> top of the navbar, then <b>"Start tour"</b>
        </p>
      </div>
    ),
    //locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: "center",
    target: "body",
  },
  {
    content: (
      <div>
        <h2>Let's begin our journey!</h2>
        <p>
          In the section <b>dashboard</b> it’s possible to immediately access
          the most significant informations.
        </p>
      </div>
    ),
    //locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: "center",
    target: "body",
  },
  {
    title: "Cards",
    content: (
      <div>
        <p>In the upper part of the dashboard we can find information about:</p>
        <ul>
          <li>Monitoring structures</li>
          <li>Maintenance</li>
          <li>Reports</li>
        </ul>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    styles: {
      tooltipContent: {
        alignItems: "left",
        justifyContent: "start",
      },
    },
    spotlightPadding: 20,
    target: ".demo_cards",
  },
  {
    content: (
      <div>
        <p>
          Notifications per month refers to the amount of notifications per day
          that have occurred in the last 30 days.
        </p>
      </div>
    ),
    placement: "right",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".demo_month_anomalies",
    title: "Month notifications",
  },
  {
    title: "Month notifications types",
    content: (
      <div>       
        <p>
          Here we can see the number of notifications broken down by type of
          anomaly (trends and daily alerts) in the last 30 days.
        </p>
      </div>
    ),
    placement: "left",
    target: ".demo_month_notification_type",
  },
  {
    title: "Current year maintenance",
    content: (
      <div>
        <p>
          In this summary chart we can see an overview of notifications by month
          of the current year.
        </p>
      </div>
    ),
    placement: "top",
    target: ".demo_current_month_maintenance",
  },
  {
    title: "Current week maintenance",
    content: (
      <div>
        <p>
          In this summary chart we can see an overview of notifications by day
          of the current week.
        </p>
      </div>
    ),
    placement: "top",
    target: ".demo_current_week_maintenance",
  },
  {
    title: "Compression stats",
    content: (
      <div>
        <p>
          In the compression section we can see the compression ratio of the
          data collection. Here we see:
          <ul>
            <li>
              Total storage size: the total amount of the data collection.
            </li>
            <li>
              Compressed size: the total of data compressed by the software.
            </li>
            <li>
              Precompressed size: space occupied by data before compression.
            </li>
          </ul>
        </p>
      </div>
    ),
    placement: "top",
    target: ".demo_compression_stats",
  },
  {
    title: "Disk Usage",
    content: (
      <div>
        <p>
          In the Disk section we can view the total usage of the storage
          installed on the collector.
          <ul>
            <li>Total disk size: physical disk capacity.</li>
            <li>Total disk free: available free space.</li>
            <li>Total disk used: used space.</li>
          </ul>
        </p>
      </div>
    ),
    placement: "top",
    target: ".demo_disk_usage",
  },
];
