import _ from "lodash";
import { ResponseWrapper } from "../../../models/reponse";
import { maintenance_data } from "./data";

export class MaintenanceUsageBasedCompleteDemo {
  //Operations send always a post request, if the operation already exists automatic
  // update.

  save(
    data: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    //TODO save complete maintenance

    return new Promise<any>((resolve, reject) => {      
      const maintenances = maintenance_data.getData();
      console.debug(maintenances)
      const maintenance_info = _.find(maintenances, {
        id: data.usage_based_maintenance_id,
      });

      if (maintenance_info) {
        if (
          maintenance_info.current_value != null &&
          maintenance_info.current_value < maintenance_info.expiration
        ) {
          reject({
            status: 400,
            version: "demo",
            response: {
              data: { response: { error: "The maintenance is not expired" } },
            },
          });
        }
      
        //Reset maintenance
        maintenance_info.current_value = 0.0;
       
        maintenance_info.operations.forEach((x) => (x.completed = false));

        maintenance_data.update(maintenance_info);

        const response: ResponseWrapper<any> = {
          status: 200,
          version: "demo",
          response: maintenance_info,
        } as ResponseWrapper<any>;
        resolve(response);
      }
    });
  }

  checkErrors(error: any) {
    //Nothing
  }
}
