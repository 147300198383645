import { FunctionComponent } from "react";

interface DescriptorProps {
  label: string;
  value?: string | number | JSX.Element;
}

const Descriptor: FunctionComponent<DescriptorProps> = ({ label, value }) => {
  return (
    <div className="d-flex justify-content-between mb-1">
      <span>
        <b>{label}</b>
      </span>
      <span>{value}</span>
    </div>
  );
};

export default Descriptor;