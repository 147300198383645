import { ResponseWrapper } from "../../models/reponse";
import Http from "../base/http-services";
import { BaseService } from "../base/base-service";

const endpoint = "auth/groups";

const endpoint_permissions = "auth/permissions";

export interface IUserGroup {
  id: number;
  name: string;
  level: number;
  permissions: IPermission[];
}

export interface IPermission {
  id: number;
  name: string;
  codename: string;
}

class UserGroupService extends BaseService<IUserGroup> {
  constructor() {
    super(endpoint, Http);
  }

  getPermisisions = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IPermission[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get(endpoint_permissions + "/" + "?" + params)
    );
  };
}

export const user_group_service = new UserGroupService();
