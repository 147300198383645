import { Card, Col, Row } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHdd } from "@fortawesome/free-solid-svg-icons";

interface DiskStatsProps {
  diskFree?: number;
  diskUsed?: number;
  diskTotal?: number;
  colorIcon?: string;
}

const style = (value: any) => {
  return buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: "round",

    // Text size
    textSize: "1rem",    

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: value > 90 ? "red" : "green",
    textColor: "black",
    // justifyContent:"",
    trailColor: "#d6d6d6",
    backgroundColor: "red",
  });
};

const style_center: any = {
  margin: "auto",
  padding: "2rem",
};

const DiskStats: React.FunctionComponent<DiskStatsProps> = ({
  colorIcon,
  diskTotal,
  diskFree,
  diskUsed,
}) => {
  let diskPer: number = 0;
  diskPer =
    diskUsed && diskTotal
      ? parseInt(((diskUsed * 100) / diskTotal).toFixed(2))
      : 0;
  return (
    <Card data-testid="diskStats" className="p-3" style={{ minHeight: 200 }}>
      <Row>
        <div className="m-2">
          <div className="float-start">
            <h4 data-testid="header">Disk usage</h4>
            {/* <FontAwesomeIcon icon={faCompressArrowsAlt} size="2x" /> */}
          </div>
          <div className="float-end">
            <FontAwesomeIcon icon={faHdd} color={colorIcon} size="2x" />
          </div>
        </div>
      </Row>

      <Row>
        <Col xxl xl={12} lg={6} md={6} sm={6} xs={6}>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <h3 className="font-weight-bold text-gray-800">
                <b>{diskTotal ? diskTotal : 0} GB</b>
              </h3>
              <span>Total disk size.</span>
            </li>
            <li className="list-group-item">
              <h3 className="font-weight-bold text-gray-800">
                <b>{diskFree ? diskFree : 0} GB </b>
              </h3>
              <span>Total disk free. </span>
            </li>
            <li className="list-group-item">
              <h3 className="font-weight-bold text-gray-800">
                <b>{diskUsed ? diskUsed : 0} GB</b>
              </h3>
              <span>Total disk used.</span>
            </li>
          </ul>
        </Col>
        <Col xxl xl={12} lg={6} md={6} sm={6} xs={6} style={style_center}>
          <div className="">
            <CircularProgressbar
              className="w-75"
              value={diskPer}
              text={`${diskPer}%`}
              styles={style(diskPer)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default DiskStats;
