import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Card, Container, Button, Badge } from "react-bootstrap";
import { MdEditNote, MdUpload, MdSave } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AppForm from "../../../formik-forms/AppForm";
import FormSwitch from "../../../formik-forms/FormSwitch";
import FormText from "../../../formik-forms/FormText";
import withLayout from "../../../../hoc/withLayout";
import {
  IStructDetailVariable,
  IVariable,
  struct_service,
  IStruct,
} from "../../../../services/struct/struct-service";
import StructVariableModal from "./struct-variable-modal";
import FormSubmit from "../../../formik-forms/FormSubmit";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FormikHelpers, FormikValues } from "formik";
import { struct_detail_service } from "../../../../services/struct/struct-detail";

import * as alertService from "../../../../utils/alertService";
import useApi from "../../../../hooks/useApi";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import Backdrop from "../../../common/backdrop/backdrop";
import { Routes } from "../../../../Routes";
import FormSelect from "../../../formik-forms/FormSelect";
import { useGetSignalCategories } from "../../../../queries/signal.categories.hook";
import { SelectOption } from "../../../common/selects/select-option";
import { useGetStructsById } from "../../../../queries/struct.hook";
import { useGetModelType } from "../../../../queries/model.type.hook";
interface StructFormProps {}

enum OperationType {
  Hidden = "Hidden",
  Status = "Status",
}

const StructForm: FunctionComponent<StructFormProps> = () => {
  const [structDetail, setStructDetail] = useState<
    IStructDetailVariable[] | undefined
  >(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [struct, setStruct] = useState<IStruct>();
  const [variable, setVariable] = useState<IStructDetailVariable>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [categories, setCategories] = useState<SelectOption[]>([]);

  let { id } = useParams<any>();
  const { state } = useLocation<any>();

  const history = useHistory();

  const categories_data = useGetSignalCategories();

  const struct_data = useGetStructsById();

  const saveStructDetail = useApi(struct_detail_service.save);

  const checkId = async () => {
    try {
      setIsBusy(true);

      const result = await struct_data.mutateAsync(id);

      setStruct(result.response);

      const structDetail = await struct_service.GetDetailById(id);

      if (!structDetail) return history.replace("/not-found");

      setStructDetail(structDetail ? structDetail.response : undefined);
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      history.replace("/404");
    }
  };

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      await saveStructDetail.request(values);
      alertService.Ok("Data saved successfully");
      console.debug(values);
    } catch (error: any) {
      struct_detail_service.checkErrors(error);
    }
  };

  useEffect(() => {
    if (categories_data.data?.response) {
      categories.length = 0;
      const result = categories_data.data?.response;
      result.forEach((category: any) => {
        categories.push({ value: category.id, label: category.name });
      });
      setCategories(categories);
    }
  }, [categories_data.data]);

  useEffect(() => {
    checkId();
    setStruct(state);
    console.debug(state);
  }, []);

  return (
    <>
      <Backdrop show={isBusy} />
      <Container>
        <Card>
          <Card.Body>
            <h5>
              <GoBackNavigation url={Routes.management_struct} />
              <b>{struct?.description}</b>
              <Badge bg="secondary" className="ms-1">
                {struct?.type_id?.description}
              </Badge>
            </h5>

            <StructInformation struct={struct} />

            <br />
            <h5>
              <b>List Variables</b>
            </h5>

            {structDetail?.map((detail, index) => (
              <React.Fragment key={"struct-detail" + index}>
                <AppForm
                  //validationSchema={validationSchema}
                  initialValues={{
                    id: detail.id,
                    struct_id: detail.struct_id,
                    variable_id: detail.variable.id,
                    operation_type: detail.operation_type,
                    is_plot: detail.is_plot,
                    is_stats: detail.is_stats,
                    category_id: detail.category ? detail.category.id : null,
                  }}
                  onSubmit={doSubmit}
                >
                  <Row
                    style={{ minHeight: 50 }}
                    className="border justify-content-center align-items-center  m-1 shadow-sm"
                  >
                    <Col>{index + 1}</Col>
                    <Col>{detail.variable.description}</Col>
                    {detail.operation_type != OperationType.Hidden.toString() &&
                    detail.operation_type != OperationType.Status.toString() ? (
                      <>
                        <Col>{detail.variable.unit_of_measurement}</Col>
                        <Col>{detail.operation_type}</Col>
                        <Col>
                          <FormSwitch name={"is_plot"} label={"Enable plot"} />
                        </Col>
                        <Col>
                          <FormSwitch
                            name={"is_stats"}
                            label={"Enable stats"}
                          />
                        </Col>
                        <Col>
                          <FormSelect
                            options={categories}
                            //label="Category"
                            name="category_id"
                            placeholder="Select category"
                          />
                          {/* {detail.category
                            ? detail.category?.name
                            : "Not defined"} */}
                        </Col>
                        <Col>
                          <span className="mt-1 me-1">
                            <FormSubmit
                              title=""
                              size="sm"
                              icon={faSave}
                              disabled={saveStructDetail.loading}
                            />
                          </span>
                          {struct?.processing == "Default" && (
                            <Button
                              className=""
                              variant="outline-success"
                              size="sm"
                              onClick={() => {
                                setVariable(detail);
                                setShowModal(true);
                              }}
                            >
                              <MdEditNote />
                            </Button>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col></Col>
                        <Col>{detail.operation_type}</Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                      </>
                    )}
                  </Row>
                </AppForm>
              </React.Fragment>
            ))}
          </Card.Body>
        </Card>
      </Container>

      <StructVariableModal
        data={variable}
        showModal={showModal}
        onClose={(refresh) => {
          if (refresh) {
            checkId();
          }
          setShowModal(false);
        }}
      />
    </>
  );
};

export default StructForm;

interface StructInformationProps {
  struct?: IStruct;
}

const StructInformation: FunctionComponent<StructInformationProps> = ({
  struct,
}) => {
  const model_type_data = useGetModelType();
  const [modelType, setModelType] = useState<SelectOption[]>([]);
  useEffect(() => {
    if (model_type_data.data?.response) {
      modelType.length = 0;
      const result = model_type_data.data?.response;
      result.forEach((model) => {
        modelType.push({
          value: model.id?.toString(),
          label: model.description,
        });
      });
      setModelType(modelType);
    }
  }, [model_type_data.data]);

  if (!struct) return null;

  return (
    <AppForm
      //validationSchema={validationSchema}
      initialValues={{
        id: struct.id,
        name: struct.name,
        description: struct.description,
        processing: struct.processing,
        enabled: struct.enabled,
        type_id: struct.type_id?.id,
      }}
      onSubmit={(data) => console.debug("data")}
    >
      <Row>
        <Col>
          <FormText name="name" label="Name" placeholder="Insert Name..." />{" "}
        </Col>
        <Col>
          <FormText
            name="description"
            label="Description"
            placeholder="Insert Description..."
          />{" "}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormSelect
            label="Processing"
            placeholder="Select processing..."
            name="processing"
            options={[
              { label: "Default", value: "Default" },
              { label: "Temperature", value: "Temperature" },
              { label: "Valve", value: "Valve" },
              { label: "Pid", value: "Pid" },
            ]}
          />
        </Col>
        <Col>
          <FormSelect
            label="Model type"
            placeholder="Select model type"
            name="type_id"
            options={modelType}
          />
        </Col>
        <Col>
          <br></br>
          <FormSwitch name={"enabled"} label={"Enable"} />
        </Col>
      </Row>
    </AppForm>
  );
};
