import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import Http, {
  DEFAULT_HOST,
  PATH_API,
} from "../../../services/base/http-services";
import * as Yup from "yup";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdSave,
  MdCleaningServices,
} from "react-icons/md";
import ReactTooltip from "react-tooltip";
import ThemeContext from "context/theme/themeContext";

interface BaseUrlProps {}

const BaseUrl: FunctionComponent<BaseUrlProps> = ({}) => {
  const defaultHost: string = DEFAULT_HOST;
  const [value, setValue] = useState<string>(defaultHost);
  const [recent, setRecent] = useState<string[]>([]);

  const [error, setError] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const { theme } = useContext(ThemeContext);

  const regexDomain =
    /^(http|https):\/\/((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})(:\d{4})?$/;

  const regexIp =
    /^((http|https):\/\/)(localhost|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))(:\d{4})?$/;

  const maxLengUrl: number = 40;
  const valueSchema = Yup.string()
    .required()
    .max(maxLengUrl, "The url is too long.")
    // .matches(
    //   regexIp,
    //   "Insert a valid ip address"
    // )
    .test(
      "valid domain or ip",
      "Insert a valid ip address or domain name. Example http://example.com | https://127.0.0.1:7000",
      function (value) {
        if (value) {
          let isValidDomain = regexDomain.test(value);
          let isValidIp = regexIp.test(value);

          if (!isValidDomain && !isValidIp) {
            return false;
          }
        }
        return true;
      }
    );

  const loadRecentLocations = () => {
    try {
      const recent = localStorage.getItem("recent");
      if (recent) {
        const recent_elements: string[] = JSON.parse(recent);
        setRecent(recent_elements);
      }
    } catch (error) {}
  };

  const loadServerAndPort = () => {
    try {
      const value = localStorage.getItem("server");
      if (value) {
        setValue(value);
        validateValue(value);
        return;
      } else {
        //try to get the server address from the ENV variable.
        const url = process.env.REACT_APP_API_URL;
        if (url) {
          const urlObject = new URL(url);
          const server_name = `${urlObject.protocol}//${urlObject.hostname}${
            urlObject.port ? ":" + urlObject.port : ""
          }`;
          localStorage.setItem("server", server_name);
          setValue(server_name);
          validateValue(server_name);
          return;
        } else {
          //Set the default values on the storage.
          localStorage.setItem("server", defaultHost);
          return;
        }
      }
    } catch (error: any) {
      return;
    }
  };

  const validateValue = (value: string) => {
    valueSchema
      .validate(value)
      .then(() => {})
      .catch((error) => setError(error.errors));
  };

  const saveRecentLocations = (newValue: string) => {
    const recent = localStorage.getItem("recent");
    if (recent) {
      const recent_elements: string[] = JSON.parse(recent);
      if (!recent_elements.includes(newValue)) {
        recent_elements.push(newValue);
        const crop = recent_elements.slice(-3);
        localStorage.setItem("recent", JSON.stringify(crop));
        setRecent(crop);
      }
    } else {
      const recent_elements: string[] = [newValue];
      localStorage.setItem("recent", JSON.stringify(recent_elements));
      setRecent(recent_elements);
    }
  };

  const saveServerAndPort = (newValue: string) => {
    const url = new URL(newValue);
    //Base url needs to have the "Path to api".
    const new_url = `${url.protocol}//${url.hostname}:${url.port}${PATH_API}`;
    Http.setBaseUrl(new_url);
    localStorage.setItem("server", newValue);
  };

  const handleValueChange = async ({ currentTarget }: any) => {
    try {
      const newValue: string = currentTarget.value;
      setValue(newValue);
      await valueSchema.validate(newValue);
      setError("");
    } catch (err: any) {
      setError(err.errors);
      console.debug(err.errors);
    }
  };
  //OnBlur save the value in localStorage.
  const handleBlur = ({ currentTarget }: any) => {
    if (!error) {
      const newValue: string = currentTarget.value;
      saveServerAndPort(newValue);
    }
  };
  //OnSave save, add thee value to recent locations
  const handleSave = (value: string) => {
    if (!error) {
      saveRecentLocations(value);
    }
  };

  const deleteRecentLocations = () => {
    localStorage.removeItem("recent");
    setRecent([]);
  };

  const deleteRecent = (value: string) => {
    const recent_elements = recent.filter((x) => x != value);
    localStorage.setItem("recent", JSON.stringify(recent_elements));
    setRecent(recent_elements);
  };

  useEffect(() => {
    loadRecentLocations();
    loadServerAndPort();
  }, []);

  return (
    <>
      <ReactTooltip
        place="top"
        type="info"
        effect="solid"
        html={true}
        border={false}
        delayShow={500}
      />
      <div className="d-flex justify-content-between">
        <div>
          {visible && (
            <>
              <a
                className="btn btn-sm"
                data-tip={"Save current url"}
                onClick={() => handleSave(value)}
              >
                <MdSave size={"1.1rem"} />
              </a>
              <a
                className="btn btn-sm"
                data-tip={"Remove recent urls"}
                onClick={() => deleteRecentLocations()}
              >
                <MdCleaningServices size={"1.1rem"} />
              </a>
            </>
          )}
        </div>
        <a
          onClick={async () => setVisible(!visible)}
          className="btn mr-1 btn-sm"
        >
          {visible ? (
            <>
              <MdArrowUpward size={"1.1rem"} /> Close options.
            </>
          ) : (
            <>
              <MdArrowDownward size={"1.1rem"} /> Open options.
            </>
          )}
        </a>
      </div>
      {visible && (
        <>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="validationFormikUsername">
                <Form.Floating>
                  <Form.Control
                    isValid={error ? false : true}
                    isInvalid={error ? true : false}
                    type={"text"}
                    placeholder={"server point."}
                    value={value}
                    onBlur={(data) => {
                      handleBlur && handleBlur(data);
                    }}
                    onChange={(data) => {
                      handleValueChange && handleValueChange(data);
                    }}
                  />

                  <label style={{ color: "grey" }}>{"Server location"}</label>
                  <Form.Control.Feedback type="invalid">
                    {error && error}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                {recent.map((url, index) => (
                  <Badge
                    style={{ cursor: "pointer", fontSize: "0.9rem" }}
                    key={"r" + index}
                    onClick={() => {
                      saveServerAndPort(url);
                      setValue(url);
                      setError("");
                    }}
                    bg={url === value ? "success " : "secondary"}
                    className="mt-1"
                  >
                    {url.substring(0, maxLengUrl)}

                    {/*
                    TODO REMOVE ONE BADGE
                    <span
                      style={{
                        paddingLeft: "15px",
                        fontWeight: "bold",
                        float: "right",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        console.log("clicking");
                      }}
                    >
                      &times;
                    </span> */}
                  </Badge>
                ))}
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BaseUrl;
