import React, { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import ErrorBoundary from "../../pages/errorBoundary";
import storage from "../../services/base/storage";


interface ProtectedRouteProps extends RouteProps {
     
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  path,
  render,
  children,
  ...rest
}) => {

  const user = storage.getUser();  
  if (!user) {
    return <Redirect to={{ pathname: "/login" }} />;
  } else {
    return (
      <Route {...rest} path={path}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Route>
    );
  }
};

export default ProtectedRoute;
