import React, { FunctionComponent } from "react";
import { Form,InputGroup } from "react-bootstrap";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
interface FormTextFloatingProps {
  name: string;
  label: string;
  placeholder: string;
  type?: "email" | "text" | "password";
  disabled?: boolean;
}

const FormTextFloating: FunctionComponent<FormTextFloatingProps> = ({
  name,
  label,
  placeholder,
  type = "text",
  disabled=false,
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  return (
    <Form.Floating className="mb-3">
      <Form.Control 
        disabled={disabled}
        isInvalid={errors[name] ? true : false}
        size="sm"
        //id="floatingInputCustom"
        type={type}
        placeholder={placeholder}
        value={values[name]}
        onChange={({ currentTarget }: any) => {
          setFieldValue(name, currentTarget.value);
        }}
      />
           
      <Form.Text className="text-danger">
        {errors[name] && errors[name]}
      </Form.Text>

      <label style={{color:"grey"}} htmlFor="floatingInputCustom">{label}</label>
    </Form.Floating>
  );
};

export default FormTextFloating;
