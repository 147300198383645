import { useFormikContext } from "formik";
import { FunctionComponent, useContext } from "react";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import { MdHelpOutline } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import ThemeContext from "../../context/theme/themeContext";
interface FormTextProps {
  name: string;
  label: string;
  placeholder: string;
  helpText?: string;
  type?: "email" | "text" | "password" | "number";
  size?: "sm" | "lg";
  readOnly?: boolean;
  onChangeValue?: (value: string | number | undefined)=> void;
}

const FormText: FunctionComponent<FormTextProps> = ({
  name,
  label,
  placeholder,
  helpText,
  type = "text",
  size = undefined,
  readOnly = false,
  onChangeValue  
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const { theme } = useContext(ThemeContext);
  const error = get(errors, name);
  const value = get(values, name);
  return (
    <>
      <ReactTooltip
        place="right"
        type="light"
        html={true}
        border={false}
        backgroundColor={theme.secondary}
        textColor={"white"}
        arrowColor={theme.secondary}
        effect="solid"
      />
      <Form.Group className="m-1" controlId={name}>
        <Form.Label>
          {label + " "}
          {helpText && (
            <a data-tip={helpText}>
              <MdHelpOutline style={{ fontSize: "15px" }}></MdHelpOutline>
            </a>
          )}
        </Form.Label>
        <Form.Control
          readOnly={readOnly}
          type={type}
          step={"any"}
          size={size}
          min={0}
          placeholder={placeholder}
          //value={values[name]}
          value={value}
          isInvalid={error ? true : false}
          onChange={({ currentTarget }: any) => {
            //If the value is empty send undefined otherwise error in endpoint.
            let value: string | number | undefined;
            const newValue =
              currentTarget.value === "" ? undefined : currentTarget.value;

            if (type == "number") value = parseFloat(newValue);
            else value = newValue;

            setFieldValue(name, value, true);
            onChangeValue && onChangeValue(value)
          }}
        />
        <Form.Text className="text-danger">{error && error}</Form.Text>
      </Form.Group>
    </>
  );
};

export default FormText;
