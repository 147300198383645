import { FunctionComponent, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";

export type ITimeSnipped = {
  label: string;
  hour: number;
  minutes: number;
};
interface DatePickerProps {
  placeholder: string;
  selectedDate?: string | Date | Moment | undefined;
  onDateChange?: (date: string | Moment) => void;
  timeEnabled?: boolean;
  dateEnabled?: boolean;
  timeSnipped?: ITimeSnipped[];
  isValidDate?: (currentDate: any, selectedDate: any) => boolean;
}

const DateTimePicker: FunctionComponent<DatePickerProps> = ({
  selectedDate,
  placeholder,
  dateEnabled = true,
  timeEnabled = true,
  timeSnipped,
  onDateChange,
  isValidDate,
}) => {
  const [date, setDate] = useState(selectedDate);

  useEffect(() => {
    setDate(selectedDate);
  }, [selectedDate]);

  const handleChangeTimeFormat = (hour: number, minutes: number) => {
    if (selectedDate instanceof String) {
      //Nothing
    }
    if (selectedDate instanceof Date) {
      // var newdate=selectedDate.setHours(6, 0);
      // setDate(newdate)
    }
    if (selectedDate instanceof moment) {
      var newdate = moment(selectedDate).set({ hours: hour, minutes: minutes });
      setDate(newdate);
      handleChangeDateTime(newdate);
    }
  };
  const renderView = (mode: any, renderDefault: any) => {
    // Only for years, months and days view
    if (mode === "days") return renderDefault(); //["time"]
    return (
      <div className="wrapper">
        {renderDefault()}
        <div className="controls">
          {timeSnipped &&
            timeSnipped.map((time, i) => {
              return (
                <a
                  className="btn"
                  key={i}
                  onClick={() =>
                    handleChangeTimeFormat(time.hour, time.minutes)
                  }
                >
                  {time.label}
                </a>
              );
            })}
        </div>
      </div>
    );
  };

  const handleChangeDateTime = (date: string | Moment) => {
    //Only time
    if (timeEnabled && !dateEnabled) {
      console.debug(date);
      if (typeof date != "string") {
        onDateChange && onDateChange(date.format("HH:mm"));
        return;
      }
    }
    //only date
    else if (dateEnabled && !timeEnabled) {
      if (typeof date != "string") {
        onDateChange && onDateChange(date.format("L"));
        return;
      }
    }
    //Only datetime.
    onDateChange && onDateChange(date);
  };
  return (
    <>
      <Datetime
        renderView={renderView}
        initialValue={selectedDate}
        isValidDate={isValidDate}
        value={date}
        inputProps={{
          placeholder: placeholder,
          style: {
            height: 36,
          },
        }}
        timeFormat={timeEnabled ? "HH:mm" : false}
        dateFormat={dateEnabled}
        onChange={handleChangeDateTime}
      />
    </>
  );
};

export default DateTimePicker;
