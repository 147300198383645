import { FunctionComponent, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { createUseStyles } from "react-jss";
import { ITheme } from "../../../context/theme/theme";
import ThemeContext from "../../../context/theme/themeContext";
import { Button, ButtonProps } from "react-bootstrap";
// interface DefaultButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
//   label: string;
//   icon?: IconProp;
//   outline?: boolean;
//   onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
// }

interface DefaultButtonProps extends ButtonProps {
  label: string;
  icon?: IconProp;
  outline?: boolean;
  size?: "sm" | "lg";
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
}

const DefaultButton: FunctionComponent<DefaultButtonProps> = ({
  label,
  icon,
  outline = false,
  onClick,
  size,
  className,
  ...rest
}) => {
  const useStyles = createUseStyles({
    defaultButton: (theme: ITheme) => ({
      color: outline ? "black" : "#fff",
      backgroundColor: outline ? "white" : theme.primary,
      borderColor: theme.primary,
      outlineColor: theme.primary,
      "&:hover": {
        color: "#fff",
        backgroundColor: theme.primary,
        borderColor: theme.primary,
      },
      "&:active": {
        color: "#fff !important",
        backgroundColor: theme.primary+" !important",
        borderColor: theme.primary+" !important",
      },
      "&:focus": {
        outline: "none !important",
        backgroundColor: theme.primary,
        boxShadow: "none",
      },
      "&:after": {
        outline: "none !important",
        backgroundColor: theme.primary,
        boxShadow: "none",
      },
      "&:disabled": {
        outline: "none !important",
        backgroundColor: theme.primary,
        boxShadow: "none",
        borderColor: theme.primary,
      },
    }),
  });
  const { theme } = useContext(ThemeContext);
  const styles = useStyles({ ...theme });

  return (
    <Button
      size={size}
      data-testid="defaultButton"
      {...rest}
      onClick={onClick}
      className={className + " " + styles.defaultButton}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon ? icon : faTimes} className="mr-1" />
      ) : undefined}{" "}
      {label}
    </Button>
  );
};

export default DefaultButton;
