import React, { FunctionComponent, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useLocation } from "react-router-dom";

import _ from "lodash";
import ReactTooltip from "react-tooltip";

import "react-datetime/css/react-datetime.css";
import { statistic_plot_service } from "../../services/statistic/statistic-plot-service";
import {
  IStruct,
  IStructByCategory,
} from "../../services/struct/struct-service";
import * as alertService from "../../utils/alertService";

import Backdrop from "components/common/backdrop/backdrop";
import * as dateOperation from "../../utils/date-operations";

import PlotBarAnalytics from "components/features/plot-analytics/plot-bar-analytics";
import PlotBarCategoriesAnalytics from "components/features/plot-analytics/plot-bar-categories-analytics";
import Restricted from "context/permission/restricted";
import { permissions } from "services/permissions/permissions";
import CardCollapsible from "components/common/card-collapsible";
import PlotAnalyticsView from "components/features/plot-analytics/default/plot-analytics-view";
import { GroupBase } from "react-select";
import { SelectOption } from "components/common/selects/select-option";
import { Badge, Nav } from "react-bootstrap";

import {
  StructOrder,
  useGetStructsByCategory,
  useGeteStructsDefault,
} from "./../../queries/struct.hook";
import { useGetSignalCategories } from "../../queries/signal.categories.hook";
import FindOptions, {
  IFindOption,
} from "../../components/features/plot-common/find-options";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsDefaultState,
  saveAnalyticSignals,
  saveDates,
  saveSearchBy,
  saveSignalType,
  saveStruct,
  saveStructuresSelected,
} from "./../../store/reducers/analytics/analytics.default-reducer";
import PredictionOptions from "../../components/features/plot-analytics/prediction-options";
import { Step } from "react-joyride";
import { Tour } from "tour";
import { Routes } from "Routes";

export type IAnalyticsDate = {
  startDate: Moment;
  endDate: Moment;
};

interface Analytics2Props {}

const AnalyticsDefault: FunctionComponent<Analytics2Props> = () => {
  console.debug("render-Analytics");
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [structs, setStructs] = useState<GroupBase<SelectOption>[]>([]);
  const [selectedStruct, setSelectedStruct] = useState<number>(0);
  const [categories, setCategories] = useState<SelectOption[]>([]);
  const [category, setCategory] = useState<number>(0);
  const [structsSelected, setStructsSelected] = useState<SelectOption[]>();
  const [structsByCategory, seStructsByCategory] = useState<
    GroupBase<SelectOption>[]
  >([]);

  const [dates, setDates] = useState<IAnalyticsDate>({
    startDate: moment()
      .subtract(7, "d")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    endDate: moment(),
  });
  const [findBy, setFindBy] = useState<IFindOption>("category");
  const [state, setState] = useState<any>({
    time_series: undefined,
    alarms: [],
  });

  const dispatch = useDispatch();
  const analytics_state = useSelector(getAnalyticsDefaultState);

  const location = useLocation();

  const structs_data = useGeteStructsDefault();

  const categories_data = useGetSignalCategories();

  const struct_by_category = useGetStructsByCategory();

  useEffect(() => {
    if (structs_data.data?.response) {
      const result = StructOrder(structs_data.data?.response, "type_id.type");
      setStructs(result);
    }
  }, [structs_data.data]);

  useEffect(() => {
    if (categories_data.data?.response) {
      categories.length = 0;
      const result = categories_data.data?.response;
      result.forEach((category: any) => {
        categories.push({ value: category.id, label: category.name });
      });
      setCategories(categories);
    }
  }, [categories_data.data]);
  //#region struct

  const handleChangeStruct = async (data: SelectOption) => {
    if (data.value) {
      const id = parseInt(data.value);
      setSelectedStruct(id);
      dispatch(saveStruct(id));
    }
  };

  //#endregion

  //#region Categories

  const handleChangeCategory = async (obj: SelectOption) => {
    if (obj.value) {
      console.debug("handleChangeCategory" + obj.value);
      setCategory(parseInt(obj.value));
      setStructsSelected([]);
      loadStructByCategory(obj.value);
      dispatch(saveSignalType(obj.value));
    }
  };

  const loadStructByCategory = async (categoryId: any) => {
    try {
      console.debug("loadStructByCategory" + categoryId);
      const structs = await struct_by_category.mutateAsync({
        categoryId: categoryId,
        processing: "Default",
      });
      const { response } = structs;
      if (response) {
        const result = orderStructCategory(response);

        seStructsByCategory(result);
      }
    } catch (error: any) {
      //alert(error);
    }
  };

  const orderStructCategory = (response: IStructByCategory[]) => {
    const StructOrderByType = _.chain(response)
      .groupBy("struct_type_id.type")
      .map((structs, name) => ({
        label: name,
        options: _.map(structs, (struct) => {
          return {
            label:
              struct.struct_name +
              " - " +
              struct.struct_variable.variable.description,
            value: struct.struct_variable.id.toString(),
            isDisabled: !struct.struct_enabled,
            variable: {
              variable_id: struct.struct_variable.variable.id,
              struct_id: struct.struct_id,
            },
          };
        }),
      }))
      .value();

    //console.debug(StructOrderByType);
    return StructOrderByType;
  };

  const handleStructSelecteds = (data: any) => {
    if (data) {
      setStructsSelected(data);
      dispatch(saveStructuresSelected(data));
    }
  };
  //#endregion

  //#region Dates

  const handleStartDate = (date: any) => {
    const date_ = moment(date);
    dates.startDate = moment(date_);
    dispatch(
      saveDates({ start_date: dates.startDate, end_date: dates.endDate })
    );
  };
  const handleEndDate = (date: any) => {
    const date_ = moment(date);
    dates.endDate = moment(date_);
    dispatch(
      saveDates({ start_date: dates.startDate, end_date: dates.endDate })
    );
  };

  //#endregion

  const handleFindOptions = (value: IFindOption) => {
    if (findBy !== value) {
      setFindBy(value);
      setState({
        time_series: undefined,
        alarms: [],
        table_time_series: [],
      });
      dispatch(saveSearchBy(value));
      dispatch(saveAnalyticSignals(undefined));
    }
  };
  const readQueryParams = async () => {
    let params = new URLSearchParams(location.search);
    const structure = params.get("structure");
    const date = params.get("date");
    if (structure && date) {
      await setFindBy("structure");
      setSelectedStruct(parseInt(structure));

      let start = new Date(date);
      let end = new Date(date);
      //console.debug(start)
      start.setDate(start.getDate() - 90);
      end.setDate(end.getDate() + 2);

      handleStartDate(start);
      handleEndDate(end);

      await handleRefresh();
    }
  };

  const plotByStruct = async () => {
    try {
      setIsBusy(true);
      const result_all = await statistic_plot_service.getPlotAndStatistic(
        selectedStruct,
        {
          start_date: dateOperation.getDateOnString(dates.startDate?.toDate()),
          end_date: dateOperation.getDateOnString(dates.endDate?.toDate()),
        }
      );

      const { response } = result_all;

      let signals_with_id = response ? response.time_series : undefined;
      let num = 1;
      if (signals_with_id) {
        signals_with_id.forEach((signal: any) => {
          if (!signal.id) {
            signal.id = num.toString();
            num++;
          }
        });
      }

      setState({
        time_series: signals_with_id,
        alarms: response?.alarm,
      });
      dispatch(saveAnalyticSignals(signals_with_id));
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      statistic_plot_service.checkErrors(error);
    }
  };

  const plotByCategories = async () => {
    try {
      setIsBusy(true);
      //TODO put types variablesIds
      let variables_onstruct = structsSelected?.map((x) => x.variable);
      //console.debug(variables_onstruct);
      if (variables_onstruct) {
        let result = await statistic_plot_service.getPlotAndStatisticByIds(
          variables_onstruct,
          dateOperation.getDateOnString(dates.startDate.toDate()),
          dateOperation.getDateOnString(dates.endDate.toDate())
        );
        //console.debug(result.data.response);

        const { response } = result;

        let signals_with_id = response ? response.time_series : undefined;
        let num = 1;
        if (signals_with_id) {
          signals_with_id.forEach((signal) => {
            if (!signal.id) {
              signal.id = num.toString();
              num++;
            }
          });
        }

        setState({
          time_series: signals_with_id,
          alarms: response?.alarm,
        });
        dispatch(saveAnalyticSignals(signals_with_id));
      }
      setIsBusy(false);
    } catch (error: any) {
      //alert("error plot categories");
      setIsBusy(false);
      statistic_plot_service.checkErrors(error);
    }
  };

  const handleRefresh = async () => {
    if (findBy === "category") {
      if (!structsSelected) {
        alertService.errorMessage("You have to select a signal type.");
        return;
      }
      if (structsSelected?.length === 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
      setState({
        time_series: undefined,
        alarms: [],
        table_time_series: [],
      });
      await plotByCategories();
    } else {
      if (selectedStruct <= 0) {
        alertService.errorMessage("You have to select a structure.");
        return;
      }
      setState({
        time_series: undefined,
        alarms: [],
        table_time_series: [],
      });
      await plotByStruct();
    }
  };

  const loadState = () => {
    if (analytics_state.signals) {
      setState({
        ...state,
        time_series: analytics_state.signals,
      });
    }
    if (analytics_state.search_by) {
      setFindBy(analytics_state.search_by);
    }
    if (analytics_state.dates) {
      setDates({
        startDate: moment(analytics_state.dates.start_date),
        endDate: moment(analytics_state.dates.end_date),
      });
    }
    if (analytics_state.signal_type) {
      setCategory(parseInt(analytics_state.signal_type));
      loadStructByCategory(analytics_state.signal_type);
    }
    if (analytics_state.structures_selected) {
      setStructsSelected(analytics_state.structures_selected);
    }
    if (analytics_state.struct_selected) {
      setSelectedStruct(analytics_state.struct_selected);
    }
  };

  useEffect(() => {
    loadState();
    readQueryParams();
  }, []);

  const findMotor = (): SelectOption | undefined => {
    var group_value: SelectOption | undefined = undefined;
    structs.forEach((io) => {
      let prospect = io.options.find((o: any) => o.value == selectedStruct);
      if (prospect !== undefined) {
        group_value = prospect;
      }
    });
    return group_value;
  };

  //TODO move to tour folder
  const steps: Step[] = [
    {
      title: "Analytics",
      placement: "center",
      target: "body",
      content: (
        <div>
          <p>
            In the section <b>Analytics</b> it's possible to: In the Analytics
            section it is possible to view overall structure performance over a
            selected period, with any:
          </p>
          <ul>
            <li>Anomalies</li>
            <li>Linear trends</li>
            <li>Prediction</li>
          </ul>
          <p></p>
        </div>
      ),
    },
    {
      title: "Search by",
      content: (
        <div>
          <p>It’s possible to view analytics in two different forms:</p>
          <ul>
            <li>
              <b>By structure:</b> all signals of a selected structures.
            </li>
            <li>
              <b>By type of signal</b>: all signals from diferente structures
              type (e.g. torque, current, speed, temperature) of all selected
              structures
            </li>
          </ul>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_searchby",
    },
    {
      title: "Signal type",
      content: (
        <div>
          <p>Select the signal type</p>
        </div>
      ),
      locale: {
        next: (
          <a
            onClick={() => {
              if (categories?.[0]) handleChangeCategory(categories[0]);
            }}
          >
            Next
          </a>
        ),
      },
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_signaltype",
    },
    {
      title: "Select structures",
      content: (
        <div>
          <p>Select the structures of the current type.</p>
        </div>
      ),
      locale: {
        next: (
          <a
            onClick={() => {
              if (structsByCategory?.[0]?.options?.[0])
                handleStructSelecteds([structsByCategory[0].options[0]]);
            }}
          >
            Next
          </a>
        ),
      },
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_structures",
    },
    {
      title: "From",
      content: (
        <div>
          <p>Select beginning date of the range to display</p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_from",
    },
    {
      title: "To",
      content: (
        <div>
          <p>Select end date of the range to display</p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: ".tour_to",
    },
    {
      title: "Request",
      content: (
        <div>
          <p>Click on the button plot data.</p>
        </div>
      ),
      locale: {
        next: <a onClick={() => handleRefresh()}>Click</a>,
      },
      showSkipButton: false,
      hideBackButton: true,
      hideFooter: false,
      placement: "top",
      showProgress: false,
      target: ".tour_request",
    },
    {
      title: "",
      content: (
        <div>
          <p>
            Now we can go further and see the in depth performance of the
            structure.
          </p>
        </div>
      ),
      hideFooter: false,
      placement: "center",
      showProgress: false,
      target: "body",
    },
    {
      title: "Analytic-series signals",
      content: (
        <div>
          <p>
            On the bellow card we see all the analytic-series signals associate
            to the structures/type selected.
          </p>
          <p>
            The table contains basic statistics relating to the single signals
            in the selected period.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      placement: "top",
      target: ".tour_analytics_signals",
    },
    {
      title: "Actions",
      content: (
        <div>
          <p>
            In both view mode it’s possible to interact with the graph by using
            the available buttons. From left to right:
          </p>
          <ul>
            <li>Download</li>
            <li>Drag</li>
            <li>Move</li>
            <li>Zoom in</li>
            <li>Zoom out</li>
            <li>Reset scale</li>
          </ul>
        </div>
      ),

      hideFooter: false,
      placement: "left",
      target: ".modebar",
    },
  ];

  console.debug("render analytics");

  return (
    <React.Fragment>
      <Tour steps={steps} redirectTo={Routes.notification} />
      <Restricted to={permissions.main.graph} showFallback={true}>
        <ReactTooltip place="top" effect="solid" />
        <Backdrop show={isBusy} />
        <CardCollapsible
          title={
            <>
              <h5>Analytics</h5>

              {findBy && findBy === "category" && (
                <>
                  <Badge className="ms-1" bg="secondary" text="white">
                    {
                      categories.find(
                        (x) => x.value && parseInt(x.value) == category
                      )?.label
                    }
                  </Badge>
                  <Badge className="ms-1" bg="secondary">
                    {structsSelected && structsSelected?.length > 0 && (
                      <span>{structsSelected?.length} structures</span>
                    )}
                  </Badge>
                </>
              )}
              {findBy && findBy === "structure" && (
                <Badge className="ms-1" bg="secondary">
                  {findMotor() && findMotor()?.label}
                </Badge>
              )}

              <Badge className="ms-1" bg="secondary">
                <span>
                  {dates.startDate.format("L")} - {dates.endDate.format("L")}
                </span>
              </Badge>
            </>
          }
        >
          <div className="tour_searchby">
            <FindOptions
              selectedValue={findBy}
              onChangeFindOptions={handleFindOptions}
            />
          </div>

          {findBy && findBy === "structure" && (
            <React.Fragment>
              <PlotBarAnalytics
                isInAutoRefresh={false}
                timeEnabled={false}
                options={structs}
                selectedStartDate={dates.startDate}
                selectedEndDate={dates.endDate}
                selectedStruct={selectedStruct}
                onClick={handleRefresh}
                onStructChange={handleChangeStruct}
                onStartDate={handleStartDate}
                onEndDate={handleEndDate}
              />
              <br />
              <Restricted to={permissions.main.analytics.forecast}>
                <PredictionOptions struct_id={selectedStruct} />
              </Restricted>
            </React.Fragment>
          )}
          {findBy && findBy === "category" && (
            <PlotBarCategoriesAnalytics
              isInAutoRefresh={false}
              timeEnabled={false}
              structCategories={structsByCategory} //the structures filter by category
              selectedStartDate={dates.startDate} // current start date
              selectedEndDate={dates.endDate} // current end date
              selectedStructs={structsSelected} // structs actual selected
              categories={categories} // categories avaliable
              selectedCategory={category} // category selected
              onCategoryChange={handleChangeCategory} // on change category
              onClick={handleRefresh}
              onStructSelected={handleStructSelecteds}
              onStartDate={handleStartDate}
              onEndDate={handleEndDate}
            />
          )}
          <br />
        </CardCollapsible>

        <PlotAnalyticsView time_series={state.time_series} />
      </Restricted>
    </React.Fragment>
  );
};

export default AnalyticsDefault;
