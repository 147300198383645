import { FunctionComponent } from "react";
import Restricted from "../../../../context/permission/restricted";
import Backdrop from "../../../common/backdrop/backdrop";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import { Button, Card, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../../../Routes";
import {
  MdAddCircle,
  MdDeleteOutline,
  MdModeEditOutline,
  MdRefresh,
} from "react-icons/md";
import { permissions } from "../../../../services/permissions/permissions";
import Table from "../../../table/table";
import {
  useDeleteValveCondition,
  useGetValveCondition,
} from "../../../../queries/management/valve.condition.hook";
import { IValveCondition } from "../../../../services/valve/valve-condition-service";
import { TableColumn } from "react-data-table-component";
import alertService from "../../../../utils/alertService";
import withLayout from "../../../../hoc/withLayout";

interface ValveConditionListProps {}

const ValveConditionList: FunctionComponent<ValveConditionListProps> = () => {
  const history = useHistory();
  const { data, isLoading, refetch, isRefetching } = useGetValveCondition();
  const deleteData = useDeleteValveCondition();

  const handleDelete = async (valve_condition: IValveCondition) => {
    let func = () => deleteData.mutate({ id: valve_condition.id });
    const response = alertService.WaitForRequest(
      "Confirm",
      "This operation can't be undo ",
      func
    );
    if ((await response) === true) {
      await alertService.Ok("Valve condition deleted");
    }
  };
  const columns: TableColumn<IValveCondition>[] = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : ""),
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : ""),
      sortable: true,
    },
    {
      name: "Range",
      selector: (row) => row.start_position + "-" + row.target_position,
    },
    {
      cell: (row) => (
        <>
          <Restricted to={permissions.management.valve_condition.change}>
            <Button
              variant="outline-warning me-1"
              onClick={() => {
                history.push(Routes.management_valve_condition + "/" + row.id);
              }}
              size="sm"
            >
              <MdModeEditOutline />
            </Button>
          </Restricted>
          <Restricted to={permissions.management.valve_condition.delete}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => handleDelete(row)}
            >
              <MdDeleteOutline />
            </Button>
          </Restricted>
        </>
      ),
      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  return (
    <>
      <Restricted
        to={permissions.management.valve_condition.view}
        showFallback={true}
      >
        \<Backdrop show={isLoading || isRefetching} />
        <Card className="p-3">
          <h4>
            <GoBackNavigation url="/management" />
            Valves conditions
          </h4>
          <hr />
          <Row>
            <div className="d-flex justify-content-start">
              <Restricted to={permissions.management.valve_condition.add}>
                <Link
                  to={{
                    pathname: Routes.management_valve_condition_new,
                  }}
                  className="btn mr-1 btn-sm"
                >
                  <MdAddCircle /> New
                </Link>
              </Restricted>
              <a
                onClick={async () => await refetch()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </div>
          </Row>

          <Table
            columns={columns}
            data={data?.response ? data.response : []}
            pagination={true}
          />
        </Card>
      </Restricted>
    </>
  );
};

export default ValveConditionList;
