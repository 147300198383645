import { Annotations, Config, Data, PlotData, ScatterLine } from "plotly.js";
import { FunctionComponent } from "react";
import Plot from "react-plotly.js";
import {
  ITemperature,
  ITemperatureResponse,
} from "../../../../services/statistic/statistic-plot-temperature";
import { color_plot } from "../colors";
import { config } from "../config";
import moment from "moment";
interface PlotTemperatureProps {
  name: string;
  signal: ITemperature;
  highlight?: { time: string; value: number };
  range?: { start: any; end: any };
  onInitialized?: any;
  onUpdate?: any;
  onRelayout?: any;
}

const generateLegend = (signal: ITemperature) => {
  const statusLegend: string[] = [];
  if (signal.starts) {
    signal.starts.forEach((element, index) => {
      let element_: string | undefined = undefined;
      if (signal.initial_values) {
        var start_temp = signal.initial_values[index];
        element_ = "<br /> - Initial temp: " + start_temp;
      }
      if (signal.setpoints) {
        var set_point = signal.setpoints[index];
        element_ += "<br /> - Set point: " + set_point;
      }
      var newStuff = element_?.replace(/,/g, "<br /> - ");
      //console.debug(newStuff);
      statusLegend.push(newStuff ? newStuff : "No Status");
    });
  }
  //}
  // else {
  //   //Put fake date
  //   if (signal.time) {
  //     for (let index = 0; index < signal.time.length; index++) {
  //       statusLegend.push("No Status");
  //     }
  //   }
  // }
  return statusLegend;
};

const PlotTemperature: FunctionComponent<PlotTemperatureProps> = ({
  signal,
  name,
  onInitialized,
  onRelayout,
  onUpdate,
  highlight,
  range,
}) => {
  const status = generateLegend(signal);

  const temperature_not_reach: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  const temperature_error: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  const temperature_success: { time: Date[]; value: number[] } = {
    time: [],
    value: [],
  };

  signal.out_of_tholds?.forEach((value, index) => {
    if (value >= 1) {
      temperature_error.time.push(signal.starts[index]);
      temperature_error.value.push(signal.durations[index]);
    } else {
      const not_reached = signal.did_not_reach[index];
      if (not_reached >= 1) {
        temperature_not_reach.time.push(signal.starts[index]);
        temperature_not_reach.value.push(signal.durations[index]);
      } else {
        temperature_success.time.push(signal.starts[index]);
        temperature_success.value.push(signal.durations[index]);
      }
    }
  });

  const bar_current: Partial<Data> = {
    x: temperature_success.time,
    y: temperature_success.value,
    text: temperature_success.value.map((x) => x?.toFixed(2).toString()),
    name: "Heating time", // always blank with custom hover template
    mode: "number",
    type: "bar",
    width: [50],
    customdata: status, //handleStatus(signal.status),
    hovertemplate:
      "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <b>Info:</b>  %{customdata} <br /><br />",
    showlegend: true,
    marker: {
      color: "green",
      //color: myArrayColors,
      opacity: 0.6,
    },
  };

  const bar_warnings: Partial<Data> = {
    x: temperature_error.time,
    y: temperature_error.value,
    text: temperature_error.value.map((x) => x?.toFixed(2).toString()),
    name: "Warning", // always blank with custom hover template
    mode: "number",
    type: "bar",
    customdata: status, //handleStatus(signal.status),
    hovertemplate:
      "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <b>Info:</b>  %{customdata} <br /><br />",
    showlegend: true,
    marker: {
      color: "red",
      //color: myArrayColors,
      opacity: 0.6,
    },
  };

  const bar_not_reach: Partial<Data> = {
    x: temperature_not_reach.time,
    y: temperature_not_reach.value,
    text: temperature_not_reach.value.map((x) => x?.toFixed(2).toString()),
    name: "Error Heating", // always blank with custom hover template
    mode: "number",
    type: "bar",
    customdata: status,
    hovertemplate:
      "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /> <b>Info:</b>  %{customdata} <br /><br />",
    showlegend: true,
    marker: {
      color: color_plot[9],
      opacity: 0.6,
    },
  };

  const trend_values = signal.trend ? signal.trend : null;

  //Aggiungo 1 giorno per allugare le threshold perché i grafici di tipo barra
  // hanno un width grosso.
  const x_high_low = temperature_success
    ? moment(temperature_success.time[0])
        .subtract(1, "d")
        .format("YYYY-MM-DD HH:mm:ss")
    : null;
  const y_high_low = trend_values
    ? moment(trend_values?.end.time).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
    : moment(temperature_success.time.slice(-1)[0])
        .add(1, "d")
        .format("YYYY-MM-DD HH:mm:ss");

  const high_threshold: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      signal.threshold_max ? signal.threshold_max : null,
      signal.threshold_max ? signal.threshold_max : null,
    ],
    mode: "lines",
    name: "High threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    // line: { color: "#E7E6F0", width: "2" },
  };

  const low_threashold: Partial<Data> = {
    x: [x_high_low, y_high_low],
    y: [
      signal.threshold_min ? signal.threshold_min : null,
      signal.threshold_min ? signal.threshold_min : null,
    ],
    mode: "lines",
    name: "Low threshold",
    hovertemplate: "<b>Value:</b> %{y} <br /><br />",
    line: { color: "#FFB775", width: 2 },
    //line: { color: "#E7E6F0", width: "2" },
  };

  const trend: Partial<Data> = {
    x: [
      trend_values && trend_values?.start.time,
      trend_values && trend_values?.end.time,
    ],
    y: [
      trend_values && trend_values?.start.value,
      trend_values && trend_values?.end.value,
    ],
    name: "Trend",
    mode: "lines",
    hovertemplate: "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br />",
    line: { color: "#FFA3A6", shape: "spline", width: 1, dash: "dot" },
  };
  const annotation_highlight: Array<Partial<Annotations>> = [
    {
      //x: point && point > 0 ? signal.starts[point].toString() : "",
      x: highlight ? highlight?.time : "",
      y: highlight ? highlight?.value : "",
      xref: "x",
      yref: "y",
      text: "Warning",
      showarrow: true,
      arrowhead: 1,
      ax: 0,
      ay: -50,
      arrowcolor: "grey",
    },
  ];

  //Style
  //Style empty
  //xref /yref = x domain doest exist in the library react-plotly
  //const annotation_empty: Array<Partial<Annotations>>
  const annotation_empty = signal.durations
    ? undefined
    : [
        {
          text: "No data found",
          xref: "x domain",
          yref: "y domain",
          showarrow: false,
          font: {
            size: 24,
          },
        },
      ];
  //https://community.plotly.com/t/how-to-make-the-messy-date-ticks-organized/7477/3
  const layout: any = {
    //title: signal.description,
    title: name,
    hoverlabel: { bgcolor: "#FFF" },
    height: 300,
    autosize: true,
    annotations: annotation_empty
      ? annotation_empty
      : highlight
      ? annotation_highlight
      : undefined,
    margin: {
      r: "50",
      l: "50",
      t: "80",
      b: "50",
    },
    showlegend: signal.durations ? true : false,
    xaxis: {
      range: [
        range?.start,
        range?.end,
        //  temperature_success && temperature_success.time[0],
        //temperature_success && temperature_success.time.slice(-1)[0],
      ],
      //type: "date",
      visible: signal.durations ? true : false,
      showgrid: true,
      //tickmode:"linear",
      //tickformat: '%e %b, %y %H:%M',
      //tickformat: "%e %b, %y",
      //tick0:signal.time[0],
      //dtick:"M1" //30 * 24 * 60 * 60 * 1000 // milliseconds
      // rangeselector: selectorOptions, TODO
    },
    yaxis: {
      //title: signal && signal.unit_of_measurement && signal.unit_of_measurement,
      visible: signal.durations ? true : false,
      ticksuffix: " s",
      //showtickprefix : 'last'
      showgrid: true,
    },
    // updatemenus: [
    //   {
    //     pad: { t: 0, r: 10 },
    //     //type: 'buttons',
    //     xanchor: "left",
    //     yanchor: "top",
    //     x: 0,
    //     y: 1.35,
    //     direction: "bottom",
    //     buttons: [
    //       {
    //         method: "restyle",
    //         args: ["marker.color", ["green", "red"]],
    //         label: "green",
    //       },
    //       {
    //         method: "restyle",
    //         args: ["marker.color", ["blue", "red"]],
    //         label: "blue",
    //       },
    //     ],
    //   },
    // ],
  };

  return (
    <Plot
      data={[
        high_threshold,
        low_threashold,
        bar_current,
        bar_warnings,
        bar_not_reach,
        trend,
      ]}
      style={{ margin: 0, backgroundColor: "none" }}
      config={config}
      useResizeHandler={true}
      layout={layout}
      divId={signal.name}
      //onInitialized={(figure) => onInitialized(figure, signal.id)}
      //onUpdate={(figure) => onUpdate(figure, signal.id)}
      onRelayout={onRelayout}
    />
  );
};

export default PlotTemperature;
