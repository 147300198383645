import React from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js";

const config = {
  displaylogo: false,
  responsive: true,
  autosizable: false,
  fillFrame: false,
  displayModeBar: false,
  plotlyServerURL: false,
  editable: false,
  edits: false,
  showLink: false,
  displayModeBar: true,
  showEditInChartStudio: false,
  watermark: false,
  showTips: false,
};

const graph_height = 350;

const color_alarms = [
    "#E67174",
    "#89A1E6",
    "#A9D979",
    "#F0D27A",
    "#E67AB4",
    "#E69275",
    "#AF75E6",
    "#7AF0CD",
  ];

  const toDateTime =(array)=> {
    var custom_format = [];
    if (array) {
      array.forEach((x) => {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(x);

        custom_format.push(t.getHours() + "h " + t.getMinutes() + "m");
      });
    }
    return custom_format;
  }

const PlotAlarms = ({ alarm,height=graph_height }) => {
  return (
    <Plot
      data={[
        {
          values: alarm.value,
          labels: alarm.label,
          hole: 0.6,
          type: "pie",
          name: "Count",
          domain: { column: 0, row: 0 },
          customdata: alarm.value,
          marker: {
            colors: color_alarms,
          },
          hovertemplate:
            "<extra><br /> Count %{customdata}<br /> %{percent}</extra>",
        },
        {
          values: alarm.duration,
          labels: alarm.label,

          domain: { column: 1, row: 0 },
          hole: 0.6,
          type: "pie",
          name: "Duration",
          customdata: toDateTime(alarm.duration),
          hovertemplate: "<extra>%{customdata}<br /> %{percent}</extra>",
          //hoverinfo: 'customdata+percent+label',
        },
      ]}
      style={{ margin: 0, backgroundColor: "none" }}
      config={config}
      useResizeHandler={true}
      layout={{
        title: alarm.description,
        height: graph_height,
        autosize: true,
        modebar: {
          orientation: "v",
        },
        margin: {
          r: "50",
          l: "50",
          t: "50",
          b: "50",
        },
        paper_bgcolor: "#FFF3",
        title: {
          text: "Alarms",
          size: 18,
          color: "black",
        },
        legend: {
          yanchor: "top",
          xanchor: "left",
        },
        annotations: [
          {
            font: {
              size: 18,
            },
            showarrow: false,
            text: "Count",
            x: 0.19,
            y: 0.5,
          },
          {
            font: {
              size: 18,
            },
            showarrow: false,
            text: "Duration",
            x: 0.83,
            y: 0.5,
          },
        ],
        grid: { rows: 1, columns: 2 },
      }}
    />
  );
};

export default React.memo(PlotAlarms);
