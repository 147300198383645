import { AxiosError } from "axios";
import { ResponseWrapper } from "../../../models/reponse";
import { maintenance_scheduled_data, operation_Data } from "./data";
import moment from "moment";

type IResult = {
  comment: string;
  programmed_date: string;
  programmed_hour: string;
  scheduled_maintenance_id: number;
};
export class MaintenanceScheduledResultDemo {
  save(
    data: IResult,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      const maintenances = maintenance_scheduled_data.getData();
      const operations = operation_Data.getData();
      const maintenance_info = maintenances.find(
        (x) => x.id === data.scheduled_maintenance_id
      );

      if (maintenance_info) {
        if (moment(data.programmed_date) > moment()) {
          reject({
            status: 400,
            version: "demo",
            response: {
              data: { response: { error: "The maintenance is not expired" } },
            },
          });
        }
        const uncompleted_operation = operations.filter(
          (x) => x.completed === false
        );
        console.debug(uncompleted_operation);
        if (uncompleted_operation.length > 0) {
          reject({
            status: 400,
            version: "demo",
            response: {
              data: {
                response: {
                  error: "You have to complete all operations first",
                },
              },
            },
          });
        }

        const new_result = {
          comment: data.comment,
          programmed_date: data.programmed_date,
          programmed_hour: data.programmed_hour,
          updated_at: new Date(),
          user: {
            username: "demo",
            email: "demo@costal.it",
            first_name: "Demo",
            last_name: "Demo",
          },
        };
        maintenance_info.results.push(new_result);

        operations.forEach((x) =>
          x.scheduled_maintenance_id.results.push(new_result)
        );

        operation_Data.update_all(operations);

        maintenance_scheduled_data.update(maintenance_info);

        const response: ResponseWrapper<any> = {
          status: 200,
          version: "demo",
          response: maintenance_info,
        } as ResponseWrapper<any>;

        resolve(response);
      }
    });
  }

  checkErrors(error: AxiosError) {
    console.debug(error);
  }
}
