import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ResponseWrapper } from "../models/reponse";
import {
  IRequestCondition,
  ITemperatureConditionResponse,
  ITemperatureResponse,
  statistic_plot_temperature_service,
} from "../services/statistic/statistic-plot-temperature";
import alertService from "../utils/alertService";
import { Moment } from "moment";
import { timezone_service } from "../services/timezoneService";

type IPlot = {
  start: Moment;
  end: Moment;
  struct_ids: number[];
  resample_rule?: number;
};

export const usePlotTemperature = () => {
  const load_plot = useMutation<
    ResponseWrapper<ITemperatureResponse[]>,
    any,
    IPlot
  >({
    mutationFn: (data) => {
      return statistic_plot_temperature_service.getPlot(
        data.start,
        data.end,
        data.struct_ids,
        data.resample_rule
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {},
  });
  return load_plot;
};

export const usePlotTemperatureByCondition = () => {
  const timezone: string = timezone_service.getTimezone();
  const load_plot = useMutation<
    ResponseWrapper<ITemperatureConditionResponse>,
    any,
    IRequestCondition
  >({
    mutationFn: (data) => {
      data.timezone = timezone;
      return statistic_plot_temperature_service.getPlotByCondition(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data.response.error);
      }
    },
    onSuccess: (data, variables, context) => {},
  });
  return load_plot;
};
