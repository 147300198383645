import { SignalCategoryDemo } from "../../demo/signalCategories/signal-category-demo";
import Http from "../base/http-services";
import storage from "../base/storage";
import { BaseService } from '../base/base-service';

const endpoint = "signal-categories";


export interface ICategory {
  id: number;
  created_at: Date;
  updated_at: Date;
  name: string;
}

class SignalCategoryService extends BaseService<ICategory> {
  constructor() {
    super(endpoint, Http);
  }
}


const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new SignalCategoryDemo()
    : new SignalCategoryService();

export const signal_category_service = instance;
