import React from "react";

type TourContextType = {
  runTour: boolean;
  setTour: (run: boolean) => void;
};

const defaultBehaviour: TourContextType = {
  setTour: (run) => {},
  runTour: true,
};

// Create the context
const TourContext = React.createContext<TourContextType>(defaultBehaviour);

export default TourContext;
