import { useFormikContext } from "formik";
import { FunctionComponent, useContext } from "react";
import ReactTooltip from "react-tooltip";
import ThemeContext from "../../context/theme/themeContext";
import get from "lodash/get";
import { MdHelpOutline } from "react-icons/md";
import DateTimePicker, { ITimeSnipped } from "../common/date-time-picker";
import moment, { Moment } from "moment";

interface FormDateTimeProps {
  name: string;
  label: string;
  placeholder: string;
  helpText?: string;
  timeEnabled?: boolean;
  dateEnabled?: boolean;
  timeSnipped?: ITimeSnipped[];
}

const FormDateTime: FunctionComponent<FormDateTimeProps> = ({
  name,
  label,
  placeholder,
  helpText,
  timeSnipped,
  timeEnabled = true,
  dateEnabled = true,
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const { theme } = useContext(ThemeContext);
  const error = get(errors, name);
  const value = get(values, name);
  return (
    <>
      <ReactTooltip
        place="right"
        type="light"
        html={true}
        border={false}
        backgroundColor={theme.secondary}
        textColor={"white"}
        arrowColor={theme.secondary}
        effect="solid"
      />

      <label>
        {label + " "}
        {helpText && (
          <a data-tip={helpText}>
            <MdHelpOutline style={{ fontSize: "15px" }}></MdHelpOutline>
          </a>
        )}
      </label>
      <DateTimePicker
        timeSnipped={timeSnipped}
        selectedDate={timeEnabled ? moment(value, "HH:mm ") : moment(value)}
        timeEnabled={timeEnabled}
        dateEnabled={dateEnabled}
        onDateChange={(date: string | Moment) => {
          setFieldValue(name, date, true);
        }}
        placeholder={placeholder}
      />
      <span className="text-danger">{error && error}</span>
    </>
  );
};

export default FormDateTime;
