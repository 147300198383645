import { FunctionComponent } from "react";
import RadioButton3 from "../../common/radio-button";

interface FindOptionsProps {
  selectedValue: string;
  onChangeFindOptions?: (value: IFindOption) => void;
}

export type IFindOption = "category" | "structure";

const FindOptions: FunctionComponent<FindOptionsProps> = ({
  selectedValue,
  onChangeFindOptions,
}) => {
  const findbyoptions: { label: string; value: IFindOption }[] = [
    { label: "Signal type", value: "category" },
    { label: "Structure", value: "structure" },
  ];

  const handleChange = (data: any) => {
    const value = data.currentTarget["value"];
    onChangeFindOptions && onChangeFindOptions(value);
  };

  return (
    <>
      <span> Search by: </span>

      {findbyoptions &&
        findbyoptions.map((option) => (
          <RadioButton3
            key={option.label}
            name="findby"
            label={option.label}
            checked={selectedValue ? selectedValue === option.value : false}
            value={option.value}
            onChange={handleChange}
          />
        ))}
    </>
  );
};

export default FindOptions;
