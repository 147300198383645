import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Row, Col, Container, Badge, ListGroup, Tab } from "react-bootstrap";
import _ from "lodash";
import { MdCheckCircle, MdRefresh, MdWarning } from "react-icons/md";
import { createUseStyles } from "react-jss";
import { IMaintenanceUsedModel } from "../../../../services/maintenance/usage-based/usage-based-service";
import SearchBox from "../../../common/searchbox";
import Backdrop from "../../../common/backdrop/backdrop";
import "react-circular-progressbar/dist/styles.css";
import MaintenanceUsedModal from "./maintenance-used-modal";
import { useGetUsageBased } from "../../../../queries/maintenance/usage.based.hook";
import ThemeContext from "../../../../context/theme/themeContext";
import { ITheme } from "../../../../context/theme/theme";
import CardProgress from "./card-progress";
import { Tour, operation_steps, usage_steps } from "tour";
import { Step } from "react-joyride";
import { IMaintenanceTabs } from "pages/maintenance";
interface MaintenanceUsedProps {
  onChangeTab?: (tab: IMaintenanceTabs) => void; //Only used for the tour.
}

type MaintenanceType = {
  name: string;
  total: number;
  expired: number;
  maintenances: IMaintenanceUsedModel[];
};

type IState = {
  maintenances?: MaintenanceType[];
  total: number;
  expired: number;
};

const useStyles = createUseStyles({
  badgeOk: (theme: ITheme) => ({
    backgroundColor: theme.colors?.success + " !important",
    color: "white",
  }),
  badgeDanger: (theme: ITheme) => ({
    backgroundColor: theme.colors?.danger + " !important",
    color: "white",
  }),
  listGroupItem: (theme: ITheme) => ({
    "&.nav-tabs .nav-link": {
      color: "black",
    },
    "&.nav-tabs .nav-link:hover": {
      color: "black",
    },
    "&.list-group-item.active": {
      backgroundColor: theme.primary,
      borderColor: theme.primary + " #dee2e6 #fff",
      borderStyle: "double",
      borderWidth: "2px 1px 1px 1px",
    },
  }),
});

const MaintenanceUsed: FunctionComponent<MaintenanceUsedProps> = ({
  onChangeTab,
}) => {
  const [state, setState] = useState<IState>({} as IState);
  const [searchValue, setSerachValue] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const { theme } = useContext(ThemeContext);
  const classes = useStyles({ ...theme });

  const maintenancesDefault = useRef<MaintenanceType[]>([]);
  const maintenance_data = useRef<IMaintenanceUsedModel>(
    {} as IMaintenanceUsedModel
  );
  const maintenances_ = useGetUsageBased();

  const searchByProperties = (
    data: MaintenanceType[],
    query: string,
    properties: string[]
  ) => {
    //data has to be an array of objects
    //query an string
    // properties array of string with the path. ['name','user.name',..]
    let filter = _.filter<any>(data, (item: MaintenanceType) => {
      let matchQuery = null;
      properties.some((path) => {
        if (_.has(item, path)) {
          matchQuery = _.get(item, path)
            .toString()
            .toLowerCase()
            .includes(query);
          return matchQuery;
        }
      });
      if (matchQuery) return matchQuery;
    });
    //console.debug(filter);
    return filter;
  };

  const handleSearch = (value: string) => {
    if (value === "") {
      setState({ ...state, maintenances: maintenancesDefault.current });
      setSerachValue(value);
      return;
    }
    const result = searchByProperties(
      maintenancesDefault.current,
      value.toLowerCase(),
      ["name"]
    );

    setState({ ...state, maintenances: result });

    setSerachValue(value);
  };

  const handleOpenMaintenance = async (maintenance: IMaintenanceUsedModel) => {
    maintenance_data.current = maintenance;
    setShowModal(true);
  };

  const getTotalExpired = (maintenances: IMaintenanceUsedModel[]) => {
    let count = 0;
    maintenances.forEach((man: any) => {
      if (man.current_value >= man.expiration) count = count + 1;
    });
    return count;
  };

  const handleCloseModal = async (refresh: boolean) => {
    setShowModal(false);
    if (refresh) {
      maintenances_.refetch();
    }
  };

  const prepareMaintenance = async (
    maintenances: IMaintenanceUsedModel[] | undefined
  ) => {
    try {
      //filter maintenance only enabled.
      const maintenance_enabled = _.filter(maintenances, { enabled: true });

      let OrderByStruct: MaintenanceType[] = [];

      if (maintenance_enabled.length > 0) {
        OrderByStruct = _.chain(maintenance_enabled)
          .groupBy("struct.name")
          .map((maintenances, name) => ({
            name,
            total: maintenances.length,
            expired: getTotalExpired(maintenances),
            maintenances: _.chain(maintenances)
              .sort(
                (a, b) =>
                  (b.expiration -
                    (a.current_value ? a.current_value : 0) / b.expiration) *
                  100
              ) // sort takes in comparator
              .value(),
          }))
          .value();

        const sum_expired = _.sumBy(OrderByStruct, (main) => {
          return main.expired;
        });

        const sum_total = _.sumBy(OrderByStruct, (main) => {
          return main.total;
        });

        const order = _.orderBy(OrderByStruct, ["expired"], ["desc"]);
        maintenancesDefault.current = OrderByStruct;
        setState({
          maintenances: order,
          total: sum_total,
          expired: sum_expired,
        });
      } else {
        setState({
          ...state,
          maintenances: [],
        });
      }
    } catch (error: any) {
      console.debug(error);
    }
  };

  useEffect(() => {
    if (maintenances_.data?.response) {
      prepareMaintenance(maintenances_.data?.response);
    }
  }, [maintenances_.data]);

  const { maintenances, expired, total } = state;

  const steps = [
    ...usage_steps(
      () =>
        maintenances?.[0].maintenances[0] &&
        handleOpenMaintenance(maintenances?.[0].maintenances[0])
    ),
    ...operation_steps,
  ];
  return (
    <React.Fragment>
      <Tour steps={steps} onTourFinish={()=>onChangeTab && onChangeTab("re")} />

      <Backdrop show={maintenances_.isLoading || maintenances_.isRefetching} />

      <Row>
        <Col sm={4}>
          <div className="d-flex justify-content-center">
            <h5 className="tour_info">
              <Badge bg="light" text="dark">
                <MdWarning style={{ color: theme.colors?.danger }} /> Expired:{" "}
                {expired !== null ? expired : 0}
              </Badge>{" "}
              <Badge bg="light" text="dark">
                <MdCheckCircle style={{ color: theme.colors?.success }} /> Ok:{" "}
                {total && expired !== null ? total - expired : 0}
              </Badge>{" "}
              <a
                onClick={async () => maintenances_.refetch()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </h5>
          </div>
        </Col>
      </Row>

      {maintenances && (
        <Container fluid>
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#0">
            <Row>
              <Col sm={4}>
                <Row>
                  <Col className="tour_search ms-5 me-5">
                    <SearchBox
                      value={searchValue}
                      size="md"
                      placeholder="Search by structure..."
                      onChange={handleSearch}
                    />
                  </Col>
                </Row>
                <ListGroup
                  className="tour_elements"
                  variant="flush"
                  style={{
                    maxHeight: "500px",
                    overflowY: "auto",
                  }}
                >
                  {maintenances &&
                    maintenances.length > 0 &&
                    maintenances.map((motor, index) => (
                      <ListGroup.Item
                        //action={true}
                        key={"group-item" + index}
                        href={"#" + index}
                        style={{ cursor: "pointer" }}
                        className={
                          classes.listGroupItem +
                          " d-flex justify-content-between align-items-start"
                        }
                      >
                        <div className="ms-2 me-auto">
                          <div className="">{motor.name}</div>
                        </div>
                        {motor &&
                          motor.maintenances &&
                          motor.total &&
                          motor.total - motor.expired > 0 && (
                            <Badge className={classes.badgeOk}>
                              {motor.total - motor.expired}
                            </Badge>
                          )}
                        {motor && motor.maintenances && motor.expired > 0 && (
                          <Badge className={classes.badgeDanger}>
                            {motor.expired}
                          </Badge>
                        )}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
              <Col
                sm={8}
                style={{ padding: "10px", backgroundColor: "#fafafa" }}
              >
                <Tab.Content>
                  <br />

                  {maintenances && maintenances.length === 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No usaged-based maintenances to show...
                    </div>
                  )}

                  {maintenances &&
                    maintenances.length > 0 &&
                    maintenances.map((motor, index) => (
                      <Tab.Pane key={"tab-" + index} eventKey={"#" + index}>
                        <Row className="justify-content-center align-items-center">
                          {motor.maintenances &&
                            motor.maintenances.map(
                              (maintenance: any, index: any) => (
                                <Col
                                  className="tour_element"
                                  lg={3}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  key={"progress-" + index}
                                >
                                  <CardProgress
                                    maintenance={maintenance}
                                    onClick={async (maintenance) =>
                                      await handleOpenMaintenance(maintenance)
                                    }
                                  />
                                </Col>
                              )
                            )}
                        </Row>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      )}

      <MaintenanceUsedModal
        onClose={handleCloseModal}
        showModal={showModal}
        maintenance_info={maintenance_data.current}
      />
    </React.Fragment>
  );
};

export default MaintenanceUsed;
