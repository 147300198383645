import { FunctionComponent, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Backdrop from "../../common/backdrop/backdrop";
import Restricted from "../../../context/permission/restricted";
import { permissions } from "../../../services/permissions/permissions";
import AppForm from "../../formik-forms/AppForm";
import FormText from "../../formik-forms/FormText";
import FormSubmit from "../../formik-forms/FormSubmit";
import { FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import FormSelect from "../../formik-forms/FormSelect";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { SelectOption } from "../../common/selects/select-option";
import { useGetProfile, useUpdateProfile } from "../../../queries/profile.hook";
import Paragraph from "../../common/paragraph";

interface ProfileInfoProps {}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  username: Yup.string().required().label("Username"),
  email: Yup.string().email().required().label("Email"),
  first_name: Yup.string().required().label("First name"),
  last_name: Yup.string().required().label("Last name"),
  profile: Yup.object({
    address: Yup.string().nullable().min(0).label("Address"),
    gender: Yup.string().nullable().min(0).label("Gender"),
    phone: Yup.string()
      .matches(
        /^\+(?:[0-9]●?){3,15}[0-9]$/,
        "Phone must have the code of country (+39) and number of max of 15 numbers example: +155566685"
      )
      .nullable()
      .min(0)
      .label("Phone"),
    city: Yup.string().nullable().min(0).label("City"),
    zip: Yup.number().nullable().label("ZipCode"),
  }),
});

const ProfileInfo: FunctionComponent<ProfileInfoProps> = ({}) => {
  const get_profile = useGetProfile();
  const save_profile = useUpdateProfile();

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    
    if (values.profile.gender) {
      values.profile.gender = parseInt(values.profile.gender);
    }
    save_profile.mutate(values);
  };

  const genders: SelectOption[] = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Other" },
  ];

  const profile = get_profile.data?.response;

  return (
    <>
      <Restricted to={permissions.auth.view_me} showFallback={true}>
        {/* <Backdrop show={get_profile.isLoading} /> */}
        {get_profile.isLoading ? (
          <Paragraph rows={10} height={200} card={false} />
        ) : (
          <Row>
            <Col className="p-3">
              <h5>
                <b>Personal details</b>
              </h5>

              {profile && profile.profile && (
                <AppForm
                  validationSchema={validationSchema}
                  initialValues={{
                    id: profile && profile.id,
                    username: profile && profile.username,
                    email: profile && profile.email,
                    first_name: profile && profile.first_name,
                    last_name: profile && profile.last_name,
                    profile: {
                      gender: profile && profile.profile.gender,
                      address: profile && profile.profile.address,
                      phone: profile && profile.profile.phone,
                      zip: profile && profile.profile.zip,
                      city: profile && profile.profile.city,
                    },
                  }}
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col>
                      <FormText
                        name="username"
                        label="Username"
                        placeholder="Insert username..."
                      />
                    </Col>
                    <Col>
                      <FormText
                        name="email"
                        label="Email"
                        placeholder="Insert email..."
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormText
                        name="first_name"
                        label="First name"
                        placeholder="Insert first name..."
                      />
                    </Col>
                    <Col>
                      <FormText
                        name="last_name"
                        label="Last name"
                        placeholder="Insert last name..."
                      />
                    </Col>
                  </Row>

                  <br />
                  <h5>
                    <b>Billing information</b>
                  </h5>
                  <Row>
                    <Col>
                      <FormText
                        name="profile.address"
                        label="Address"
                        placeholder="Insert Address..."
                      />
                    </Col>
                    <Col>
                      <FormText
                        name="profile.phone"
                        label="Phone"
                        placeholder="Insert Phone..."
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormSelect
                        placeholder="Select Gender"
                        label="Gender"
                        name="profile.gender"
                        options={genders}
                      />
                    </Col>
                    <Col>
                      <FormText
                        name="profile.city"
                        label="City"
                        placeholder="Insert City..."
                      />
                    </Col>
                    <Col>
                      <FormText
                        name="profile.zip"
                        label="Zip"
                        placeholder="Insert Zip..."
                      />
                    </Col>
                  </Row>

                  <br />
                  <FormSubmit
                    title="Save changes"
                    icon={faSave}
                    disabled={save_profile.isLoading}
                  />
                </AppForm>
              )}
            </Col>
          </Row>
        )}
      </Restricted>
    </>
  );
};

export default ProfileInfo;
