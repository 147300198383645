import { FunctionComponent, useContext } from "react";
import { Card, Stack, Container, Button } from "react-bootstrap";
import { FaUserCog, FaArrowCircleRight, FaQuestion } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import Icon from "../../common/Icon";
import { IconType } from "react-icons/lib";
import { useHistory } from "react-router-dom";
import ThemeContext from "../../../context/theme/themeContext";
interface CardManagmentProps {
  title: string;
  description: string;
  link: string;
  icon: IconType;
}

const useStyles = createUseStyles({
  cardStyle: () => ({
    maxWidth: "50rem",
    minHeight: "18rem",
    transition: "all ease 200ms",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow:
        "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
  }),
  description: () => ({
    maxHeight: "3rem",
    minHeight: "3rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
  }),
});

//SEE
//https://bbbootstrap.com/snippets/bootstrap-5-jobs-card-listing-59188500
const CardManagment: FunctionComponent<CardManagmentProps> = ({
  title,
  description,
  link,
  icon,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { theme } = useContext(ThemeContext);
  return (
    <Card className={"shadow m-1 " + classes.cardStyle}>
      <Card.Body>
        <Container fluid className="text-center text-justify">
          <Stack gap={1}>
            <div className="m-auto">
              <div className="mb-2">
                <Icon
                  IconColor={"white"}
                  Icon={icon}
                  backgroundColor={theme.primary}
                  size={60}
                />
              </div>
            </div>
            <h5>
              <b>{title}</b>
            </h5>
            <div className={classes.description}>
              <span className={" text-muted"}>
                {description}              
              </span>
            </div>
            <hr />
            <div>
              <div className="float-end">
                <a
                  onClick={() => history.push(link)}
                  className="btn mr-1 btn-sm"
                >
                  <FaArrowCircleRight /> Manage
                </a>
              </div>
              {/* <Button
                size={"sm"}
                variant="outline-secondary"
                className="float-end"
                onClick={() => history.push(link)}
              >
                Manage <FaArrowCircleRight />
              </Button> */}
            </div>
          </Stack>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default CardManagment;
