interface PermissionsObject {
  main: MainPermissions;
  dashboard: DashboardPermissions;
  auth: UserPermissions;
  management: ManagementPermissions;
}
interface DefaultPermissions {
  view?: string;
  add?: string;
  change?: string;
  delete?: string;
}

interface UserPermissions extends DefaultPermissions {
  view_me: string;
}

interface ReportPermissions extends DefaultPermissions {
  add_maintenance: string;
}

interface NotificationPermissions extends DefaultPermissions { }

interface AnalyticsPermissions extends DefaultPermissions {
  forecast: string;
}

interface MainPermissions {
  graph: string;
  analytics: AnalyticsPermissions;
  struct: StructPermissions;
  notification: NotificationPermissions;
  report: ReportPermissions;
  usage_maintenances: UsageMaintenancePermissions;
  usage_maintenances_complete: UsageMaintenanceCompletePermissions;
  usage_maintenances_operation_complete: UsageMaintenanceOperationCompletePermissions;
  scheduled_maintenance: ScheduledMaintenancePermissions;
  scheduled_maintenance_complete: ScheduledMaintenanceCompletePermissions;
  scheduled_maintenance_operation_complete: ScheduledMaintenanceOperationCompletePermissions;
}
interface DashboardPermissions {
  view: string;
  view_maintenances?: string;
  view_stats?: string;
  view_notifications?: string;
}

interface StructPermissions extends DefaultPermissions { }
interface UsageMaintenancePermissions extends DefaultPermissions { }
interface UsageMaintenanceCompletePermissions extends DefaultPermissions { }
interface UsageMaintenanceOperationCompletePermissions
  extends DefaultPermissions { }
interface ScheduledMaintenancePermissions extends DefaultPermissions { }
interface ScheduledMaintenanceCompletePermissions extends DefaultPermissions { }
interface ScheduledMaintenanceOperationCompletePermissions
  extends DefaultPermissions { }

/* Management*/
interface ManagementPermissions {
  temperature_condition: TemperatureConditionPermissions;
  valve_condition: ValveConditionPermissions;
}

interface TemperatureConditionPermissions extends DefaultPermissions { }
interface ValveConditionPermissions extends DefaultPermissions { }

export const permissions: PermissionsObject = {
  dashboard: {
    view: "main.view_dashboard",
    view_maintenances: "main.view_maintenances_dashboard",
    view_stats: "main.view_stats_dashboard",
    view_notifications: "main.view_notifications_dashboard",
  },
  auth: {
    view_me: "main.view_me",
    add: "main.add_user",
    view: "main.view_user",
    change: "main.change_user",
    delete: "main.delete_user",
  },

  main: {
    graph: "main.view_plot",
    analytics: {
      view: "statistic.view_statistic",
      forecast: "statistic.can_forecast",
    },
    notification: {
      view: "main.view_notification",
      change: "main.change_notification",
    },
    struct: {
      add: "main.add_struct",
      view: "main.view_struct",
      change: "main.change_struct",
      delete: "main.delete_struct",
    },
    report: {
      add: "main.add_report",
      view: "main.view_report",
      add_maintenance: "main.add_report_maintenance",
    },
    usage_maintenances: {
      view: "main.view_usagebasedmaintenance",
      add: "main.add_usagebasedmaintenance",
      change: "main.change_usagebasedmaintenance",
      delete: "main.delete_usagebasedmaintenance",
    },
    usage_maintenances_complete: {
      view: "main.view_usagebasedmaintenancecomplete",
      add: "main.add_usagebasedmaintenancecomplete",
      change: "main.change_usagebasedmaintenancecomplete",
      delete: "main.delete_usagebasedmaintenancecomplete",
    },
    usage_maintenances_operation_complete: {
      view: "main.view_usagebasedoperationcomplete",
      add: "main.add_usagebasedoperationcomplete",
      change: "main.change_usagebasedoperationcomplete",
      delete: "main.delete_usagebasedoperationcomplete",
    },
    scheduled_maintenance: {
      view: "scheduled_maintenance.view_scheduledmaintenance",
      add: "scheduled_maintenance.add_scheduledmaintenance",
      change: "scheduled_maintenance.change_scheduledmaintenance",
      delete: "scheduled_maintenance.delete_scheduledmaintenance",
    },
    scheduled_maintenance_complete: {
      view: "scheduled_maintenance.view_usagebasedmaintenancecomplete",
      add: "scheduled_maintenance.add_scheduledmaintenancecomplete",
      change: "scheduled_maintenance.change_scheduledmaintenancecomplete",
      delete: "scheduled_maintenance.delete_scheduledmaintenancecomplete",
    },
    scheduled_maintenance_operation_complete: {
      view: "scheduled_maintenance.view_scheduledoperationcomplete",
      add: "scheduled_maintenance.add_scheduledoperationcomplete",
      change: "scheduled_maintenance.change_scheduledoperationcomplete",
      delete: "scheduled_maintenance.delete_scheduledoperationcomplete",
    },
  },
  management: {
    temperature_condition: {
      add: "main.add_temperaturecondition",
      change: "main.change_temperaturecondition",
      view: "main.view_temperaturecondition",
      delete: "main.delete_temperaturecondition",
    },
    valve_condition: {
      add: "main.add_valvecondition",
      view: "main.view_valvecondition",
      change: "main.change_valvecondition",
      delete: "main.delete_valvecondition",
    },
  },
};
