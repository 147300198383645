import React, { useContext, useState } from "react";
import {
  Col,
  Row,
  Card,
  Image,
  Container,
  Tabs,
  Tab,
  TabPane,
} from "react-bootstrap";
import Restricted from "../context/permission/restricted";
import { permissions } from "../services/permissions/permissions";
import Backdrop from "../components/common/backdrop/backdrop";
import ProfileChangePassword from "../components/features/profile/profile-change-password";
import ProfileInfo from "../components/features/profile/profile-info";
import withLayout from "../hoc/withLayout";
import ThemeContext from "../context/theme/themeContext";
import { ITheme } from "../context/theme/theme";
import { createUseStyles } from "react-jss";
import ProfileOverview from "../components/features/profile/profile-overview";

interface ProfileProps { }

const useStyles = createUseStyles({
  tabStyle: (theme: ITheme) => ({
    "&.nav-tabs .nav-link": {
      color: "black",
    },
    "&.nav-tabs .nav-link:hover": {
      color: "black",
    },
    "&.nav-tabs .nav-link.active": {
      color: "black",
      borderColor: theme.primary + " #dee2e6 #fff",
    },
  }),
});

const Profile: React.FunctionComponent<ProfileProps> = () => {
  const [tab, setTab] = useState<string | null>("ms");
  const { theme } = useContext(ThemeContext);
  const styles = useStyles({ ...theme });

  const onSelectTab = (
    eventKey: string | null,
    e: React.SyntheticEvent<unknown>
  ) => {
    setTab(eventKey);
  };

  return (
    <React.Fragment>
      <Restricted to={permissions.auth.view_me} showFallback={true}>
        <Container fluid>
          <Row>
            <Col sm={12} md={4}>
              <ProfileOverview />
            </Col>
            <Col sm={12} md={8}>
              <Card className="p-2">
                <Tabs
                  defaultActiveKey="ms"
                  transition={true}
                  onSelect={onSelectTab}
                  id="noanim-tab-example"
                  className={styles.tabStyle + " mb-3 justify-content-center"}
                >
                  <Tab
                    tabClassName="link"
                    eventKey="ms"
                    title={"Personal details"}
                  >
                    <ProfileInfo />
                  </Tab>
                  <Tab eventKey="mm" title={"Change password"}>
                    <ProfileChangePassword />
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Container>
      </Restricted>
    </React.Fragment>
  );
};

export default Profile;
