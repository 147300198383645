import React from "react";
import { Permission } from "./permission";

type PermissionContextType = {
  isAllowedTo: (permission: Permission) => boolean;
  setPermissions: (new_permissions: Permission[] | undefined) => void;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => {
    return false;
  },
  setPermissions: (permission) => {},
};

// Create the context
const PermissionContext =
  React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
