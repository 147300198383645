import { FunctionComponent, useEffect, useState } from "react";
import AppForm from "../../../formik-forms/AppForm";
import {
  useSaveTemperatureCondition,
  useTemperatureConditionById,
} from "../../../../queries/management/temperature.condition.hook";
import { FormikHelpers, FormikValues } from "formik";
import alertService from "../../../../utils/alertService";
import withLayout from "../../../../hoc/withLayout";
import { Card, Col, Container, Row } from "react-bootstrap";
import FormText from "../../../formik-forms/FormText";
import CloseButton from "../../../common/buttons/close-button";
import FormSubmit from "../../../formik-forms/FormSubmit";
import { useHistory, useParams } from "react-router-dom";
import FormSelect from "../../../formik-forms/FormSelect";
import { ITemperatureCondition } from "../../../../services/temperature/temperature-condition-service";
import { useGetTemperatures } from "../../../../queries/management/temperature.hook";
import _ from "lodash";
import { SelectOption } from "../../../common/selects/select-option";
import Backdrop from "../../../common/backdrop/backdrop";
import * as Yup from "yup";
interface TemperatureConditionProps {}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required(),
  description: Yup.string()
    .matches(
      /^(?![\n]*$)/,
      "Description can't be only line breaks and whitespace"
    )
    .required(),
  temperature_id: Yup.number().required("Temperature is required"),
  start_lower_range: Yup.number()
    .required("Start value is required")
    .default(0),
  start_upper_range: Yup.number()
    .required("Start value is required")
    .default(0),
  target_lower_range: Yup.number()
    .required("Start value is required")
    .default(0),
  target_upper_range: Yup.number()
    .required("Start value is required")
    .default(0),
  default_avg: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .nullable(true),
  threshold_max: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .nullable(true),
  threshold_min: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .nullable(true),
});

const TemperatureConditionForm: FunctionComponent<
  TemperatureConditionProps
> = ({}) => {
  const [SelectedCondition, setSelectedCondition] =
    useState<ITemperatureCondition>();
  const [conditions, setConditions] = useState<SelectOption[]>([]);
  const history = useHistory();
  let { id } = useParams<any>();

  const dataById = useTemperatureConditionById();

  const save = useSaveTemperatureCondition();

  const temperatures = useGetTemperatures();

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      save.mutate(values);
      console.debug(values);
    } catch (error: any) {}
  };

  const checkId = async () => {
    try {
      if (id === "new" || id === undefined) {
        setSelectedCondition({} as ITemperatureCondition);
        return;
      }
      const condition = await dataById.mutateAsync(id);

      if (!condition) return history.replace("/not-found");

      setSelectedCondition(condition.response);
    } catch (error: any) {
      history.replace("/404");
    }
  };

  const mapOptions = (response: any[] | undefined) => {
    const structs_format = _.map(response, (item) => {
      console.debug(item);
      return {
        label: item.temperature_variable_id.description,
        value: item.id,
      };
    });
    return structs_format;
  };

  useEffect(() => {
    checkId();
  }, []);

  useEffect(() => {
    if (conditions.length == 0) {
      if (temperatures.data?.response) {
        const result = mapOptions(temperatures.data.response);
        console.debug(result);
        setConditions(result);
      }
    }
  }, [temperatures]);

  return (
    <Container>
      <Backdrop show={dataById.isLoading} />
      <Card className="p-3">
        {<h3>{id === "new" || id === undefined ? "New" : "Modify"}</h3>}
        <hr />
        <Card.Body>
          {SelectedCondition && (
            <AppForm
              validationSchema={validationSchema}
              initialValues={{
                id: SelectedCondition?.id,
                name: SelectedCondition?.name,
                description: SelectedCondition?.description,
                start_lower_range: SelectedCondition?.start_lower_range,
                start_upper_range: SelectedCondition?.start_upper_range,
                target_lower_range: SelectedCondition?.target_lower_range,
                target_upper_range: SelectedCondition?.target_upper_range,
                temperature_id: SelectedCondition?.temperature_id?.id,
                threshold_max: SelectedCondition?.threshold_max,
                threshold_min: SelectedCondition?.threshold_min,
                default_avg: SelectedCondition?.default_avg,
              }}
              onSubmit={doSubmit}
            >
              <Row>
                <h5>
                  <b>Condition details</b>
                </h5>
                <Col>
                  <FormText
                    name="name"
                    label="Name"
                    placeholder="Insert Name..."
                  />
                </Col>
                <Col>
                  <FormText
                    name="description"
                    label="Description"
                    placeholder="Insert description..."
                  />
                </Col>
                <Col>
                  <FormSelect
                    placeholder="Select Temperature"
                    label="Temperature"
                    helpText="The temperature to apply to the condition"
                    name="temperature_id"
                    options={conditions}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormText
                    name="start_lower_range"
                    label="Start lower range"
                    placeholder="Insert start lower.."
                    type={"number"}
                  />
                </Col>
                <Col>
                  <FormText
                    name="start_upper_range"
                    label="Start upper range"
                    placeholder="Insert start upper..."
                    type={"number"}
                  />
                </Col>
                <Col>
                  <FormText
                    name="target_lower_range"
                    label="Target lower range"
                    placeholder="Insert target lower..."
                    type={"number"}
                  />
                </Col>
                <Col>
                  <FormText
                    name="target_upper_range"
                    label="target upper range"
                    placeholder="Insert target upper..."
                    type={"number"}
                  />
                </Col>
              </Row>
              <Row></Row>
              <br />
              <h5>
                <b>Threshold options</b>
              </h5>
              <Row>
                <Col>
                  <FormText
                    name={"default_avg"}
                    label="Average"
                    placeholder="Define average..."
                    type={"number"}
                    helpText="The typical daily average"
                  />
                </Col>
                <Col>
                  <FormText
                    name={"threshold_max"}
                    label="Max limit"
                    placeholder="Define max limit..."
                    type={"number"}
                    helpText={
                      "The upper limit of the daily average to be considered good"
                    }
                  />
                </Col>
                <Col>
                  <FormText
                    name={"threshold_min"}
                    label="Min limit"
                    placeholder="Define min limit..."
                    type={"number"}
                    helpText="The lower limit of the daily average to be considered good"
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-5">
                <CloseButton label="Close" onClick={() => history.goBack()} />
                <span className="me-2"></span>
                <FormSubmit title="Save changes" />
              </div>
            </AppForm>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TemperatureConditionForm;
