import { FormikHelpers, FormikValues } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import AppForm from "../../../formik-forms/AppForm";
import {
  IMaintenanceUsedModel,
  usage_based_service,
} from "../../../../services/maintenance/usage-based/usage-based-service";
import * as Yup from "yup";
import FormText from "../../../formik-forms/FormText";
import FormTextArea from "../../../formik-forms/FormTextArea";
import FormSelect from "../../../formik-forms/FormSelect";
import FormSwitch from "../../../formik-forms/FormSwitch";
import CloseButton from "../../../common/buttons/close-button";
import FormSubmit from "../../../formik-forms/FormSubmit";
import { useHistory, useParams } from "react-router-dom";
import FormArray, {
  FormArrayButtonTemplateProps,
} from "../../../formik-forms/FormArray";
import { FormArrayTemplateProps } from "../../../formik-forms/FormArray";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import { MdDeleteOutline, MdAddCircleOutline } from "react-icons/md";
import withLayout from "../../../../hoc/withLayout";
import { struct_service } from "../../../../services/struct/struct-service";
import _, { values } from "lodash";
import useApi from "../../../../hooks/useApi";
import { Routes } from "../../../../Routes";
import { usage_based_operation_service } from "../../../../services/maintenance/usage-based/operation-service";
import { WaitForRequest } from "../../../../utils/alertService";
import Backdrop from "../../../common/backdrop/backdrop";
import { SelectOption } from "../../../common/selects/select-option";

interface UsageBasedFormProps {}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  title: Yup.string().required(),
  description: Yup.string()
    .matches(
      /^(?![\n]*$)/,
      "Description can't be only line breaks and whitespace"
    )
    .required(),
  expiration: Yup.number().required("Expiration is required"),
  initial_value: Yup.number().required("Initial value is required").default(0),
  enabled: Yup.boolean().required().default(true),
  is_seconds: Yup.boolean().required().default(false),
  struct_id: Yup.string().required("Select a structure"),
  monitored_variable_id: Yup.number(),
  operations: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().required("Order is required"),
      title: Yup.string().required("Title is required"),
      description: Yup.string()
        .required("Description is required")
        .typeError("A Description is required"),
    })
  ),
});

//https://morioh.com/p/e0af642621cc

const UsageBasedForm: FunctionComponent<UsageBasedFormProps> = () => {
  const [structs, setStructs] = useState<SelectOption[]>([]);
  const [variables, setVariables] = useState<SelectOption[]>([]);
  const [usageBased, setUsageBased] = useState<IMaintenanceUsedModel>(); // for modify the user
  const history = useHistory();
  let { id } = useParams<any>();
  const saveMaintenance = useApi(usage_based_service.save);
  const getMaintenance = useApi(usage_based_service.getById);
  const structApi = useApi(struct_service.get);
  const structVariablesApi = useApi(struct_service.getStructVariables);

  const doSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      console.debug(values);
      if (values.description && values.description.trim())
        console.debug("space");
      //console.debug(valueToUse)
      const response = await saveMaintenance.request(values);
      history.push(Routes.management_usage_based);
    } catch (error: any) {
      usage_based_service.checkErrors(error);
    }
  };
  const loadStruct = async () => {
    try {
      const structs_db = await structApi.request();
      const { response } = structs_db;
      const structs_format = _.map(response, (struct) => {
        return { label: struct.name, value: struct.id };
      });

      setStructs(structs_format);
    } catch (error: any) {
      struct_service.checkErrors(error);
    }
  };

  const handleChangeStruct = async (value?: string) => {
    try {
      var structs_var = await structVariablesApi.request(value);

      const { response } = structs_var;
      const variables_format = _.map(response, (variable) => {
        return { label: variable.description, value: variable.id };
      });
      if (variables_format) {
        setVariables(variables_format);
      }
    } catch (error: any) {
      console.debug(error);
    }
  };

  const checkId = async () => {
    try {
      if (id === "new" || id === undefined) {
        setUsageBased({
          initial_value: 0,
          enabled: true,
        } as IMaintenanceUsedModel);
        return;
      }
      const usageBased = await getMaintenance.request(id);

      if (!usageBased) return history.replace("/not-found");
      await handleChangeStruct(usageBased?.response?.struct?.id.toString());
      setUsageBased(usageBased.response);
    } catch (error: any) {
      history.replace("/404");
    }
  };

  useEffect(() => {
    loadStruct();
    checkId();
    //if (usageBased) handleChangeStruct(usageBased?.struct?.id.toString());
  }, []);

  let operations =
    usageBased && _.orderBy(usageBased.operations, ["order"], ["asc"]);
  return (
    <Container>
      <Backdrop
        show={
          structApi.loading ||
          structVariablesApi.loading ||
          getMaintenance.loading
        }
      />
      <Card>
        <Card.Body>
          {<h3>{id === "new" || id === undefined ? "New" : "Modify"}</h3>}
          <hr />
          {usageBased && (
            <AppForm
              validationSchema={validationSchema}
              initialValues={{
                id: usageBased && usageBased.id,
                title: usageBased && usageBased.title,
                description: usageBased && usageBased.description,
                struct_id: usageBased && usageBased.struct?.id,
                expiration: usageBased && usageBased.expiration,
                initial_value: usageBased && usageBased.initial_value,
                enabled: usageBased && usageBased.enabled,
                is_seconds: usageBased && usageBased.is_seconds,
                monitored_variable_id:
                  usageBased && usageBased.monitored_variable?.id,
                operations: operations && operations,
              }}
              onSubmit={doSubmit}
            >
              <Row>
                <Col>
                  <FormText
                    name="title"
                    label="Title"
                    placeholder="Insert a title..."
                    helpText="Title of the maintenance"
                  />
                </Col>
                <Col>
                  <FormTextArea
                    name="description"
                    label="Description"
                    placeholder="Insert a description..."
                    rows={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormSelect
                    placeholder="Select structure"
                    label="Structure"
                    helpText="Structure of the monitored variable."
                    name="struct_id"
                    options={structs}
                    onChangeValue={handleChangeStruct}
                  />
                </Col>
                <Col>
                  <FormSelect
                    placeholder="Select variable"
                    label="Monitored variable"
                    name="monitored_variable_id"
                    helpText="Variable monitored for the maintenance."
                    options={variables}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormText
                    name="expiration"
                    label="Expiration"
                    placeholder="Insert a expiration..."
                    type={"number"}
                    helpText="Value that triggers the maintenance."
                  />
                </Col>
                <Col>
                  <FormText
                    name="initial_value"
                    label="Initial value"
                    placeholder="Insert a initial value..."
                    type={"number"}
                    helpText="Start value for the maintenance."
                  />
                </Col>
                <Col sm={2} md={2}>
                  <br />
                  <FormSwitch
                    name={"enabled"}
                    label={"Is enabled"}
                    helpText="Enabled if the monitored variable is expressed in seconds and want the be converted in hours."
                  />
                </Col>
                <Col sm={2} md={2}>
                  <br />
                  <FormSwitch
                    name={"is_seconds"}
                    label={"Is seconds"}
                    helpText="Enabled for active the maintenance."
                  />
                </Col>
              </Row>
              <Row></Row>

              <h4>Operations</h4>
              <hr />
              <FormArray
                name={"operations"}
                TemplateArrayComponent={FormArrayTemplate}
                TemplateAddButtonComponent={FormArrayButtonTemplate}
              />
              <br></br>

              <CloseButton
                label="Close"
                onClick={() => history.goBack()}
                disabled={saveMaintenance.loading}
              />
              <span className="me-2"></span>
              <FormSubmit
                title="Save changes"
                disabled={saveMaintenance.loading}
              />
            </AppForm>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UsageBasedForm;

const FormArrayTemplate: FunctionComponent<FormArrayTemplateProps> = ({
  name,
  index,
  item,
  OnRemove,
}) => {
  const deleteFromDb = async (item: any, index: number) => {
    try {
      if (item && item.id) {
        let func = async () =>
          await usage_based_operation_service.delete(item.id);
        const response = await WaitForRequest(
          "Confirm",
          "Are you sure you want to delete this operation ?",
          func
        );
        if (response) {
          //OK
        } else {
          return;
        }
      }

      OnRemove && OnRemove(index);
    } catch (error: any) {
      usage_based_operation_service.checkErrors(error);
    }
  };
  return (
    <Row className="border justify-content-center p-1 m-1 shadow-sm">
      <Col lg={1} md={2} sm={6}>
        <FormText
          name={`${name}.${index}.order`}
          label="Order"
          placeholder="Write a Order..."
          type={"number"}
          helpText="Define the order."
        />
      </Col>
      <Col lg={4} md={4} sm={6}>
        <FormText
          name={`${name}.${index}.title`}
          label="Title"
          placeholder="Write a title..."
          helpText="Title of the operation, needs to be unique."
        />
      </Col>
      <Col lg={5} md={4} sm={12}>
        <FormTextArea
          name={`${name}.${index}.description`}
          label="Description"
          placeholder="Write a description..."
          rows={2}
          helpText="Description of the operation"
        />
      </Col>
      <Col lg={2} md={2} sm={12}>
        <Button
          className="mt-5"
          variant="outline-danger"
          size="sm"
          onClick={() => {
            //OnRemove && OnRemove(index);
            deleteFromDb(item, index);
          }}
        >
          <MdDeleteOutline />
        </Button>
      </Col>
    </Row>
  );
};

export const FormArrayButtonTemplate: FunctionComponent<
  FormArrayButtonTemplateProps
> = ({ OnPush }) => {
  return (
    <>
      <Row>
        <div className="d-flex justify-content-start">
          <a
            onClick={() =>
              OnPush && OnPush({ order: "", description: "", title: "" })
            }
            className="btn mr-1 btn-sm"
          >
            <MdAddCircleOutline /> Add operation.
          </a>
        </div>
      </Row>
      {/* <Button
        variant="outline-primary"
        size="sm"
        onClick={() =>
          OnPush && OnPush({ order: "", description: "", title: "" })
        }
      >
        <MdAddCircleOutline />
      </Button> */}
    </>
  );
};
