import { ICategory } from "../../services/struct/signal-category-service";
import {
  IStruct,
  IStructByCategory,
} from "../../services/struct/struct-service";
import data from "./struct-default-data.json";
import temperature from "./struct-temperature-data.json";
import valve from "./struct-valve-data.json";
import struct_category_data from "./struct-category-demo-data.json";

class StructDemoData {
  private struct_default: IStruct[];
  private struct_temperature: IStruct[];
  private struct_valve: IStruct[];
  private struct_category: IStructByCategory[];
  constructor() {
    this.struct_default = this.loadStructDefault();
    this.struct_temperature = this.loadStructTemperature();
    this.struct_valve = this.loadStructValve();
    this.struct_category = this.loadStructCategory();
  }

  private loadStructDefault = () => {
    const myJSON = JSON.stringify(data.response);
    const obj: IStruct[] = JSON.parse(myJSON);
    return obj;
  };

  private loadStructTemperature = () => {
    const myJSON = JSON.stringify(temperature.response);
    const obj: IStruct[] = JSON.parse(myJSON);
    return obj;
  };

  private loadStructValve = () => {
    const myJSON = JSON.stringify(valve.response);
    const obj: IStruct[] = JSON.parse(myJSON);
    return obj;
  };

  private loadStructCategory = () => {
    const myJSON = JSON.stringify(struct_category_data.response);
    const obj: IStructByCategory[] = JSON.parse(myJSON);
    return obj;
  };

  getDefaultStruct = () => {
    return this.struct_default;
  };

  getTemperaturetStruct = () => {
    return this.struct_temperature;
  };

  getValveStruct = () => {
    return this.struct_valve;
  };

  update = (new_data: IStruct) => {
    const index = this.struct_default.findIndex((x) => x.id === new_data.id);
    if (index > -1) {
      this.struct_default[index] = new_data;
    }
  };
}
class StructCategoryDemoData {
  private struct_category: IStructByCategory[];
  constructor() {
    this.struct_category = this.loadStructCategory();
  }

  private loadStructCategory = () => {
    const myJSON = JSON.stringify(struct_category_data.response);
    const obj: IStructByCategory[] = JSON.parse(myJSON);
    return obj;
  };
  getData = () => {
    return this.struct_category;
  };
}
export const struct_demo_data = new StructDemoData();
export const struct_category_demo_data = new StructCategoryDemoData();
