import { AxiosError } from "axios";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";
import { NotificationTemperatureDemo } from "../../demo/notification/notification-temperature-demo";
import storage from "../base/storage";

export interface IResultInfo {
  threshold_max: number;
  threshold_min: number;
  duration: number;
  reference_period: Date;
  did_not_reach: number;
  resample_rule?: any;  
}

interface ITypeId {
  id: number;
  created_at: Date;
  updated_at: Date;
  description: string;
  type: string;
}

interface IStruct {
  id: number;
  name: string;
  description: string;
  processing: string;
  type_id: ITypeId;
}

interface ICondition {
  id: number;
  name: string;
  start_lower_range: number;
  start_upper_range: number;
  target_lower_range: number;
  target_upper_range: number;
}

export interface INotificationTemperature {
  id: number;
  updated_at: Date;
  type: string;
  day_to_threshold?: any;
  monotony?: any;
  threshold_approaching?: any;
  mail_sent_at?: any;
  is_sent?: any;
  is_read: boolean;
  comment?: any;
  user?: any;
  is_incorrect: boolean;
  result_info: IResultInfo;
  struct: IStruct;
  condition: ICondition;
}

export interface ISaveTemperatureNotification {
  id: number;
  comment: string;
  is_incorrect: boolean;
  is_read: boolean;
}

class NotificationTemperatureService {
  private http: IHttp = Http;
  private endpoint = "notifications/warning/temperature";

  private getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<INotificationTemperature[]>);
        })
        .catch((response: Error | AxiosError<any>) => {
          reject(response);
        });
    });
  }

  private generateParams(query_params: Record<string, any> = {}): string {
    let params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  get = (
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<INotificationTemperature[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(this.http.get(this.endpoint + "/" + "?" + params));
  };

  updateFeedback(
    data: ISaveTemperatureNotification
  ): Promise<ResponseWrapper<any>> {
    return this.getPromise(this.http.post(this.endpoint + "/", data));
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new NotificationTemperatureDemo()
    : new NotificationTemperatureService();

export const notification_temperature_service = instance;
