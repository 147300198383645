import React, { FunctionComponent, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import NoDataFound from "../../plot-common/no-data-found";
import PlotAnalytics from "./plot-analytics";

import Plotly, { PlotRelayoutEvent } from "plotly.js";
import {
  TimeSeries,
  Statistic,
} from "../../../../services/statistic/statistic-plot-service";
import TableStas from "../table-stats";
interface PlotAnalyticsViewProps {
  time_series: TimeSeries[];
}

const graph_height = 300;

const table_stats_columns: any[] = [
  {
    label: "AVG",
    key: "avg",
    compare: "mean_ref",
    tooltip:
      "Average:the mean between the average value of each day that is part of the period.",
  },
  {
    label: "Ref",
    key: "mean_ref",
    tooltip:
      "Reference: the standard value that the the daily average of the signal should assume.",
  },
  {
    label: "Dif",
    key: "ref_compare",
    tooltip:
      "Difference: the gap between the average and the desired value (reference).",
  },
  {
    label: "Min",
    key: "min",
    tooltip:
      "Minimum: minimum daily average among the days belonging to the reporting period",
  },
  {
    label: "Max",
    key: "max",
    tooltip:
      "Maximum: maximum daily average among the days belonging to the reporting period",
  },
  {
    label: "Std",
    key: "std_dev",
    tooltip: "Standard Deviation: how much the values deviates from the mean.",
  },
  {
    label: "5 %",
    key: "pctl_5",
    tooltip:
      "5-th Percentile: is the value above which 95% of the observations may be found.",
  },
  {
    label: "95 %",
    key: "pctl_95",
    tooltip:
      "95-th Percentile: is the value below which 95% of the observations may be found.",
  },
];

const getTableColumns = (signal: Statistic) => {
  var copy_table = [...table_stats_columns];
  console.debug(signal.day_to_threshold);
  if (signal && signal.day_to_threshold && signal.day_to_threshold >= 0) {
    if (signal.day_to_threshold <= 30)
      copy_table.push({
        label: "Days thold",
        key: "day_to_threshold",
        tooltip:
          "Days to thold: days remaining until the threshold is reached.",
        color: "#FFA3A6",
      });
  }
  return copy_table;
};

// #region Events Plots
const graph: any[] = []; // global variable to store the information of the graph
var plot_range: any[] = [];
const handleInitializeGraph = (figure: any, id: any) => {
  try {
    //console.debug("Initialize " + id);
    var doc = document.getElementById(id);
    graph[id] = doc;
    //this.connectPlotlyEvents(doc);
  } catch (error) {}
};
const handleUpdateGraph = (figure: any, id: any) => {
  try {
    //console.debug("Updating " + id);
    var doc = document.getElementById(id);
    graph[id] = doc;
    graph.forEach(async (element) => {
      /* console.debug("Remove event");
        await element?.removeAllListeners("plotly_relayout", this.handleRelaout); */
      //console.debug("Connect event");
      try {
        await element?.removeAllListeners("plotly_relayout", handleRelaout);
        await element.on("plotly_relayout", (events: any) => {
          handleRelaout(events);
        });
      } catch (error) {}
    });
  } catch (error) {}
};
const handleRelaout = async (e: any) => {
  const events: PlotRelayoutEvent = e;
  //console.debug(e);
  var key = Object.keys(e)[0];
  var key2 = Object.keys(e)[1];
  var x1_new = e[key];
  var x2_new = e[key2];
  //if the keys are undefined return.
  if (key === undefined || key2 === undefined) return;
  //if the keys are not xaxis return.
  if (!key.includes("xaxis") && !key2.includes("xaxis")) {
    return;
  }

  // if the values are undefined return
  if (x1_new === undefined || x2_new === undefined) {
    return;
  } else {
    //if the values are equal return
    if (
      plot_range !== null &&
      plot_range !== undefined &&
      plot_range[0] === x1_new &&
      plot_range[1] === x2_new
    ) {
      return;
    }
  }
  //console.debug("here");
  //remove all listeners
  graph.forEach(async (element) => {
    //console.debug("Relayout");
    try {
      await element?.removeAllListeners("plotly_relayout", handleRelaout);
    } catch (error) {}
  });
  //relayout
  graph.forEach(async (element) => {
    //console.debug("Relayout");
    var layout = {
      xaxis: {
        range: [x1_new, x2_new],
      },
    };
    try {
      await Plotly.relayout(element, layout);
    } catch (error) {}
  });
  //connet listeners
  graph.forEach(async (element) => {
    //console.debug("Connect event");
    try {
      await element.on("plotly_relayout", (events: any) => {
        handleRelaout(events);
      });
    } catch (error) {}
  });
};
//#endregion

const PlotAnalyticsView: FunctionComponent<PlotAnalyticsViewProps> = ({
  time_series,
}) => {
  //var stats = organizedDataByItemId(time_series, table_time_series);

  useEffect(() => {}, []);
  return (
    <Row>
      {time_series && time_series.length === 0 && (
        <>
          <Col>
            <NoDataFound height={graph_height} />
          </Col>
        </>
      )}
      {time_series &&
        time_series.length > 0 &&
        time_series.map((signal) => (
          <React.Fragment
            key={signal && signal.struct_name + signal.description}
          >
            <Col lg={6} md={12} sm={12} className="tour_analytics_signals">
              <Card className="p-2" style={{ minHeight: graph_height }}>
                <PlotAnalytics
                  signal={signal}
                  onInitialized={handleInitializeGraph}
                  onUpdate={handleUpdateGraph}
                  onRelayout={handleRelaout}
                />
                {signal.graph.mean_values && (
                  <TableStas
                    columns={getTableColumns(signal.statistic)}
                    signal={[signal.statistic]}
                  />
                )}
              </Card>
            </Col>
          </React.Fragment>
        ))}
    </Row>
  );
};

export default React.memo(PlotAnalyticsView);
