import { PlotVariableDemo } from "../../demo/plot/plot-variable-demo";
import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";
import storage from "../base/storage";
import { IResponse } from "./plot-struct-service";
const endpoint = "plot/variable/";

export type IPlotVariableRequest = {
  variables: number[];
  start_date: string;
  end_date: string;
  timezone?: string;
  bucket?: string;
};

class PlotVariableService {
  endpoint: string;
  http: any;
  constructor() {
    this.endpoint = endpoint;
    this.http = Http;
  }
  
  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
  getMany(data: IPlotVariableRequest): Promise<ResponseWrapper<IResponse>> {
    return this.getPromise<any>(this.http.post(endpoint, data));
  }
}


const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new PlotVariableDemo()
    : new PlotVariableService();

export const plot_variable_service = instance
