import { FunctionComponent } from "react";
import { Card, Row, Container, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";
import {
  MdDeleteOutline,
  MdModeEditOutline,
  MdAddCircle,
  MdRefresh,
  MdCheckCircle,
  MdCancel,
} from "react-icons/md";
import { IUser } from "../../../../services/users/user-service";
import Backdrop from "../../../common/backdrop/backdrop";
import * as alertService from "../../../../utils/alertService";
import Restricted from "../../../../context/permission/restricted";
import { permissions } from "../../../../services/permissions/permissions";
import Table from "../../../table/table";
import { TableColumn } from "react-data-table-component";
import withLayout from "../../../../hoc/withLayout";
import { Routes } from "../../../../Routes";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import { useDeleteUser, useGetUsers } from "../../../../queries/user.hook";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearch,
  saveSearch,
} from "../../../../store/reducers/users.management-reducer";
interface UsersProps {}

const Users: FunctionComponent<UsersProps> = () => {
  const history = useHistory();

  //Redux store
  const dispatch = useDispatch();
  const search = useSelector(getSearch);

  //RQ
  const { data, isLoading, refetch } = useGetUsers();
  const deletUser = useDeleteUser();

  const columns: TableColumn<IUser>[] = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "First name",
      selector: (row) => row.first_name,
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "Group",
      selector: (row) => (row.group ? row.group.name : "No group"),
      sortable: true,
    },
    {
      name: "Active",
      //selector: (row) => row.is_active,
      sortable: true,
      cell: (user) => (
        <>
          {user.is_active && (
            <MdCheckCircle style={{ color: "green", fontSize: "15px" }} />
          )}
          {!user.is_active && (
            <MdCancel style={{ color: "red", fontSize: "15px" }} />
          )}
        </>
      ),
    },
    {
      name: "Staff member",
      //selector: (row) => row.is_staff,
      sortable: true,
      cell: (user) => (
        <>
          {user.is_staff && (
            <MdCheckCircle style={{ color: "green", fontSize: "15px" }} />
          )}
          {!user.is_staff && (
            <MdCancel style={{ color: "red", fontSize: "15px" }} />
          )}
        </>
      ),
    },
    {
      cell: (user) => (
        <>
          <Restricted to={permissions.auth.change}>
            <Button
              variant="outline-warning"
              onClick={() => {
                history.push(Routes.users + "/" + user.id);
              }}
              size="sm"
            >
              <MdModeEditOutline />
            </Button>
          </Restricted>
          <span> &nbsp; </span>
          <Restricted to={permissions.auth.delete}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => handleDelete(user)}
            >
              <MdDeleteOutline />
            </Button>
          </Restricted>
        </>
      ),
      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  const orderUsers = (data: IUser[] | undefined) => {
    const filter = orderBy(data, (user) => user.group.name, ["asc"]);
    return filter;
  };

  const handleDelete = async (user: IUser) => {
    let func = (password: string) =>
      deletUser.mutate({ dataId: user.id, password });
    const response = alertService.WaitForRequestWithInput(
      "Confirm",
      "This operation can't be undo ",
      func
    );
    if ((await response) === true) {
      await alertService.Ok("User deleted");
    }
  };
  return (
    <>
      <Container fluid>
        {/* <BreadCrumb />  */}

        <Restricted to={permissions.auth.view} showFallback={true}>
          <Backdrop show={isLoading} />
          <Card className="p-3">
            <h4>
              <GoBackNavigation url={Routes.management} />
              Users
            </h4>
            <hr />
            <Row>
              <div className="d-flex justify-content-start">
                <Restricted to={permissions.auth.add}>
                  <Link to={Routes.users_new} className="btn mr-1 btn-sm">
                    <MdAddCircle /> New
                  </Link>
                </Restricted>
                <a
                  onClick={() => {
                    refetch();
                  }}
                  className="btn mr-1 btn-sm"
                >
                  <MdRefresh /> Refresh
                </a>
              </div>
            </Row>

            <Table
              columns={columns}
              data={data?.response ? data?.response : []}
              pagination={true}
              filterItems={undefined}
              searchText={search}
              onSearchChange={(text: string) => {
                dispatch(saveSearch(text));
              }}
            />
          </Card>
        </Restricted>
      </Container>
    </>
  );
};

export default Users;
