import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import alertService from "../utils/alertService";
import { QueryKeys, StaleTime } from "./query_keys";
import { ResponseWrapper } from "../models/reponse";
import { model_type_service } from "../services/model_type/model-type-service";

// TODO implement keys by file? or globals?

export const useGetModelType = () => {
  const model_type = useQuery({
    queryKey: [QueryKeys.GET_MODEL_TYPE],
    queryFn: () => model_type_service.get(),
    onSuccess: (data) => {},
    onError: (error: AxiosError<any>) => {
      if (error && error.response?.data) {
        alertService.errorMessage(error.response?.data);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });
  return model_type;
};
