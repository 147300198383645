import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { timezone_service } from "../../services/timezoneService";
import alertService from "../../utils/alertService";
import { QueryKeys, StaleTime } from "../query_keys";
import { ResponseError, ResponseWrapper } from "../../models/reponse";
import { useDispatch } from "react-redux";
import { decreaseScheduled } from "../../store/reducers/badge-reducer";
import { scheduled_service } from "../../services/maintenance/scheduled/scheduled-service";
import {
  IOperation,
  sheduled_operation_service,
} from "../../services/maintenance/scheduled/operation-service";
import { scheduled_result_service } from "../../services/maintenance/scheduled/complete-service";

export const useGetScheduled = () => {
  const timezone: string = timezone_service.getTimezone();

  const scheduled = useQuery({
    queryKey: [QueryKeys.GET_SCHEDULED_MAINTENANCE],
    queryFn: () => scheduled_service.get({ timezone: timezone }),
    onSuccess: (data) => {
      return data.response;
    },
    onError: (error?: ResponseError) => {
      if (error) {
        error.error && alertService.errorMessage(error?.error);
      }
    },
    staleTime: StaleTime.SECONDS_30, //Important when the data is not valid
  });

  return scheduled;
};

type IGetOperation = {
  id: number;
  date: string;
  hour: string;
  timezone: string;
};

export const useGetScheduledOperations = () => {
  const get_operations = useMutation<
    ResponseWrapper<IOperation[]>,
    any,
    IGetOperation
  >({
    mutationFn: (data) => {
      return sheduled_operation_service.getOperations(
        data.id,
        data.date,
        data.hour,
        data.timezone
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {},
  });
  return get_operations;
};

type ISaveOperation = {
  id: number;
  checked: boolean;
  date: string;
  time: string;
};

export const useSaveScheduledOperations = () => {
  const timezone: string = timezone_service.getTimezone();
  const get_operations = useMutation<
    any, //no response
    any,
    ISaveOperation
  >({
    mutationFn: (data) => {
      return sheduled_operation_service.save(
        {
          scheduled_operation_id: data.id,
          completed: data.checked,
          programmed_date: data.date,
          programmed_hour: data.time,
        },
        { timezone: timezone }
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {},
  });
  return get_operations;
};

interface ICompleteScheduled {
  scheduled_maintenance_id: number;
  programmed_date: string;
  programmed_hour: string;
  comment: string;
}

export const useCompleteScheduled = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const timezone: string = timezone_service.getTimezone();
  const get_operations = useMutation<
    any, //no response
    any,
    ICompleteScheduled
  >({
    mutationFn: (data) => {
      return scheduled_result_service.save(data, { timezone: timezone });
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {
      //invalid query per refresh notification
      queryClient.invalidateQueries([QueryKeys.GET_BADGES]);
      //decrease the number of expired notifications
      dispatch(decreaseScheduled());
    },
  });
  return get_operations;
};
