import axios, { AxiosPromise } from "axios";
import storage from "./storage";
import * as alertService from "../../utils/alertService";
import { IAccess } from "./authentication";
import { ResponseWrapper } from "../../models/reponse";

export const DEFAULT_HOST = "http://localhost:7000";

export const PATH_API = "/api/";

export const getUrl = (): string | undefined => {
  try {
    const url = process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : DEFAULT_HOST + PATH_API;

    const server = localStorage.getItem("server");

    const local_url =
      server && server != null ? `${server}${PATH_API}` : undefined;

    return local_url ? local_url : url;
  } catch (error) {
    return undefined;
  }
};
//Singleton instance of axios for the application.
export const instance = axios.create({
  baseURL: getUrl(),
  timeout: 60000 * 5,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

//Instance to handle the refresh request.
const instance_refresh = axios.create({
  baseURL: getUrl(),
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

// Request interceptors for API calls
instance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers["Authorization"] = `JWT ${storage.getToken()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//Response interceptors for API calls
instance.interceptors.response.use(undefined, async (error) => {
  if (error.response) {
    //console.debug(error.response);
  }
  //Intercept network errors.
  const networkerror = String(error).toLowerCase();
  if (networkerror.includes("network error")) {
    alertService.customMessage(
      "Server unreachable",
      "Check your network connection and try again."
    );
  }
  //intercept Internal server error
  const expectedError = error.response && error.response.status == 500;

  if (expectedError) {
    alertService.errorMessage("Internal server error");
  }
  return Promise.reject(error);
});

let isRefreshing = false;
let refreshSubscribers: any[] = [];

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = () => {
  const token = storage.getToken();
  refreshSubscribers.forEach((cb) => cb(token));
  console.debug("calling onRrefreshed " + refreshSubscribers.length);
};
//Intercept Unauthorized request.
instance.interceptors.response.use(undefined, async (error) => {
  if (
    error.response &&
    error.response.status === 401 &&
    error.response.headers
  ) {
    //Quando il token non è più valido ci provo ad aggiornarlo
    //nel caso che vengono fatti più richieste vengono messe in coda
    // nel array "refreshSubscribers".
    const user = storage.getUser();
    if (user !== null) {
      if (!isRefreshing) {
        isRefreshing = true;
        tryToRefreshToken().then((success) => {
          if (success) {
            isRefreshing = false;
            onRefreshed();
            refreshSubscribers = [];
          } else {
            Promise.reject();
            alertService.autoRedirectMessage(
              "Your session has expired!",
              "You will be redirect to login",
              () => {
                //Remove invalid token and redirect
                const path = window.location.pathname;
                storage.removeToken();
                window.location.href = "/login?redirect=" + path;
              }
            );
          }
        });
      }

      const retryOrigReq: Promise<AxiosPromise<any>> = new Promise(
        (resolve, reject) => {
          subscribeTokenRefresh((token: string) => {
            const config = error?.config;
            config.headers = {
              ...config.headers,
              Authorization: `JWT ${token}`,
            };
            //return instance(config);
            resolve(axios(config));
          });
        }
      );
      return retryOrigReq;
    }
  }
  return Promise.reject(error);
});

const refresCall = async (refreshToken: string) => {
  const endpoint_refresh = "auth/jwt/refresh";
  try {
    const { data } = await instance_refresh.post<ResponseWrapper<IAccess>>(
      endpoint_refresh + "/",
      {
        refresh: refreshToken,
      }
    );
    return data;
  } catch (error: any) {
    return null;
  }
};

const tryToRefreshToken = async () => {
  const refreshToken = storage.getRefreshToken();
  if (refreshToken) {
    var result = await refresCall(refreshToken);
    if (result?.response) {
      //save the data of the new token.
      storage.setTokenObj(result.response);

      return true;
    }
  }
  return false;
};

const setBaseUrl = (url: string) => {
  instance.defaults.baseURL = url;
  instance_refresh.defaults.baseURL = url;
};

export type IHttp = {
  get: typeof instance.get;
  post: typeof instance.post;
  put: typeof instance.put;
  patch: typeof instance.patch;
  delete: typeof instance.delete;
  setBaseUrl: typeof setBaseUrl;
};

const Http: IHttp = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  patch: instance.patch,
  delete: instance.delete,
  setBaseUrl: setBaseUrl,
};

export default Http;

// export default Http ={
//   get = instance.get;
//   post = instance.post;
//   put = instance.put;
//   patch = instance.patch;
//   delete = instance.delete;
//   setJwt = setJwt;
// }
