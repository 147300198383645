import report from "../assests/report_demo.pdf";

/**
 * get the url for the report demo, from the web browser will on the folder src.
 * instead in the electron app need to be the folder of the public assets.
 *
 */
const report_demo_url =report;

export { report_demo_url };
