import React, { FunctionComponent, useContext, useState } from "react";
import { Navbar, Nav, NavItem, Dropdown, Badge } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useMenuItems } from "./menu-items";
import ThemeContext from "../../context/theme/themeContext";
import { createUseStyles } from "react-jss";
import { ITheme } from "../../context/theme/theme";
import {
  MdSettingsApplications,
  MdAccountCircle,
  MdExitToApp,
  MdOutlineKeyboardArrowDown,
  MdSettings,
} from "react-icons/md";
import Settings from "./settings";
import storage, { IUser } from "../../services/base/storage";
import { useTranslation } from "react-i18next";
import { timezone_service } from "../../services/timezoneService";
import { useGetBadges } from "../../queries/badge.hook";
import { getUrl } from "services/base/http-services";

interface BottomNavbarProps {
  onLogOut?: React.MouseEventHandler<HTMLAnchorElement>;
}

const getUrlServer = (): string | undefined => {
  const PATH_ADMIN = "/admin/";
  const url = getUrl();
  if (url) {
    const urlObject = new URL(url);
    const server_name = `${urlObject.protocol}//${urlObject.hostname}:${urlObject.port}${PATH_ADMIN}`;
    return server_name;
  }
};

const TopNavBar: FunctionComponent<BottomNavbarProps> = ({ onLogOut }) => {
  const useStyles = createUseStyles({
    NavItem: (theme: ITheme) => ({
      fontSize: "1rem",
      marginLeft: "10px",
      borderRadius: "5px",
      "&.active": {
        backgroundColor: theme.primary + " !important",
        borderColor: theme.primary,
        color: "white !important",
        opacity: 0.8,
        borderRadius: "5px",
      },
      "&:hover": {
        backgroundColor: theme.primary + " !important",
        borderColor: theme.primary,
        color: "white !important",
        textDecoration: "none",
        opacity: 0.8,
        borderRadius: "5px",
      },
      "&:focus": {
        textDecoration: "none",
        borderRadius: "5px",
      },
    }),
    NavBotoomItem: (theme: ITheme) => ({
      fontSize: "0.8rem",
      marginLeft: "1px",
      borderRadius: "5px",
      "&.active": {
        backgroundColor: theme.primary + " !important",
        borderColor: theme.primary,
        color: "white !important",
        opacity: 0.8,
        borderRadius: "5px",
      },
      "&:hover": {
        backgroundColor: theme.primary + " !important",
        borderColor: theme.primary,
        color: "white !important",
        textDecoration: "none",
        opacity: 0.8,
        borderRadius: "5px",
      },
      "&:focus": {
        textDecoration: "none",
        borderRadius: "5px",
      },
    }),
    NavSettings: (theme: ITheme) => ({
      padding: "0.5rem 1rem",
      fontSize: "1rem",
      borderRadius: "5px",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: theme.primary,
        borderColor: theme.primary,
        opacity: 0,
        color: "white !important",
        borderRadius: "5px",
      },
    }),
    NavDropDown: (theme: ITheme) => ({
      textDecoration: "none",
      fontSize: "1rem",
      borderRadius: "5px",
      //color: "black",
      "&:hover": {
        color: "white !important",
        backgroundColor: theme.primary,
        borderColor: theme.primary,
        borderRadius: "5px",
        opacity: 0.8,
      },
      "&.active": {
        backgroundColor: theme.primary + " !important",
        borderColor: theme.primary,
        color: "white !important",
        opacity: 0.8,
        borderRadius: "5px",
      },
    }),
  });
  const [showSettings, setShowSettings] = useState(false);
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = useStyles({ ...theme });
  const user: IUser | null = storage.getUser();
  const timezone_offset = timezone_service.getOffset();
  const timezone = timezone_service.getTimezone();
  const badges = useGetBadges();
  const menuItems = useMenuItems();

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
      <Nav.Link
        className={classes.NavDropDown}
        active={history.location.pathname == "/profile"}
        //style={{ textDecoration: "none" }}
        href=""
        ref={ref}
        onClick={(e: any) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {/* Render custom icon here */}

        {children}
      </Nav.Link>
    )
  );

  return (
    <>
      <div id="TopNav" className="d-none d-lg-flex d-xl-flex">
        <Navbar
          style={{
            backgroundColor: "white",
            borderBottom: "4px solid " + theme.third,
            width: "100%",
          }}
          sticky="top"
          className="shadow rounded d-none d-lg-flex d-xl-flex"
          variant="light"
          //fixed="top"
          //expand="lg"
        >
          <Navbar.Brand>
            <Link to={"/dashboard"}>
              <img
                src={theme.logo_url}
                //width="160px"
                height={"30px"}
                style={{ marginLeft: "1rem", aspectRatio: "auto 1 / 1" }}
              />
            </Link>
          </Navbar.Brand>
          <Nav className="m-auto text-center ">
            {menuItems.map((item, index) => (
              <NavItem key={"nav-" + index}>
                <Nav.Link
                  className={classes.NavItem + " position-relative"}
                  active={
                    history.location.pathname === item.path ||
                    history.location.pathname.startsWith(item.path)
                  }
                  as={Link}
                  to={item.path}
                >
                  {item.icon}

                  {/* <span className="d-none d-xl-inline"> */}
                  {t(item.text)}

                  {/* </span> */}

                  {item.badge > 0 && (
                    <Badge
                      pill
                      bg="danger"
                      className="position-absolute mt-1 top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      {item.badge > 99 ? "99+" : item.badge}
                    </Badge>
                  )}

                  {/* <span
                    style={{ left: 35,top:5 }}
                    className="position-absolute top-0 translate-middle badge rounded-pill bg-danger"
                  >
                    99+
                    <span className="visually-hidden">unread messages</span>
                  </span> */}
                </Nav.Link>
              </NavItem>
            ))}
          </Nav>
          <Nav className="float-end ">
            <div className="vr me-1"></div>

            <NavItem className="d-none d-xl-flex">
              <Nav.Link className={classes.NavItem}>
                {timezone + timezone_offset}
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Dropdown drop="down" id="basic-nav-dropdown">
                <Dropdown.Toggle as={CustomToggle} id="basic-nav-dropdown">
                  {user && user.first_name}

                  <MdOutlineKeyboardArrowDown />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ marginLeft: "-30px" }}>
                  {user?.token_type != "demo" && (
                    <>
                      <Dropdown.Item
                        style={{ marginLeft: "-0px" }}
                        as={Link}
                        className={classes.NavItem}
                        to={"/profile"}
                        active={history.location.pathname === "/profile"}
                      >
                        <MdAccountCircle /> Profile
                      </Dropdown.Item>

                      <Dropdown.Item
                        style={{ marginLeft: "-0px" }}
                        className={classes.NavItem}
                        target="_blank"
                        href={getUrlServer()}
                      >
                        <MdSettingsApplications />
                        Admin panel
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  <Dropdown.Item
                    style={{ marginLeft: "-0px" }}
                    className={classes.NavItem}
                    onClick={onLogOut}
                  >
                    Logout <MdExitToApp />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </NavItem>

            <NavItem>
              <Nav.Link
                className={classes.NavItem}
                onClick={() => setShowSettings(true)}
              >
                <MdSettings size={20} />
                Settings
              </Nav.Link>
              <Settings
                show={showSettings}
                onClose={() => setShowSettings(false)}
              />
            </NavItem>
          </Nav>
        </Navbar>
      </div>

      <div id="BottomNav" className="d-lg-none mt-2">
        <Navbar
          style={{
            backgroundColor: "white",
            borderTop: "3px solid " + theme.third,
          }}
          className="shadow d-lg-none "
          variant="light"
          fixed="bottom"
        >
          <Navbar.Brand>
            <Link to={"/dashboard"}>
              <img
                src={theme.small_logo_url}
                width="35"
                style={{ marginLeft: "10px" }}
              />
            </Link>
          </Navbar.Brand>
          <Nav className="m-auto text-center ">
            {menuItems.map((item, index) => (
              <NavItem key={"nav-" + index}>
                <Nav.Link
                  className={classes.NavBotoomItem + " position-relative"}
                  active={
                    history.location.pathname === item.path ||
                    history.location.pathname.startsWith(item.path)
                  }
                  as={Link}
                  to={item.path}
                  //style={{ fontSize: 11 }}
                >
                  {item.icon}
                  {item.badge > 0 && (
                    <Badge
                      pill
                      bg="danger"
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      {item.badge > 99 ? "99+" : item.badge}
                    </Badge>
                  )}
                </Nav.Link>
              </NavItem>
            ))}
          </Nav>

          <Nav className="p-1 float-end ">
            <div className="vr me-1"></div>
            {/* <NavItem>
              <Nav.Link className={classes.NavBotoomItem}>{timezone_offset}</Nav.Link>
            </NavItem> */}
            <NavItem>
              <Dropdown drop="up" id="basic-nav-dropdown">
                <Dropdown.Toggle as={CustomToggle} id="basic-nav-dropdown">
                  <MdAccountCircle size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ marginLeft: "-100px" }}>
                  {user?.token_type != "demo" && (
                    <>
                      <Dropdown.Item
                        as={Link}
                        className={classes.NavBotoomItem}
                        to={"/profile"}
                        active={history.location.pathname === "/profile"}
                      >
                        <MdAccountCircle /> Profile
                      </Dropdown.Item>

                      <Dropdown.Item
                        className={classes.NavBotoomItem}
                        target="_blank"
                        href={getUrlServer()}
                      >
                        <MdSettingsApplications />
                        Admin panel
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                  <Dropdown.Item
                    className={classes.NavBotoomItem}
                    onClick={onLogOut}
                  >
                    Logout <MdExitToApp />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </NavItem>
            <NavItem>
              <Nav.Link
                className={classes.NavBotoomItem}
                onClick={() => setShowSettings(true)}
              >
                <MdSettings size={20} />
              </Nav.Link>
              <Settings
                show={showSettings}
                onClose={() => setShowSettings(false)}
              />
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    </>
  );
};

export default TopNavBar;
