import Http, { IHttp } from "../../base/http-services";
import { ResponseWrapper } from "../../../models/reponse";
import * as alertService from "../../../utils/alertService";
import storage from "../../base/storage";
import { MaintenanceScheduledOperationDemo } from "../../../demo/maintenance/scheduled/operation-demo";


interface User {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

interface ResultInOperation {
  programmed_date: string;
  programmed_hour: string;
  comment: string;
  updated_at: Date;
  user: User;
}

interface ScheduledInOperation {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  description: string;
  trigger_hour: string;
  trigger_day: number[];
  start_date: string;
  end_date: string;
  enabled: boolean;
  results: ResultInOperation[];
}

export interface IOperation {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  scheduled_maintenance_id: ScheduledInOperation;
  description: string;
  order: number;
  completed: boolean;
  user?: User;
}

const endpoint_operations_complete = "maintenance/scheduled/operation/complete";
const endpoint_operations = "maintenance/scheduled/operation";
const endpoint_operations_check = "maintenance/scheduled/operation/check";
class MaintenanceScheduledOperationService {
  //Operations send always a post request, if the operation already exists automatic
  // update.
  private http = Http;
  save(
    data: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    const params = this.generateParams(query_params);
    return this.getPromise(
      this.http.post(endpoint_operations_complete + "/?" + params, data)
    );
  }

  getOperations(
    id: number,
    date: string,
    hour: string,
    timezone: string,
  ): Promise<ResponseWrapper<IOperation[]>> {
    const params = "date=" + date + "&hour=" + hour + "&timezone=" + timezone;

    return this.http
      .get(endpoint_operations_check + "/" + id + "?" + params)
      .then((response: any) => {
        return response.data as ResponseWrapper<IOperation[]>;
      });
  }

  delete(dataId: number): Promise<ResponseWrapper<any>> {
    return this.getPromise(
      this.http.delete(endpoint_operations + "/" + dataId.toString())
    );
  }

  protected getPromise(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<any>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  protected generateParams(query_params: Record<string, any> = {}): string {
    var params = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return params;
  }

  checkErrors(error: any) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error.response &&
      error.response.data &&
      error.response.status !== 403
    ) {
      var message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new MaintenanceScheduledOperationDemo()
    : new MaintenanceScheduledOperationService();

export const sheduled_operation_service = instance
