import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { MdWarning } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";
import { MdSchedule } from "react-icons/md";
import * as Yup from "yup";
import AppForm from "../../../formik-forms/AppForm";
import FormSubmit from "../../../formik-forms/FormSubmit";
import CloseButton from "../../../common/buttons/close-button";
import Operation, { OperationData } from "../operation";
import { IScheduledEvent } from "./maintenance-scheduled-calendar";
import { Ok, WaitForRequest } from "../../../../utils/alertService";
import * as dateService from "../../../../utils/date-operations";
import { FormikHelpers, FormikValues } from "formik";
import FormTextArea from "../../../formik-forms/FormTextArea";
import {
  useCompleteScheduled,
  useSaveScheduledOperations,
} from "../../../../queries/maintenance/scheduled.hook";

interface MaintenanceScheduledModalProps {
  maintenance_info: IScheduledEvent;
  maintenance_operation: any;
  showModal: boolean;
  onClose?: (refresh: boolean) => void;
}

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .matches(/^(?![\n]*$)/, "Comment can't be only line breaks and whitespace")
    .required()
    .max(1000)
    .nullable()
    .label("comment"),
});

const isCompleted = (maintenance: IScheduledEvent) => {
  /**
   * Funzione per verificare se la manutenzione è già completata
   * ritorna True se si, False se è scheduled oppure Expired.
   */
  if (!maintenance) return false;

  if (maintenance.isFinish === null) return false;

  return maintenance.isFinish;
};

const MaintenanceScheduledModal: FunctionComponent<
  MaintenanceScheduledModalProps
> = ({ maintenance_info, maintenance_operation, showModal, onClose }) => {
  const [operations, setOperations] = useState<any>([]);

  const saveOperation = useSaveScheduledOperations();
  const saveScheduled = useCompleteScheduled();

  useEffect(() => {
    setOperations(maintenance_operation);
  }, [maintenance_operation]);

  const handleOperationChange = async (
    operation: OperationData,
    checked: boolean
  ) => {
    try {
      const { _date, _time } = dateService.separateDateAndTime(
        maintenance_info.date
      );
      let func = async () =>
        await saveOperation.mutateAsync({
          id: operation.id,
          checked: checked,
          date: _date,
          time: _time,
        });

      const response = await WaitForRequest(
        "Confirm",
        checked ? "Mark as complete?" : "Unmark as complete?",
        func
      );
      if (response) {
        var update_maintenance = [...operations]; //clone array.
        var index = update_maintenance.findIndex((x) => x.id === operation.id);
        if (index !== -1) {
          update_maintenance[index].completed = checked;

          setOperations(update_maintenance);
        }
        Ok("Operation save");
      }
    } catch (error: any) {
      console.debug(error);
    }
  };

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { _date, _time } = dateService.separateDateAndTime(
      maintenance_info.date
    );

    try {
      //send timezone.
      let func = async () =>
        await saveScheduled.mutateAsync({
          scheduled_maintenance_id: maintenance_info.eventData.id,
          programmed_date: _date,
          programmed_hour: _time,
          comment: values.comment,
        });
      var response = await WaitForRequest(
        "Confirm",
        "Once the maintenance is completed it will not be possible to make changes. <br /> Are you sure you want to complete the maintenance?",
        func
      );
      if (response) {
        Ok("Scheduled maintenance completed sucessful.");

        onClose && onClose(true);
      }
    } catch (error: any) {}
  };

  console.debug("Render modal scheduled");
  return (
    <>
      <Modal
        scrollable={false}
        show={showModal}
        onHide={() => onClose && onClose(false)}
        size="lg"
        centered
      >
        <AppForm
          validationSchema={validationSchema}
          initialValues={{
            comment: maintenance_info ? maintenance_info.comment : "",
          }}
          onSubmit={handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Overview scheduled maintenance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {errors && <span className="text-danger">{errors[0]}</span>} */}
            <div className="tour_body">
              <p>
                <b>State</b>:{" "}
                {maintenance_info && maintenance_info.isFinish !== null ? (
                  maintenance_info.isFinish ? (
                    <Badge pill bg="success" text="white" className="m-1">
                      <MdCheckCircle fontSize="15px" /> Completed
                    </Badge>
                  ) : (
                    <Badge pill bg="danger" text="white" className="m-1">
                      <MdWarning fontSize="15px" /> Expired
                    </Badge>
                  )
                ) : (
                  <Badge pill bg="secondary" text="white" className="m-1">
                    <MdSchedule fontSize="15px" /> Scheduled
                  </Badge>
                )}
                {maintenance_info && maintenance_info.user && (
                  <span>
                    (<b>By</b>:{" "}
                    {maintenance_info.user.first_name &&
                    maintenance_info.user.last_name
                      ? maintenance_info.user.first_name +
                        " " +
                        maintenance_info.user.last_name
                      : maintenance_info.user.email}
                    )
                  </span>
                )}
              </p>
              <p>
                <b>Title</b>:{" "}
                {maintenance_info &&
                  maintenance_info.title &&
                  maintenance_info.title}
              </p>

              <p>
                <b>Period</b>:{" "}
                {maintenance_info &&
                  moment(maintenance_info.eventData.start_date).format(
                    "L"
                  )}{" "}
                -{" "}
                {maintenance_info &&
                  moment(maintenance_info.eventData.end_date).format("L")}
              </p>
              <p>
                <b>Datetime</b>:{" "}
                {
                  maintenance_info && (
                    <>
                      {moment(maintenance_info.date).format("L")}
                      {" - "}
                      {moment(
                        maintenance_info.eventData.trigger_hour,
                        "HH:mm:ss"
                      ).format("HH:mm")}
                    </>
                  )
                  //new Date(maintenance_info.event).toLocaleDateString()
                }
              </p>

              <p>
                <b>Trigger day</b>:{" "}
                {maintenance_info &&
                  dateService.getDays(maintenance_info.eventData.trigger_day)}
              </p>
            </div>

            <p>
              <b>Description</b>:{" "}
              {maintenance_info && maintenance_info.eventData.description
                ? maintenance_info.eventData.description
                : "No description assing for this maintenance..."}
            </p>

            <p>
              <b>Operations: </b>
              <ol className="tour_operations" style={{ lineHeight: "110%" }}>
                {operations && operations.length > 0 ? (
                  operations.map((operation: any) => (
                    <li key={"operation-"+operation.id}>
                      <Operation
                        key={operation.id}
                        data={operation}
                        disabled={isCompleted(maintenance_info)}
                        onOperationChange={handleOperationChange}
                      />
                    </li>
                  ))
                ) : (
                  <span>No operations assigned to this maintenance...</span>
                )}
              </ol>
            </p>

            <FormTextArea
              className="tour_comment"
              name="comment"
              label="Comment"
              placeholder="Write a comment..."
              rows={3}
            />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <CloseButton
              label="Close"
              onClick={() => onClose && onClose(false)}
            />
            <FormSubmit
              className="tour_complete"
              title="Complete"
              disabled={isCompleted(maintenance_info)}
            />
          </Modal.Footer>
        </AppForm>
      </Modal>
    </>
  );
};

export default MaintenanceScheduledModal;
