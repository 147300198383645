import { PlotData, Config, LayoutAxis } from "plotly.js";
import React, { FunctionComponent, useEffect, useState } from "react";
import Plot, { Figure } from "react-plotly.js";
import Plotly, { PlotRelayoutEvent } from "plotly.js";
import { MAX_POINTS_ON_GRAPH } from "./constants";
import { IRange } from "./interfaces";
import { Button } from "react-bootstrap";
import TabsCustom from "../../../common/tabs-custom";

//const MAX_POINTS_ON_GRAPH =200000;

const config: Partial<Config> = {
  displaylogo: false,
  responsive: true,
  autosizable: true,
  fillFrame: false,
  displayModeBar: true,
  showAxisRangeEntryBoxes: false,
  plotlyServerURL: "",
  editable: false,
  showLink: false,
  showEditInChartStudio: false,
  scrollZoom: false,
  //watermark: false,
  showTips: false,
  showAxisDragHandles: false,
  modeBarButtonsToRemove: ["lasso2d", "select2d", "resetScale2d"],
};

type graphMode =
  | "lines"
  | "markers"
  | "text"
  | "lines+markers"
  | "text+markers"
  | "text+lines"
  | "text+lines+markers"
  | "none"
  | "gauge"
  | "number"
  | "delta"
  | "number+delta"
  | "gauge+number"
  | "gauge+number+delta"
  | "gauge+delta";

const colors = {
  blu: "rgb(0, 88, 176)",
  grey: "#7D6B7D",
  grey_light: "#E7E6F0",
  blu_light: "rgb(0, 128, 200,0.1)",
  green: "#81C784",
};

type ILayoutAxis = {
  key: string;
  data: Partial<LayoutAxis>;
};

const createTraces = (
  signals: ISignal[],
  graphMode: graphMode,
  range: IRange
): { traces: any[]; annotations: any[]; layout_obj: any[] } => {
  const traces: Partial<PlotData>[] = [];
  const layout_axes: ILayoutAxis[] = [];
  var annotations: any[] = [];
  var layout_obj: any = {};
  let count: number = 1;

  signals.forEach((signal) => {
    const status = generateLegend(signal);
    const signal_size = signal.time ? signal.time.length : 0;
    const toManyPoints: boolean = signal_size >= MAX_POINTS_ON_GRAPH;
    const data: Partial<PlotData> = {
      x: signal.time,
      y: signal.value,
      xaxis: count > 1 ? "x" + count.toString() : "x",
      yaxis: count > 1 ? "y" + count.toString() : "y",
      mode: graphMode ? graphMode : "lines+markers", //graphMode,
      type: "scattergl",
      name: "", //Blank always with custom hover template
      customdata: status, //handleStatus(signal.status),
      //hoverlabel: {},
      hoverinfo: "skip",
      hovertemplate: toManyPoints
        ? undefined
        : "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br /> <b>Status:</b>  %{customdata} <br />",
      showlegend: false,
      line: {
        shape: "hv",
        width: 2.8,
        //color: "rgb(112, 180, 255)",
        color: graphMode === "lines" ? colors.blu : colors.grey_light,
      },
      marker: {
        //color: "rgb(0, 128, 200,0.1)",
        color: colors.blu,
        //color: "#81C784",
        //color: "#7D6B7D",
        size: 4.5,
      },
    };
    //:Partial<Annotations>
    const annotation = {
      text: "<b> " + signal.struct_name + "</b> - " + signal.description,
      showarrow: false,
      align: "center",
      x: 0,
      xref: "x" + count.toString() + " domain",
      y: 1.15,
      yref: "y" + count.toString() + " domain",
    };
    //Partial<Annotations>
    //Anotation when the graph dont have data.
    const annotation_empty = {
      text: "No data found",
      xref: "x" + count.toString() + " domain",
      yref: "y" + count.toString() + " domain",
      showarrow: false,
      font: {
        size: 24,
      },
    };
    const annotation_to_many = {
      text: "Too many points, the legend will not be displayed, try a lower range.",
      showarrow: false,
      align: "center",
      x: 0,
      xref: "x" + count.toString() + " domain",
      y: 1.1,
      yref: "y" + count.toString() + " domain",
      font: {
        size: 10,
        color: "red",
      },
    };

    //layout style for each axe X and Y
    const layout_xaxe: ILayoutAxis = {
      key: "xaxis" + count.toString(), //chiave da mappare.
      data: {
        type: "date",
        visible: signal.time ? true : false,
        range: range && [range.rangeX1, range.rangeX2],
      },
    };
    const layout_yaxe: ILayoutAxis = {
      key: "yaxis" + count.toString(), //chiave da mappare.
      data: {
        visible: signal.time ? true : false,
        ticksuffix:
          signal &&
          signal.unit_of_measurement &&
          " " + signal.unit_of_measurement,
        showgrid: true,
      },
    };

    if (signal.time === null && signal.value === null) {
      annotations.push(annotation_empty);
    }
    if (toManyPoints) {
      annotations.push(annotation_to_many);
    }

    annotations.push(annotation);
    traces.push(data);
    layout_axes.push(layout_xaxe);
    layout_axes.push(layout_yaxe);

    count = count + 1;
  });
  //console.debug(layout_axes)
  //creo object

  layout_axes.forEach((element: any) => {
    layout_obj[element.key] = { ...element.data };
  });
  //console.debug(layout_obj)
  return { traces, annotations, layout_obj };
};
// Layout can non asssing a type because "xref : x domain " does not exist.
const createLayout = (
  rows: number,
  columns: number,
  numberOftraces: number,
  annotations: any = undefined,
  layout_obj: any = undefined
) => {
  //console.debug(numberOftraces)
  var layout: any = {
    title: "",
    grid: {
      rows: rows,
      columns: columns,
      pattern: "independent",
      //subplots:[['xy','x2y','x3y'],['x4y1','x5y1','x6y1']],
      //roworder:'bottom to top',
    },
    ...layout_obj,
    margin: {
      r: 70,
      l: 70,
      t: 70,
      b: 70,
    },
    hovermode: "closed", //false to disabled
    autosize: true,
    hoverlabel: { bgcolor: "#FFF" }, //Color hover
    annotations: annotations ? annotations : undefined,
    paper_bgcolor: "#1C00ff00",
    plot_bgcolor: "#1C00ff00",
    modebar: {
      orientation: "h",
      bgcolor: "#1C00ff00",
      activecolor: "#6c757d",
      color: "#adb5bd",
    },
  };
  return layout;
};

const generateLegend = (signal: any) => {
  const statusLegend = [];
  if (signal.status) {
    signal.status.forEach((element: any) => {
      element = "<br /> - " + element;
      const newStuff = element.replace(/,/g, "<br /> - ");
      //console.debug(newStuff);
      statusLegend.push(newStuff ? newStuff : "No Status");
    });
  } else {
    //Put fake date
    if (signal.time) {
      for (const element of signal.time) {
        statusLegend.push("No Status");
      }
    }
  }
  return statusLegend;
};

const calcolateRows = (numberOftraces: number, columns: number) => {
  if (numberOftraces <= columns) {
    return 1;
  }
  let rows_cal: number = numberOftraces / columns;
  if (rows_cal % 1 !== 0) {
    //has decimal
    rows_cal = parseInt(rows_cal.toFixed(0)) + 1;
    return rows_cal;
  }
  return rows_cal;
};

interface ISignal {
  id: string;
  ref?: any;
  name: string;
  struct_name: string;
  description: string;
  time: string[];
  value: string[];
  status: string[];
  unit_of_measurement: string;
}

type Props = {
  SelectedGraphMode?:
    | "lines"
    | "markers"
    | "text"
    | "lines+markers"
    | "text+markers"
    | "text+lines"
    | "text+lines+markers"
    | "none"
    | "gauge"
    | "number"
    | "delta"
    | "number+delta"
    | "gauge+number"
    | "gauge+number+delta"
    | "gauge+delta";
  signals: ISignal[];
  selectedGraphByRow?: number;
  selectedRange?: IRange;
  onUpdateRange?: (range: IRange) => void;
  onAfterPlot?: (() => void) | undefined;
};

const SubPlotRaw: React.FunctionComponent<Props> = ({
  signals,
  selectedGraphByRow = 3,
  SelectedGraphMode = "lines+markers",
  selectedRange = {} as IRange,
  onUpdateRange,
  onAfterPlot,
}) => {
  const [graphMode, setGraphMode] = useState(SelectedGraphMode);
  const [columns, setCols] = useState(selectedGraphByRow);
  const [rangeDefined, setRangeDefined] = useState<any>(selectedRange);
  useEffect(() => {
    if (
      selectedRange.rangeX1 != rangeDefined.rangeX1 &&
      selectedRange.rangeX2 != rangeDefined.rangeX2
    ) {
      console.debug("here1");
      setRangeDefined(selectedRange);
    }
  }, [selectedRange]);

  useEffect(() => {
    setCols(selectedGraphByRow);
  }, [selectedGraphByRow]);

  useEffect(() => {
    setGraphMode(SelectedGraphMode);
  }, [SelectedGraphMode]);

  console.debug("render subplot");

  const handleRelayout = async (e: any) => {
    const events: PlotRelayoutEvent = e;

    const key = Object.keys(e)[0];
    const key2 = Object.keys(e)[1];
    //if the keys are undefined return.
    if (key === undefined || key2 === undefined) return;
    //if the keys are not xaxis return.
    if (!key.includes("xaxis") && !key2.includes("xaxis")) {
      return;
    }
    const x1_new: any = e[key];
    const x2_new: any = e[key2];
    //console.debug(x1);
    //console.debug(x2);
    // if the values are undefined return
    if (x1_new === undefined || x2_new === undefined) return;
    //if the values are equal return
    if (rangeDefined.rangeX1 === x1_new && rangeDefined.rangeX2 === x2_new)
      return;

    if (x1_new && x2_new) {
      //console.debug([x1_new,x2_new]);
      const total = signals.length;
      const layout_axes: any[] = [];
      const layout_obj: any = {};
      for (let index = 1; index <= total; index++) {
        const layout_xaxe = {
          key: index === 1 ? "xaxis" : "xaxis" + index.toString(), //chiave da mappare.
          data: {
            range: [x1_new, x2_new],
            //range:[rangeDefined.rangeX1,rangeDefined.rangeX2]
          },
        };
        const layout_yaxe = {
          key: index === 1 ? "yaxis" : "yaxis" + index.toString(), //chiave da mappare.
          data: {
            range: null,
          },
        };
        layout_axes.push(layout_xaxe);
        //layout_axes.push(layout_yaxe) lose the unit of mesuarement
      }
      layout_axes.forEach((element: any) => {
        layout_obj[element.key] = { ...element.data };
      });
      //console.debug(...layout_obj)
      try {
        const element: any = document.getElementById("subplot");
        await element?.removeAllListeners("plotly_relayout", handleRelayout);
        await Plotly.relayout("subplot", { ...layout_obj });
        await element?.on("plotly_relayout", (events: any) => {
          handleRelayout(events);
        });
      } catch (error: any) {}

      //Update the range const
      rangeDefined.rangeX1 = x1_new;
      rangeDefined.rangeX2 = x2_new;
      onUpdateRange && onUpdateRange({ rangeX1: x1_new, rangeX2: x2_new });
    }
  };

  const rows = calcolateRows(signals.length, columns);

  const { traces, annotations, layout_obj } = createTraces(
    signals,
    graphMode,
    rangeDefined
  );
  const layuout_final = createLayout(
    rows,
    columns,
    signals.length,
    annotations,
    layout_obj
  );
  console.debug("Render SubPlot " + rows + " columns " + columns);
  return (
    <React.Fragment>
      {/* <ButtonRestyle /> */}
      <Plot
        onAfterPlot={onAfterPlot}
        data={traces}
        divId={"subplot"}
        style={{ minHeight: 400 * rows, margin: 0, backgroundColor: "none" }}
        onRelayout={handleRelayout}
        layout={layuout_final}
        config={config}
        useResizeHandler={false}
      />
      <br />
    </React.Fragment>
  );
};

export default React.memo(SubPlotRaw);

interface ButtonRestyleProps {}

const ButtonRestyle: FunctionComponent<ButtonRestyleProps> = () => {
  const [value, setValue] = useState<string>("lines+markers");
  const handleRestiling = async (divId: string, update: Partial<PlotData>) => {
    await Plotly.restyle("subplot", update);
  };
  const options = [
    { key: "lines+markers", content: "Lines and Markers" },
    { key: "lines", content: "Lines" },
    { key: "markers", content: "Markers" },
  ];
  return (
    <div className="d-flex justify-content-center ">
      <TabsCustom
        value={value}
        onTabChanged={(tab) => tab && setValue(tab)}
        source={options}
      />
      <Button
        className="m-1"
        onClick={() =>
          handleRestiling("subplot", {
            line: { color: "red" },
            mode: "lines+markers",
          })
        }
      >
        Lines and markers
      </Button>

      <Button
        className="m-1"
        onClick={() =>
          handleRestiling("subplot", {
            line: { color: "red" },
            mode: "lines",
          })
        }
      >
        Lines
      </Button>
      <Button
        className="m-1"
        onClick={() =>
          handleRestiling("subplot", {
            line: { color: "red" },
            mode: "markers",
          })
        }
      >
        Markers
      </Button>
    </div>
  );
};
