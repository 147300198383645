import moment, { Moment } from "moment";

// validate dates
const today = moment();
export function isLessThatTodayAndIsAfterDate(
  current: Moment,
  startDate: Moment
) {
  let isSame=current.isSameOrBefore(today,"days")
  let isAfter=current.isSameOrAfter(startDate,"days")


  
  //console.debug(startDate.format("YYYY-MM-DD HH:mm") + " is After "+  current.format("YYYY-MM-DD HH:mm") + isAfter)
  /*Valida the given data if is less that today and greater that passing date*/
  return isSame && isAfter;
}

export function isLessThatTodayAndDate(current: Moment, endDate: Moment) {
  return current.isSameOrBefore(today) && current.isSameOrBefore(endDate);
}

//get the days on the specific month.
export function getDaysInMonth(month: number, year: number): Date[] {
  var date: Date = new Date(year, month, 1);
  var days: Date[] = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
//get the days on the specific month In UTC.
export function getDaysInMonthUTC(month: number, year: number) {
  var date = new Date(Date.UTC(year, month, 1));
  var days = [];
  while (date.getUTCMonth() === month) {
    days.push(new Date(date));
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return days;
}

export function getMomentOnString(date: Moment) {
  var month = "" + date.month();
  var day = "" + date.day();
  var year = "" + date.year();
  // console.debug(month)
  // console.debug(day)
  // console.debug(year)

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  var date_string = [year, month, day].join("-");

  return date_string;
}

export function separateDateAndTime(date: string | Date) {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();
  var hour = "" + d.getHours();
  var minuts = "" + d.getMinutes();
  var seconds = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (hour.length < 2) hour = "0" + hour;
  if (minuts.length < 2) minuts = "0" + minuts;
  if (seconds.length < 2) seconds = "0" + seconds;

  var _date = [year, month, day].join("-");
  var _time = [hour, minuts, seconds].join(":");
  return { _date, _time };
}
export function getDateTimeOnString(
  date: Date,
  withSeconds: boolean = true
): string {
  var d = new Date(date);

  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();
  var hour = d.getHours().toString();
  var minuts = d.getMinutes().toString();
  var seconds = "" + d.getSeconds().toString();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (hour.length < 2) hour = "0" + hour;
  if (minuts.length < 2) minuts = "0" + minuts;
  if (seconds.length < 2) seconds = "0" + seconds;

  var date_string = [year, month, day].join("-");
  var time_string = "";
  //if want seconds
  if (withSeconds) {
    time_string = [hour, minuts, seconds].join(":");
  } else {
    time_string = [hour, minuts, "00"].join(":");
  }
  //console.debug(time_string);
  return date_string + " " + time_string;
}

export function getDateOnString(date: Date): string {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  var date_string = [year, month, day].join("-");
  return date_string;
}

export function getTimeOnString(date: Date): string {
  var d = new Date(date);
  var hour = d.getHours().toString();
  var minuts = d.getMinutes().toString();

  return [hour, minuts].join(":");
}

//Function get day of week for calendar

export function getDay(day: number): string {
  return days[day];
}

export function getDays(array: number[]): string {
  var filter: string[] = [];
  array.forEach((day) => {
    filter.push(days[day]);
  });
  return filter.join(",");
}

const days: { [key: string]: any } = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};
