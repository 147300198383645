
import { ResponseWrapper } from "../../models/reponse";
import {
  IPlotAnalyticsModel,
  IStatisticVariableId,
} from "../../services/statistic/statistic-plot-service";
import { plot_analytics_demo } from "./data";

export class PlotAnalyticsDemo {
  getPlotAndStatistic(
    struct_id: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IPlotAnalyticsModel>> {
    return new Promise<any>((resolve, reject) => {
      const plot_analytics = plot_analytics_demo.getDefaultPlot();
      const response: ResponseWrapper<IPlotAnalyticsModel> = {
        status: 200,
        version: "demo",
        response: plot_analytics,
      } as ResponseWrapper<IPlotAnalyticsModel>;
      resolve(response);
    });
  }

  getPlotAndStatisticByIds = (
    variablesIds: IStatisticVariableId[],
    start_date: string,
    end_date: string
  ): Promise<ResponseWrapper<IPlotAnalyticsModel>> => {
    return new Promise<any>((resolve, reject) => {
      const plot_analytics = plot_analytics_demo.getDefaultPlot();
      
      
      const ids = variablesIds.map((x) => x.variable_id.toString());
      
      const result = plot_analytics.time_series.filter((x) => {
        if (ids.includes(x.id.toString())) return x;
      });

      const response: ResponseWrapper<IPlotAnalyticsModel> = {
        status: 200,
        version: "demo",
        response: {
          time_series: result,
          alarm: [],
        },
      } as ResponseWrapper<IPlotAnalyticsModel>;
      resolve(response);
    });
  };

  checkErrors(error: any) {
    console.debug(error);
  }
}
