import { ResponseWrapper } from "../../models/reponse";
import Http from "../base/http-services";
import { BaseService } from "../base/base-service";

const endpoint = "model-type";

type IModelType = {
  id?: number;
  update_at: Date;
  description: string;
  type: string;
};

class ModelTypeService extends BaseService<IModelType> {
  constructor() {
    super(endpoint, Http);
  }
}

export const model_type_service = new ModelTypeService();
