import React from 'react';
import { ITheme } from './theme';

type ThemeContextType = {
    theme:ITheme;
}


const defaultBehaviour: ThemeContextType = {
   theme:{}
}

// Create the context
const ThemeContext = React.createContext<ThemeContextType>(defaultBehaviour);

export default ThemeContext;