import React, { useState, useEffect, FunctionComponent } from "react";
import { Row, Col } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { GroupBase } from "react-select";
import Datetime from "react-datetime";
import Button from "react-bootstrap/Button";
import moment, { Moment } from "moment";
import * as alertService from "../../../utils/alertService";
import {
  isLessThatTodayAndDate,
  isLessThatTodayAndIsAfterDate,
} from "../../../utils/date-operations";
import SelectSingle from "../../common/selects/select-single";
import { SelectOption } from "../../common/selects/select-option";
import SelectMultiple from "../../common/selects/select-multiple";

interface PlotBarCategoriesAnalyticsProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  selectedStartDate: Moment;
  onStartDate?: (date: any) => void | undefined;
  selectedEndDate: Moment;
  onEndDate?: (date: any) => void | undefined;

  //Structures
  structCategories: GroupBase<SelectOption>[];
  selectedStructs?: SelectOption[];
  onStructSelected?: (data: SelectOption[], input: any) => void | undefined;

  //Categories Select
  categories: SelectOption[];
  selectedCategory: number;
  onCategoryChange?: (data: SelectOption) => void;

  currentInputValue?: any;
  timeEnabled: boolean;
  isInAutoRefresh: boolean;
}

const PlotBarCategoriesAnalytics: FunctionComponent<PlotBarCategoriesAnalyticsProps> = ({
  structCategories,
  onClick,
  selectedStartDate,
  onStartDate,
  selectedEndDate,
  onEndDate,
  currentInputValue,
  selectedStructs,
  onStructSelected,
  categories,
  selectedCategory,
  onCategoryChange,
  isInAutoRefresh = false,
  timeEnabled = true,
}) => {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(7, "d")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const [endDate, setEndDate] = useState(moment());
  const [structsSelected, setStructsSelected] = useState(selectedStructs);
  const [categoryId, setCategoryId] = useState(selectedCategory);
  const [input, setInput] = useState(currentInputValue);
  const [disabledTo, setDisabledTo] = useState(isInAutoRefresh);

  console.debug("Render Plotbar Categories.jsx");

  useEffect(() => {
    setDisabledTo(isInAutoRefresh);
  }, [isInAutoRefresh]);

  useEffect(() => {
    setCategoryId(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    setInput(currentInputValue);
  }, [currentInputValue]);

  useEffect(() => {
    setStructsSelected(selectedStructs);
  }, [selectedStructs]);

  useEffect(() => {
    setStartDate(selectedStartDate);
  }, [selectedStartDate]);

  useEffect(() => {
    setEndDate(selectedEndDate);
  }, [selectedEndDate]);

  const graphMode = [
    { label: "Lines and markers", value: "lines+markers" },
    { label: "Lines", value: "lines" },
    { label: "Markers", value: "markers" },
  ];

  const onInputChange = (query: any, { action }: any) => {
    // Prevents resetting our input after option has been selected
    if (action !== "set-value") setInput(query);
  };

  const isSameDate = () => {
    if (startDate === undefined) return false;
    if (endDate === undefined) return false;
    const start = moment(startDate);
    return start.isSame(endDate, "days");
  };

  const isSameHour = () => {
    if (startDate === undefined) return false;
    if (endDate === undefined) return false;
    const start = moment(startDate);
    const end = moment(endDate);
    return start?.hour() === end?.hour() && start.isSame(end, "days");
  };

  const selectValue = categories.find((x) => x.value == categoryId.toString());
  return (
    <React.Fragment>
      <Row>
        <Col lg={3} md={3} sm={12} className="tour_signaltype">
          <label>Signal type:</label>
          <SelectSingle
            options={categories}
            placeholder="Select signal type..."
            value={selectValue}
            onValueChange={(data: SelectOption) => {
              onCategoryChange && onCategoryChange(data);
              setCategoryId(data.value ? parseInt(data.value) : 0);
              setStructsSelected([]); //Clean selected structs
            }}
          />
        </Col>

        <Col lg={4} md={3} sm={12} className="tour_structures">
          <label>Structure:</label>
          <SelectMultiple
            label={""}
            placeholder="Select structures..."
            options={structCategories}
            value={structsSelected}
            onValueChange={(data: SelectOption[]) => {
              if (data && data.length > 12) {
                alertService.errorMessage(
                  "Max number of selected items (12) exceeded"
                );
                return;
              }
              onStructSelected && onStructSelected(data, input);
              setStructsSelected(data);
            }}
          />
        </Col>

        <Col lg={2} md={3} xs={6} className="tour_from">
          <label>From:</label>
          <Datetime
            isValidDate={(current, selected) =>
              isLessThatTodayAndDate(current, endDate)
            }
            initialValue={startDate}
            value={startDate}
            inputProps={{
              placeholder: "From",
              style: {
                height: 35,
              },
            }}
            timeFormat={timeEnabled ? "HH:mm" : false}
            onChange={(date: any) => {
              setStartDate(date);
              onStartDate && onStartDate(date);
            }}
          />
        </Col>

        <Col lg={2} md={3} xs={6} className="tour_to">
          <label>To:</label>
          <Datetime
            isValidDate={(current, selected) =>
              isLessThatTodayAndIsAfterDate(current, startDate)
            }
            timeConstraints={{
              hours: {
                min:
                  startDate && endDate && isSameDate()
                    ? startDate.hours?.()
                    : 0,
                max: 23,
                step: 1,
              },
              minutes: {
                min:
                  startDate && endDate && isSameHour()
                    ? startDate.minutes?.()
                    : 0,
                max: 59,
                step: 1,
              },
            }}
            initialValue={endDate}
            value={endDate}
            timeFormat={timeEnabled ? "HH:mm" : false}
            inputProps={{
              placeholder: "To",
              disabled: disabledTo,
              style: {
                height: 35,
              },
            }}
            onChange={(date: any) => {
              setEndDate(date);
              onEndDate && onEndDate(date);
            }}
          />
        </Col>
        <Col lg={1} md={2} sm={12} className="tour_request">
          <label></label>
          <br></br>
          <Button variant="secondary" onClick={onClick}>
            <MdSearch size={25}></MdSearch>
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PlotBarCategoriesAnalytics;
