import React, { FunctionComponent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Restricted from "../../../../context/permission/restricted";
import Backdrop from "../../../common/backdrop/backdrop";
import {
  IMaintenanceUsedModel,
  usage_based_service,
} from "../../../../services/maintenance/usage-based/usage-based-service";
import { Row, Button, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { permissions } from "../../../../services/permissions/permissions";
import {
  MdAddCircle,
  MdDeleteOutline,
  MdModeEditOutline,
  MdRefresh,
  MdCheckCircle,
  MdCancel,
} from "react-icons/md";
import Table from "../../../table/table";

import withLayout from "../../../../hoc/withLayout";
import { Routes } from "../../../../Routes";
import { WaitForRequest } from "../../../../utils/alertService";
import {
  FilterItem,
  FilterDropdownItem,
} from "../../../table/filter-dropdown";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getSearch, saveSearch } from "../../../../store/reducers/usage.based.management-reducer";

interface ListUsageBasedProps {}

const ListUsageBased: FunctionComponent<ListUsageBasedProps> = () => {
  const [maintenanceUsage, setMaintenanceUsage] = useState<
    IMaintenanceUsedModel[]
  >([]);
  const [maintenanceUsageFilter, setMaintenanceUsageFilter] = useState<
    IMaintenanceUsedModel[]
  >([]);
  const [checkedItems, setCheckedItems] = useState<FilterItem[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const history = useHistory();
  const columns: TableColumn<IMaintenanceUsedModel>[] = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Monitored variable",
      selector: (row) => row.monitored_variable.description,
      sortable: true,
    },
    {
      name: "Struct",
      selector: (row) => row.struct.name,
      sortable: true,
    },
    {
      name: "Current value",
      // selector: (row) => row.current_value,
      selector: (row) =>
        row.is_seconds
          ? row.current_value + " hours"
          : row.monitored_variable.unit_of_measurement
          ? row.current_value + " " + row.monitored_variable.unit_of_measurement
          : row.current_value + " N/A",
      sortable: true,
    },
    {
      name: "Expiration",
      selector: (row) =>
        row.is_seconds
          ? row.expiration + " hours"
          : row.monitored_variable.unit_of_measurement
          ? row.expiration + " " + row.monitored_variable.unit_of_measurement
          : row.expiration + " N/A",
      sortable: true,
    },
    {
      name: "Enable",
      cell: (row) =>
        row.enabled === true ? (
          <MdCheckCircle color="green" />
        ) : (
          <MdCancel color="red" />
        ),
      sortable: false,
    },
    {
      cell: (maintenance) => (
        <>
          <Restricted to={permissions.main.usage_maintenances.change}>
            <Button
              variant="outline-warning"
              onClick={() =>
                history.push(
                  Routes.management_usage_based + "/" + maintenance.id
                )
              }
              size="sm"
            >
              <MdModeEditOutline />
            </Button>
          </Restricted>
          <span> &nbsp; </span>
          <Restricted to={permissions.main.usage_maintenances.delete}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => handleDelete(maintenance.id)}
            >
              <MdDeleteOutline />
            </Button>
          </Restricted>
        </>
      ),
      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  const dispatch = useDispatch();
  const search = useSelector(getSearch);

  const handleDelete = async (id: number) => {
    try {
      let func = async () => await usage_based_service.delete(id);
      const response = await WaitForRequest(
        "Confirm",
        "Are you sure you want to delete the maintenance ?",
        func
      );
      if (response) {
        let update_maintenance = maintenanceUsage.filter((x) => x.id !== id);
        setMaintenanceUsage(update_maintenance);
      }
    } catch (error: any) {
      usage_based_service.checkErrors(error);
    }
  };

  const loadMaintenance = async () => {
    try {
      setIsBusy(true);
      var result = await usage_based_service.get();
      if (result.response) {
        setMaintenanceUsage(result.response);
        setMaintenanceUsageFilter(result.response);
      }
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    loadMaintenance();
  }, []);

  useEffect(() => {
    handleCheckedChange(checkedItems, maintenanceUsage);
  }, [checkedItems]);

  const handleCheckedChange = (
    data: FilterItem[],
    maintenances: IMaintenanceUsedModel[]
  ) => {
    let filter: IMaintenanceUsedModel[] = [...maintenances];
    const new_filter: IMaintenanceUsedModel[] = [];
    data.forEach((dataItem) => {
      type ObjectKey = keyof IMaintenanceUsedModel;
      const result = _.filter(filter, (item) => {
        const element = item[dataItem.path as ObjectKey];
        return element == null
          ? false
          : element.toString() === dataItem.value.toString();
      });
      new_filter.push(...result);
    });

    setMaintenanceUsageFilter(new_filter.length > 0 ? new_filter : filter);
  };

  const FilterItems: FilterDropdownItem[] = [
    {
      header: "Status",
      items: [
        { label: "Enabled", path: "enabled", value: "true" },
        { label: "Disabled", path: "enabled", value: "false" },
      ],
    },
  ];

  return (
    <>
      <Restricted
        to={permissions.main.usage_maintenances.view}
        showFallback={true}
      >
        <Backdrop show={isBusy} />

        <Card className="p-3">
          <h4>
            <GoBackNavigation url="/management" />
            Usage-based maintenance
          </h4>
          <hr />
          <Row>
            <div className="d-flex justify-content-start">
              <Restricted to={permissions.main.usage_maintenances.add}>
                <Link
                  to={Routes.management_usage_based_new}
                  className="btn mr-1 btn-sm"
                >
                  <MdAddCircle /> New
                </Link>
              </Restricted>
              <a
                onClick={async () => await loadMaintenance()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </div>
          </Row>

          <Table
            filterItems={FilterItems}
            columns={columns}
            data={maintenanceUsageFilter}
            pagination={true}
            searchText={search}
            onSearchChange={(text: string) => {
              dispatch(saveSearch(text));
            }}
          />
        </Card>
      </Restricted>
    </>
  );
};

export default ListUsageBased;
