import { FunctionComponent, useContext } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import ThemeContext from '../../context/theme/themeContext';


interface LoaderProps {
  size:number;
  color?:string;
}
 
const Loader: FunctionComponent<LoaderProps> = ({size,color}) => {
  const {theme}=useContext(ThemeContext);
  return (
    <div className="center">
      <BounceLoader size={size} color={color ? color : theme.primary}>
        <div className="text">Loading...</div>
      </BounceLoader>
    </div>
  );
}
 
export default Loader;

