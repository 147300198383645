export enum Routes {
  dashboard = "/dashboard",
  users = "/management/users",
  users_edit = "/management/users/:id",
  users_new = "/management/users/new",
  user_groups="/management/user_groups",
  user_groups_new="/management/user_groups/new",
  user_groups_edit="/management/user_groups/:id",
  report = "/reports",
  notification = "/notifications",
  graph = "/graph",
  analytics = "/analytics",
  maintenance = "/maintenance",
  profile = "/profile",
  login = "/login",
  notFound = "/404",
  management = "/management",
  management_usage_based = "/management/maintenance/usage-based",
  management_usage_based_new = "/management/maintenance/usage-based/new",
  management_usage_based_edit = "/management/maintenance/usage-based/:id",
  management_scheduled = "/management/maintenance/scheduled",
  management_scheduled_new = "/management/maintenance/scheduled/new",
  management_scheduled_edit = "/management/maintenance/scheduled/:id",
  management_struct = "/management/struct",
  management_struct_new = "/management/struct/new",
  management_struct_edit = "/management/struct/:id",
  management_temperature_condition = "/management/temperature",
  management_temperature_condition_new = "/management/temperature/new",
  management_temperature_condition_edit = "/management/temperature/:id",
  management_valve_condition = "/management/valve",
  management_valve_condition_new = "/management/valve/new",
  management_valve_condition_edit = "/management/valve/:id",
}
