import { useFormikContext } from "formik";
import { FunctionComponent, useContext } from "react";
import Switch from "../common/switch";
import { MdHelpOutline } from "react-icons/md";
import ThemeContext from "../../context/theme/themeContext";
interface FormSwitchProps {
  name: string;
  label: string;
  helpText?: string;
}

const FormSwitch: FunctionComponent<FormSwitchProps> = ({
  name,
  label,
  helpText,
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Switch
        name={name}
        label={
          <>
            {label +" "}

            {helpText && (
              <a data-tip={helpText}>
                <MdHelpOutline style={{ fontSize: "15px" }}></MdHelpOutline>
              </a>
            )}
          </>
        }
        checked={values[name]}
        error={errors[name] && errors[name]}
        onchange={(input: any, checked: boolean) => {
          setFieldValue(name, checked);
        }}
      />
    </>
  );
};

export default FormSwitch;
