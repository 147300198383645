import { FunctionComponent, useEffect, useState } from "react";
import AppForm from "../../../formik-forms/AppForm";
import { Card, Col, Container, Row } from "react-bootstrap";
import Backdrop from "../../../common/backdrop/backdrop";
import FormText from "../../../formik-forms/FormText";
import CloseButton from "../../../common/buttons/close-button";
import FormSubmit from "../../../formik-forms/FormSubmit";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { FormikHelpers, FormikValues } from "formik";
import {
  useGetByIGroupUsers,
  useGetUserPermissions,
  useSaveGroupUsers,
} from "../../../../queries/user.groups.hook";
import {
  IPermission,
  IUserGroup,
} from "../../../../services/users/user-groups-service";
import withLayout from "../../../../hoc/withLayout";
import "react-dual-listbox/lib/react-dual-listbox.css";
import DualListBox, { ValueOption } from "react-dual-listbox";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
interface UserGroupsFormProps {}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required().label("Name"),
  level: Yup.number().required().label("Level"),
});

const UserGroupsForm: FunctionComponent<UserGroupsFormProps> = () => {
  const [group, setGroup] = useState<IUserGroup>();
  const [selected, setSelected] = useState<number[]>([]);
  const [options, setOptions] = useState<ValueOption<number>[]>([]);
  const history = useHistory();
  let { id } = useParams<any>();
  const getPermissions = useGetUserPermissions();

  const saveGroup = useSaveGroupUsers();
  const groupById = useGetByIGroupUsers();

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      values.permissions = selected;
      saveGroup.mutate(values);
    } catch (error: any) {}
  };

  const checkId = async () => {
    try {
      if (id === "new" || id === undefined) {
        setGroup({} as IUserGroup);
        return;
      }
      const group = await groupById.mutateAsync(id);

      if (!group) return history.replace("/not-found");

      //Map selected permissions.
      if (group.response?.permissions) {
        const ids = group.response?.permissions.map(
          (permission) => permission.id
        );
        setSelected(ids);
      }

      setGroup(group.response);
    } catch (error: any) {
      history.replace("/404");
    }
  };

  const mapPermissions = (data: IPermission[] | undefined) => {
    const groupsOptions: ValueOption<number>[] = [];
    data?.map((permission) => {
      groupsOptions.push({ label: permission.name, value: permission.id });
    });
    setOptions(groupsOptions);
  };

  useEffect(() => {
    console.debug(getPermissions.data?.response);
    if (getPermissions.data?.response) {
      mapPermissions(getPermissions.data?.response);
    }
  }, [getPermissions.data]);

  useEffect(() => {
    checkId();
  }, []);

  return (
    <Container>
      <Backdrop
        show={
          saveGroup.isLoading || getPermissions.isLoading || groupById.isLoading
        }
      />
      <Card className="p-3">
        {<h3>{id === "new" ? "New" : "Modify"}</h3>}
        <hr />
        {group && (
          <AppForm
            validationSchema={validationSchema}
            initialValues={{
              id: group?.id,
              name: group?.name,
              level: group?.level,
            }}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <FormText
                  name="name"
                  label="Name"
                  placeholder="Insert Name..."
                />
              </Col>
              <Col>
                <FormText
                  type="number"
                  name="level"
                  label="Level"
                  placeholder="Insert email..."
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="p-3">
                <DualListBox
                  canFilter
                  showHeaderLabels
                  showNoOptionsText
                  showOrderButtons
                  options={options}
                  selected={selected}
                  onChange={(value) => setSelected(value)}
                  icons={{
                    moveAllLeft: (
                      <>
                        <MdKeyboardArrowLeft />
                        <MdKeyboardArrowLeft />
                      </>
                    ),
                    moveLeft: <MdKeyboardArrowLeft />,
                    moveRight: <MdKeyboardArrowRight />,
                    moveAllRight: (
                      <>
                        <MdKeyboardArrowRight />
                        <MdKeyboardArrowRight />
                      </>
                    ),
                  }}
                />
              </Col>
            </Row>
            <CloseButton label="Close" onClick={() => history.goBack()} />
            <span className="me-2"></span>
            <FormSubmit
              title="Save changes"
              //disabled={isCompleted(maintenance_info)}
              //disabled={profile_api.loading}
              //                  icon={}
            />
          </AppForm>
        )}
      </Card>
    </Container>
  );
};

export default UserGroupsForm;
