export const createTimer = (callback: Function, time: number) => {
  return setInterval(callback, time);
};

export const clearTimer = (timer: NodeJS.Timer) => {
  clearInterval(timer);
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
