import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ResponseWrapper } from "../models/reponse";

import alertService from "../utils/alertService";
import { Moment } from "moment";
import {
  IRequestValveCondition,
  IValveResponse,
  IValveConditionResponse,
  statistic_plot_valve_service,
} from "../services/statistic/statistic-plot-valve";
import { timezone_service } from "../services/timezoneService";

type IPlot = {
  start: Moment;
  end: Moment;
  struct_ids: number[];
  resample_rule?: number;
};

export const usePlotValve = () => {
  const load_plot = useMutation<ResponseWrapper<IValveResponse[]>, any, IPlot>({
    mutationFn: (data) => {
      return statistic_plot_valve_service.getPlot(
        data.start,
        data.end,
        data.struct_ids,
        data.resample_rule
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {},
  });
  return load_plot;
};

export const usePlotValveByCondition = () => {
  const timezone: string = timezone_service.getTimezone();
  const load_plot = useMutation<
    ResponseWrapper<IValveConditionResponse>,
    any,
    IRequestValveCondition
  >({
    mutationFn: (data) => {
      data.timezone = timezone;
      return statistic_plot_valve_service.getPlotByCondition(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      alertService.errorMessage(error.response?.data.response.error);
    },
    onSuccess: (data, variables, context) => {},
  });
  return load_plot;
};
