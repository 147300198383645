import { useFormikContext } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import get from "lodash/get";
import SelectSingle from "../common/selects/select-single";
import { MdHelpOutline } from "react-icons/md";
import { SelectOption } from "../common/selects/select-option";
interface FormSelectProps {
  name: string;
  label?: string;
  placeholder: string;
  helpText?: string;
  options: SelectOption[];
  onChangeValue?: (value?: string) => void;
}

const FormSelect: FunctionComponent<FormSelectProps> = ({
  label,
  name,
  placeholder,
  options,
  helpText,
  onChangeValue,
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const [currentValue, SetCurrentValue] = useState<SelectOption>();
  useEffect(() => {
    const value = get(values, name);
    if (value) {
      const currentValue =
        options && options.find((x) => x.value?.toString() === value.toString());
      SetCurrentValue(currentValue);
    }
  }, [options, values]);

  const error = get(errors, name);
  return (
    <>
      <SelectSingle
        label={label &&
          <div className="mt-2">
            <label className="mb-1 me-1">{label + " "}</label>           

            {helpText && (
              <a data-tip={helpText}>
                <MdHelpOutline style={{ fontSize: "15px" }}></MdHelpOutline>
              </a>
            )}
          </div>
        }
        //value={values[name]}
        value={currentValue}
        placeholder={placeholder}
        options={options}
        onValueChange={(data: SelectOption) => {
          setFieldValue(name, data.value);
          onChangeValue && onChangeValue(data.value);
        }}
      />
      <span className="text-danger">
      {error && error}
        </span>
    </>
  );
};

export default FormSelect;
