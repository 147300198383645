import React, { useState } from "react";

import TourContext from "./tourContext";

type Props = {
  runTour: boolean;
};

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const TourProvider: React.FunctionComponent<Props> = ({
  runTour,
  children,
}) => {
  const [tour, setTour] = useState<boolean>(runTour);

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <TourContext.Provider value={{ setTour, runTour: tour }}>
      {children}
    </TourContext.Provider>
  );
};

export default TourProvider;
