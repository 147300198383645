import { ResponseWrapper } from "../../models/reponse";
import { INotification } from "../../services/notifications/warning-notification-service";
import { BaseServiceGenericDemo } from "../base-service-demo";
import { notification_data } from "./data";

export class WarningNotificationDemo extends BaseServiceGenericDemo<INotification> {
  constructor() {
    super();
    this.data = notification_data.getNotificationDefault();
  }

  updateFeedback(data: any): Promise<ResponseWrapper<any>> {
    return new Promise<any>((resolve, reject) => {
      reject({
        status: 400,
        version: "demo",
        response: {
          data: {
            response: { error: "The update feedback is not allowed on demo" },
          },
        },
      });
    });
  }
}


