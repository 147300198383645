import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, user_service } from "../../services/users/user-service";
import type { RootState } from "../configureStore";

// Define a type for the slice state
interface UserState {
  users: IUser[];  
  status: "idle" | "loading" | "succeeded" | "failed";
  error?: string;
  search?: string;
}
type KnownError = {
  errorMessage?: string;
};

// Define the initial state using that type
const initialState: UserState = {
  users: [],
  status: "idle",
  search:"",  
};

export const usersManagementSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    addUser(state, action: PayloadAction<IUser>) {
      state.users.push(action.payload);
    },
    updateUser(state, action: PayloadAction<IUser>) {
      const find = state.users.findIndex(
        (user) => user.id == action.payload?.id
      );
      state.users[find] = action.payload;
    },
    removeUser(state, action) {},
  },
});

export const { addUser, updateUser, removeUser, saveSearch } =
  usersManagementSlice.actions;

export const getSearch = (state: RootState) => state.users.search;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.users.users.length;

export const selectAllUsers = (state: RootState) => state.users.users;

export const getError = (state: RootState) => state.users.error;

export const getStatus = (state: RootState) => state.users.status;

export default usersManagementSlice.reducer;
