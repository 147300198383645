import { FieldArray, useFormikContext } from "formik";
import { FunctionComponent } from "react";
import get from "lodash/get";
interface FormArrayProps {
  name: string;
  TemplateArrayComponent: React.FunctionComponent<FormArrayTemplateProps>;
  TemplateAddButtonComponent: React.FunctionComponent<FormArrayButtonTemplateProps>;
}

export interface FormArrayTemplateProps {
  name: string;
  index: number;
  item: any;
  OnRemove?: <T>(index: number) => T | undefined;
}

export interface FormArrayButtonTemplateProps {
  OnPush?: (obj: any) => void;
}

const FormArray: FunctionComponent<FormArrayProps> = ({
  name,
  TemplateArrayComponent,
  TemplateAddButtonComponent,
  children,
}) => {
  const {
    errors,
    values,    
  } = useFormikContext<any>();
  const error = get(errors, name);
  const value = get(values, name);
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <>
          <TemplateAddButtonComponent OnPush={push} />
          <br />
          {values[name] &&
            values[name].length > 0 &&
            values[name].map((item: any, index: number) => {
              return (
                <TemplateArrayComponent
                  item={item}
                  key={index}
                  name={name}
                  index={index}
                  OnRemove={remove}
                />
              );
            })}
        </>
      )}
    </FieldArray>
  );
};

export default FormArray;
