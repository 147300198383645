import { AxiosError } from "axios";
import { ResponseWrapper } from "./../../models/reponse";
import * as alertService from "../../utils/alertService";
import Http, { IHttp } from "../base/http-services";
import { MaintenanceReportDemo } from "demo/maintenance/maintenance-report-demo";
import storage from "services/base/storage";
const endpoint = "report/generate/maintenance";
const endpoint_get = "report/maintenance";

export interface IReport {
  created_at?: Date;
  filename?: string;
  size?: string;
  url?: string;
}

export interface IMaintenanceReport {
  generateReport: (
    start_date: string,
    end_date: string,
    timezone: string
  ) => Promise<ResponseWrapper<IReport>>;
  get?: () => Promise<ResponseWrapper<IReport[]>>;
}

export class MaintenanceReport implements IMaintenanceReport {
  http: IHttp;
  constructor() {
    this.http = Http;
  }

  private getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  checkErrors(error: AxiosError<any>) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.status !== 403
    ) {
      var message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }

  get = (): Promise<ResponseWrapper<IReport[]>> => {
    return this.getPromise<IReport[]>(this.http.get(endpoint_get + "/"));
  };

  generateReport(
    start_date: string,
    end_date: string,
    timezone: string
  ): Promise<ResponseWrapper<IReport>> {
    const params =
      "start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&timezone=" +
      timezone;
    return this.getPromise<IReport>(
      this.http.get(endpoint + "/" + "?" + params)
    );
  }
}

const user = storage.getUser();

const isDemo = user && user.token_type == "demo" ? true : false;

export const maintenance_report = isDemo
  ? new MaintenanceReportDemo()
  : new MaintenanceReport();
