import { ResponseWrapper } from "../../models/reponse";
import Http, { IHttp } from "../base/http-services";

// const endpoint_model = "statistic/prediction/time-series/store-model";
const endpoint_predict = "statistic/prediction/time-series/predict";
class PredictionService {
  http = Http

  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
  // generateModel(
  //   struct_id: number,
  //   query_params: Record<string, any> = {}
  // ): Promise<ResponseWrapper<any>> {
  //   var queryString = Object.keys(query_params)
  //     .map((key: any) => key + "=" + query_params[key])
  //     .join("&");
  //   return this.getPromise<any>(
  //     this.http.get(endpoint_model + "/" + struct_id + "?" + queryString)
  //   );
  // }

  doPrediction(
    struct_id: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<any>> {
    var queryString = Object.keys(query_params)
      .map((key: any) => key + "=" + query_params[key])
      .join("&");
    return this.getPromise<any>(
      this.http.get(endpoint_predict + "/" + struct_id + "?" + queryString)
    );
  }
}

export const prediction_service = new PredictionService();
