import { StructDemo } from "../../demo/structs/struct-demo";
import Http from "../base/http-services";
import storage from "../base/storage";
import { ResponseWrapper } from "../../models/reponse";
import { BaseService } from "../base/base-service";
const endpoint = "structs";

export interface IStruct {
  id?: number;  
  updated_at?: Date;
  name?: string;
  description?: string;
  enabled?: boolean;
  type_id?: IType;
  processing:string;
}

interface IType {
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  description?: string;
  type?: string;
}

export interface IStructDetailVariable {
  id:number;
  struct_id:number;
  operation_type:string;
  is_plot: boolean;
  is_stats: boolean;
  variable: IVariable;
  category?: ICategory;
}
export interface IStructByCategory {

          struct_id: number;
          struct_name: string;
          struct_description: string;
          struct_type_id: IType;
          struct_enabled: boolean;
          struct_variable: IStructDetailVariable;
}

export interface IVariable {
  id: number;
  unit_of_measurement?: string;
  description: string;
  default_avg?: number
  threshold_max?: number
  threshold_min?: number
  is_writable?: boolean
}

interface ICategory {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface IVariableShort{
  id?: number;   
  description?: string;
  unit_of_measurement?: string;
  }
class StructService extends BaseService<IStruct> {
  constructor() {
    super(endpoint, Http);
  }

  GetDetailById = (
    dataId: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IStructDetailVariable[]>> => {
    // return this.getPromise(
    //   this.http.get(this.Url(dataId) + "/detail" + "?" + params)
    // );
    var params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get(this.Url(dataId, false) + "/detail" + "?" + params)
    );
  };

  getStructByCategory = (
    categoryId: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IStructByCategory[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get(
        this.endpoint + "/signal-categories/" + categoryId + "?" + params
      )
    );
  };
  getStructVariables = (
    id: any,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IVariableShort[]>> => {
    let params = this.generateParams(query_params);
    return this.getPromise(
      this.http.get(this.endpoint + "/" + id + "/variables/" + "?" + params)
    );
  };
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new StructDemo()
    : new StructService();

export const struct_service = instance;
