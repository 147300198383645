import { FunctionComponent } from "react";
import * as Yup from "yup";
import { Card } from "react-bootstrap";
import AppForm from "../../formik-forms/AppForm";
import FormText from "../../formik-forms/FormText";
import FormSubmit from "../../formik-forms/FormSubmit";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { user_profile_service } from "../../../services/users/user-profile-service";
import { Ok, WaitForRequest, errorMessage } from "../../../utils/alertService";
import { FormikHelpers, FormikValues } from "formik";
import useApi from "../../../hooks/useApi";
interface ProfileChangePasswordProps {}

interface SetPassword {
  new_password: string;
  re_new_password: string;
  current_password: string;
}

const validationSchema = Yup.object().shape({
  new_password: Yup.string().required().min(8).max(1000).label("new password"),
  re_new_password: Yup.string()
    .required()
    .min(8)
    .max(1000)
    .label("repeat password"),
  current_password: Yup.string()
    .required()
    .min(8)
    .max(1000)
    .label("current password"),
  //8 carateri,
  // solo carateri
  // no puo essere solo numeri.
  // no comuni.
});

const password_requirements = [
  { id: 1, name: "It must contain at least 8 characters." },
  { id: 2, name: "Can't be similar to the username." },
  { id: 3, name: "Can't be common." },
  { id: 4, name: "Can't be only numbers." },
];

const ProfileChangePassword: FunctionComponent<
  ProfileChangePasswordProps
> = ({}) => {
  const profile_api = useApi(user_profile_service.changePassword);
  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var result = await profile_api.request(values);
      Ok("Password changed");
    } catch (error: any) {
      user_profile_service.checkErrors(error);
    }
  };
  return (    
    <div className="p-2">
      <h5>
        <b>Modify password</b>
      </h5>
      <AppForm
        validationSchema={validationSchema}
        initialValues={{
          new_password: "",
          re_new_password: "",
          current_password: "",
        }}
        onSubmit={handleSubmit}
      >
        <FormText
          name="current_password"
          label="Current Password"
          placeholder="Insert current password..."
          type="password"
        />

        <FormText
          name="new_password"
          label="New Password"
          placeholder="Insert new password..."
          type="password"
        />

        <FormText
          name="re_new_password"
          label="Repeat Password"
          placeholder="Insert new password..."
          type="password"
        />
        <br />
        <ul>
          {password_requirements.map((requirement: any) => (
            <li key={requirement.id}>{requirement.name}</li>
          ))}
        </ul>
        <FormSubmit
          title="Change password"
          //disabled={isCompleted(maintenance_info)}
          disabled={profile_api.loading}
          icon={faKey}
        />
      </AppForm>
    </div>
  );
};

export default ProfileChangePassword;
