import React from "react";
import Plot, { Figure } from "react-plotly.js";
import Plotly, { Config, Data } from "plotly.js";
import { MAX_POINTS_ON_GRAPH } from "./constants"
//https://plotly.com/javascript/configuration-options/#
//https://github.com/plotly/plotly.js/blob/master/src/plot_api/plot_config.js#L22-L86
//https://github.com/plotly/react-plotly.js/blob/a42185ed5b773d423de684ecea4c13ddf399cfd4/README.md

const config: Partial<Config> = {
  displaylogo: false,
  responsive: true,
  autosizable: true,
  fillFrame: false,
  displayModeBar: true,
  showAxisRangeEntryBoxes: false,
  plotlyServerURL: "",
  editable: false,
  showLink: false,
  showEditInChartStudio: false,
  scrollZoom: false,
  //watermark: false,
  showTips: false,
  showAxisDragHandles: false,
  modeBarButtonsToRemove: ["lasso2d", "select2d", "resetScale2d"],
  // modeBarButtonsToAdd: [
  //   {
  //     name: 'button1',
  //     icon: Plotly.Icons.pencil,
  //     direction: 'up',
  //     click: function(gd) {alert('button1')
  //   }}],
};

var selectorOptions = {
  buttons: [
    {
      step: "day",
      stepmode: "backward",
      count: 1,
      label: "1d",
    },
    {
      step: "week",
      stepmode: "backward",
      count: 2,
      label: "2w",
      name: "1 week",
    },
    {
      step: "month",
      stepmode: "backward",
      count: 1,
      label: "1m",
    },
    {
      step: "month",
      stepmode: "backward",
      count: 3,
      label: "3m",
    },
    {
      step: "year",
      stepmode: "todate",
      count: 1,
      label: "YTD",
    },
    {
      step: "year",
      stepmode: "backward",
      count: 1,
      label: "1y",
    },
    {
      step: "all",
    },
  ],
  // bordercolor:"#9cf58f",
  // bgcolor:"",
  // activecolor:"#737573",
};

const colors = {
  blu: "rgb(0, 88, 176)",
  grey: "#7D6B7D",
  grey_light: "#E7E6F0",
  blu_light: "rgb(0, 128, 200,0.1)",
  green: "#81C784",
};

const graph_height = 350;

const generateLegend = (signal: any) => {
  const statusLegend = [];
  if (signal.status) {
    signal.status.forEach((element: any) => {
      // var newStuff = element.map(function (item, index) {
      //   return item.replace(/,/g, "<br /> -");
      // });
      element = "<br /> - " + element
      var newStuff = element.replace(/,/g, "<br /> - ");
      //console.debug(newStuff);
      statusLegend.push(newStuff ? newStuff : "No Status");
    });
  } else {
    //Put fake date
    if (signal.time) {
      for (let index = 0; index < signal.time.length; index++) {
        statusLegend.push("No Status");
      }
    }
  }
  return statusLegend;
};

interface ISignal {
  id: string;
  ref?: any;
  name: string;
  struct_name: string;
  description: string;
  time: string[];
  value: string[];
  status: string[];
  unit_of_measurement: string;
}

interface PlotRawProps {
  signal: ISignal;
  onInitialized?:
  | ((figure: Readonly<Figure>, graphDivId: string) => void)
  | undefined;
  onRelayout?:
  | ((event: Readonly<Plotly.PlotRelayoutEvent>) => void)
  | undefined;
  onUpdate?:
  | ((figure: Readonly<Figure>, graphDivId: string) => void)
  | undefined;
  dragmode?:
  | "zoom"
  | "pan"
  | "select"
  | "lasso"
  | "orbit"
  | "turntable"
  | false;
  range?: string[];
  graphMode?: "lines" | "markers" | "lines+markers";
  height?: number;
}

const PlotRaw: React.FunctionComponent<PlotRawProps> = ({
  signal,
  onInitialized,
  onRelayout,
  onUpdate,
  dragmode,
  range,
  graphMode = "lines",
  height = graph_height,
}) => {
  //const MAX_POINTS_ON_GRAPH =200000;
  const signal_size = signal.time ? signal.time.length : 0;
  const toManyPoints: boolean = signal_size >= MAX_POINTS_ON_GRAPH;
  const status = generateLegend(signal);
  const annotation_empty: any = signal.time
    ? undefined
    : {
      text: "No data found",
      xref: "x domain",
      yref: "y domain",
      showarrow: false,
      font: {
        size: 24,
      },
    };
  const trace_signal: Data = {
    x: signal.time,
    y: signal.value,
    mode: graphMode,
    type: "scattergl",
    name: "", //Blank always with custom hover template
    customdata: status, //handleStatus(signal.status),
    //hoverlabel: "",
    hoverinfo: "skip",
    hovertemplate: toManyPoints ? undefined :
      "<b>Value:</b> %{y} <br /><b>Time:</b> %{x} <br /><br /> <b>Status:</b>  %{customdata} <br />",
    showlegend: false,
    line: {
      shape: "hv",
      width: 2.8,
      color: graphMode === "lines" ? colors.blu : colors.grey_light,
    },
    marker: {
      color: colors.blu,
      size: 4.5,
    },
  };

  //Layout can be with type, beacuse the type "Layout" is not available in the library.
  //  const layout: Partial<Plotly.Layout> = {
  const annotation_to_many = {
    text: "Too many points, the legend will not be displayed.",
    showarrow: false,
    xref: "paper",
    yref: "paper",
    xanchor: 'left',
    yanchor: 'bottom',
    //align: "center",
    x: 0,
    y: 1,
    font: {
      size: 10,
      color: "red"
    },
  };
  const layout: any = {
    title: signal.struct_name + "<br />" + signal.description,
    paper_bgcolor: "#1C00ff00",
    plot_bgcolor: "#1C00ff00",
    height: height,
    hovermode: "closest", //false to disabled
    hoverdistance: 100, //# Distance to show hover label of data point
    //spikethickness: 1000, //# Distance to show spike
    hoverlabel: { bgcolor: "#FFF" }, //Color hover
    autosize: true,
    dragmode: dragmode ? dragmode : "zoom",
    annotations: annotation_empty ? [annotation_empty] : toManyPoints ? [annotation_to_many] : undefined,
    //annotations: [annotation_to_many],
    modebar: {
      orientation: "v",
      bgcolor: "#1C00ff00",
      activecolor: "#6c757d",
      color: "#adb5bd",
    },

    legend: {
      xanchor: "left",
      orientation: "v",
    },
    margin: {
      l: 50,
      r: 50,
      b: 50,
      t: 50,
    },
    xaxis: {
      //autorange:true,
      range: range && range,
      type: "date",
      visible: signal.time ? true : false,
      // rangeselector: selectorOptions, TODO
    },
    yaxis: {
      visible: signal.time ? true : false,
      ticksuffix:
        signal &&
        signal.unit_of_measurement &&
        " " + signal.unit_of_measurement,
      showgrid: true,
      autorange: true,
    },
  };

  return (
    <Plot
      data={[trace_signal]}
      layout={layout}
      config={config}
      divId={signal.id}
      onInitialized={(figure) =>
        onInitialized && onInitialized(figure, signal.id)
      }
      onRelayout={onRelayout}
      onUpdate={(figure) => onUpdate && onUpdate(figure, signal.id)}
      ref={signal.ref}
      style={{ margin: 0, backgroundColor: "none" }}
      useResizeHandler={false}
    />
  );
};

export default React.memo(PlotRaw);
