import { FunctionComponent, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import {
  MdRefresh,
  MdRemoveRedEye,
  MdTimer,
  MdError,
  MdCancel,
} from "react-icons/md";
import SearchBox from "../../components/common/searchbox";
import NotificationStats, {
  IStatsNotification,
} from "../../components/features/notification/notification-stats";
import {
  calcolateStats,
  checkLimits,
  checkThresholds,
  renderNotificationIcon,
  renderNotificationType,
} from "./notification.utils";
import ListItemCustom, {
  ItemCustom,
} from "../../components/features/notification/list-item-custom";
import moment from "moment";
import _ from "lodash";
import Backdrop from "../../components/common/backdrop/backdrop";
import FormTextArea from "../../components/formik-forms/FormTextArea";
import FormSwitch from "../../components/formik-forms/FormSwitch";
import * as Yup from "yup";
import AppForm from "../../components/formik-forms/AppForm";
import CloseButton from "../../components/common/buttons/close-button";
import FormSubmit from "../../components/formik-forms/FormSubmit";
import { FormikHelpers, FormikValues } from "formik";
import alertService from "../../utils/alertService";
import {
  useGetValveNotification,
  useSaveValveNotification,
} from "../../queries/notification/notification.valve.hook";
import { INotificationValve } from "../../services/notifications/notification-valve-service";
import {
  usePlotValve,
  usePlotValveByCondition,
} from "../../queries/analytics.valve.hook";
import { IValveResponse } from "../../services/statistic/statistic-plot-valve";
import PlotValve from "../../components/features/plot-analytics/valve/plot-valve";
import Descriptor from "../../components/features/notification/notification-descriptor";
import { IValveConditionResponse } from "./../../services/statistic/statistic-plot-valve";
interface NotificationValveProps {}

const NotificationValve: FunctionComponent<NotificationValveProps> = () => {
  const [searchValue, setSerachValue] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statsNotification, setStatsNotification] =
    useState<IStatsNotification>({} as IStatsNotification);

  const [notification, SetNotification] = useState<INotificationValve>(
    {} as INotificationValve
  );

  const [notificationFilter, setNotificationFilter] = useState<
    INotificationValve[]
  >([]);

  const valve = useGetValveNotification();

  const handleOpenDetail = async (notification: INotificationValve) => {
    setShowModal(true);
    SetNotification(notification);
  };

  const handleSearch = (value: string) => {
    if (value === "") {
      setSerachValue(value);
      if (valve.data?.response) setNotificationFilter(valve.data?.response);

      return;
    }
    const result = _.filter(valve.data?.response, (item) => {
      return item.struct.name.toLowerCase().includes(value.toLowerCase());
    });
    console.debug(result);

    setNotificationFilter(result);
    setSerachValue(value);
  };

  const handleClose = (refresh: boolean) => {
    if (refresh) {
      valve.refetch();
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (valve.data?.response) {
      const stats = calcolateStats(valve.data?.response);
      setStatsNotification(stats);
      setNotificationFilter(valve.data?.response);
    }
  }, [valve.data]);

  return (
    <>
      <Backdrop show={valve.isRefetching || valve.isLoading} />
      <Row>
        <Col className="mb-1" sm={12} md={9} lg={9}>
          <Card className="p-3">
            <h4>Notifications</h4>
            <hr></hr>
            <Row>
              <div className="d-flex justify-content-end">
                <a onClick={() => valve.refetch()} className="btn mr-1 btn-sm">
                  <MdRefresh /> Refresh
                </a>
              </div>
            </Row>
            <SearchBox
              value={searchValue}
              size="md"
              placeholder="Search by valve..."
              onChange={handleSearch}
            />

            <ListItemCustom
              key={"list valve"}
              data={notificationFilter}
              keyExtrator={(item) => item.id.toString()}
              renderItem={(item) => (
                <>
                  <ItemCustom
                    key={item.id}
                    Icon={renderNotificationIcon(item.type)}
                    title={item.struct.name}
                    subtitle={<>{item.condition?.name + " "}</>}
                    actions={
                      <>
                        <div>
                          <p className="text-muted me-3">
                            <MdTimer />{" "}
                            {moment(
                              item.result_info.reference_period
                            ).fromNow()}
                          </p>

                          <span className="">
                            {item.is_incorrect && (
                              <MdError size={20} color="red" />
                            )}

                            {item.is_read ? (
                              <Badge pill bg="success">
                                Viewed{" "}
                              </Badge>
                            ) : (
                              <Badge pill bg="warning">
                                Pending
                              </Badge>
                            )}
                          </span>
                        </div>

                        <Button
                          className="me-1"
                          variant="outline-dark"
                          onClick={() => handleOpenDetail(item)}
                        >
                          <MdRemoveRedEye />
                        </Button>
                      </>
                    }
                  />
                </>
              )}
            />
          </Card>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <NotificationStats data={statsNotification} />
        </Col>
      </Row>

      <NotificationModalValve
        notification={notification}
        open={showModal}
        onClose={handleClose}
      />
    </>
  );
};

export default NotificationValve;

interface NotificationModalValveProps {
  notification: INotificationValve;
  open: boolean;
  onClose?: (refresh: boolean) => void;
}

const NotificationModalValve: FunctionComponent<
  NotificationModalValveProps
> = ({ notification, open, onClose }) => {
  const [graph, setGraph] = useState<IValveConditionResponse>();
  const load_plot = usePlotValveByCondition();
  const saveNotification = useSaveValveNotification();

  const handlePlot = async () => {
    if (notification.struct?.id === undefined) return;

    const id: number = notification.struct?.id;
    const condition_id: number = notification.condition.id;
    const period = notification.result_info?.reference_period;
    const result = await load_plot.mutateAsync({
      start_date: moment(period).subtract(30, "d"),
      end_date: moment(period).add(10, "d"),
      struct_id: id,
      condition_id: condition_id,
    });
    if (result.response) setGraph(result.response);
  };

  useEffect(() => {
    if (notification) {
      handlePlot();
    }
  }, [notification]);

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    comment: Yup.string()
      .matches(
        /^(?![\n]*$)/,
        "Comment can't be only line breaks and whitespace"
      )
      .required()
      .nullable()
      .label("Comment"),
    is_incorrect: Yup.boolean().default(false).required().label("Anomaly"),
    is_read: Yup.boolean().default(false).required().label("Read"),
  });

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      let func = async () =>
        await saveNotification.mutateAsync({
          id: values.id,
          comment: values.comment,
          is_incorrect: values.is_incorrect,
          is_read: values.is_read,
        });
      const response = await alertService.WaitForRequest(
        "Confirm",
        "Are you sure to save the changes? ",
        func
      );

      if (response) {
        onClose && onClose(true);
      }
    } catch (error: any) {}
  };

  const { result_info,user,updated_at } = notification;

  return (
    <Modal
      scrollable={false}
      show={open}
      onHide={() => onClose && onClose(false)}
      size="xl"
      aria-labelledby="contained-modal-title-center"
    >
      <AppForm
        validationSchema={validationSchema}
        initialValues={{
          id: notification.id,
          comment: notification.comment,
          is_incorrect: notification.is_incorrect,
          is_read: notification.is_read,
        }}
        onSubmit={handleSubmit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-center">
            <Col lg="4" className="">
              <div className="p-3">
                <h5>
                  <b>Structure Info</b>
                </h5>

                <hr />
                <Descriptor
                  label="Type"
                  value={
                    notification.type &&
                    renderNotificationType(notification.type)
                  }
                />
                <Descriptor label="Name" value={notification.struct?.name} />
                <Descriptor
                  label="Condition"
                  value={notification.condition?.name}
                />
                <Descriptor
                  label="Unit of measurement"
                  value={
                    notification.condition?.unit_of_measurement
                      ? notification.condition?.unit_of_measurement
                      : ""
                  }
                />
                <Descriptor
                  label="Start position"
                  value={notification.condition?.start_position}
                />
                <Descriptor
                  label="Target position "
                  value={notification.condition?.target_position}
                />

                <br />
                <h5>
                  <b>Threshold</b>
                </h5>
                <hr />
                <Descriptor
                  label="Period"
                  value={
                    moment(notification.result_info?.reference_period).format(
                      "L"
                    ) +
                    " ( " +
                    moment(
                      notification.result_info?.reference_period
                    ).fromNow() +
                    ")"
                  }
                />
                <Descriptor
                  label="Duration"
                  value={
                    notification &&
                    notification.result_info &&
                    checkLimits(
                      notification.result_info.duration,
                      notification.result_info.threshold_max,
                      notification.result_info.threshold_min
                    )
                  }
                />

                <Descriptor
                  label="Threshold max"
                  value={notification.result_info?.threshold_max?.toFixed(2)}
                />
                <Descriptor
                  label="Threshold min"
                  value={notification.result_info?.threshold_min?.toFixed(2)}
                />

                {user && (
                  <Descriptor
                    label="Review by"
                    value={
                      user.email
                        ? user.email + " (" + moment(updated_at).fromNow() + ")"
                        : "None"
                    }
                  />
                )}

                <div className="d-flex justify-content-between total font-weight-bold mt-4">
                  {notification &&
                    checkThresholds(
                      notification.result_info?.duration,
                      notification.result_info?.threshold_max,
                      notification.result_info?.threshold_min
                    )}
                </div>

                {/* {notification. && (
                  <Alert key={"not_reach"} variant="danger">
                    <MdCancel style={{ color: "danger", fontSize: "20px" }} />                    
                    The temperature had not reached the target temperature.
                  </Alert>
                )} */}
              </div>
            </Col>
            <Col className="justify-content-center">
              {graph && (
                <PlotValve
                  signal={graph.data}
                  name={graph.name}
                  unit_of_measurement={graph.unit_of_measurement}
                  highlight={{
                    time: result_info?.reference_period.toString(),
                    value: result_info?.duration,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Row>
              <Col sm={12} md={8}>
                <FormTextArea
                  name="comment"
                  label="Comment"
                  placeholder="Write a comment..."
                  rows={3}
                />
              </Col>
              <Col sm={12} md={2}>
                <FormSwitch name={"is_incorrect"} label={"Mark as anomly"} />
              </Col>
              <Col sm={12} md={2}>
                <FormSwitch name={"is_read"} label={"Mark as read"} />
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton
            label="Close"
            onClick={() => onClose && onClose(false)}
          />
          <FormSubmit
            title="Save changes"
            disabled={saveNotification.isLoading}
          />
        </Modal.Footer>
      </AppForm>
    </Modal>
  );
};
