import { FunctionComponent } from "react";
import { IconType } from "react-icons/lib";
import { createUseStyles } from "react-jss";

interface IconProps {
  size?: number;
  backgroundColor?: string;
  Icon: IconType;
  IconColor?: string;
}

const Icon: FunctionComponent<IconProps> = ({
  Icon,
  size = 30,
  backgroundColor,
  IconColor = "white",
}) => {
  const useStyles = createUseStyles({
    iconStyle: () => ({
      width: size,
      height: size,
      borderRadius: size / 2,
      backgroundColor: backgroundColor,
      opacity: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all ease 200ms",
      //color: IconColor,
      //   "&:hover": {
      //     transform: "scale(1.03)",
      //     boxShadow:
      //       "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
      //   },
    }),
  });

  const classes = useStyles();

  return (
    
      <div className={classes.iconStyle}>
        {<Icon color={IconColor} size={size * 0.5} />}
      </div>    
  );
};

export default Icon;
