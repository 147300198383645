import { AxiosError } from "axios";
import { PlotAnalyticsDemo } from "../../demo/analytics/plot-analytcis-demo";
import { ResponseWrapper } from "../../models/reponse";
import alertService from "../../utils/alertService";
import Http, { IHttp } from "../base/http-services";
import storage from "../base/storage";
const endpoint = "plot/statistic/struct"; //Only plot
const endpoint2 = "statistic/plot/struct"; // Plot + statistic table
const endpoint_compare = "statistic/plot/variable"; // plot + statistic table by category.

export interface IPlotAnalyticsModel {
  time_series: TimeSeries[];
  alarm: any;
}

export interface TimeSeries {
  struct_name: string;
  id: string;
  itemid: string;
  description: string;
  unit_of_measurement: string;
  statistic: Statistic;
  graph: Graph;
}

interface Graph {
  time?: string[];
  mean_values: number[];
  pctl_5_values: number[];
  pctl_95_values: number[];
  high_threshold: number[];
  low_threshold: number[];
  anomalies_values: number[];
  anomalies_time: string[];
  p_period_begin: PPoint;
  p_period_end: PPoint;
  p_thold_end: PPoint;
  p_thold_begin: PPoint;
  days_left: number;
  prediction: Prediction;
  previous_prediction: Prediction;
}

interface Prediction {
  x: string[];
  y: number[];
}
export interface Statistic {
  mean_ref: number;
  ref_compare: number;
  day_to_threshold: number;
  avg: number;
  std_dev: number;
  min: number;
  max: number;
  pctl_5: number;
  pctl_95: number;
  m: number;
  q: number;
}
interface PPoint {
  x: string;
  y: number;
}

export interface IStatisticVariableId {
  variable_id: number;
  struct_id: number;
}

class StructPlotStatistic {
  http: IHttp;
  constructor() {
    this.http = Http;
  }

  protected getPromise<T>(func: Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      func
        .then((response: any) => {
          resolve(response.data as ResponseWrapper<T>);
        })
        .catch((response: Error | AxiosError<any>) => {
          reject(response);
        });
    });
  }

  /**
   * Returns the statistic graph of the given structure.
   *
   *
   * @param struct_id struct_id - struct id
   * @param query_params - start date and end date required in format YYYY-MM-DD
   *
   * @returns {IPlotAnalyticsModel} the signals
   *
   * @FDB
   */

  getPlotAndStatistic(
    struct_id: number,
    query_params: Record<string, any> = {}
  ): Promise<ResponseWrapper<IPlotAnalyticsModel>> {
    let queryString = Object.keys(query_params)
      .map((key: any) => {
        if (query_params[key] !== undefined)
          return key + "=" + query_params[key];
      })
      .join("&");
    return this.getPromise<IPlotAnalyticsModel>(
      this.http.get(endpoint2 + "/" + struct_id + "?" + queryString)
    );
  }

  /**
   * Returns the statistic graph of the given ids.
   *
   *
   * @param variablesIds variablesIds - array of signals with struct id and variable id
   * @param start_date - start date of the variable in format YYYY-MM-DD
   * @param end_date end date of the variable in format YYYY-MM-DD
   *
   * @returns {IPlotAnalyticsModel} the signals
   *
   * @FDB
   */

  getPlotAndStatisticByIds = (
    variablesIds: IStatisticVariableId[],
    start_date: string,
    end_date: string
  ): Promise<ResponseWrapper<IPlotAnalyticsModel>> => {
    return this.getPromise<IPlotAnalyticsModel>(
      this.http.post(endpoint_compare + "/", {
        variables: variablesIds,
        start_date: start_date,
        end_date: end_date,
      })
    );
  };

  checkErrors(error: AxiosError<any>) {
    //only check if the server return error in the response.
    //403 Forbbiden -- Don't have permissions
    //400 Bad Request
    //
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.status !== 403 &&
      error.response.status < 500
    ) {
      let message = error.response.data.response.error;
      alertService.errorMessage(message);
    }
  }
}

const user = storage.getUser();
const instance =
  user && user.token_type == "demo"
    ? new PlotAnalyticsDemo()
    : new StructPlotStatistic();

export const statistic_plot_service = instance;
