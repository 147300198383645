import React, { useContext } from "react";
import PermissionContext from "./permissionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPaper } from "@fortawesome/free-solid-svg-icons";

type Props = {
  to: string | undefined;
  fallback?: JSX.Element | string;
  showFallback?: boolean;
};

const notAllowed = (
  <div className="container">
    <div className="row center-content-50">
      <div className="col">
        <FontAwesomeIcon icon={faHandPaper} size={"5x"} />
        <h4>Not Allowed </h4>
        You are not allowed to access this feature, please contact your
        administrator.
      </div>
    </div>
  </div>
);

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({
  to,
  showFallback = false,
  fallback,
  children,
}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useContext(PermissionContext);

  // If the user has that permission, render the children
  if (isAllowedTo(to === undefined ? "" : to)) {
    return <>{children}</>;
  }

  // Otherwise, render the fallback
  if (showFallback) {
    return <>{fallback ? fallback : notAllowed}</>;
  }
  return null;
};

export default Restricted;
