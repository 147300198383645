
import some from 'lodash/some';

export const FieldHasData = (array: any[], field:string) => {
    /**
     * Check if the field is null data on any of the items in the array
     * 
     * @param array
     */
    return some(array, (item)=>{
        return item[field] !== null;
    });
}