import { FunctionComponent } from "react";
import TopNavBar from "./top-nav";
import * as alertService from "../../utils/alertService";
import { useHistory } from "react-router-dom";
import storage from "../../services/base/storage";
import { Container } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { QueryKeys } from "queries/query_keys";
interface LayoutProps {}
/*
 
THIS COMPONENT IS IN USE ,

NOT USE THE HIGH ORDER COMPONENT "WithLayout"
 
 **/

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const cleanAll = () => {
    dispatch({ type: "USER_LOGOUT" });
    queryClient.removeQueries();
    queryClient.invalidateQueries();
    queryClient.clear();
  };
  const handleLogout = () => {
    alertService
      .Confirm("warning", "Are you sure?", "Are you sure exit ?")
      .then(async (result: any) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          cleanAll();
          const isDemo = storage.isDemo();
          storage.removeToken();
          if (isDemo) {
            window.location.reload();
            return;
          }
          history.push("/login");
        } else if (result.isDenied) {
          return false;
        }
      });
  };

  const useStyles = createUseStyles({
    wrapper: () => ({
      display: "flex",
      flexFlow: "row wrap",
      "&.wrapper > *": {
        flex: "1 100%",
        //backgroundColor: "black", //HERE TO CHANGE DARK MODE
        backgroundColor: "#e9ecef", //HERE TO CHANGE DARK MODE
        marginBottom: "1vh",
      },
    }),
  });

  const styles = useStyles();

  return (
    <div className={"wrapper " + styles.wrapper}>
      <TopNavBar onLogOut={handleLogout} />

      <Container
        fluid
        //style={{ minHeight: "100vh" }}
        //className="d-flex  flex-column justify-content-center"
      >
        {" "}
        {children}
        <br />
        <br />
        <br />
      </Container>
    </div>
  );
};

export default Layout;
