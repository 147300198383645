import { FunctionComponent } from "react";
import Restricted from "../../../../context/permission/restricted";
import Backdrop from "../../../common/backdrop/backdrop";
import { Card, Row, Button } from "react-bootstrap";
import GoBackNavigation from "../../../common/buttons/back-navigation";
import {
  MdAddCircle,
  MdDeleteOutline,
  MdModeEditOutline,
  MdRefresh,
} from "react-icons/md";
import Table from "../../../table/table";
import {
  useDeleteTemperatureCondition,
  useGetTemperatureCondition,
} from "../../../../queries/management/temperature.condition.hook";
import { permissions } from "../../../../services/permissions/permissions";
import { TableColumn } from "react-data-table-component";
import withLayout from "../../../../hoc/withLayout";
import { useHistory, Link } from "react-router-dom";
import { Routes } from "../../../../Routes";
import { ITemperatureCondition } from "../../../../services/temperature/temperature-condition-service";
import alertService from "../../../../utils/alertService";

interface TemperatureConditionProps {}

const TemperatureCondition: FunctionComponent<
  TemperatureConditionProps
> = () => {
  const history = useHistory();
  const { data, isLoading, refetch, isRefetching } =
    useGetTemperatureCondition();

  const deleteData = useDeleteTemperatureCondition();

  const handleDelete = async (temperature_condition: ITemperatureCondition) => {
    let func = () => deleteData.mutate({ id: temperature_condition.id });
    const response = alertService.WaitForRequest(
      "Confirm",
      "This operation can't be undo ",
      func
    );
    if ((await response) === true) {
      await alertService.Ok("Temperature condition deleted");
    }
  };

  const columns: TableColumn<ITemperatureCondition>[] = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : ""),
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : ""),
      sortable: true,
    },
    {
      name: "Start range",
      selector: (row) => row.start_lower_range + "-" + row.start_upper_range,
    },
    {
      name: "Start range",
      selector: (row) => row.target_lower_range + "-" + row.target_upper_range,
    },
    {
      name: "UoM",
      selector: (row) =>
        row.unit_of_measurement ? row.unit_of_measurement : "",
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Restricted to={permissions.management.temperature_condition.change}>
            <Button
              variant="outline-warning me-1"
              onClick={() => {
                history.push(
                  Routes.management_temperature_condition + "/" + row.id
                );
              }}
              size="sm"
            >
              <MdModeEditOutline />
            </Button>
          </Restricted>
          <Restricted to={permissions.management.temperature_condition.delete}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => handleDelete(row)}
            >
              <MdDeleteOutline />
            </Button>
          </Restricted>
        </>
      ),
      name: "Actions",
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  return (
    <>
      <Restricted
        to={permissions.management.temperature_condition.view}
        showFallback={true}
      >
        <Backdrop show={isLoading || isRefetching} />

        <Card className="p-3">
          <h4>
            <GoBackNavigation url="/management" />
            Temperatures conditions
          </h4>
          <hr />
          <Row>
            <div className="d-flex justify-content-start">
              <Restricted to={permissions.management.temperature_condition.add}>
                <Link
                  to={{
                    pathname: Routes.management_temperature_condition_new,
                  }}
                  className="btn mr-1 btn-sm"
                >
                  <MdAddCircle /> New
                </Link>
              </Restricted>
              <a
                onClick={async () => await refetch()}
                className="btn mr-1 btn-sm"
              >
                <MdRefresh /> Refresh
              </a>
            </div>
          </Row>

          <Table
            columns={columns}
            data={data?.response ? data.response : []}
            pagination={true}
          />
        </Card>
      </Restricted>
    </>
  );
};

export default TemperatureCondition;
